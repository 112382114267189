import { Alert, CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Navigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { NoPos } from './NoPos/NoPos';
import { useBusinessIsNoPos } from '../business/hooks/useBusinessIsNoPos';
import { useBusinessId } from '../business/hooks/useBusinessId';


function StripeSetupDone() {
  const [loading, setLoading] = useState(true);
  const [stripeRedirectionError, setStripeRedirectionError] = useState(false);
  const [stripeSetupActionDone, setStripeSetupActionDone] = useState(false);

  const location = useLocation();
  const businessId = useBusinessId();
  const queryClient = useQueryClient();

  const venueIsNoPos = useBusinessIsNoPos();

  useEffect(() => {
    setStripeRedirectionError(!businessId);

    if (businessId) {
      setStripeSetupActionDone(true);
      setLoading(false);
    }
  }, [location, queryClient, businessId]);

  if (venueIsNoPos) return <NoPos />;

  if (stripeRedirectionError) {
    return (
      <Alert severity="error">
        <FormattedMessage id="stripe.error.redirect" />
      </Alert>
    );
  }

  if (loading) {
    return (
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <CircularProgress />
        </Grid>
        <Grid item>
          <FormattedMessage id="stripe.info.configuration" />
        </Grid>
      </Grid>
    );
  }

  if (stripeSetupActionDone) return <Navigate to="/" />;

  return <></>;
}

export default StripeSetupDone;
