import { companyPositionNeeded, powerOfAttorneyNeeded, RepresentativeDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';

export const isAuthorisedSignatoryComplete = ({ representative, containsErrors }: {
  representative: RepresentativeDraft | undefined,
  containsErrors: boolean
}): boolean => {
  const isUBO = !!representative?.roles?.ubo;
  const needsCompanyPosition = companyPositionNeeded(representative);
  const needsPowerOfAttorney = powerOfAttorneyNeeded(representative);
  const isAddressComplete = !!representative?.address?.city && !!representative.address?.country && !!representative.address?.addressLine1 && !!representative.address?.zip;
  const isUboExtraFieldsComplete = !isUBO || !!representative.ownershipPercentage;
  const isCompositionPositionFilled = !needsCompanyPosition || !!representative?.companyPosition;
  const hasPowerOfAttorneyDocumentIfNotRequired = !needsPowerOfAttorney || !!representative?.certifiedAuthorisedSignatoryDocument;

  const isDocumentIdComplete = !!representative?.identificationFrontDocument &&
    (!!representative?.identificationFrontDocument || representative?.identificationType === 'passport');
  if (containsErrors || !representative) return false;
  return !!representative!.firstName &&
    !!representative.lastName &&
    !!representative.dateOfBirth &&
    !!representative.countryOfBirth &&
    !!representative.identificationType &&
    !!representative?.email &&
    isDocumentIdComplete &&
    isAddressComplete &&
    hasPowerOfAttorneyDocumentIfNotRequired &&
    isUboExtraFieldsComplete &&
    isCompositionPositionFilled;
};
