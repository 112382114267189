import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@sundayapp/b2b-react-component-library';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { PAGE_CONTENT_WIDTH } from 'src/app/pages/constants';
import { useVenueRepository } from '../../../venues/hook';
import { VenueDetails } from '../../../venues/types';
import { PromoCodeAmount } from '../../types';
import { usePushNotification } from '../../../common/components/Notifications.hook';
import { useCreatePromoCodeAmount } from '../../hooks';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const PageContainer = styled.div`
  max-width: ${PAGE_CONTENT_WIDTH}px;
`;

export const Vouchers = () => {
  const business = useCurrentBusinessOrThrow();
  const venueRepository = useVenueRepository();
  const [venue, setVenue] = useState<VenueDetails>();

  const [voucherCodePrefix, setVoucherCodePrefix] = useState<string>('');
  const [voucherCodeStart, setVoucherCodeStart] = useState<string>('');
  const [voucherCodeEnd, setVoucherCodeEnd] = useState<string>('');
  const [voucherAmount, setVoucherAmount] = useState<string>('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState('');

  const [, createPromoCode] = useCreatePromoCodeAmount();
  const [, pushNotification] = usePushNotification();
  const promoCodes = useRef<PromoCodeAmount[]>([]);

  useEffect(() => {
    if (!business.oapEnrollment) return;
    venueRepository.getVenue(business.oapEnrollment.id).then(setVenue);
  }, [venueRepository, business]);

  const validate = (): string => {
    setIsValid(false);
    if (voucherCodePrefix.trim().length === 0) return 'Prefix mandatory';
    if (voucherAmount.trim().length === 0 || parseInt(voucherAmount.trim(), 10) === 0) return 'Amount mandatory';

    if (voucherCodeStart.trim().length > 0 && voucherCodeEnd.trim().length > 0 && parseInt(voucherCodeStart.trim(), 10) > parseInt(voucherCodeEnd.trim(), 10)) {
      return 'Code start should be greater than code end';
    }
    setIsValid(true);
    return '';
  };

  useEffect(() => {
    const msg = validate();
    setMessage(msg);
  }, [voucherCodePrefix, voucherAmount, voucherCodeStart, voucherCodeEnd]);

  if (!business.oapEnrollment) {
    return <>Order and pay not enabled</>;
  }
  const enrollmentId = business.oapEnrollment.id;

  if (!venue) {
    return <Loader size="large" />;
  }

  const handleClose = () => {
    setConfirmationOpen(false);
  };
  const generateVouchers = async () => {
    if (voucherCodeEnd.trim().length === 0) {
      const promoCode: PromoCodeAmount = {
        enrollmentId: enrollmentId,
        code: voucherCodePrefix + voucherCodeStart,
        amount: parseFloat(voucherAmount) * 100000,
        currency: 'EUR',
        availableFromEpochMilli: Date.parse('2024-07-19T00:00:00+0200'),
        availableUntilEpochMilli: Date.parse('2024-08-31T00:00:00+0200'),
        availabilities: [
          { weekDay: 1, startTime: '00:00', endTime: '23:59' },
          { weekDay: 2, startTime: '00:00', endTime: '23:59' },
          { weekDay: 3, startTime: '00:00', endTime: '23:59' },
          { weekDay: 4, startTime: '00:00', endTime: '23:59' },
          { weekDay: 5, startTime: '00:00', endTime: '23:59' },
          { weekDay: 6, startTime: '00:00', endTime: '23:59' },
          { weekDay: 7, startTime: '00:00', endTime: '23:59' },
        ],
        excludedBoxIds: [],
        maxUsage: 1,
      };
      promoCodes.current = [promoCode];
    } else {
      promoCodes.current = [];
      const start = parseInt(voucherCodeStart.trim(), 10);
      const end = parseInt(voucherCodeEnd.trim(), 10);
      // eslint-disable-next-line no-plusplus
      for (let i = start; i <= end; i++) {
        promoCodes.current.push({
          enrollmentId: enrollmentId,
          code: voucherCodePrefix + i,
          amount: parseInt(voucherAmount, 10) * 100000,
          currency: 'EUR',
          availableFromEpochMilli: Date.parse('2024-07-19T00:00:00+0200'),
          availableUntilEpochMilli: Date.parse('2024-08-31T00:00:00+0200'),
          availabilities: [
            { weekDay: 1, startTime: '00:00', endTime: '23:59' },
            { weekDay: 2, startTime: '00:00', endTime: '23:59' },
            { weekDay: 3, startTime: '00:00', endTime: '23:59' },
            { weekDay: 4, startTime: '00:00', endTime: '23:59' },
            { weekDay: 5, startTime: '00:00', endTime: '23:59' },
            { weekDay: 6, startTime: '00:00', endTime: '23:59' },
            { weekDay: 7, startTime: '00:00', endTime: '23:59' },
          ],
          excludedBoxIds: [],
          maxUsage: 1,
        });
      }
    }
    setConfirmationOpen(true);
  };

  const createVoucher = async () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const p of promoCodes.current) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await createPromoCode(p, enrollmentId);
        console.log('creation success', p.code);
        // eslint-disable-next-line no-await-in-loop
        await pushNotification(`voucher code ${p.code} has been successfully created`);
      } catch {
        console.log('creation failed', p.code);
        // eslint-disable-next-line no-await-in-loop
        await pushNotification(
          `an error occurred while creating the voucher code (Maybe already existing) ${p.code}`,
        );
      } finally {
        handleClose();
      }
    }
  };

  return (
    <PageContainer>
      <Box component="div" display="flex" flexDirection="column" gap="16px">
        <Box component="div" display="flex" flexDirection="row" gap="16px">
          <TextField
            variant="outlined"
            size="medium"
            label="prefix"
            sx={{ width: '200px' }}
            value={voucherCodePrefix}
            onChange={(e) => setVoucherCodePrefix(e.target.value.toUpperCase())}
          />
          <TextField
            variant="outlined"
            size="medium"
            label="start range"
            sx={{ width: '200px' }}
            value={voucherCodeStart}
            onChange={(e) => setVoucherCodeStart(e.target.value)}
          />

          <TextField
            variant="outlined"
            size="medium"
            label="end range (leave empty for one voucher)"
            sx={{ width: '200px' }}
            value={voucherCodeEnd}
            onChange={(e) => setVoucherCodeEnd(e.target.value)}
          />
        </Box>

        <TextField
          variant="outlined"
          size="medium"
          label="Montant"
          sx={{ width: 250 }}
          type="number"
          value={voucherAmount}
          onChange={(e) => setVoucherAmount(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">{venue.currency}</InputAdornment>,
          }}
        />
        {message}
        <Button variant="contained" onClick={generateVouchers} disabled={!isValid}>
          Créer le/les voucher(s)
        </Button>
      </Box>

      <Dialog
        open={confirmationOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          Generate
          {' '}
          {promoCodes.current.length}
          {' '}
          vouchers ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to generate those vouchers for an amount of
            {' '}
            {voucherAmount}
            {' '}
            {venue.currency}
            {' '}
            ?
            <ul>
              {promoCodes.current.map((p) => <li key={p.code}>{p.code}</li>)}
            </ul>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage
              id="menus.product.modal.cancel"
              defaultMessage="cancel"
            />
          </Button>
          <Button onClick={createVoucher} autoFocus variant="contained" color="error">
            Generate vouchers
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};
