import { createContext, useContext } from 'react';

export type NotificationToAddType = {
  variant: 'error' | 'success' | 'info' | 'warning';
  text: string;
};

interface SnackBarContextInterface {
  addNotification: (notification: NotificationToAddType) => void;
}

export const SnackBarContext = createContext<SnackBarContextInterface>({
  addNotification: () => {},
});

export const useSnackbar = () => useContext(SnackBarContext);
