import { Button, InputTime, Modal } from '@sundayapp/b2b-react-component-library';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import moment from 'moment';
import { DateRange } from '../domain/DateRange';
import { ShiftHour } from '../domain/ShiftHour';
import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material';

type ConfigureShiftHoursModalProps = {
  isOpened: boolean,
  onClose: () => void,
  onSave: (lunchStart: string, lunchEnd: string, dinnerStart: string, dinnerEnd: string) => void
  accountingRanges: {
    dinner: DateRange,
    lunch: DateRange
  }
};

const InputTimeLayout = styled.div`
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  width: 100%;
`;

const dateToDisplayedTime = (date: Date): string => moment(date).format('HH:mm');

export const ConfigureShiftHoursModal = ({
  isOpened, onClose, onSave, accountingRanges,
}: ConfigureShiftHoursModalProps) => {
  const { formatMessage } = useIntl();
  const [lunchStart, setLunchStart] = useState(dateToDisplayedTime(accountingRanges.lunch.startDate));
  const [lunchEnd, setLunchEnd] = useState(dateToDisplayedTime(accountingRanges.lunch.endDate));
  const [dinnerStart, setDinnerStart] = useState(dateToDisplayedTime(accountingRanges.dinner.startDate));
  const [dinnerEnd, setDinnerEnd] = useState(dateToDisplayedTime(accountingRanges.dinner.endDate));
  const theme = useTheme();
  const lunchHoursHaveErrors = ShiftHour.fromHourAndMinute(lunchStart).toMoment().isAfter(ShiftHour.fromHourAndMinute(lunchEnd).toMoment());
  const isFormValid = !lunchHoursHaveErrors && lunchStart && lunchEnd && dinnerStart && dinnerEnd;

  return (
    <Modal
      title={formatMessage({ id: 'accounting.what_are_your_opening_hours' })}
      isOpened={isOpened}
      onClose={onClose}
      cancelButtonMessage={formatMessage({ id: 'accounting.cancel' })}
      maxWidth="407px"
    >
      <Stack gap={2}>
        <Typography color={theme.palette.text.disabled}>
          <FormattedMessage id="accounting.specify_opening_hours" />
        </Typography>
        <Card>
          <CardContent>
            <Typography variant={'button'}>
              <FormattedMessage id="accounting.lunch" />
            </Typography>
            <InputTimeLayout>
              <InputTime
                value={lunchStart}
                label={formatMessage({ id: 'accounting.lunch_start' })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setLunchStart(e.target.value)}
                size="medium"
              />
              <InputTime
                value={lunchEnd}
                label={formatMessage({ id: 'accounting.lunch_end' })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setLunchEnd(e.target.value)}
                size="medium"
              />
            </InputTimeLayout>

            {lunchHoursHaveErrors && <Typography variant="subtitle2" color={'error'}><FormattedMessage
              id="accounting.date_validation" /></Typography>}
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant={'button'}>
              <FormattedMessage id="accounting.dinner" />
            </Typography>
            <InputTimeLayout>
              <InputTime
                value={dinnerStart}
                label={formatMessage({ id: 'accounting.dinner_start' })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setDinnerStart(e.target.value)}
                size="medium"
              />
              <InputTime
                value={dinnerEnd}
                label={formatMessage({ id: 'accounting.dinner_end' })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setDinnerEnd(e.target.value)}
                size="medium"
              />
            </InputTimeLayout>
          </CardContent>
        </Card>
        <Button
          disabled={!isFormValid}
          variant="primary"
          size="medium"
          fullWidth
          onClick={() => onSave(lunchStart, lunchEnd, dinnerStart, dinnerEnd)}
        >
          {formatMessage({ id: 'accounting.save' })}
        </Button>
      </Stack>
    </Modal>
  )
  ;
};
