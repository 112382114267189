import {
  CurrencyCode,
  differenceMoney,
  divideMoney,
  divideMoneys,
  Money,
  money,
  multiplyMoney,
  sumMoneys,
} from '@sundayapp/web-money';
import { OrderForAccountingPort } from '../domain/OrderForAccountingPort';
import { AccountingRepository } from '../infrastructure/AccountingRepository';
import { ReportingDigest } from '../domain/ReportingDigest';
import { OrderForAccounting } from '../domain/OrderForAccounting';
import { PaymentOverSundayForAccounting } from '../domain/PaymentsOverSunday';
import { computeInconsistency } from './ComputeInconsistencyUseCase';
import { BusinessId } from 'src/business/domain/Business';

export class ReportingDigestUseCaseForUsa {
  private noMoney: Money = money(0, CurrencyCode.UNKNOWN);

  constructor(
    private currency: CurrencyCode,
    private orderForAccountingPort: OrderForAccountingPort,
    private accountingRepository: AccountingRepository,
  ) {
  }

  async handle(businessId: BusinessId, startDate: Date, endDate: Date): Promise<ReportingDigest> {
    const startDateMinus6Hours = new Date(startDate.getTime() - 1000 * 60 * 60 * 6);
    const orders = await this.orderForAccountingPort.findOrders(
      businessId,
      startDateMinus6Hours,
      endDate,
      this.currency,
    );

    const summary = await this.accountingRepository.summaryOnAPeriod(businessId, startDate, endDate);
    const reportingDigest = new ReportingDigest();
    Object.entries(summary.detailsBySource).forEach(([ provider, details ]) => {
      const computedValues = this.computeNetAndSalesTaxesAndServiceCharge(orders, details.paymentIds);
      reportingDigest.addDetails(
        provider,
        computedValues.gross,
        details.tipRevenue,
        computedValues.net,
        computeInconsistency(orders, details.paymentIds, this.currency),
        computedValues.serviceCharge ?? 0,
      );
    });
    reportingDigest.additionalRevenue = summary.additionalRevenue;
    return reportingDigest;
  }

  private computeNetAndSalesTaxesAndServiceCharge(
    orders: OrderForAccounting[],
    paymentIds: string[],
  ): { gross: Money; net: Money; salesTaxes: Money; serviceCharge: Money } {
    if (orders) {
      const accountingDigestValues = orders.flatMap((order) =>
        order.paymentsOverSunday
          .filter((payment) => paymentIds.includes(payment.id))
          .map((payment) => {
            if (order.orderAmount.amount === 0) {
              // orderAmount === 0 when there is a walkout
              return {
                gross: money(0, this.currency),
                net: money(0, this.currency),
                salesTaxes: money(0, this.currency),
                serviceCharge: money(0, this.currency),
              };
            }

            return ReportingDigestUseCaseForUsa.computeAccountingValues(
              order.orderAmount,
              new PaymentOverSundayForAccounting(payment).paidAmountWithDeducedRefunds,
              order.salesTaxAmount,
              order.serviceCharge,
            );
          }));

      return accountingDigestValues.reduce(
        (acc, curr) => ({
          gross: sumMoneys(acc.gross, curr.gross),
          net: sumMoneys(acc.net, curr.net),
          salesTaxes: sumMoneys(acc.salesTaxes, curr.salesTaxes),
          serviceCharge: sumMoneys(acc.serviceCharge, curr.serviceCharge),
        }),
        {
          gross: money(0, this.currency),
          net: money(0, this.currency),
          salesTaxes: money(0, this.currency),
          serviceCharge: money(0, this.currency),
        },
      );
    }

    return {
      gross: this.noMoney,
      net: this.noMoney,
      salesTaxes: this.noMoney,
      serviceCharge: this.noMoney,
    };
  }

  public static computeAccountingValues(
    orderAmount: Money,
    paymentAmount: Money,
    totalSalesTaxAmount: Money,
    totalServiceChargeAmount: Money,
  ): { gross: Money; net: Money; salesTaxes: Money; serviceCharge: Money } {
    const grossRevenueForAllOrder = differenceMoney(orderAmount, totalServiceChargeAmount);
    const netRevenueForAllOrder = differenceMoney(grossRevenueForAllOrder, totalSalesTaxAmount);
    const salesTaxRate = totalSalesTaxAmount.amount > 0 ? divideMoneys(netRevenueForAllOrder, totalSalesTaxAmount) : 0;

    /*
    coef = payment amount / total order amount
    service charge paid = total service charge * coef
    gross revenue paid = payment amount - service charge paid
    net revenue paid  = (gross revenue paid * sales tax rate) / (1 + sales tax rate)
    paid sales tax = gross revenue paid - net revenue paid
    */

    const coef = divideMoneys(paymentAmount, orderAmount);
    const paidServiceCharge = multiplyMoney(totalServiceChargeAmount, coef.value / 10000);
    const paidGrossRevenue = differenceMoney(paymentAmount, paidServiceCharge);
    const paidNetRevenue = salesTaxRate && salesTaxRate.value > 0
      ? divideMoney(multiplyMoney(paidGrossRevenue, salesTaxRate.value / 10000), 1 + salesTaxRate.value / 10000)
      : paidGrossRevenue;
    const paidSalesTax = differenceMoney(paidGrossRevenue, paidNetRevenue);

    return {
      gross: paidGrossRevenue,
      net: paidNetRevenue,
      salesTaxes: paidSalesTax,
      serviceCharge: paidServiceCharge,
    };
  }
}
