import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { PicturePicker } from 'src/ordering/common/components/PicturePicker/PicturePicker';
import { Menu } from '../../../domain/Menu';
import { useUpdateMenuPictureMutation } from '../../../mutations/menu/useMenuMutation';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type MenuImagePickerProps = {
  menu: Menu;
};

const ImageCard = styled(Card)`
  min-width: fit-content;
`;

const Title = styled('div')`
  margin-bottom: 24px;
`;

const PicturePickerWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const MenuImagePicker = ({ menu }: MenuImagePickerProps) => {
  const business = useCurrentBusinessOrThrow();

  const updateMenuPicture = useUpdateMenuPictureMutation(business.id, menu.id);
  const onImageChanged = (canvasBase64: string) => {
    updateMenuPicture.mutateAsync(canvasBase64);
  };


  const pictureWidth = business.patEnrollment ? 480 : 288;
  const pictureHeight = business.patEnrollment ? 144 : 288;

  return (
    <ImageCard>
      <CardContent>
        <Title>
          <Typography variant="h6" component="div">
            <FormattedMessage id="menus.settings.image.label" defaultMessage="header image" />
          </Typography>
        </Title>

        <PicturePickerWrapper>
          <PicturePicker
            imageUrl={menu.imageUrl}
            updating={false}
            onImageChanged={onImageChanged}
            width={pictureWidth}
            height={pictureHeight}
          />
        </PicturePickerWrapper>
      </CardContent>
    </ImageCard>
  );
};
