import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { NotificationToAddType } from './SnackBarContext';

export const SnackBarNotification = ({
  notification,
  onClose,
}: {
  notification: NotificationToAddType | undefined;
  onClose: () => void;
}) => (
  <>
    {notification && (
      <Snackbar
        open
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={onClose}
      >
        <Alert severity={notification.variant} sx={{ width: '100%' }} variant="filled">
          {notification.text}
        </Alert>
      </Snackbar>
    )}
  </>
);
