import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import {
  ControllingCompanies,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/ControllingCompanies/ControllingCompanies';
import {
  AuthorisedSignatory,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/AuthorisedSignatory/AuthorisedSignatory';
import {
  LegalRepresentatives,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/LegalRepresentatives/LegalRepresentatives';
import { UBOs } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/UBO/UBOs';
import { Controls } from './IndividualRepresentatives/Control/Controls';
import { Directors } from './IndividualRepresentatives/Director/Directors';


export const KeyBusinessStakeholders = ({ businessCountryCode }: { businessCountryCode: string }) => {
  return <Stack direction="column" spacing={3}>
    <Typography variant={'h5'}>
      3 · <FormattedMessage id={'settings.payments.checkout.onboarding.keyStakeholders'} />
    </Typography>
    <Stack spacing={3}>
      <AuthorisedSignatory />
      <UBOs />
      {businessCountryCode == 'FR' && <LegalRepresentatives />}
      {businessCountryCode == 'GB' && <Controls />}
      {businessCountryCode == 'GB' && <Directors />}
      <ControllingCompanies />
    </Stack>
  </Stack>;
};
