import { styled } from '@mui/material';

type BusinessSelectionIconProps = {
  imageUrl: string | undefined;
  name: string;
  size?: number;
};

type BusinessImgProps = {
  size: number;
};
const BusinessImg = styled('img')<BusinessImgProps>`
  object-fit: cover;
  min-width: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0, 0.1);
`;

type NoImageProps = {
  hue: number;
  size: number;
};

const NoImage = styled('div')<NoImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0, 0.1);
  background-color: hsl(${(props) => props.hue} 80% 50%);
`;

const computeHueFromName = (name: string): number => {
  let value = 0;
  for (let i = 0, sz = name.length; i < sz; i += 1) {
    value += name.charCodeAt(i);
  }
  return value % 360;
};

export const BusinessSelectionIcon = ({ imageUrl, name, size = 24 }: BusinessSelectionIconProps) => {
  if (!imageUrl) {
    return (
      <NoImage size={size} hue={computeHueFromName(name)}>
        {name[0]}
      </NoImage>
    );
  }

  return <BusinessImg size={size} src={imageUrl} />;
};

BusinessSelectionIcon.defaultProps = {
  size: 24,
};
