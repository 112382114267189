import React from 'react';
import { useFormContext } from 'react-hook-form';
import Divider from '@mui/material/Divider';
import {
  ProofOfIdentity,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/ProofOfIdentity/ProofOfIdentity';
import {
  CheckoutOnboardingInformationDraft,
  RepresentativeDraft,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Representative } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/Representative';
import {
  RepresentativeDetails,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/RepresentativeDetails';
import { ControlPersonalInfo } from './ControlPersonalInfo';
import { isCompleteControl } from './isCompleteControl';

export const EditControl = ({
  representative,
  index,
  onDelete,
}: {
  representative: RepresentativeDraft,
  onDelete: () => void,
  index: number
}) => {
  const { formState: { errors } } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[index];
  const isAuthorisedSignatory = !!representative?.roles?.authorisedSignatory;
  const isUBO = !!representative?.roles?.ubo;
  const isReadOnly = isAuthorisedSignatory || isUBO;
  const intl = useIntl();
  const isComplete = isCompleteControl({ representative, containsErrors: !!error });
  const title = representative.lastName && representative.firstName ? `${representative.lastName} ${representative.firstName}` :
    intl.formatMessage({ id: 'settings.payments.checkout.onboarding.individualRepresentatives.control.details' });

  return <Representative isComplete={isComplete} title={title} onDelete={onDelete} isReadOnly={isReadOnly}>
      <RepresentativeDetails>
        <ControlPersonalInfo representativeIndex={index} />
        <Divider style={{ margin: '32px 0' }} />
        <ProofOfIdentity representativeIndex={index} />
      </RepresentativeDetails>
  </Representative>;
};
