import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { LoyaltyRepository, PaytronixConfigureBusinessRequest } from '../infrastructure/LoyaltyRepository';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';

export const useSavePaytronixConfiguration = (businessId: BusinessId) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();
  const repository = new LoyaltyRepository(configuration.loyaltyApiBaseUrl);

  return useMutation({
    mutationFn: (request: PaytronixConfigureBusinessRequest) => repository.savePaytronixBusinessConfiguration(businessId, request),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.loyalty.GET_BUSINESS_LOYALTY_REFERENCE, { businessId }],
      }),
  });
};
