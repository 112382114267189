import { Box, TableCell, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import styled from 'styled-components';
import { CardBrandIcon } from '../components/CardBrandIcon';
import { EmptyTableCellValue } from '../components/EmptyTableCellValue';
import { PaymentActions } from '../components/PaymentActions';
import PaymentAmount from '../components/PaymentAmount';
import PaymentPosStatusBadge from '../components/PaymentPosStatusBadge';
import PaymentStatusBadge from '../components/PaymentStatusBadge';
import { ProviderIcon } from '../components/ProviderIcon';
import TotalPaidAmount from '../components/TotalPaidAmount';
import {
  isPayAtTable,
  isTabRelated,
  Payment,
  PaymentForOrdering,
  PaymentForPat,
  SpecificPayment,
} from '../domain/Payment';
import { paymentProductOrigins } from '../domain/PaymentProductOrigin';
import { BusinessDate } from 'src/components/date/BusinessDate';
import React from 'react';

const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  font-size: 14px;
`;

type Props = {
  specificPayment: SpecificPayment;
  withExtraColumn: boolean;
  hasAccessToRefund: boolean;
  releasePaymentAuthorization: (payment: Payment) => Promise<void>;
  setRefundDialogIsOpen: (isOpen: boolean) => void;
  setSelectedPayment: (selectedPayment: SpecificPayment) => void;
  isGrouped: boolean;
  tpeVenue: boolean;
  isDigitalFeeDisplayed: boolean;
};

function computeCardBrand(payment: Payment) {
  if (payment.cardBrand === 'unknown') {
    return payment.metadata.scheme || payment.cardBrand;
  }
  return payment.cardBrand;
}

function computeReference(payment: Payment) {
  if (payment.paymentMethodRef) {
    return payment.paymentMethodRef;
  }
  // Legit typo on UNKOWN instead of UNKNOWN
  if (payment.metadata.scheme && payment.metadata.scheme !== 'UNKOWN') {
    return payment.metadata.scheme;
  }
  return '';
}

const computePaymentMethod = (payment: Payment) => {
  const brand = computeCardBrand(payment);
  const reference = computeReference(payment);
  return (
    <PaymentMethodWrapper>
      <ProviderIcon methodType={payment.paymentMethodType} />
      <CardBrandIcon brand={brand} methodType={payment.paymentMethodType} />
      {reference}
    </PaymentMethodWrapper>
  );
};

const displayOrderId = (payment: PaymentForOrdering) =>
  (payment.orderDisplayId ? payment.orderDisplayId : payment.orderDisplayId);
const PayAtTableIdCell = ({ paymentForPat }: { paymentForPat: PaymentForPat }) => {
  if (isTabRelated(paymentForPat)) {
    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="body1">{paymentForPat.userName ?? paymentForPat.tabDisplayId}</Typography>
        <Typography variant="body1" color={themeV5.palette.text.secondary}>
          {paymentForPat.userName ? paymentForPat.tabDisplayId : ''}
        </Typography>
        <Typography variant="body1" color={themeV5.palette.text.secondary}>
          {paymentForPat.tableNumber !== 'N/A' ? paymentForPat.tableNumber : ''}
        </Typography>
        <Typography variant="body2" color={themeV5.palette.text.secondary}>
          <FormattedMessage id="payments.table.source.tab" />
        </Typography>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body1">{paymentForPat.tableNumber}</Typography>
      <Typography variant="body2" color={themeV5.palette.text.secondary}>
        <FormattedMessage id="payments.table.source.qr_code" />
      </Typography>
    </Box>
  );
};
const IdCell = ({ specificPayment }: { specificPayment: SpecificPayment }) => {
  if (isPayAtTable(specificPayment)) {
    return <PayAtTableIdCell paymentForPat={specificPayment} />;
  }

  if (specificPayment.type === 'pdq') {
    return <Box display="flex" flexDirection="column">
      <Typography variant="body1">{specificPayment.tableNumber}</Typography>
      <Typography variant="body2" color={themeV5.palette.text.secondary}>
        <FormattedMessage id="payments.table.source.payment_terminal" />
      </Typography>
      <Typography variant="body2" color={themeV5.palette.text.secondary}>
        {specificPayment.payment.serialNumber}
      </Typography>
    </Box>;
  }

  if (specificPayment.type === 'ordering') {
    return <Box display="flex" flexDirection="column">
      <Typography variant="body1">{displayOrderId(specificPayment as PaymentForOrdering)}</Typography>
      <Typography variant="body2" color={themeV5.palette.text.secondary}>
        <FormattedMessage id="payments.table.orderId" />
      </Typography>
    </Box>;
  }

  if (specificPayment.payment.metadata.origin === paymentProductOrigins.PAYMENT_LINK) {
    return <Box display="flex" flexDirection="column">
      <Typography variant="body2" color={themeV5.palette.text.secondary}>
        <FormattedMessage id="payments.table.source.payment_link" />
      </Typography>
    </Box>;
  }

  return <EmptyTableCellValue />;
};

const PaymentDateTime = ({ specificPayment }: { specificPayment: SpecificPayment }) => {
  return (<Box display="flex" flexDirection="column">
      <Typography variant="body1">
        <BusinessDate timestampMillis={specificPayment.payment.timestamp} format={'LT'} />
      </Typography>
      <Typography variant="body2" color={themeV5.palette.text.secondary}>
        <BusinessDate timestampMillis={specificPayment.payment.timestamp} format={'L'} tooltip={false} />
      </Typography>
    </Box>);
};

export const PaymentRow = ({
  specificPayment,
  withExtraColumn,
  hasAccessToRefund,
  releasePaymentAuthorization,
  setRefundDialogIsOpen,
  setSelectedPayment,
  isGrouped,
  tpeVenue,
  isDigitalFeeDisplayed,
}: Props) => {
  const user = useAuthenticatedUserOrThrow();
  return (
    <>
      {withExtraColumn && <TableCell />}
      <TableCell align="left">
        <PaymentDateTime specificPayment={specificPayment} />
      </TableCell>

      <TableCell align="left">
        <IdCell specificPayment={specificPayment} />
      </TableCell>

      {tpeVenue && (
        <TableCell align="left">{specificPayment.payment.waiterName ?? <EmptyTableCellValue />}</TableCell>)}

      <TableCell align="left">{computePaymentMethod(specificPayment.payment)}</TableCell>
      <TableCell align="left">
        <PaymentAmount amount={specificPayment.payment.computeTips()} />
      </TableCell>
      <TableCell align="left">
        <PaymentAmount amount={specificPayment.payment.computeSubtotal()} />
      </TableCell>
      {isDigitalFeeDisplayed && (
        <TableCell align="left">
          <PaymentAmount amount={specificPayment.payment.computeUserSundayPaymentFee()} />
        </TableCell>
      )}
      <TableCell align="left">
        <TotalPaidAmount payment={specificPayment.payment} />
      </TableCell>
      <TableCell align="left">
        <PaymentStatusBadge specificPayment={specificPayment} />
      </TableCell>
      <TableCell>
        {'pay-at-table' === specificPayment.type
          ? <PaymentPosStatusBadge payment={specificPayment as PaymentForPat} />
          : <EmptyTableCellValue />}
      </TableCell>

      <TableCell align="left">
        <PaymentActions
          specificPayment={specificPayment}
          hasAccessToRefund={hasAccessToRefund}
          setRefundDialogIsOpen={setRefundDialogIsOpen}
          setSelectedPayment={setSelectedPayment}
          user={user}
          releasePaymentAuthorization={releasePaymentAuthorization}
          isGrouped={isGrouped}
        />
      </TableCell>
    </>
  );
};
