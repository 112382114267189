// eslint-disable-next-line @typescript-eslint/no-redeclare
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Business } from 'src/business/domain/Business';
import { Box, Button, Card, CardContent, Chip, CircularProgress, Typography } from '@mui/material';
import { useGetChargebeePaymentMethodStatus } from 'src/payments/queries/getChargebeePaymentMethodStatus';
import {
  chargebeePaymentMethodStatus,
  ChargebeePaymentMethodStatusType,
} from 'src/payments/infrastructure/BookkeepingRepositoryHttp';
import { IntlShape } from 'react-intl/src/types';
import {
  useCreateChargebeePortalSessionMutation,
} from 'src/payments/mutations/useCreateChargebeePortalSessionMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { venueSettingsPaymentsPath } from 'src/app/navigation/pathHelpers';
import { ampli } from 'src/ampli';

interface ChargebeePanelProps {
  business: Business;
}

const getStatusChip = (status: ChargebeePaymentMethodStatusType | undefined, intl: IntlShape): ReactNode => {
  switch (status) {
    case chargebeePaymentMethodStatus.VALID:
      return <Chip label={intl.formatMessage({ id: 'settings.payments.sunday_subscriptions.status.valid' })}
                   color="success" />;
    case chargebeePaymentMethodStatus.EXPIRED:
      return <Chip label={intl.formatMessage({ id: 'settings.payments.sunday_subscriptions.status.expired' })}
                   color="error" />;
    case chargebeePaymentMethodStatus.EXPIRING:
      return <Chip label={intl.formatMessage({ id: 'settings.payments.sunday_subscriptions.status.expiring' })}
                   color="warning" />;
    case chargebeePaymentMethodStatus.INVALID:
      return <Chip label={intl.formatMessage({ id: 'settings.payments.sunday_subscriptions.status.invalid' })}
                   color="error" />;
    case chargebeePaymentMethodStatus.NO_PAYMENT_METHOD:
      return <Chip label={intl.formatMessage({ id: 'settings.payments.sunday_subscriptions.status.no_payment_method' })}
                   color="error" />;
    case chargebeePaymentMethodStatus.PENDING_VERIFICATION:
      return <Chip
        label={intl.formatMessage({ id: 'settings.payments.sunday_subscriptions.status.pending_verification' })}
        color="warning" />;
    case chargebeePaymentMethodStatus.UNKNOWN:
      return <Chip label={intl.formatMessage({ id: 'settings.payments.sunday_subscriptions.status.unknown' })}
                   color="warning" />;
  }
};


export function ChargebeePanel({ business }: ChargebeePanelProps) {
  const intl = useIntl();
  const { data: paymentMethodStatus } = useGetChargebeePaymentMethodStatus(business.id);
  const snackbar = useSnackbar();


  const redirectUrl = ConfigurationLoader.load().callbackUrl + venueSettingsPaymentsPath(business.id);
  const createPortalMutation = useCreateChargebeePortalSessionMutation(business.id, redirectUrl);

  const createChargebeePortalUrlAndOpen = () => {
    ampli.chargbeeConfigureClicked({
      businessId: business.id,
    });
    createPortalMutation.mutateAsync()
      .then((url: string) => {
        window.open(url, '_blank');
      }).catch(() => {
        snackbar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'settings.payments.sunday_subscriptions.error_creating_portal' }),
        });
      });
  };

  if (paymentMethodStatus == 'NO_ACCOUNT') {
    return <></>;
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
          <Typography variant="h6">
            <FormattedMessage id="settings.payments.sunday_subscriptions" />
          </Typography>
          <img width={'25'} src="/chargebee.svg" alt="chargebee" />

        </Box>
        <Typography>
          <FormattedMessage id="settings.payments.sunday_subscriptions.description" />
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

            <Typography>
              <FormattedMessage id="settings.payments.sunday_subscriptions.status" />
            </Typography>
            {getStatusChip(paymentMethodStatus, intl)}
          </Box>
          <Button variant="contained" size={'small'} onClick={createChargebeePortalUrlAndOpen}>
            <FormattedMessage id="settings.payments.sunday_subscriptions.configure" />
            {createPortalMutation.isPending && <CircularProgress />}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ChargebeePanel;
