import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { BusinessId } from 'src/business/domain/Business';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { isTaskFailed, isTaskOngoing, isTaskSucceeded, Task } from 'src/menu/common/domain/Task';
import { usePollTask } from 'src/menu/common/queries/pollTask';
import { QUERYKEY_MENU_VENUE } from '../queries/getMenuBusinessQuery';
import { AddLanguageDto } from '../repositories/AddLanguageDto';
import { MenuBusinessRepositoryHttp } from '../repositories/MenuBusinessRepositoryHttp';
import { Language } from 'src/app/i18n/AllSupportedLanguage';

export const useAddLanguageMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuBusinessRepositoryHttp = new MenuBusinessRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (languageDto: AddLanguageDto) => menuBusinessRepositoryHttp.addLanguage(businessId, languageDto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_VENUE, businessId] });
    },
  });
};

export const useAddLanguage = (businessId: BusinessId) => {
  const snackBar = useSnackbar();
  const intl = useIntl();
  const addLanguage = useAddLanguageMutation(businessId);
  const [addingLanguage, setAddingLanguage] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<Language>();
  const [currentTaskId, setTaskId] = useState<string | null>(null);

  const enablePollTask = addingLanguage && !!currentTaskId;
  const { data: currentTask } = usePollTask(currentTaskId!!, enablePollTask);

  useEffect(() => {
    if (!currentTask || !currentLanguage) {
      return;
    }

    if (isTaskOngoing(currentTask.status)) {
      snackBar.addNotification({
        variant: 'info',
        text: intl.formatMessage(
          { id: 'settings.app_settings.adding_language.task_on_going' },
          { lang: intl.formatMessage({ id: currentLanguage.label }) },
        ),
      });
      return;
    }

    setAddingLanguage(false);
    setTaskId(null);

    if (isTaskSucceeded(currentTask.status)) {
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage(
          { id: 'settings.app_settings.adding_language.task_succeeded' },
          { lang: intl.formatMessage({ id: currentLanguage.label }) },
        ),
      });
    }
    if (isTaskFailed(currentTask.status)) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage(
          { id: 'settings.app_settings.adding_language.task_failed' },
          { lang: intl.formatMessage({ id: currentLanguage.label }) },
        ),
      });
    }
  }, [currentTask]);

  const addNewLanguage = (language: Language) => {
    setAddingLanguage(true);
    setCurrentLanguage(language);

    addLanguage
      .mutateAsync({ language: language.id })
      .then((task: Task) => {
        setTaskId(task.id);
      })
      .catch(() => {
        setAddingLanguage(false);
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage(
            { id: 'settings.app_settings.adding_language.task_failed' },
            { lang: intl.formatMessage({ id: language.label }) },
          ),
        });
      });
  };

  return { addNewLanguage, currentTaskStatus: currentTask?.status };
};
