import React, { useEffect, useState } from 'react';
import { Loader } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { useGetMenus } from 'src/ordering/orderingMenus/hooks';
import { OrderingMenu } from 'src/ordering/orderingMenus/domain/OrderingMenu';
import { UpsellBoxMenu } from './UpsellBoxMenu';

type UpsellMenuProps = {
  box: BoxDetails;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UpsellMenu = ({ box }: UpsellMenuProps) => {
  const [, getMenus] = useGetMenus();

  const [menus, setMenus] = useState<OrderingMenu[]>();

  useEffect(() => {
    getMenus(box.id).then(setMenus);
  }, [getMenus, box.id]);

  if (menus === undefined) {
    return <Loader size="large" />;
  }

  return (
    <Container>
      {menus.map((menu) => (
        <UpsellBoxMenu key={menu.id} menu={menu} />
      ))}
    </Container>
  );
};
