import React, { useMemo } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Box, Tab, Tabs } from '@mui/material';
import OpenReportsTab from './OpenReportsTab';
import HistoricalReportsTab from './HistoricalReportsTab';
import { venueReconciliationTabPath } from 'src/app/navigation/pathHelpers';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { LocalisationKey } from 'src/lang/en';

const TabBox = styled(Box)`
  padding: 24px;
`;

type TabDefinition = {
  labelId: LocalisationKey,
  name: 'open' | 'history' | 'settings',
  render: () => React.ReactNode,
};

const tabDefinitions: TabDefinition[] = [
  {
    name: 'open',
    labelId: 'reconciliation.reports',
    render: () => (<OpenReportsTab />),
  },
  {
    name: 'history',
    labelId: 'reconciliation.history',
    render: () => (<HistoricalReportsTab />),
  },
];

const ReconciliationTabs = () => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const { reconciliationTab = tabDefinitions[0].name } = useParams();

  const currentTab = useMemo(
    () => tabDefinitions.find((tab) => tab.name === reconciliationTab),
    [reconciliationTab],
  );

  return (
    <Box>
      <Tabs value={reconciliationTab}>
        {tabDefinitions.map((tab) => {
          return (
            <Tab
              key={tab.name}
              value={tab.name}
              label={intl.formatMessage({ id: tab.labelId })}
              to={venueReconciliationTabPath(business.id, tab.name)}
              component={Link}
            />
          );
        })}
      </Tabs>
      <TabBox>
        {currentTab && currentTab.render()}
      </TabBox>
    </Box>
  );
};

export default ReconciliationTabs;
