import axios from 'axios';
import { CurrencyCode, money } from '@sundayapp/web-money';
import { datadogLogs } from '@datadog/browser-logs';
import { RewardProgram } from '../domain/RewardProgram';
import { RewardProgramKpis } from '../domain/RewardProgramKpis';
import { toDomain } from './RewardProgramKpisResponse';

type RewardResponse = {
  rewardProgramId: string;
  businessIds: string[];
  rewardThreshold: {
    points: number;
  };
  voucherConfiguration: {
    discount: number;
    maxAmount: {
      amount: number;
      currency: string;
    };
  };
};

export class RewardProgramRepository {
  constructor(private baseUrl: string) {

  }

  private getRewardProgramForBusinessURL(businessId: string) {
    return `${this.baseUrl}/reward-program?businessId=${businessId}`;
  }

  private getRewardProgramKpisURL(rewardProgramId: string) {
    return `${this.baseUrl}/reward-program-kpis?rewardProgramId=${rewardProgramId}`;
  }

  async getRewardProgram(businessId: string): Promise<RewardProgram | undefined> {
    try {
      const response = await axios.get(this.getRewardProgramForBusinessURL(businessId));
      if (response.data?.type !== 'VoucherRewardProgram') {
        return undefined;
      }
      const reward: RewardResponse = response.data;
      return {
        id: reward.rewardProgramId,
        businessIds: reward.businessIds,
        threshold: reward.rewardThreshold.points,
        voucherConfiguration: {
          discountPercentage: reward.voucherConfiguration.discount,
          maxAmountOfDiscount: money(
            reward.voucherConfiguration.maxAmount.amount,
            CurrencyCode[reward.voucherConfiguration.maxAmount.currency as keyof typeof CurrencyCode],
          ),
        },
      };
    } catch (e) {
      return undefined;
    }
  }

  async getKpis(rewardProgramId: string): Promise<RewardProgramKpis | undefined> {
    try {
      const response = await axios.get(this.getRewardProgramKpisURL(rewardProgramId));
      return toDomain(response.data);
    } catch (e) {
      datadogLogs.logger.warn(
        `Impossible to retrieve kpis for reward program id ${rewardProgramId}`,
        { error: e ?? '' },
      );
      return undefined;
    }
  }
}
