import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { DataGrid } from '@mui/x-data-grid';
import { ReportingDigestByWaiter } from '../../domain/ReportingDigestByWaiter';
import { buildColumns } from './buildColumns';
import { Card, CardContent, Typography } from '@mui/material';

export type WaiterReportingProps = {
  isUSVenue: boolean,
  digest: ReportingDigestByWaiter[],
};
export const WaiterReporting = ({ digest, isUSVenue }: WaiterReportingProps) => {
  const intl = useIntl();
  return (
    <Card>
      <CardContent>
        <Typography variant={'h6'}><FormattedMessage id="accounting.reporting.by_waiters.title" /></Typography>
        <DataGrid
          rows={digest}
          autoHeight
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: 'amount', sort: 'desc' }],
            },
          }}
          sx={{ width: '100%' }}
          getRowId={(row) => row.waiter}
          columns={buildColumns(intl, isUSVenue)}
        />
      </CardContent>
    </Card>
  );
};
