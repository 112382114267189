import React from 'react';

import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router-dom';
import { ErrorPageWrapper, SkullImg } from './AppErrorPage.style';
import skullImg from '../../assets/skull.png';
import { Button, Typography } from '@mui/material';

export const AppErrorPage = () => {
  const navigate = useNavigate();

  const redirectToHomePage = () => {
    navigate('/');
  };

  return (
    <ErrorPageWrapper>
      <SkullImg src={skullImg} alt="error page" />
      <Typography variant="h2">
        <FormattedMessage id="error.generic_title" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage id="error.generic_error" />
      </Typography>
      <Button
        variant="contained"
        size="large"
        onClick={redirectToHomePage}
      >
        <FormattedMessage id="authentication_error.goto" />
      </Button>
    </ErrorPageWrapper>
  );
};
