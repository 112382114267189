import { CurrencyCode, Money, useRenderMoney } from '@sundayapp/web-money';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import AwardSvg from '../../../app/component/icons/AwardSvg';
import { useStaffPerformance } from 'src/staff-performance/page/useStaffPerformance';
import { Staff } from 'src/staff-performance/page/StaffPerformanceViewModel';
import { venueStaffPerformancePath } from 'src/app/navigation/pathHelpers';
import { HomeCtaButton } from '../HomeCtaButton';
import { colorPalette } from '@sundayapp/b2b-react-component-library';

type HomeTipsAwardWithPerformanceProps = {
  businessId: string;
  timezone: string;
  currency: CurrencyCode;
};

const findBestTippedWaiter = (waiters: Staff[]): Staff | undefined => {
  let winner: Staff | undefined;
  waiters.forEach((waiter) => {
    if (waiter.totalTips === null) {
      return;
    }

    if (winner === undefined) {
      winner = waiter;
    }

    if (winner.totalTips! < waiter.totalTips) {
      winner = waiter;
    }
  });

  return winner;
};

export const HomeTipsAwardWithPerformance = ({ businessId, currency, timezone }: HomeTipsAwardWithPerformanceProps) => {
  const renderMoney = useRenderMoney(true, true);
  const navigate = useNavigate();

  const { data, isLoading, isError } = useStaffPerformance({
    range: '7_DAYS',
    businessId,
    timezone,
  });

  if (isLoading || isError || data === undefined || data.length === 0) {
    return <></>;
  }

  const bestWaiter = findBestTippedWaiter(data);
  if (bestWaiter === undefined) {
    return <></>;
  }

  const onClick = () => {
    navigate(venueStaffPerformancePath(businessId));
  };

  const tips: Money = {
    amount: bestWaiter.totalTips! * 100000,
    currency,
  };
  const tipsLabel = renderMoney(tips);

  return (
    <HomeCtaButton
      icon={<AwardSvg size={24} color={colorPalette.grey500} />}
      label={bestWaiter.waiterName}
      value={tipsLabel}
      onClick={onClick}
    />
  );
};
