import React from 'react';
import { Stack } from '@mui/material';
import { DispatchLoader } from 'src/tips/dispatch/DispatchLoader';
import { DispatchedLoader } from 'src/tips/dispatch/DispatchedLoader';

export const SundayPoolingPage = () => {
  return (
    <Stack spacing={4} >
      <DispatchLoader />
      <DispatchedLoader />
    </Stack>
  );
};
