import { DateRange } from '../../domain/RewardProgramKpis';
import { instant } from '../../../../Instant';

const dateFormat = (locale: string) => {
  if (locale.startsWith('fr')) {
    return 'DD/MM/YYYY';
  }
  return 'MM/DD/YYYY';
};

const format = (date: number, locale: string) => instant(date)
  .local()
  .format(dateFormat(locale));

export const formatDateRange = (dateRange: DateRange, locale: string) => `${format(Date.parse(dateRange.from), locale)} - ${format(Date.parse(dateRange.to), locale)}`;

export const formatDate = (date: string, locale: string) => format(Date.parse(date), locale);
