import {
  applyRate,
  CurrencyCode,
  differenceMoney,
  divideMoneys,
  fromPercentage,
  money,
  Money,
  sumMoneys,
} from '@sundayapp/web-money';
import { collection, Firestore, getDocs, query, where } from 'firebase/firestore';
import { Rate } from 'src/bills/domain/Bill';
import { PaymentOnPOS, PaymentOverSunday, PaymentOverSundayStatus } from 'src/bills/domain/orders/types';
import { AdditionalCharge } from '../domain/AdditionalCharge';
import { OrderForAccounting } from '../domain/OrderForAccounting';
import { OrderForAccountingPort } from '../domain/OrderForAccountingPort';
import { SubBill } from '../domain/SubBill';
import { BusinessId } from 'src/business/domain/Business';

const paymentRate = (payment: PaymentOverSunday, orderAmount: Money): Rate => {
  // this avoids strange division if there are both payments with an amount of 0, and a total order amount to 0 as well
  if (payment.orderAmount.amount === 0) {
    return fromPercentage(0);
  }
  const refunds = payment.refunds || { emptyRefund: { amount: money(0, orderAmount.currency) } };
  const refundAmount = sumMoneys(...Object.values(refunds).map((r) => r.amount));
  const finalPaymentOrderAmount = differenceMoney(payment.orderAmount, refundAmount);
  return divideMoneys(finalPaymentOrderAmount, orderAmount);
};

export class OrderForAccountingRepositoryFirestore implements OrderForAccountingPort {
  constructor(private db: Firestore) {
  }

  async findOrders(
    businessId: BusinessId,
    startDate: Date,
    endDate: Date,
    currency: CurrencyCode,
  ): Promise<OrderForAccounting[]> {
    const orders = await getDocs(
      query(
        collection(this.db, 'orders'),
        where('venueId', '==', businessId),
        where('openedAt', '>=', startDate),
        where('openedAt', '<=', endDate),
      ),
    );

    return orders.docs.map((o) => {
      const subBills = Object.values(o.get('bill.subBills') ?? {}).map((sb: any) => new SubBill(sb.additionalCharges));
      const additionalCharges = subBills.flatMap((sb: any) =>
        Object.values(sb.additionalCharges ?? {}).map((ac: any) => new AdditionalCharge(ac.amount, ac.code, ac.type)));

      const serviceChargeAmount = additionalCharges
        .filter((ac: any) => ac.type === 'FEE' || ac.code === 'service_charge')
        .map((ac: any) => ac.amount)
        .reduce(
          (a: any, b: any) => sumMoneys(money(a.amount, a.currency), money(b.amount, b.currency)),
          money(0, currency),
        );

      const salesTaxAmount = additionalCharges
        .filter((ac: any) => ac.type === 'TAX' || ac.code === 'sales_tax')
        .map((ac: any) => ac.amount)
        .reduce(
          (a: any, b: any) => sumMoneys(money(a.amount, a.currency), money(b.amount, b.currency)),
          money(0, currency),
        );

      const orderAmount = money(o.get('totalAmountOnPos.amount'), o.get('totalAmountOnPos.currency'));

      const posPayments: PaymentOnPOS[] = o.get('posPayments') ?? [];

      const rawPaymentsOverSunday = Object.entries(o.get('paymentsOverSunday') ?? {}).map(
        ([ paymentId, paymentOverSunday ]) => ({
          paymentId,
          paymentOverSunday: paymentOverSunday as PaymentOverSunday,
        }),
      );

      // as some order are "emptied" and their total order amount set to 0,
      // we try to recover this order amount from the sum of all payments
      // this is a weak approximation, but currently the best guess we can make at this point
      const baseAmountForPaymentRate = orderAmount.amount === 0
        ? sumMoneys(
          ...rawPaymentsOverSunday
            .map(({ paymentOverSunday: { orderAmount: amount } }) => amount)
            .concat(posPayments.map(({ amount }) => amount))
            // with a default payment in case there are no payments at all
            .concat([ money(0, orderAmount.currency) ]),
        )
        : orderAmount;
      const paymentsOverSunday: PaymentOverSunday[] = rawPaymentsOverSunday
        .map(({ paymentId, paymentOverSunday }) => {
          const rate = paymentRate(paymentOverSunday as PaymentOverSunday, baseAmountForPaymentRate);
          return {
            ...(paymentOverSunday as PaymentOverSunday),
            id: paymentId,
            serviceChargeAmount: applyRate(serviceChargeAmount, rate),
          };
        })
        .filter((paymentOverSunday) => paymentOverSunday.status === PaymentOverSundayStatus.SUCCEEDED);

      return new OrderForAccounting(
        o.get('id'),
        businessId,
        o.get('openedAt').toDate(),
        o.get('staffName'),
        o.get('exclusiveTax.rate.value') ?? 0,
        paymentsOverSunday,
        serviceChargeAmount ?? 0,
        o.get('serviceCharge.rate.value') ?? 0,
        orderAmount,
        salesTaxAmount ?? 0,
        o.get('sundayPayments') || {},
        posPayments,
      );
    });
  }
}
