import React, { createContext, ReactNode, useMemo } from 'react';
import { MenuConfig } from './MenuConfig';
import { MenuRepositoryHttp } from '../repositories/MenuRepositoryHttp';
import { ProductRepositoryHttp } from '../repositories/ProductRepositoryHttp';
import { TagRepositoryHttp } from '../repositories/TagRepositoryHttp';
import { MenuBusinessRepositoryHttp } from '../../common/repositories/MenuBusinessRepositoryHttp';

type MenuContextProviderProps = {
  menuBackendUrl: string;
  children: ReactNode;
};

type ProviderConfig = MenuConfig & {
  children: ReactNode;
};

export const MenuConfigContext = createContext<Partial<ProviderConfig>>({});

const Provider: React.FC<ProviderConfig> = ({ children, ...config }) => <MenuConfigContext.Provider value={config}>{children}</MenuConfigContext.Provider>;

export const MenuContextProvider = ({ menuBackendUrl, children }: MenuContextProviderProps) => {
  // Repo
  const menuRepository = useMemo(() => new MenuRepositoryHttp(menuBackendUrl), [menuBackendUrl]);

  const productRepository = useMemo(() => new ProductRepositoryHttp(menuBackendUrl), [menuBackendUrl]);

  const tagRepository = useMemo(() => new TagRepositoryHttp(menuBackendUrl), [menuBackendUrl]);

  const businessRepositoryHttp = useMemo(() => new MenuBusinessRepositoryHttp(menuBackendUrl), [menuBackendUrl]);

  return (
    <Provider
      menuRepository={menuRepository}
      productRepository={productRepository}
      tagRepository={tagRepository}
      venueRepository={businessRepositoryHttp}
    >
      {children}
    </Provider>
  );
};
