import { Money } from '@sundayapp/web-money';

export type RewardProgram = {
  id: string;
  businessIds: string[];
  threshold: number;
  voucherConfiguration: VoucherConfiguration;
};

export type VoucherConfiguration = {
  discountPercentage: number;
  maxAmountOfDiscount: Money;
};

export const thresholdInMoney = (rewardProgram: RewardProgram): Money => ({
  amount: rewardProgram.threshold * 100000,
  currency: rewardProgram.voucherConfiguration.maxAmountOfDiscount.currency,
});

export const numberOfBusinessesInProgram = (rewardProgram: RewardProgram): number => rewardProgram.businessIds.length;

export const isRewardPercentageBased = (rewardProgram: RewardProgram) => rewardProgram.voucherConfiguration.discountPercentage !== 100;
