import styled from 'styled-components';
import { useRenderMoney } from '@sundayapp/web-money';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import * as React from 'react';
import {
  isRewardPercentageBased,
  numberOfBusinessesInProgram,
  RewardProgram,
  thresholdInMoney,
} from '../../domain/RewardProgram';
import MailSvg from '../../../../../app/component/icons/MailSvg';
import { IntercomLauncher } from 'src/intercom/components/IntercomLauncher';
import { formatDate } from './RewardProgramKpisDatesFormat';

const Panel = styled('div')`
  padding: 1rem;
  border-radius: 1rem;
  border-color: #DDDDE4;
  border-style: solid;
  border-width: 1px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

const Properties = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Property = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 3rem 0 0;
`;

const PropertyTitle = styled.div`
  font-size: 0.8rem;
  color: #70707B;
  letter-spacing: -0.3px;
`;

const PropertyValue = styled.div`
  font-size: 1rem;
  letter-spacing: -0.5px;
`;

const Contact = styled.div`
  cursor: pointer;
  text-decoration: none;
  font-size: 0.8rem;
  letter-spacing: -0.3px;

  display: flex;
  justify-content: space-around;
  gap: 0.2rem;
`;

const ContactUs = () => (
  <IntercomLauncher>
    <Contact>
      <MailSvg color="black" size={16} />
      <span><FormattedMessage id="loyalty.program.kpi.contact" defaultMessage="Contact to edit" /></span>
    </Contact>
  </IntercomLauncher>
);

type RewardProgramOverviewProps = {
  rewardProgram: RewardProgram;
  rewardProgramLaunchDate: string;
};

export const RewardProgramKpisHeader = ({ rewardProgram, rewardProgramLaunchDate }: RewardProgramOverviewProps) => {
  const intl = useIntl();
  const renderMoney = useRenderMoney();

  const percentage = () => (isRewardPercentageBased(rewardProgram)
    ? `${rewardProgram.voucherConfiguration.discountPercentage}%`
    : `${renderMoney(rewardProgram.voucherConfiguration.maxAmountOfDiscount)}`);

  return (
    <Panel>
      <Properties>
        <Property>
          <PropertyTitle><FormattedMessage id="loyalty.program.kpi.threshold" defaultMessage="Reward threshold" /></PropertyTitle>
          <PropertyValue>{renderMoney(thresholdInMoney(rewardProgram))}</PropertyValue>
        </Property>
        <Property>
          <PropertyTitle><FormattedMessage id="loyalty.program.kpi.reward_value" defaultMessage="Reward value" /></PropertyTitle>
          <PropertyValue>{percentage()}</PropertyValue>
        </Property>
        <Property>
          <PropertyTitle><FormattedMessage id="loyalty.program.kpi.venues_count" defaultMessage="Venues in program" /></PropertyTitle>
          <PropertyValue>{numberOfBusinessesInProgram(rewardProgram)}</PropertyValue>
        </Property>
        <Property>
          <PropertyTitle><FormattedMessage id="loyalty.program.kpi.launch_date" defaultMessage="Program launch date" /></PropertyTitle>
          <PropertyValue>{formatDate(rewardProgramLaunchDate, intl.locale)}</PropertyValue>
        </Property>
      </Properties>
      <ContactUs />
    </Panel>
  );
};
