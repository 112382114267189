import React, { useState } from 'react';
import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';
import { Menu } from '../../../domain/Menu';
import { useUpdateMenuMutation } from '../../../mutations/menu/useMenuMutation';
import { BusinessId } from 'src/business/domain/Business';
import { Switch } from '@mui/material';

type MenuEnablerProps = {
  menu: Menu;
  businessId: BusinessId;
};

const MenuToggleWrapper = styled.div`
  margin-top: ${spaceUsages.mediumSmall};
`;

const MenuEnabler = ({
  menu, businessId,
}: MenuEnablerProps) => {
  const [isProcessing, setProgressing] = useState<boolean>();
  const updateMenu = useUpdateMenuMutation(businessId, menu.id);

  const updateMenuEnabledStatus = async () => {
    if (menu.enabled) {
      await updateMenu.mutateAsync({ enable: false });
    } else {
      await updateMenu.mutateAsync({ enable: true });
    }
  };

  const onChange = async () => {
    if (isProcessing) {
      return;
    }

    setProgressing(true);
    try {
      await updateMenuEnabledStatus();
    } finally {
      setProgressing(false);
    }
  };

  return (
    <>
      {menu.enabled !== undefined && (
        <MenuToggleWrapper>
          <Switch onChange={onChange} checked={menu.enabled} />
        </MenuToggleWrapper>
      )}
    </>
  );
};

export default MenuEnabler;
