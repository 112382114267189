import { AxiosInstance } from 'axios';
import { SourceFilterValue } from '../domain/Tips';
import { TipsRepository } from '../domain/TipsRepository';
import { TipsSummaryByWaiter } from '../domain/TipsSummaryByWaiter';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { BusinessId } from 'src/business/domain/Business';
import { TippingConfiguration, TippingConfigurationSchema } from 'src/tips/domain/TippingConfiguration';

export class HttpTipsRepository implements TipsRepository {
  constructor(
    private axios: AxiosInstance,
    private baseUrl: string,
  ) {}

  async findAllBy(
    businessId: string,
    source: SourceFilterValue,
    startDate: Date,
    endDate: Date,
  ): Promise<TipsSummaryByWaiter[]> {
    const params = new URLSearchParams();
    params.set('from', startDate.toISOString());
    params.set('to', endDate.toISOString());
    params.set('source', source);

    const url = new URL(`${this.baseUrl}/tips/${businessId}`);
    url.search = params.toString();
    const response = await this.axios.get<TipsSummaryByWaiter[]>(url.toString());
    return response.data;
  }

  async getDirectTippingConfiguration(businessId: string): Promise<Record<EnrollmentId, boolean>> {
    const response = await this.axios.get<DirectTippingConfigurationApiResponse>(
      `${this.baseUrl}/direct-tipping/${businessId}`,
    );

    return response.data.directTippingByEnrollment;
  }

  async getTippingConfiguration(businessId: BusinessId): Promise<TippingConfiguration> {
    const res = await this.axios.get(`${this.baseUrl}/tips/${businessId}/tipping-configuration`);
    return TippingConfigurationSchema.parse(res.data);
  }
}

type DirectTippingConfigurationApiResponse = {
  directTippingByEnrollment: Record<EnrollmentId, boolean>;
};
