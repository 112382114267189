import {
  CurrencyCode, differenceMoney, money, Money,
} from '@sundayapp/web-money';
import { OrderForAccounting } from '../domain/OrderForAccounting';
import { PaymentOverSundayStatus, RefundStatus } from 'src/bills/domain/orders/types';
import { safeSumMoney } from 'src/utils/MoneyUtils';

const orderAmountWithDeducedRefunds = (orderAmount: Money, refundAmount: Money) => {
  const paidAmountHavingDeducedRefunds = differenceMoney(orderAmount, refundAmount);
  return money(Math.max(0, paidAmountHavingDeducedRefunds.amount), orderAmount.currency);
};

export const computeInconsistency = (
  orders: OrderForAccounting[],
  paymentIds: string[],
  currency: CurrencyCode,
): Money => {
  const paymentWhichMayCauseInconsistency = orders.flatMap((order) => {
    const notAcknowledgedPayments = order.paymentsOverSunday
      .filter((payment) => payment.status === PaymentOverSundayStatus.SUCCEEDED)
      .filter((payment) => paymentIds.includes(payment.id));

    if (order.sundayPayments) {
      Object.entries(order.sundayPayments).forEach(([paymentId, paymentStatus]) => {
        if (paymentStatus.status === 'POS_ACKNOWLEDGED') {
          const indexToRemove = notAcknowledgedPayments.findIndex(
            (paymentOverSunday) => paymentOverSunday.id === paymentId,
          );
          if (indexToRemove !== -1) {
            notAcknowledgedPayments.splice(indexToRemove, 1);
          }
        }
      });
    }

    return notAcknowledgedPayments;
  });

  const paymentWhichMayCauseInconsistencyWithRefundDeduced = paymentWhichMayCauseInconsistency.map((p) => {
    const refund = safeSumMoney(
      Object.values(p.refunds ?? {})
        .filter((r) => r.status === RefundStatus.SUCCEEDED)
        .map((r) => r.amount),
      currency,
    );
    return orderAmountWithDeducedRefunds(p.paidAmount, refund);
  });

  return safeSumMoney(paymentWhichMayCauseInconsistencyWithRefundDeduced, currency);
};
