import React from 'react';
import { CircularProgress } from '@mui/material';
import { SundayPoolingError } from 'src/tips/dispatch/components/SundayPoolingError';
import { Dispatched } from 'src/tips/dispatch/components/Dispatched';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGetAllDoneTipsDispatch } from 'src/tips/dispatch/infrastructure/useGetAllDoneTipsDispatch';

const today = () => new Date(new Date().setHours(0, 0, 0, 0));

const oneYearAgo = () => new Date(today().setFullYear(today().getFullYear() - 1));

export const DispatchedLoader = () => {
  const business = useCurrentBusinessOrThrow();
  const to = today();
  const from = oneYearAgo();
  const { data: tipsDispatch, error } = useGetAllDoneTipsDispatch(business.id, from, to);

  if (!tipsDispatch) return <CircularProgress color="inherit" />;

  if (error) return <SundayPoolingError />;

  return <Dispatched tipsDispatch={tipsDispatch} />;
};
