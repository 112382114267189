import React, { useEffect, useState } from 'react';
import { Loader } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import { BoxRankingVenueSetting } from '../components/BoxRanking/BoxRankingVenueSetting';
import { VenueDetails } from '../../venues/types';
import { useVenueRepository } from '../../venues/hook';
import { SundayDevice } from '../../area/domain/SundayDevice';
import { useGetSundayDevices } from '../hooks';
import { QrCodes } from '../../common/components/qrCodes/QrCodes';
import { EnrollmentId } from 'src/business/domain/Enrollment';

type FoodCourtProps = {
  enrollmentId: EnrollmentId;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FoodCourt = ({ enrollmentId }: FoodCourtProps) => {
  const venueRepository = useVenueRepository();
  const [venue, setVenue] = useState<VenueDetails>();

  const [sundayDevices, setSundayDevices] = useState<SundayDevice[]>([]);
  const [, getSundayDevices] = useGetSundayDevices();

  useEffect(() => {
    venueRepository.getVenue(enrollmentId).then(setVenue);
  }, [venueRepository, enrollmentId]);

  useEffect(() => {
    getSundayDevices(enrollmentId).then(setSundayDevices);
  }, [getSundayDevices, enrollmentId]);

  if (!venue) {
    return <Loader size="large" />;
  }
  const foodCourtDevices = sundayDevices.filter((device) => !device.areaId && !device.tableId);

  return (
    <Container>
      <QrCodes devices={foodCourtDevices} title="food court qr code" />
      <BoxRankingVenueSetting venue={venue} setVenue={setVenue} />
    </Container>
  );
};
