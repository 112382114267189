import {
  CustomerEngagement,
  CustomerEngagementByVenue,
  DateRanges,
  RewardProgramKpis,
} from '../domain/RewardProgramKpis';

export type Kpis = {
  allTime: number | undefined | null;
  last7Days: number | undefined | null;
  prev7Days: number | undefined | null;
};

type LoyaltyParticipationRate = Kpis;
type NbActiveLoyaltyCustomers = Kpis;
type NbLoyaltyPayments = Kpis;
type NbNewLoyaltySignUps = Kpis;
type NbRewardsEarned = Kpis;
type NbRewardsRedeemed = Kpis;

export type DateRangeResponse = {
  allTime: {
    from: string;
    to: string;
  },
  last7Days: {
    from: string;
    to: string;
  },
  prev7Days: {
    from: string;
    to: string;
  }
};

type CustomerEngagementResponse = {
  dateRange: DateRangeResponse;
  loyaltyParticipationRate: LoyaltyParticipationRate;
  nbActiveLoyaltyCustomers: NbActiveLoyaltyCustomers;
  nbLoyaltyPayments: NbLoyaltyPayments;
  nbNewLoyaltySignUps: NbNewLoyaltySignUps;
  nbRewardsEarned: NbRewardsEarned;
  nbRewardsRedeemed: NbRewardsRedeemed;
};

type VenueKpis = {
  venueId: string;
  venueName: string;
  kpis: {
    customerEngagement: CustomerEngagementResponse
  };
};

export type RewardProgramKpisResponse = {
  rewardProgramKpis: {
    customerEngagement: CustomerEngagementResponse;
  };
  venueKpis: VenueKpis[];
};

const dateRange = (response: DateRangeResponse): DateRanges => ({
  allTime: response.allTime,
  last7Days: response.last7Days,
  prev7Days: response.prev7Days,
});

const getOrMapNullToUndefined = (v: number | undefined | null) => {
  if (v === null) {
    return undefined;
  }
  return v;
};

const allTime = (response: CustomerEngagementResponse): CustomerEngagement => ({
  loyaltyParticipationRate: getOrMapNullToUndefined(response.loyaltyParticipationRate.allTime),
  nbActiveLoyaltyCustomers: getOrMapNullToUndefined(response.nbActiveLoyaltyCustomers.allTime),
  nbLoyaltyPayments: getOrMapNullToUndefined(response.nbLoyaltyPayments.allTime),
  nbNewLoyaltySignUps: getOrMapNullToUndefined(response.nbNewLoyaltySignUps.allTime),
  nbRewardsEarned: getOrMapNullToUndefined(response.nbRewardsEarned.allTime),
  nbRewardsRedeemed: getOrMapNullToUndefined(response.nbRewardsRedeemed.allTime),
});

const allTimeCustomerEngagementsByVenue = (venueKpis: VenueKpis[]): CustomerEngagementByVenue[] => venueKpis.map((item) => {
  const customerEngagementByVenue: CustomerEngagementByVenue = {
    venueId: item.venueId,
    venueName: item.venueName,
    customerEngagement: allTime(item.kpis.customerEngagement),
  };
  return customerEngagementByVenue;
});

const last7Days = (response: CustomerEngagementResponse): CustomerEngagement => ({
  loyaltyParticipationRate: getOrMapNullToUndefined(response.loyaltyParticipationRate.last7Days),
  nbActiveLoyaltyCustomers: getOrMapNullToUndefined(response.nbActiveLoyaltyCustomers.last7Days),
  nbLoyaltyPayments: getOrMapNullToUndefined(response.nbLoyaltyPayments.last7Days),
  nbNewLoyaltySignUps: getOrMapNullToUndefined(response.nbNewLoyaltySignUps.last7Days),
  nbRewardsEarned: getOrMapNullToUndefined(response.nbRewardsEarned.last7Days),
  nbRewardsRedeemed: getOrMapNullToUndefined(response.nbRewardsRedeemed.last7Days),
});

const last7DaysCustomerEngagementsByVenue = (venueKpis: VenueKpis[]): CustomerEngagementByVenue[] => venueKpis.map((item) => {
  const customerEngagementByVenue: CustomerEngagementByVenue = {
    venueId: item.venueId,
    venueName: item.venueName,
    customerEngagement: last7Days(item.kpis.customerEngagement),
  };
  return customerEngagementByVenue;
});

const prev7Days = (response: CustomerEngagementResponse): CustomerEngagement => ({
  loyaltyParticipationRate: getOrMapNullToUndefined(response.loyaltyParticipationRate.prev7Days),
  nbActiveLoyaltyCustomers: getOrMapNullToUndefined(response.nbActiveLoyaltyCustomers.prev7Days),
  nbLoyaltyPayments: getOrMapNullToUndefined(response.nbLoyaltyPayments.prev7Days),
  nbNewLoyaltySignUps: getOrMapNullToUndefined(response.nbNewLoyaltySignUps.prev7Days),
  nbRewardsEarned: getOrMapNullToUndefined(response.nbRewardsEarned.prev7Days),
  nbRewardsRedeemed: getOrMapNullToUndefined(response.nbRewardsRedeemed.prev7Days),
});

export const toDomain = (response: RewardProgramKpisResponse): RewardProgramKpis => (
  {
    customerEngagements: {
      dateRanges: dateRange(response.rewardProgramKpis.customerEngagement.dateRange),
      allTime: allTime(response.rewardProgramKpis.customerEngagement),
      last7Days: last7Days(response.rewardProgramKpis.customerEngagement),
      prev7Days: prev7Days(response.rewardProgramKpis.customerEngagement),
    },
    customerEngagementsByVenue: {
      allTime: allTimeCustomerEngagementsByVenue(response.venueKpis),
      last7Days: last7DaysCustomerEngagementsByVenue(response.venueKpis),
    },
  });
