import { BlobWithMetadata, getFilename } from '../domain/BlobWithMetadata';

export const downloadBlob = (blobWithMetadata: BlobWithMetadata, defaultFilename: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blobWithMetadata.blob);
  link.download = getFilename(blobWithMetadata, defaultFilename);
  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(link);
};

export const toYyyyMmDdFormat = (date: Date) => date.toISOString().split('T')[0].replaceAll('-', '');
