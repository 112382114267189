/* eslint-disable max-len */
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { IntlShape } from 'react-intl/src/types';
import { FulfillmentType } from './domain/FulfillmentType';

const fulfillmentTexts: Record<FulfillmentType, string> = {
  [FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER]: 'behaviour.PickUp',
  [FulfillmentType.TAKE_AWAY]: 'behaviour.TakeAway',
  [FulfillmentType.EAT_IN_SERVICE_AT_TABLE]: 'behaviour.AtTable',
  [FulfillmentType.HYBRID_ORDERING]: 'behaviour.HybridOrderingAtTable',
  [FulfillmentType.CLICK_AND_COLLECT]: 'behaviour.clickAndCollect',
  [FulfillmentType.PRE_ORDERING]: 'behaviour.PreOrdering',
};
export const getFulfillmentMethodText = (intl: IntlShape, fulfillmentMethod: FulfillmentType): string =>
  intl.formatMessage({ id: fulfillmentTexts[fulfillmentMethod] });

const fulfillmentLabels: Record<FulfillmentType, React.ReactNode> = {
  [FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER]: (
    <FormattedMessage id="behaviour.PickUp" defaultMessage="eat in with pick up" />
  ),
  [FulfillmentType.TAKE_AWAY]: <FormattedMessage id="behaviour.TakeAway" defaultMessage="take away" />,
  [FulfillmentType.EAT_IN_SERVICE_AT_TABLE]: <FormattedMessage id="behaviour.AtTable" defaultMessage="at table" />,
  [FulfillmentType.HYBRID_ORDERING]: <FormattedMessage id="behaviour.HybridOrderingAtTable"
                                                       defaultMessage="hybrid ordering" />,
  [FulfillmentType.CLICK_AND_COLLECT]: (
    <FormattedMessage
      id="behaviour.clickAndCollect"
      defaultMessage="click and collect"
      description="name of the service type 'click and collect'"
    />
  ),
  [FulfillmentType.PRE_ORDERING]: <FormattedMessage id="behaviour.PreOrdering" defaultMessage="pre ordering" />,
};
export const getFulfillmentMethodLabel = (fulfillmentMethod: FulfillmentType) => fulfillmentLabels[fulfillmentMethod];

const fulfillmentMethodDescription: Record<FulfillmentType, React.ReactNode> = {
  [FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER]: (
    <FormattedMessage
      id="box.serviceType.EatInPickUpAtCounter.description"
      defaultMessage="Your area has only one QR code and everyone uses it to order. Customers will be picking up your order from a specific spot."
    />
  ),
  [FulfillmentType.TAKE_AWAY]: (
    <FormattedMessage
      id="box.serviceType.TakeAway.description"
      defaultMessage="Activating 'take Away' in one specific area will allow customers to indicate if they want their food to be prepared 'to go'. Also will allow you offer cutlery option when they order."
    />
  ),
  [FulfillmentType.EAT_IN_SERVICE_AT_TABLE]: (
    <FormattedMessage
      id="box.serviceType.EatInServiceAtTable.description"
      defaultMessage="Your area has different tables with one QR code per table. With service at table orders will include the table number."
    />
  ),
  [FulfillmentType.HYBRID_ORDERING]: (
    <FormattedMessage
      id="box.serviceType.EatInServiceAtTableHybrid.description"
      defaultMessage="Your area has different tables with one QR code per table. With service at table orders will include the table number."
    />
  ),
  [FulfillmentType.CLICK_AND_COLLECT]: (
    <FormattedMessage
      id="box.serviceType.ClickAndCollect.description"
      defaultMessage="Click&Collect allows you to add a link to your website so customers can order from the office or home and pick up at their convenient time."
    />
  ),
  [FulfillmentType.PRE_ORDERING]: (
    <FormattedMessage
      id="box.serviceType.PreOrdering.description"
      defaultMessage="Your area allows the clients to place orders several days in advance. Can be uses to book events for large groups including the food and drinks."
    />
  ),
};

export const getFulfillmentMethodDescription = (fulfillmentMethod: FulfillmentType) =>
  fulfillmentMethodDescription[fulfillmentMethod];
