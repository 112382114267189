import { differenceMoney, Money, money, multiplyMoney, multiplyMoneyWithRoundingMode } from '@sundayapp/web-money';
import { PaymentAccount } from 'src/payments/domain/PaymentAccount';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const DEFAULT_ACCOUNT_DIGITAL_FEE_RATE = 0;

export interface DigitalFeeVenueConfig {
  charmPricing?: boolean;
  min?: number;
  max?: number;
}

const businessIdsWithDisabledServiceCharges = [
  '27221736-6879-4b9e-82bf-ed37bb897583', // Kanada-Ya - Panton St
  'f02976df-7768-413a-bbbc-fa89a7db4ba3', // Kanada Ya - St Giles
  '97416ae7-63bb-48f7-8bb4-e9fa2559184f', // Kanada Ya - Upper Street
  '3a8c2d9f-7752-4a94-b3d1-6e4638dde835', // Kanada-Ya - Carnaby
  '4276458b-ff54-4d95-928a-31f390014652', // Kanada-Ya - Ealing
  'e46e6744-de41-45c1-81e3-aec54c13b3bc', // Josie's - Bishops Waltham
  '77b7960e-13a8-4c7a-b379-52b53aa5407c', // Josie's - Romsey
  'b5155c5a-30a4-4dcd-a738-f3a2d1653abf', // Josie's - Winchester
  'a24b417c-2e6b-41a4-9ce5-e556e31485a3', // Josie's - Petersfield
];

const recomputeGBServiceChargeRate = (serviceChargeRate?: number, enrollmentId?: string): number => {
  const hasCustomServiceCharge = serviceChargeRate != null && (!enrollmentId || !businessIdsWithDisabledServiceCharges.includes(enrollmentId));
  return hasCustomServiceCharge ? serviceChargeRate : 0;
};

export const computeDefaultDigitalFeeAmount = (orderAmount: Money, digitalFeeRate: number): Money => multiplyMoneyWithRoundingMode(orderAmount, digitalFeeRate / 100_00000, 'UP');

export const computeGBDigitalFeeAmount = (
  orderAmount: Money,
  digitalFeeRate: number,
  serviceChargeRate?: number,
  enrollmentId?: EnrollmentId,
): Money => {
  const forcedServiceChargeRate = recomputeGBServiceChargeRate(serviceChargeRate, enrollmentId);
  return multiplyMoneyWithRoundingMode(orderAmount, digitalFeeRate / (10_000_000 + forcedServiceChargeRate), 'UP');
};

// eslint-disable-next-line complexity
export const adjustMinMaxRounding = (amount: Money, config?: DigitalFeeVenueConfig, digitalFeeCapping?: number) => {
  if (amount.amount === 0) {
    return amount;
  }

  const oneCent = money(1000, amount.currency);
  const adjustedMoney = differenceMoney(multiplyMoney(multiplyMoneyWithRoundingMode(amount, 0.1, 'UP'), 10), oneCent);

  let literalAmount = adjustedMoney.amount;
  if (config && config.min) {
    literalAmount = Math.max(literalAmount, config.min * 100_000);
  }
  if (config && config.max) {
    literalAmount = Math.min(literalAmount, config.max * 100_000);
  } else if (digitalFeeCapping && digitalFeeCapping > 0) {
    literalAmount = Math.min(literalAmount, digitalFeeCapping);
  }
  return money(literalAmount, adjustedMoney.currency);
};

export const computeDigitalFeeAmount = (enrollmentId: EnrollmentId | undefined, {
  digitalFeeRate,
  digitalFeeCapping,
}: {
  digitalFeeRate: PaymentAccount['digitalFeeRate'],
  digitalFeeCapping: PaymentAccount['digitalFeeCapping'],
}) => (orderAmount: Money, serviceChargeRate?: number) => {
  let amount;
  if (orderAmount.currency === 'GBP') {
    amount = computeGBDigitalFeeAmount(orderAmount, digitalFeeRate, serviceChargeRate, enrollmentId);
  } else {
    amount = computeDefaultDigitalFeeAmount(orderAmount, digitalFeeRate);
  }
  return adjustMinMaxRounding(amount, undefined, digitalFeeCapping);
};
