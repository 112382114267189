import { MenuBusiness } from 'src/menu/common/domain/MenuBusiness';
import { Box as MuiBox, Card, CardContent, Switch, Typography } from '@mui/material';
import { ExposedMenuType } from 'src/menu/common/domain/ExposedMenuType';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Link } from 'react-router-dom';
import { useUpdateBusinessMutation } from 'src/menu/common/mutations/useBusinessMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { BusinessId } from 'src/business/domain/Business';

export const SwitchEnableOrdering = ({
  menuBusiness,
  menuSettingsPath,
  businessId,
}: {
  menuBusiness: MenuBusiness;
  menuSettingsPath: string;
  businessId: BusinessId;
}) => {
  const intl = useIntl();

  const snackBar = useSnackbar();
  const updateMenuBusiness = useUpdateBusinessMutation(businessId);

  const toggleOrderingEnabled = (orderingEnabled: boolean) => {
    if (!menuBusiness) {
      return;
    }

    updateMenuBusiness
      .mutateAsync({
        orderingAllowed: menuBusiness.orderingAllowed,
        orderingEnabled,
        filterConfiguration: menuBusiness.filterConfiguration,
        openBillEnabled: !orderingEnabled ? false : menuBusiness.openBillEnabled,
      })
      .then(() => {
        snackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
        });
      })
      .catch(() => {
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
        });
      });
  };

  return (
    <Card>
      <CardContent>
        <MuiBox display="flex" flexDirection="row" gap={1} alignItems="center">
          <Switch
            checked={menuBusiness.orderingEnabled}
            disabled={menuBusiness.exposedMenuType !== ExposedMenuType.DYNAMIC_MENU}
            onChange={(e) => toggleOrderingEnabled(e.target.checked)}
          />
          <Typography color="#70707B">
            {menuBusiness.exposedMenuType !== ExposedMenuType.DYNAMIC_MENU ? (
              <FormattedMessage
                id="menus.ordering.enable.disabled"
                values={{
                  menuSettingsLink: (
                    <Link to={menuSettingsPath}>
                      <FormattedMessage
                        id="settings.menu.dynamic_menu.link_label"
                        defaultMessage="Activer le menu dynamique"
                      />
                    </Link>
                  ),
                }}
                defaultMessage="{menuSettingsLink} pour autoriser la prise de commande via les QR Codes"
              />
            ) : (
              <FormattedMessage
                id="menus.ordering.enable"
                defaultMessage="Autoriser la prise de commande via les QR Codes"
              />
            )}
          </Typography>
        </MuiBox>
      </CardContent>
    </Card>
  );
};
