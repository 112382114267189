import React, { ReactElement } from 'react';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import enMessages from '../../lang/en.json';
import enGbMessages from '../../lang/en_GB.json';
import esMessages from '../../lang/es.json';
import frMessages from '../../lang/fr.json';
import itMessages from '../../lang/it.json';
import deMessages from '../../lang/de.json';
import cleanedMessages from '../../lang/uz.json';

// Required by moment
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/pt';

// polyfill for old versions of browsers
// Absolutely required for iOS < 14.1
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/fr'; // locale-data for fr
import '@formatjs/intl-numberformat/locale-data/es'; // locale-data for es
import '@formatjs/intl-numberformat/locale-data/en'; // locale-data for en
import '@formatjs/intl-numberformat/locale-data/it'; // locale-data for it
import '@formatjs/intl-numberformat/locale-data/de';

// Required by dayjs
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/ca';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';
import 'dayjs/locale/pt';
import 'dayjs/locale/en-gb';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export interface I18nProviderProps {
  children: ReactElement;
  locale?: SupportedLanguage;
}

export const parseNavigatorLanguage = (locale: string): { lang: string, region: string | undefined } => {
  const [ lang, region ] = locale.split(/[-_]/);

  return {
    lang,
    region: region?.toLowerCase(),
  };
};

export const supportedLanguages = ['en', 'ca', 'es', 'fr', 'pt', 'nl', 'it', 'de', 'uz'] as const;
export type SupportedLanguage = typeof supportedLanguages[number];

export const language = (): SupportedLanguage => {
  const found = parseNavigatorLanguage(navigator.language).lang;
  if (supportedLanguages.includes(found as SupportedLanguage)) {
    return found as SupportedLanguage;
  }
  return 'en';
};

export const localeFullNameInEnglish = (localeCode: string): string =>{
  const lang = new Intl.DisplayNames(['en'], { type: 'language' });
  return lang.of(localeCode) ?? 'English';
};

export const getBrowserLocaleFullNameInEnglish = (): string => {
  return localeFullNameInEnglish(language());
};

type Messages = Record<string, string>;
export const getEnMessages = (locale: string, enTranslations: Messages, enGbTranslations: Messages): Messages => {
  const { region } = parseNavigatorLanguage(locale);
  if (region === 'gb') {
    return { ...enTranslations, ...enGbTranslations };
  }

  return enTranslations;
};

export const I18nProvider = ({ children, locale }: I18nProviderProps) => {
  const locales: Partial<Record<SupportedLanguage, Messages>> = {
    en: getEnMessages(navigator.language, enMessages, enGbMessages),
    es: esMessages,
    fr: frMessages,
    it: itMessages,
    de: deMessages,
    uz: cleanedMessages, // for testing purposes
  };

  const getMessages = (fromLocale: SupportedLanguage): Messages => ({ ...enMessages, ...locales[fromLocale] });
  moment.locale(navigator.language);
  dayjs.locale(navigator.language);

  return (
    <IntlProvider locale={locale ?? navigator.language}
                  messages={getMessages(locale ?? language())}>
      {children}
    </IntlProvider>
  );
};
