import { ConfigurationReplicationState, startFlow } from '../domain/ConfigurationReplicationState';
import { Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ampli } from 'src/ampli';

export const IntroduceFlowStep = ({ onNext }: { onNext: (state: ConfigurationReplicationState) => void }) => {
  const onStart = () => {
    console.log('start event');
    ampli.configurationReplicationStarted();
    onNext(startFlow());
  };
  return (
    <Stack padding="1.5rem" gap="1rem">
      <Typography variant="h5">
        <FormattedMessage id="configuration_replication.introduction.title" />
      </Typography>
      <Typography>
        <FormattedMessage id="configuration_replication.introduction.details" />
      </Typography>
      <Stack direction="row-reverse">
        <Button variant="contained" onClick={onStart}>
          <FormattedMessage id="configuration_replication.introduction.start" />
        </Button>
      </Stack>
    </Stack>
  );
};
