import { useParams } from 'react-router-dom';
import { useReport } from '../queries/getReport';

export function useCurrentReportIdOrThrow() {
  const { reportId } = useParams();
  if (!reportId) throw new Error('Report id param is expected at this point');
  return reportId;
}

export function useCurrentReportOrThrow() {
  const reportId = useCurrentReportIdOrThrow();
  const { data: report, isError } = useReport(reportId);
  if (!report || isError) throw new Error('Report has failed to be fetched');
  return report;
}
