import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { PongoStore } from '../domain/PongoStore';

export type PaytronixConfigureBusinessRequest = {
  loyaltyReference: string;
  loyaltyConfiguration: {
    storeCode: string;
    merchantId: string;
    cardTemplateCode: string;
    theme: string;
  }
};

type GetLoyaltyBusinessResponse = {
  loyaltyReference: string; theme: string; loyaltyConfiguration: {
    storeCode?: string;
    merchantId?: string;
    cardTemplateCode?: string;
    theme?: string
  };
};

export class LoyaltyRepository {
  constructor(private baseUrl: string) {
  }

  async getLoyaltyBusiness(businessId: string): Promise<GetLoyaltyBusinessResponse | null> {
    const response = await axios.get<{
      id: string;
      loyaltyReference: string;
      theme: string;
      loyaltyConfiguration: {
        storeCode?: string;
        merchantId?: string;
        cardTemplateCode?: string;
        theme?: string
      };
    } | undefined>(
      `${this.baseUrl}/businesses/${businessId}`,
    );

    return response.data ? {
      loyaltyReference: response.data.loyaltyReference,
      theme: response.data.theme,
      loyaltyConfiguration: response.data.loyaltyConfiguration,
    } : null;
  }

  async listStores(businessId: string): Promise<PongoStore[]> {
    const response = await axios.get(`${this.baseUrl}/businesses/${businessId}/pongo/stores`);
    return response.data;
  }

  async authenticateWithPongo(businessId: string, code: string) {
    try {
      await axios.post(
        `${this.baseUrl}/businesses/${businessId}/pongo/authenticate`,
        { code },
        {
          withCredentials: true,
          headers: {
            correlation_id: uuidv4(),
          },
        },
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  async saveStore(businessId: string, storeId: string, rewardCode: string): Promise<boolean> {
    try {
      await axios.post(
        `${this.baseUrl}/businesses/${businessId}/pongo/stores`,
        { id: storeId, rewardCode },
        {
          withCredentials: true,
          headers: {
            correlation_id: uuidv4(),
          },
        },
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  async savePaytronixBusinessConfiguration(businessId: string, request: PaytronixConfigureBusinessRequest) {
    try {
      await axios.post(
        `${this.baseUrl}/businesses/${businessId}/configure`,
        request,
        {
          headers: {
            'correlation_id': uuidv4(),
          },
        },
      );
      return true;
    } catch (e) {
      return false;
    }
  }
}
