import { useMediaBreakpoints } from '@hooks/useMediaBreakpoints';
import { MobileNavigationMenu } from './MobileNavigationMenu';
import useNavigationMenu from './useNavigationMenu';
import { SideBarMenu } from '@organisms/Navigation/SideBar';

export const NavigationMenu = () => {
  const menuItems = useNavigationMenu();
  const { isMobileScreen, isTabletScreen } = useMediaBreakpoints();

  if (isMobileScreen || isTabletScreen) {
    return <MobileNavigationMenu menuItems={menuItems} />;
  }

  return <SideBarMenu menuItems={menuItems} />;
};
