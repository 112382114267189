import React from 'react';
import { useNavigate } from 'react-router-dom';
import { waiterHistoryPath, waiterTippingPath } from 'src/app/navigation/pathHelpers';
import { Box, Button, Popover, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListCheckedSvg from '../../app/component/icons/ListCheckedSvg';
import GearSvg from '../../app/component/icons/GearSvg';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

interface Props {
  businessId: string
  waiterId: string
  isHover: boolean
}

export const WaiterActions: React.FC<Props> = ({ businessId, waiterId, isHover }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const displayTipping = () => {
    setAnchorEl(null);
    navigate(waiterTippingPath(businessId, waiterId));
  };

  const displayHistory = () => {
    setAnchorEl(null);
    navigate(waiterHistoryPath(businessId, waiterId));
  };

  return (
      <Box display={'flex'} flexDirection={'row-reverse'} justifyContent={'stretch'}>
        <Box>
          <Button onClick={(e) => setAnchorEl(e.currentTarget)} style={{ 'minWidth': '0px' }}>
            <MoreVertIcon />
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box m={1}>
              <Button onClick={() => displayTipping()}>
                <Box mr={1}>
                  <GearSvg color={theme.palette.text.secondary} />
                </Box>
                <FormattedMessage id="direct_tipping.action.tipping_configuration" defaultMessage="Configure instant tipping" />
              </Button>
              <Button onClick={() => displayHistory()}>
                <Box mr={1}>
                  <ListCheckedSvg color={theme.palette.text.secondary} />
                </Box>
                <FormattedMessage id="direct_tipping.action.history" defaultMessage="See historical changes" />
              </Button>
            </Box>
          </Popover>
        </Box>
        {isHover && (
          <Button onClick={() => displayTipping()}>
            <Box mr={1} mt={'4px'}>
              <GearSvg color={theme.palette.text.secondary} />
            </Box>
            <Box>
              <FormattedMessage id="direct_tipping.action.configuration" defaultMessage="Configure" />
            </Box>
          </Button>)
        }
    </Box>
  );
};
