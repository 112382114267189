import { IntlShape } from 'react-intl/src/types';
import { PayWithCashLabel } from '../../../../types';

export const payWithCashLabelOptions = (intl: IntlShape) => [
  {
    id: PayWithCashLabel.PAY_WITH_CASH,
    label: intl.formatMessage({ id: 'venue.payWithCash', defaultMessage: 'pay with cash' }),
  },
  {
    id: PayWithCashLabel.PAY_TO_STAFF,
    label: intl.formatMessage({ id: 'venue.payToStaff', defaultMessage: 'pay to staff' }),
  },
  {
    id: PayWithCashLabel.PAY_AT_CHECKOUT,
    label: intl.formatMessage({ id: 'venue.payAtCheckout', defaultMessage: 'pay at checkout' }),
  },
];
