import { Money } from '@sundayapp/web-money';
import { Tag } from './Tag';
import { Allergen } from './Allergen';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import {
  menuElementTypes,
  MenuProductElementType,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductsList';
import { LocalisationKey } from 'src/lang/en';

export type SnoozePeriod = { start: string; end: string };
export type ProductWithSubProductsType =
  typeof productTypes.PRODUCT
  | typeof productTypes.BUNDLE
  | typeof productTypes.VARIANT_GROUP
  | typeof productTypes.MODIFIER_GROUP;

export type PosConnection = {
  posConnectionId: string;
  externalRevenueCenterId?: string;
};

export interface Product {
  id: string;
  name: string;
  partnerName: string;
  shortDescription: string;
  description: string;
  partnerDescription: string;
  type: (typeof productTypes)[keyof typeof productTypes];
  source: (typeof ProductSource)[keyof typeof ProductSource];
  sku: string;
  price: Money;
  pictureUrl: string;
  partnerPictureUrl: string;
  tags: Tag[];
  allergens: Allergen[];
  relatedProductIds: string[];
  subProductIds: string[];
  isSubProductsOverridden: boolean;
  isSubProductsEditable: boolean;
  calories: number;
  eatInTax: number;
  takeAwayTax: number;
  deliveryTax: number;
  isAvailableForOrder: boolean;
  isSnoozed: boolean;
  isEligibleForOrder?: boolean;
  posConnection?: PosConnection;
}

export const getExposedProductName = (product: {
  name: Product['name'],
  partnerName?: Product['partnerName']
}) => (product.name ? product.name : product.partnerName);
export const ProductSource = {
  SUNDAY: 'SUNDAY',
  PARTNER: 'PARTNER',
} as const;

export type ProductType = ValuesOf<typeof productTypes>;

export const productTypes = {
  PRODUCT: 'PRODUCT',
  MODIFIER: 'MODIFIER',
  MODIFIER_GROUP: 'MODIFIER_GROUP',
  BUNDLE: 'BUNDLE',
  VARIANT_GROUP: 'VARIANT_GROUP',
  VARIANT: 'VARIANT',
} as const;

export const productTypesByElementType: Record<MenuProductElementType, ProductType[]> = {
  [menuElementTypes.PRODUCT]: [productTypes.PRODUCT, productTypes.VARIANT_GROUP, productTypes.BUNDLE],
  [menuElementTypes.MODIFIER]: [productTypes.MODIFIER, productTypes.MODIFIER_GROUP],
};

export const translationByProductType: Record<ProductType, {
  single: LocalisationKey,
  plural: LocalisationKey
}> = {
  [productTypes.PRODUCT]: {
    single: 'menus.products.type.product',
    plural: 'menus.products.type.product.plural',
  },
  [productTypes.MODIFIER]: {
    single: 'menus.products.type.modifier',
    plural: 'menus.products.type.modifier.plural',
  },
  [productTypes.MODIFIER_GROUP]: {
    single: 'menus.products.type.modifier_group',
    plural: 'menus.products.type.modifier_group.plural',
  },
  [productTypes.VARIANT]: {
    single: 'menus.products.type.variant',
    plural: 'menus.products.type.variant.plural',
  },
  [productTypes.VARIANT_GROUP]: {
    single: 'menus.products.type.variant_group',
    plural: 'menus.products.type.variant_group.plural',
  },
  [productTypes.BUNDLE]: {
    single: 'menus.products.type.bundle',
    plural: 'menus.products.type.bundle.plural',
  },
} as const;
