import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AuthorisedSignatoryPersonalInfo,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/AuthorisedSignatory/AuthorisedSignatoryPersonalInfo';
import {
  ProofOfIdentity,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/ProofOfIdentity/ProofOfIdentity';
import {
  CheckoutOnboardingInformationDraft,
  companyPositionNeeded,
  powerOfAttorneyNeeded,
  RepresentativeDraft,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import {
  PowerOfAttorney,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/AuthorisedSignatory/PowerOfAttorney';
import { useIntl } from 'src/app/i18n/TypedIntl';
import {
  isAuthorisedSignatoryComplete,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/AuthorisedSignatory/isAuthorisedSignatoryComplete';
import { Representative } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/Representative';
import {
  RepresentativeDetails,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/RepresentativeDetails';


export const EditAuthorisedSignatory = ({
  representative,
  index,
  onDelete,
}: {
  representative: RepresentativeDraft,
  onDelete: () => void,
  index: number
}) => {
  const { formState: { errors } } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[index];
  const isUBO = !!representative?.roles?.ubo;
  const needsCompanyPosition = companyPositionNeeded(representative);
  const intl = useIntl();
  const isComplete = isAuthorisedSignatoryComplete({ representative, containsErrors: !!error });
  const title = representative.lastName && representative.firstName ? `${representative.lastName} ${representative.firstName}` :
    intl.formatMessage({ id: 'settings.payments.checkout.onboarding.individualRepresentatives.authorisedSignatory.details' });
  const needPowerOfAttorney = powerOfAttorneyNeeded(representative);  

  return <Representative isComplete={isComplete} title={title} onDelete={onDelete}>
    <RepresentativeDetails>
      <AuthorisedSignatoryPersonalInfo isUBO={isUBO} needsCompanyPosition={needsCompanyPosition}
                                       representativeIndex={index} />
      <ProofOfIdentity representativeIndex={index} />
      {needPowerOfAttorney && <PowerOfAttorney representativeIndex={index} />}
    </RepresentativeDetails>
  </Representative>;
};
