import React from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  error?: Error;
}

const ErrorContainerElement = styled.div`
  padding: ${getSpacing(2)} ${getSpacing(3)};
  margin: ${getSpacing(1)} ${getSpacing(-3)} ${getSpacing(3)};
  background-color: ${colorPalette.lightRed};
`;

const ErrorContainer: React.FC<Props> = ({ error }) => {
  if (!error) return null;
  return <ErrorContainerElement>{error.message}</ErrorContainerElement>;
};

export default ErrorContainer;
