import { useSearchParams } from 'react-router-dom';
import { MenuElementType } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductsList';

export type Pagination = { pageSize: number; page: number };

export const useSearchProductsFilters = () => {
  const DEFAULT_PAGE_SIZE = 50;
  const DEFAULT_PAGE = 0;

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') || DEFAULT_PAGE.toString();
  const pageSize = searchParams.get('pageSize') || DEFAULT_PAGE_SIZE.toString();
  const filter = searchParams.get('filter') || undefined;
  const productType = searchParams.get('productType') || 'All';
  const advanced = searchParams.get('advanced') || 'All';
  const family = searchParams.get('family') || 'PRODUCT';

  const pagination: Pagination = {
    page: page ? +page : DEFAULT_PAGE,
    pageSize: pageSize ? +pageSize : DEFAULT_PAGE_SIZE,
  };

  return {
    family: family as MenuElementType,
    pageSize,
    pagination,
    filter,
    setSearchParams,
    productType,
    advanced,
  };
};
