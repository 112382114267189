import { CustomizationBanner, WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';
import { Customization } from './domain/Customization';
import MenuPreview from './components/MenuPreview';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import BannerSettings from './components/BannerSettings';
import { IntlShape } from 'react-intl/src/types';
import { Business } from 'src/business/domain/Business';
import { pixels } from 'src/sundayplus/Size';
import { Column } from 'src/sundayplus/Column';
import { Stack, Typography, useTheme } from '@mui/material';

interface Props {
  business: Business;
  whiteLabelCustomization: WhiteLabelCustomization;
  customization: Customization | undefined;
  intl: IntlShape;
  onSaveBanner: (banner: CustomizationBanner) => void;
}

export function MenuBanner({
  business,
  whiteLabelCustomization,
  customization,
  intl,
  onSaveBanner,
}: Props) {
  const theme = useTheme();
  return (
    <Stack flexDirection="column">
      <Column gap={pixels(16)}>
        <Typography variant="h6">
          <FormattedMessage id="settings.customization.menuBanner.title" defaultMessage="Collapsable menu banner" />
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          <FormattedMessage id="settings.customization.menuBanner.description" />
        </Typography>
        <BannerSettings
          PreviewComponent={MenuPreview}
          previewComponentProps={{
            customizationColors: whiteLabelCustomization.color,
            logoUrl: customization?.logo?.logoUrls?.noResize,
          }}
          venueId={business.patEnrollment?.id!}
          banner={whiteLabelCustomization.burgerBanner || {}}
          onSaveBanner={onSaveBanner}
          imageRatio={2 / 3}
          title={intl.formatMessage({ id: 'settings.customization.banner.menu.pictureTitle' })}
          defaultImageFilename={'menuBanner'}
          titleMaxLength={24}
          descriptionMaxLength={48}
          previewBackgroundColor={whiteLabelCustomization.color.marketingBanners}
        />
      </Column>
    </Stack>
  );
}
