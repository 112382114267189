import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import { venueMenuBlockPath } from 'src/app/navigation/pathHelpers';
import { Block } from '../../../domain/Block';
import { BusinessId } from 'src/business/domain/Business';

export type BlockItemProps = {
  businessId: BusinessId;
  block: Block;
  contextBlockList?: string[];
};

type ContainerProps = {
  backgroundColor: string;
  hasLink: boolean;
};

const BlockHeight = '130px';

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${BlockHeight};

  background-color: ${(props) => props.backgroundColor};
  border-radius: 24px;
  cursor: ${(props) => (props.hasLink ? 'pointer' : 'default')};
`;

type TitleProps = {
  fontColor: string;
  hasPicture: boolean;
};

const Title = styled.div<TitleProps>`
  font-size: ${(props) => (props.hasPicture ? '21px' : '32px')};
  letter-spacing: -0.03em;
  color: ${(props) => props.fontColor};
  padding-left: 16px;
  padding-right: 16px;
  line-height: 100%;
`;

type LinkTitleProps = {
  fontColor: string;
};

const LinkTitle = styled.div<LinkTitleProps>`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: ${(props) => props.fontColor};
  padding-right: 8px;
`;

type DescriptionContainerProps = {
  centeredContent: boolean;
};

const DescriptionContainer = styled.div<DescriptionContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.centeredContent ? 'center' : 'start')};
  height: 100%;
  width: 100%;
`;

const LinkLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
`;

const BlockImage = styled.img`
  border-radius: 0 16px 16px 0;
  overflow: hidden;
  object-fit: cover;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  flex: 0 0 50%;
  margin-left: 16px;
`;

export const BlockItem = ({ block, businessId, contextBlockList = [] }: BlockItemProps) => {
  const navigateTo = useNavigate();

  function onBlockClick() {
    return navigateTo(venueMenuBlockPath(businessId, block.id), { state: { contextBlockList } });
  }

  const hasPicture = block.imageUrl !== undefined;
  const hasLink = block.linkUrl !== undefined;

  const label = block.linkLabel;

  return (
    <Container
      data-testid="menu-block-item"
      backgroundColor={block.backgroundColor}
      hasLink={hasLink}
      onClick={onBlockClick}
    >
      <DescriptionContainer centeredContent={!hasPicture}>
        <Title hasPicture={hasPicture} fontColor={block.textColor}>
          {block.title}
        </Title>

        {label && (
          <LinkLine>
            <LinkTitle fontColor={block.textColor}>{label}</LinkTitle>
            <ChevronRight htmlColor={block.textColor} height={16} width={16} />
          </LinkLine>
        )}
      </DescriptionContainer>
      {hasPicture && <BlockImage src={block.imageUrl} />}
    </Container>
  );
};
