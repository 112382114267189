import { Money } from '@sundayapp/web-money';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { safeRenderMoney } from '../domain/TemplatesHelper';
import { dimensionsPx } from '../design/dimensions';
import { palette } from '../design/palette';

export const KpiMoney = ({
  title,
  amount,
  countryCode,
  fluctuation,
  additionalInformation,
  amountFontSize,
  color,
}: {
  title: string;
  amount: Money | null;
  countryCode: string;
  fluctuation: number | null;
  additionalInformation?: string[];
  amountFontSize?: string;
  color?: string;
}) => (
  <Stack display="flex" flexDirection="column">
    <Typography color={color}>{title}</Typography>
    <Stack flexDirection="row" gap={1}>
      <Typography color={color} fontSize={amountFontSize}>
        {safeRenderMoney(amount, countryCode)}
        {' '}
      </Typography>
      {fluctuation && fluctuation >= 0 && (
        <Typography color={palette.progress.positive} display="flex" alignItems="center">
          +
          {fluctuation}
          %
        </Typography>
      )}
      {fluctuation && fluctuation < 0 && (
        <Typography color={palette.progress.negative} display="flex" alignItems="center">
          {fluctuation}
          %
        </Typography>
      )}
    </Stack>
    {additionalInformation &&
      additionalInformation.map((additional) => (
        <Stack key={additional} flexDirection="row">
          <Typography color={palette.neutral.grey400}>{additional}</Typography>
        </Stack>
      ))}
  </Stack>
);

KpiMoney.defaultProps = { amountFontSize: dimensionsPx.fonts.l, color: palette.primary.white };
