import { Customization } from '../domain/Customization';
import axios from 'axios';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { Logo } from '../domain/Logo';
import { CoverPicture } from '../domain/CoverPicture';
import { ChargesDisplay } from 'src/pages/Settings/Customization/domain/ChargesDisplay';

export class CustomizationRepositoryHttp {
  constructor(private baseUrl: string) {}

  async getCustomization(enrollmentId: EnrollmentId): Promise<Customization> {
    const response = await axios.get<Customization>(`${this.baseUrl}/enrollments/${enrollmentId}/customizations`);
    return response.data;
  }

  async saveLogoOriginalUrl(enrollmentId: EnrollmentId, originalUrl: string) {
    await axios.put<Customization>(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/logo-original-url`, {
      originalUrl,
    });
  }

  async saveLogo(enrollmentId: EnrollmentId, logo: Logo) {
    await axios.put(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/logo`, logo);
  }

  async deleteLogo(enrollmentId: EnrollmentId) {
    await axios.delete(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/logo`);
  }

  async saveCoverBackgroundColor(enrollmentId: EnrollmentId, backgroundColor: string) {
    await axios.put(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/cover-background-color`, {
      backgroundColor,
    });
  }

  async deleteCoverBackgroundColor(enrollmentId: EnrollmentId) {
    await axios.delete(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/cover-background-color`);
  }

  async saveCoverPicture(enrollmentId: EnrollmentId, coverPicture: CoverPicture) {
    await axios.put(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/cover-picture`, coverPicture);
  }

  async saveCoverPictureOriginalUrl(enrollmentId: EnrollmentId, originalUrl: string) {
    await axios.put(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/cover-original-url`, { originalUrl });
  }

  async deleteCoverPicture(enrollmentId: EnrollmentId) {
    await axios.delete(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/cover-picture`);
  }

  async saveChargesDisplay(enrollmentId: EnrollmentId, chargesDisplay: ChargesDisplay) {
    await axios.put(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/charges-display`, chargesDisplay);
  }

  async getChargesDisplay(enrollmentId: EnrollmentId): Promise<ChargesDisplay> {
    const response = await axios.get(`${this.baseUrl}/enrollments/${enrollmentId}/customizations/charges-display`);
    return {
      benefitSurchargeExplanationLabel: response.data.benefitSurchargeExplanationLabel,
    };
  }
}
