import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Loader } from '@sundayapp/b2b-react-component-library';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { useRouting } from '../../../common/hooks';
import { Area } from '../../domain/Area';
import { useGetAreaSundayDevices, useGetBoxArea } from '../../hooks';
import { OrderingMenu } from '../../../orderingMenus/domain/OrderingMenu';
import { useGetMenus } from '../../../orderingMenus/hooks';
import { SundayDevice } from '../../domain/SundayDevice';
import { AreaSettingContainer } from '../../components/Settings.style';
import { LeftArrowIcon } from '../../../common/components/Icons';
import { AreaQrCodes } from './components/AreaQrCodes';
import { AreaDetailsServiceAtTableSettings } from './components/AreaDetailsServiceAtTableSettings';
import { AreaDetailsBoxSettingsContainer } from './components/AreaDetailsBoxSettings.style';
import { AreaDetailsNameSetting } from './components/AreaDetailsNameSettings';
import { AreaDetailsServiceSettings } from './components/AreaDetailsServiceSettings';
import { AreaDetailsDeleteButton } from './components/AreaDetailsDeleteButton';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { BusinessId } from 'src/business/domain/Business';

type AreaDetailsSettingsProps = {
  businessId: BusinessId,
  enrollmentId: EnrollmentId;
  boxId: string;
  areaId: string;
};

const GoBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${getSpacing(3)};
  color: ${colorPalette.grey600};
  cursor: pointer;
`;

const GoBackText = styled.div`
  display: flex;
  padding-left: ${getSpacing(1.5)};
  font-size: 16px;
  line-height: 20px;
`;

export const AreaDetailsSettings = ({ businessId, enrollmentId, boxId, areaId }: AreaDetailsSettingsProps) => {
  const navigation = useRouting();

  const [area, setArea] = useState<Area>();
  const [, getBoxArea] = useGetBoxArea();

  const [menus, setMenus] = useState<OrderingMenu[]>([]);
  const [getMenuStatus, getMenus] = useGetMenus();

  const [sundayDevices, setSundayDevices] = useState<SundayDevice[]>([]);
  const [getDeviceStatus, getAreaSundayDevices] = useGetAreaSundayDevices();

  useEffect(() => {
    getBoxArea(boxId, areaId).then(setArea);
  }, [getBoxArea, boxId, areaId]);

  useEffect(() => {
    getMenus(boxId).then(setMenus);
  }, [getMenus, boxId]);

  useEffect(() => {
    getAreaSundayDevices(areaId).then(setSundayDevices);
  }, [getAreaSundayDevices, areaId]);

  if (area === undefined || getMenuStatus.loading || getDeviceStatus.loading) {
    return <Loader size="large" />;
  }

  return (
    <AreaSettingContainer>
      <GoBackContainer onClick={() => navigation.goToAreas(businessId)}>
        <LeftArrowIcon color={colorPalette.grey600} />
        <GoBackText>
          <FormattedMessage id="settings.area.details.go-to-areas" defaultMessage="back to all my areas" />
        </GoBackText>
      </GoBackContainer>

      <AreaQrCodes areaId={areaId} sundayDevices={sundayDevices} />
      <AreaDetailsServiceAtTableSettings enrollmentId={enrollmentId} area={area} />
      <AreaDetailsBoxSettingsContainer>
        <AreaDetailsNameSetting area={area} setArea={setArea} />
        <AreaDetailsServiceSettings businessId={businessId} menus={menus} area={area} setArea={setArea} />
        <AreaDetailsDeleteButton businessId={businessId} area={area} />
      </AreaDetailsBoxSettingsContainer>
    </AreaSettingContainer>
  );
};
