import { z } from 'zod';

export const WaiterZ = z.object({
  waiterId: z.string(),
  name: z.string(),
  phoneNumber: z.string(),
  rates: z.record(z.string(), z.number()),
});

export type Waiter = z.infer<typeof WaiterZ>;

export const AllWaitersZ = z.object({
  waiters: z.array(WaiterZ),
  waitersWithServerAccountsRequired: z.array(z.string()),
  waitersNotEligible: z.array(z.string()),
});

export type AllWaiters = z.infer<typeof AllWaitersZ>;

export const WaiterHistoryZ = z.object({
  id: z.string(),
  waiterId: z.string(),
  phoneNumber: z.string().optional(),
  rate: z.number().optional(),
  enrollmentId: z.string().optional(),
  updatedAt: z.number(),
  triggeredBy: z.string(),
});

export type WaiterHistory = z.infer<typeof WaiterHistoryZ>;

export const WaiterWithHistoryZ = z.object({
  waiter: WaiterZ,
  lastUpdate: WaiterHistoryZ.optional(),
});

export type WaiterWithHistory = z.infer<typeof WaiterWithHistoryZ>;

export const DirectTippingConfigurationZ = z.object({
  directTippingEnabled: z.boolean(),
});

export type DirectTippingConfiguration = z.infer<typeof DirectTippingConfigurationZ>;
