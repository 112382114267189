import { Box, Modal, Typography } from '@mui/material';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { productTypes } from 'src/menu/dynamic-menu/domain/Product';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useSearchParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ProductTypeSelectionStep } from 'src/menu/dynamic-menu/pages/CreateProductPage/components/ProductTypeSelectionStep';
import { ProductForm } from 'src/menu/dynamic-menu/pages/CreateProductPage/components/ProductForm';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { VariantGroupForm } from 'src/menu/dynamic-menu/pages/CreateProductPage/components/VariantGroupForm';

export type ProductTypeAllowed =
  | typeof productTypes.BUNDLE
  | typeof productTypes.VARIANT_GROUP
  | typeof productTypes.PRODUCT;

export const lowercase = (productType: ProductTypeAllowed): Lowercase<ProductTypeAllowed> =>
  productType.toLowerCase() as Lowercase<ProductTypeAllowed>;

export const CreateProductPage = () => {
  const navigate = useNavigate();
  const close = () => navigate(-1);
  const business = useCurrentBusinessOrThrow();
  const [searchParams, setSearchParams] = useSearchParams();
  const chosenProductType = searchParams.get('type') as ProductTypeAllowed | undefined;

  const onTypeSelectionFormSubmit = (productType: ProductTypeAllowed) => {
    setSearchParams((prev) => {
      prev.set('type', productType);
      return prev;
    });
  };

  return (
    <Modal open={true}>
      <Box sx={{ background: 'white', height: '100%', overflow: 'auto' }} display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          padding="20px 24px"
          borderBottom={`1px solid ${themeV5.palette.secondary.light}`}
        >
          {chosenProductType ? (
            <Box
              minWidth="50px"
              display="flex"
              alignItems={'center'}
              sx={{ cursor: 'pointer' }}
              gap={1}
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon sx={{ width: '20px', height: '20px' }} />
              <Typography>
                <FormattedMessage id="venue.back" />
              </Typography>
            </Box>
          ) : (
            <Box minWidth="50px"></Box>
          )}
          <Box flexGrow={1} display="flex" justifyContent={'center'}>
            <Typography>
              <FormattedMessage id={`menus.products.new.${lowercase(chosenProductType || productTypes.PRODUCT)}`} />
            </Typography>
          </Box>
          <Box minWidth="50px" display="flex" alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={close}>
            <CrossIcon />
          </Box>
        </Box>

        <Box
          sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
          display="flex"
          padding="16px"
          flexDirection="column"
          maxWidth={700}
        >
          <Box padding="60px 0" display="flex" justifyContent="center">
            {!chosenProductType && <ProductTypeSelectionStep onSubmit={onTypeSelectionFormSubmit} />}
            {chosenProductType === productTypes.PRODUCT && (
              <ProductForm business={business} productType={chosenProductType} />
            )}
            {chosenProductType === productTypes.BUNDLE && (
              <ProductForm business={business} productType={chosenProductType} />
            )}
            {chosenProductType === productTypes.VARIANT_GROUP && <VariantGroupForm business={business} />}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
