import { isEqual } from 'lodash';
import { IntlShape } from 'react-intl';
import { GroupedHourConstraints } from './GroupedHourConstraints';
import { MenuScheduleTranslationUtils } from '../../menuSchedule/components/MenuScheduleTranslationUtils';
import { DayConstraint, Hours, MenuConstraints } from '../../domain/StaticMenus';

type MatchingResult = {
  matching: GroupedHourConstraints[];
  unMatching: GroupedHourConstraints[];
};

export class MenuScheduleUtils {
  static groupedConstraintToString(groupedConstraints: GroupedHourConstraints, translation: IntlShape): string[] {
    return MenuScheduleTranslationUtils.groupedConstraintToString(groupedConstraints, translation);
  }

  static menuToGroupedConstraints(menuConstraint: MenuConstraints): GroupedHourConstraints[] {
    let rawWeekdays = [
      MenuScheduleUtils.buildGroupConstraint(true, false, false, false, false, false, false, menuConstraint.monday),
      MenuScheduleUtils.buildGroupConstraint(false, true, false, false, false, false, false, menuConstraint.tuesday),
      MenuScheduleUtils.buildGroupConstraint(false, false, true, false, false, false, false, menuConstraint.wednesday),
      MenuScheduleUtils.buildGroupConstraint(false, false, false, true, false, false, false, menuConstraint.thursday),
      MenuScheduleUtils.buildGroupConstraint(false, false, false, false, true, false, false, menuConstraint.friday),
    ].filter((schedule) => schedule.constraints?.available);
    let rawWeekend = [
      MenuScheduleUtils.buildGroupConstraint(false, false, false, false, false, true, false, menuConstraint.saturday),
      MenuScheduleUtils.buildGroupConstraint(false, false, false, false, false, false, true, menuConstraint.sunday),
    ].filter((schedule) => schedule.constraints?.available);

    if (MenuScheduleUtils.hasSameConstraintsOnAllDays(rawWeekdays, 5)) {
      rawWeekdays = [
        MenuScheduleUtils.buildGroupConstraint(true, true, true, true, true, false, false, rawWeekdays[0].constraints),
      ];
    }
    if (MenuScheduleUtils.hasSameConstraintsOnAllDays(rawWeekend, 2)) {
      rawWeekend = [
        MenuScheduleUtils.buildGroupConstraint(
          false,
          false,
          false,
          false,
          false,
          true,
          true,
          rawWeekend[0].constraints,
        ),
      ];
    }

    const groupedConstraints = MenuScheduleUtils.findMatchingDays(rawWeekdays).concat(
      MenuScheduleUtils.findMatchingDays(rawWeekend),
    );
    return groupedConstraints.filter((it) => it.constraints?.hours);
  }

  static hasSameConstraintsOnAllDays(groupedConstraints: GroupedHourConstraints[], expectedLength: number): boolean {
    if (groupedConstraints.length !== expectedLength) {
      return false;
    }

    return groupedConstraints.every((item) => isEqual(item.constraints, groupedConstraints[0].constraints));
  }

  static buildGroupConstraint(
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
    constraints: DayConstraint,
  ): GroupedHourConstraints {
    return {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      constraints,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static groupedConstraintToMenuConstraint(groupedConstraints: GroupedHourConstraints[]): MenuConstraints {
    const menuRowConstraint: MenuConstraints = {
      monday: { available: false },
      tuesday: { available: false },
      wednesday: { available: false },
      thursday: { available: false },
      friday: { available: false },
      saturday: { available: false },
      sunday: { available: false },
    };

    return groupedConstraints.reduce((acc, item) => {
      if (item.monday) {
        acc.monday.hours = (acc.monday.hours || []) as Hours[];
        acc.monday.hours = acc.monday.hours.concat(item.constraints.hours as Hours[]);
        acc.monday.available = true;
      }
      if (item.tuesday) {
        acc.tuesday.hours = (acc.tuesday.hours || []) as Hours[];
        acc.tuesday.hours = acc.tuesday.hours.concat(item.constraints.hours as Hours[]);
        acc.tuesday.available = true;
      }
      if (item.wednesday) {
        acc.wednesday.hours = (acc.wednesday.hours || []) as Hours[];
        acc.wednesday.hours = acc.wednesday.hours.concat(item.constraints.hours as Hours[]);
        acc.wednesday.available = true;
      }
      if (item.thursday) {
        acc.thursday.hours = (acc.thursday.hours || []) as Hours[];
        acc.thursday.hours = acc.thursday.hours.concat(item.constraints.hours as Hours[]);
        acc.thursday.available = true;
      }
      if (item.friday) {
        acc.friday.hours = (acc.friday.hours || []) as Hours[];
        acc.friday.hours = acc.friday.hours.concat(item.constraints.hours as Hours[]);
        acc.friday.available = true;
      }
      if (item.saturday) {
        acc.saturday.hours = (acc.saturday.hours || []) as Hours[];
        acc.saturday.hours = acc.saturday.hours.concat(item.constraints.hours as Hours[]);
        acc.saturday.available = true;
      }
      if (item.sunday) {
        acc.sunday.hours = (acc.sunday.hours || []) as Hours[];
        acc.sunday.hours = acc.sunday.hours.concat(item.constraints.hours as Hours[]);
        acc.sunday.available = true;
      }
      return acc;
    }, menuRowConstraint);
  }

  static optimizeGroupedConstraints(groupedConstraints: GroupedHourConstraints[]): GroupedHourConstraints[] {
    return groupedConstraints;
  }

  private static findMatchingDays(days: GroupedHourConstraints[]): GroupedHourConstraints[] {
    if (days.length === 0) {
      return [];
    }

    const matchingResult = this.findMatchingDaysWithFirstDays(days);
    const groupedHourConstraints = MenuScheduleUtils.buildGroupedHourConstraint(matchingResult.matching);
    if (matchingResult.unMatching.length === 0) {
      return [groupedHourConstraints];
    }
    return [groupedHourConstraints].concat(MenuScheduleUtils.findMatchingDays(matchingResult.unMatching));
  }

  private static buildGroupedHourConstraint(days: GroupedHourConstraints[]): GroupedHourConstraints {
    const result = days[0];
    return days.reduce(
      (acc, item) => ({
        constraints: acc.constraints,
        monday: acc.monday || item.monday,
        tuesday: acc.tuesday || item.tuesday,
        wednesday: acc.wednesday || item.wednesday,
        thursday: acc.thursday || item.thursday,
        friday: acc.friday || item.friday,
        saturday: acc.saturday || item.saturday,
        sunday: acc.sunday || item.sunday,
      }),
      result,
    );
  }

  private static findMatchingDaysWithFirstDays(days: GroupedHourConstraints[]): MatchingResult {
    const matching: GroupedHourConstraints[] = [];
    const unMatching: GroupedHourConstraints[] = [];

    days.forEach((comparingDay) => {
      if (isEqual(days[0].constraints, comparingDay.constraints)) {
        matching.push(comparingDay);
      } else {
        unMatching.push(comparingDay);
      }
    });

    return {
      matching,
      unMatching,
    };
  }
}
