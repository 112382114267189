import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { GoToSvg, IconWrapper, Loader } from '@sundayapp/b2b-react-component-library';
import { AreaDetailsGroup } from './AreaDetailsGroup';
import { Area } from '../../../domain/Area';
import { hasServiceAtTable } from '../../../utils/hasServiceAtTable';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { AreaDetailsTableAssociationPanel } from './AreaDetailsTableAssociationPanel';
import { AreaDetailsTableDisplayPanel } from './AreaDetailsTableDisplayPanel';
import { Table } from '../../../../venues/domain/Table';
import { SundayDevice } from '../../../domain/SundayDevice';
import { useGetTableSundayDevices } from '../../../hooks';
import { PlusIcon } from '../../../../common/components/Icons';
import IconButton from '../../../../common/components/IconButton';
import { AreaDetailsBoxSettingsContainer } from './AreaDetailsBoxSettings.style';
import { useGetVenueTables } from '../../../../venues/hook';
import { EnrollmentId } from 'src/business/domain/Enrollment';

type AreaDetailsServiceAtTableSettingsProps = {
  enrollmentId: EnrollmentId;
  area: Area;
};

const Container = styled.div`
  padding-bottom: ${getSpacing(1)};
`;

const SeeTablesPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorPalette.grey200};
  padding: ${getSpacing(2)};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  width: 100%;
  cursor: pointer;
`;

const SeeTablesText = styled.div`
  font-size: 18px;
  line-height: 20px;
  padding-right: ${getSpacing(2)};
`;

const LinkTables = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

const LinkTablesText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${colorPalette.grey600};
  margin-left: ${getSpacing(1)};
`;

export const AreaDetailsServiceAtTableSettings = ({ enrollmentId, area }: AreaDetailsServiceAtTableSettingsProps) => {
  const intl = useIntl();

  const [tables, setTables] = useState<Table[]>([]);
  const [getTablesStatus, getTables] = useGetVenueTables();

  useEffect(() => {
    getTables(enrollmentId).then(setTables);
  }, [getTables, enrollmentId]);

  const [sundayDevices, updateSundayDeviceMap] = useState<Map<string, SundayDevice>>(new Map());
  const [getTableSundayDevicesStatus, getTableSundayDevices] = useGetTableSundayDevices();

  const onTableAssociationUpdated = useCallback(() => {
    getTableSundayDevices(area.id).then((foundSundayDevices) => {
      const sundayDeviceMap = new Map();
      foundSundayDevices.forEach((device) => {
        sundayDeviceMap.set(device.tableId, device);
      });
      updateSundayDeviceMap(sundayDeviceMap);
    });
  }, [getTableSundayDevices, area]);

  useEffect(() => {
    onTableAssociationUpdated();
  }, [onTableAssociationUpdated]);

  const [shouldShowTables, updateShowTables] = useState<boolean>(false);
  const [shouldLinkTables, updateLinkTables] = useState<boolean>(false);
  const containServiceAtTable = useMemo(() => hasServiceAtTable(area), [area]);
  if (!containServiceAtTable) {
    return <></>;
  }

  const showTables = () => {
    updateLinkTables(false);
    updateShowTables(true);
  };

  const linkTables = () => {
    updateShowTables(false);
    updateLinkTables(true);
  };

  if (getTablesStatus.loading || getTableSundayDevicesStatus.loading) {
    return <Loader size="large" />;
  }

  return (
    <AreaDetailsBoxSettingsContainer>
      <Container>
        <AreaDetailsGroup
          title={intl.formatMessage({
            id: 'settings.area.details.service-at-table-group.title',
            defaultMessage: 'service at table',
          })}
        >
          <SeeTablesPanel onClick={showTables}>
            <SeeTablesText>
              <FormattedMessage
                id="settings.area.details.service-at-table.see-all-table"
                defaultMessage="see all table"
              />
            </SeeTablesText>
            <IconWrapper size="small" color={colorPalette.grey500}>
              <GoToSvg />
            </IconWrapper>
          </SeeTablesPanel>
          <LinkTables onClick={linkTables}>
            <IconButton Icon={PlusIcon} onClick={linkTables} color={colorPalette.defaultTextColor} />
            <LinkTablesText>
              <FormattedMessage
                id="settings.area.details.service-at-table.associate-tables"
                defaultMessage="link new table to an area"
              />
            </LinkTablesText>
          </LinkTables>
        </AreaDetailsGroup>
        {shouldLinkTables && (
          <AreaDetailsTableAssociationPanel
            areaId={area.id}
            tables={tables}
            setTables={setTables}
            onTableUpdated={onTableAssociationUpdated}
            isOpened={shouldLinkTables}
            onClosed={() => updateLinkTables(false)}
          />
        )}
        {shouldShowTables && (
          <AreaDetailsTableDisplayPanel
            isOpened={shouldShowTables}
            areaId={area.id}
            tables={tables}
            sundayDevices={sundayDevices}
            onClosed={() => updateShowTables(false)}
          />
        )}
      </Container>
    </AreaDetailsBoxSettingsContainer>
  );
};
