import React from 'react';
import { Loader } from '@sundayapp/b2b-react-component-library';
import { useAuthentication } from '../../hooks/useAuthentication';

/* eslint-disable react/jsx-props-no-spreading */
const SignIn = () => {
  const authentication = useAuthentication();
  authentication.logIn();
  return (<Loader clearLoader size="large" />);
};

export default SignIn;
