import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { MoneyView } from '@sundayapp/web-money';
import * as React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { RowProps, salesSummaryRevenueNames } from './RowProps';
import { paymentMethodIcon } from 'src/components/icons/paymentIcons/PaymentIcons';
import { EndOfServiceConfiguration } from '../model/EndOfServiceConfiguration';
import { ServiceCharge } from './ServiceCharge';

type SingleSalesRowProps = {
  revenue: RowProps;
  isRoot?: boolean;
  hideTips: boolean;
  eosConfiguration: EndOfServiceConfiguration;
};

export const RowWithoutDetails = ({
  revenue,
  isRoot,
  hideTips,
  eosConfiguration,
}: SingleSalesRowProps) => {
  const intl = useIntl();

  const headChildStyle = {
    color: isRoot ? 'inherit' : '#70707B',
    background: isRoot ? 'inherit' : '#FAFAFA',
  };
  const childStyle = {
    color: '#70707B',
    background: isRoot ? 'inherit' : '#FAFAFA',
  };

  const localisationKey = salesSummaryRevenueNames.find(v => v === revenue.name);
  const label = localisationKey ? intl.formatMessage({ id: localisationKey }) : revenue.name;


  return (
    <TableRow>
      <TableCell sx={headChildStyle}>
        <Box display="flex" gap="10px" paddingLeft={'2.3rem'}>
          {paymentMethodIcon(revenue.name)}
          <Typography alignSelf="center" color={isRoot ? 'black' : '#70707B'}>
            { label }
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={headChildStyle} align="right" color={isRoot ? 'black' : '#70707B'}>
        <Typography>
          { revenue.numberOfPayment }
        </Typography>
      </TableCell>
      <TableCell sx={headChildStyle} align="right" color={isRoot ? 'black' : '#70707B'}>
        <MoneyView value={revenue.total} />
      </TableCell>
      {!hideTips &&
        <TableCell sx={childStyle} align="right">
          <MoneyView value={revenue.revenue} />
        </TableCell>
      }
      {!hideTips &&
        <TableCell sx={childStyle} align="right">
          <MoneyView value={revenue.tips} />
        </TableCell>
      }
      { eosConfiguration.displayServiceCharge &&
        <TableCell sx={childStyle} align="right">
          <ServiceCharge revenue={revenue} />
        </TableCell>
      }
    </TableRow>
  );
};

RowWithoutDetails.defaultProps = {
  hasDetails: false,
  isRoot: false,
  isLeaf: false,
};
