import { Box, Card, styled } from '@mui/material';
import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import BreakdownByVenueImg from '../assets/BreakdownByVenue.svg?react';
import { CustomerEngagementByVenue } from '../../domain/RewardProgramKpis';
import { fromDomain } from './CustomerEngagementViewModel';
import { BreakdownByVenueNoDataOverlay } from './BreakdownByVenueNoDataOverlay';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const StyledCard = styled(Card)`
  max-width: 100%;
`;

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  font-size: 1rem;

  > * + * {
    margin-left: 1rem;
  }
`;

const Title = styled('div')`
  font-size: 1rem;
`;

const GridHeaderCell = styled('span')`
  color: #70707b;
`;

const BreakdownByVenueDataGrid = styled(DataGrid)(() => ({
  'letter-spacing': '-0.01em',
  '& .selectedVenue': {
    backgroundColor: 'rgba(221, 221, 228, 0.25)',
  },
}));

type Props = {
  customerEngagementByVenue: CustomerEngagementByVenue[];
};

export const BreakdownByVenueCard = ({ customerEngagementByVenue }: Props) => {
  const intl = useIntl();
  const useCurrentBusiness = useCurrentBusinessOrThrow();

  const keepCurrentVenueAtFirstPosition = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customerEngagementByVenue.sort((a, _) => (a.venueId === useCurrentBusiness.id ? -1 : 1));
  };

  const rows = () => {
    keepCurrentVenueAtFirstPosition();
    return customerEngagementByVenue.map((item) => ({
      id: item.venueId,
      venueName: item.venueName,
      ...fromDomain(item.customerEngagement, intl.locale),
    }));
  };

  const columnGroupingModel = [
    {
      groupId: 'Basic info',
      colSpan: 6,
      headerClassName: 'headerGroupWithoutBorder',
      children: [
        { field: 'venueName' },
        { field: 'nbActiveLoyaltyCustomers' },
        { field: 'loyaltyParticipationRate' },
        { field: 'nbRewardsEarned' },
        { field: 'nbRewardsRedeemed' },
        { field: 'nbNewLoyaltySignUps' },
      ],
      renderHeaderGroup: () => (
        <Box>
          <TitleContainer>
            <BreakdownByVenueImg />
            <Title>
              <FormattedMessage id="loyalty.program.kpi.breakdown.title" />
            </Title>
          </TitleContainer>
        </Box>
      ),
    },
  ];

  const columns = (): GridColDef[] => [
    {
      field: 'venueName',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <GridHeaderCell>
          <FormattedMessage id="loyalty.program.kpi.breakdown.venue_name" />
        </GridHeaderCell>
      ),
    },
    {
      field: 'nbActiveLoyaltyCustomers',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <GridHeaderCell>
          <FormattedMessage id="loyalty.program.kpi.breakdown.active_loyalty_customers" />
        </GridHeaderCell>
      ),
    },
    {
      field: 'loyaltyParticipationRate',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <GridHeaderCell>
          <FormattedMessage id="loyalty.program.kpi.breakdown.loyalty_participation_rate" />
        </GridHeaderCell>
      ),
    },
    {
      field: 'nbRewardsEarned',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <GridHeaderCell>
          <FormattedMessage id="loyalty.program.kpi.breakdown.rewards_earned" />
        </GridHeaderCell>
      ),
    },
    {
      field: 'nbRewardsRedeemed',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <GridHeaderCell>
          <FormattedMessage id="loyalty.program.kpi.breakdown.rewards_redeemed" />
        </GridHeaderCell>
      ),
    },
    {
      field: 'nbNewLoyaltySignUps',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <GridHeaderCell>
          <FormattedMessage id="loyalty.program.kpi.breakdown.new_loyalty_sign_ups" />
        </GridHeaderCell>
      ),
    },
  ];

  return (
    <Box>
      <BreakdownByVenueDataGrid
      autoHeight
      slots={{ noRowsOverlay: BreakdownByVenueNoDataOverlay }}
      rows={rows()}
      columns={columns()}
      getRowClassName={(params) => (params.row.id === useCurrentBusiness.id ? 'selectedVenue' : '')}
      disableRowSelectionOnClick
      hideFooter
      columnGroupingModel={columnGroupingModel}
    />
    </Box>
  );
};
