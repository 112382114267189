import { useAsync } from 'react-use';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { RewardProgram } from '../domain/RewardProgram';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { RewardProgramRepository } from '../infrastructure/RewardProgramRepository';
import { RewardProgramKpisOverview } from './kpis/RewardProgramKpisOverview';

export const RewardProgramDetails = ({ rewardProgram }: { rewardProgram: RewardProgram }) => {
  const configuration = ConfigurationLoader.load();
  const repository = new RewardProgramRepository(configuration.loyaltyApiBaseUrl);

  const { value: rewardProgramKpis, loading } = useAsync(async () => repository.getKpis(rewardProgram.id), [rewardProgram.id]);

  return (
    <>
      {!loading && rewardProgramKpis && (
        <RewardProgramKpisOverview rewardProgram={rewardProgram} rewardProgramKpis={rewardProgramKpis} />
      )}
      {loading && (<CircularProgress />)}
    </>
  );
};
