import { Stack } from '@mui/material';
import { AccountNumber } from 'src/payments/components/Checkout/CheckoutOnboardingFR/BankAccount/AccountNumber';
import { BankCode } from 'src/payments/components/Checkout/CheckoutOnboardingFR/BankAccount/BankCode';
import React from 'react';

export const UkBankInfo = () => <Stack
  direction="column" spacing={3}>
  <AccountNumber />
  <BankCode />
</Stack>;
