import { EnrollmentId } from 'src/business/domain/Enrollment';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { TipsSuggestion } from 'src/pages/Settings/AppSettings/tips/domain/TipsSuggestion';
import { useSetTipsSuggestion } from 'src/pages/Settings/AppSettings/tips/mutation/useSetTipsSuggestion';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, CardContent, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TipsGeneralConfiguration } from 'src/pages/Settings/AppSettings/components/TipsGeneralConfiguration';
import { SundayCustomerExperience } from 'src/pages/Settings/AppSettings/components/SundayCustomerExperience';
import { BusinessCustomerExperience } from 'src/pages/Settings/AppSettings/components/BusinessCustomerExperience';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const TipsConfigurationLoaded2 = ({
  enrollmentId,
  product,
  tipsConfig,
}: { enrollmentId: EnrollmentId, product: ServiceType, tipsConfig: TipsSuggestion }) => {
  const {
    saveTips,
    mutation: { isPending },
  } = useSetTipsSuggestion(enrollmentId);
  const form = useForm<TipsSuggestion>({
    defaultValues: tipsConfig,
    values: tipsConfig,
  });
  const [tipAreTheSameError, setTipAreTheSameError] = useState(false);

  const firstTip = form.watch('tippingPercentageFirst')
    .toString();
  const secondTip = form.watch('tippingPercentageSecond')
    .toString();
  const thirdTips = form.watch('tippingPercentageThird')
    .toString();

  useEffect(function validateThatTipAreNotTheSame() {
    return setTipAreTheSameError(firstTip === secondTip || firstTip === thirdTips || secondTip === thirdTips);
  }, [firstTip, secondTip, thirdTips]);

  return <FormProvider {...form}>
    <form onSubmit={form.handleSubmit((data) => saveTips(data)
      .finally(() => form.reset(data)))}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="flex-end">
          <Button type="submit" variant="contained" disabled={isPending || tipAreTheSameError || !form.formState.isDirty}>
            <FormattedMessage id={'settings.app_settings.tips.save_button'} />
          </Button>
        </Stack>
        <Stack spacing={4}>
          <Card>
            <CardContent>
              <TipsGeneralConfiguration />
            </CardContent>
          </Card>
          {form.watch('tippingEnabled') && (
            <Card>
              <CardContent>
                {form.watch('tipsConfigurationMode') === 'SUNDAY' && <SundayCustomerExperience />}
                {form.watch('tipsConfigurationMode') === 'BUSINESS' && <BusinessCustomerExperience product={product} tipAreTheSameError={tipAreTheSameError} />}
              </CardContent>
            </Card>
          )}
        </Stack>
      </Stack>
    </form>
  </FormProvider>
  ;
};
