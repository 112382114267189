import React from 'react';
import { StaffAllocation } from 'src/tips/dispatch/model/StaffAllocation';
import { Checkbox, Grid, Input, InputAdornment, Stack, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { useRenderMoney } from '@sundayapp/web-money';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const StaffDispatch = ({
  staffAllocations,
  onUpdateStaffWeeklyWorkingHours,
  onUpdateAllStaffWeeklyWorkingHours,
}: {
  staffAllocations: StaffAllocation[];
  onUpdateStaffWeeklyWorkingHours: (staffId: string, weeklyWorkedHours: number) => void;
  onUpdateAllStaffWeeklyWorkingHours: (weeklyWorkedHours: number) => void;
}) => {
  const renderMoney = useRenderMoney(true, false);

  const handleWeeklyWorkedHoursChange = (staffId: string, candidateWeeklyWorkedHours: string) => {
    const parsedWeeklyWorkedHours = parseInt(candidateWeeklyWorkedHours);
    onUpdateStaffWeeklyWorkingHours(staffId, Number.isNaN(parsedWeeklyWorkedHours) ? 0 : parsedWeeklyWorkedHours);
  };

  const handleStaffSelection = (selected: boolean, staffId: string) => {
    onUpdateStaffWeeklyWorkingHours(staffId, selected ? 40 : 0);
  };

  const handleAllStaffSelection = (selected: boolean) => {
    onUpdateAllStaffWeeklyWorkingHours(selected ? 40 : 0);
  };

  const staffColor = (weeklyWorkedHours: number) => {
    return weeklyWorkedHours > 0 ? themeV5.palette.text.primary : themeV5.palette.text.secondary;
  };

  return (
    <Stack spacing={2.4} direction={'column'}>
      <Stack spacing={0.75} direction={'column'}>
        <Typography variant={'h6'}>
          <FormattedMessage id={'tips.sunday_pooling.dispatch.configure.title'} />
        </Typography>
        <Typography variant={'body2'} color={themeV5.palette.text.secondary}>
          <FormattedMessage id={'tips.sunday_pooling.dispatch.configure.description'} />
        </Typography>
      </Stack>

      <Stack direction={'column'} spacing={1.2}>
        <Grid container color={themeV5.palette.text.secondary}>
          <Grid item xs={1} alignContent={'center'}>
            <Checkbox defaultChecked onChange={(event) => handleAllStaffSelection(event.target.checked)} />
          </Grid>
          <Grid item xs={6} alignContent={'center'}>
            <Typography variant={'body1'}>
              <FormattedMessage id={'tips.sunday_pooling.dispatch.staff_name'} />
            </Typography>
          </Grid>
          <Grid item xs={3} alignContent={'center'}>
            <Typography variant={'body1'} align={'right'}>
              <FormattedMessage id={'tips.sunday_pooling.dispatch.staff_weekly_worked_hours'} />
            </Typography>
          </Grid>
          <Grid item xs={2} alignContent={'center'}>
            <Typography variant={'body1'} align={'right'}>
              <FormattedMessage id={'tips.sunday_pooling.report.staff_receive'} />
            </Typography>
          </Grid>
        </Grid>
        {staffAllocations.map((staffAllocation) => (
          <Grid container key={staffAllocation.staffId} padding={'0.5rem 0 0 0'}>
            <Grid item xs={1} alignContent={'center'}>
              <Checkbox
                id={staffAllocation.staffId}
                checked={staffAllocation.weeklyWorkedHours > 0}
                onChange={(event) => handleStaffSelection(event.target.checked, staffAllocation.staffId)}
              />
            </Grid>
            <Grid item xs={6} alignContent={'center'}>
              <label htmlFor={staffAllocation.staffId}>
                <Typography variant={'body1'} color={staffColor(staffAllocation.weeklyWorkedHours)}>
                  {staffAllocation.staffName}
                </Typography>
              </label>
            </Grid>
            <Grid item xs={3} textAlign={'right'} alignContent={'center'}>
              <Input
                value={staffAllocation.weeklyWorkedHours}
                size={'small'}
                sx={{
                  textAlignLast: 'end',
                  alignItems: 'center',
                  width: '3rem',
                  '& .MuiInput-input': {
                    padding: 0,
                  },
                }}
                endAdornment={<InputAdornment position="end">h</InputAdornment>}
                onChange={(event) => handleWeeklyWorkedHoursChange(staffAllocation.staffId, event.target.value)}
              ></Input>
            </Grid>
            <Grid item xs={2} alignContent={'center'}>
              <Typography variant={'body1'} color={themeV5.palette.text.secondary} align={'right'}>
                {renderMoney(staffAllocation.allocation)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Stack>
  );
};
