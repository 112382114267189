import { Card, CardContent, Chip, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import AvatarImg from '../assets/Avatar.svg?react';
import { CustomerEngagementViewModel } from './CustomerEngagementViewModel';
import { CustomerEngagementVariationViewModel, SAME, variationColor } from './CustomerEngagementVariationViewModel';

const StyledCard = styled(Card)`
  max-width: 100%;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:0 1em 0 1em;
`;

const Header = styled('div')`
  font-size: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: 1rem;
`;

const Title = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  > *+* {
    margin-left: 1rem;
  }
`;

const Content = styled('div')`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
 `;

const ActiveCustomerDetail = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  letter-spacing: -0.5px;

  > *+* {
    margin-left: 0.5rem;
    letter-spacing: -0.3px;
  }
`;

const KpiDetail = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: baseline;
  letter-spacing: -0.5px;

  > *+* {
    margin-left: 0.5rem;
    letter-spacing: -0.3px;
  }
`;

const ActiveCustomersValue = styled('div')`
  font-size: 3rem;
  letter-spacing: -3px;
`;

const ActiveCustomersVariation = styled(Chip)`
  font-size: 0.8rem;
  letter-spacing: -0.3px;
  position: relative;
  bottom: -0.3em;
`;

const Kpi = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const KpiName = styled('div')`
  font-size: 0.9rem;
`;

const KpiValue = styled('div')`
  font-size: 1.3rem;

  text-align: right;
  min-width: 3rem;
`;

const KpiVariation = styled('div')<{ color: string }>`
  color: ${(p) => p.color};
  font-size: 0.9rem;
  min-width: 1.6rem;
`;

type Props = {
  customerEngagement: CustomerEngagementViewModel;
  variation: CustomerEngagementVariationViewModel;
  displayVariation: boolean;
};

export const CustomerEngagementCard = ({ customerEngagement, variation, displayVariation }: Props) => {
  const theme = useTheme();

  return (
    <StyledCard>
      <StyledCardContent>
        <Header>
          <Title>
            <AvatarImg />
            <div>
              <Typography color={theme.palette.text.secondary} variant="subtitle2"><FormattedMessage id="loyalty.program.kpi.engagement.customer_engagement" /></Typography>
              <Typography variant="subtitle1"><FormattedMessage id="loyalty.program.kpi.engagement.active_loyalty_customers" /></Typography>
            </div>
          </Title>
          <ActiveCustomerDetail>
            <ActiveCustomersValue>{customerEngagement.nbActiveLoyaltyCustomers}</ActiveCustomersValue>
            {displayVariation && (
              <ActiveCustomersVariation
                color={(variation.nbActiveLoyaltyCustomers.status === 'greater' || variation.nbActiveLoyaltyCustomers.value === SAME) ? 'success' : 'default'}
                label={variation.nbActiveLoyaltyCustomers.value}
              />
            )}
          </ActiveCustomerDetail>
        </Header>
        <Content>
          <Kpi>

            <KpiName><FormattedMessage id="loyalty.program.kpi.engagement.loyalty_participation_rate" /></KpiName>
            <KpiDetail>
              <KpiValue>{customerEngagement.loyaltyParticipationRate}</KpiValue>
              {displayVariation && (
                <KpiVariation color={variationColor(variation.loyaltyParticipationRate.status)}>{variation.loyaltyParticipationRate.value}</KpiVariation>
              )}
            </KpiDetail>
          </Kpi>
          <Kpi>
            <KpiName><FormattedMessage id="loyalty.program.kpi.engagement.rewards_earned" /></KpiName>
            <KpiDetail>
              <KpiValue>{customerEngagement.nbRewardsEarned}</KpiValue>
              {displayVariation && (
                <KpiVariation color={variationColor(variation.nbRewardsEarned.status)}>{variation.nbRewardsEarned.value}</KpiVariation>
              )}
            </KpiDetail>
          </Kpi>
          <Kpi>
            <KpiName><FormattedMessage id="loyalty.program.kpi.engagement.rewards_redeemed" /></KpiName>
            <KpiDetail>
              <KpiValue>{customerEngagement.nbRewardsRedeemed}</KpiValue>
              {displayVariation && (
                <KpiVariation color={variationColor(variation.nbRewardsRedeemed.status)}>{variation.nbRewardsRedeemed.value}</KpiVariation>
              )}
            </KpiDetail>
          </Kpi>
          <Kpi>
            <KpiName><FormattedMessage id="loyalty.program.kpi.engagement.new_loyalty_sign_ups" /></KpiName>
            <KpiDetail>
              <KpiValue>{customerEngagement.nbNewLoyaltySignUps}</KpiValue>
              {displayVariation && (
                <KpiVariation color={variationColor(variation.nbNewLoyaltySignUps.status)}>{variation.nbNewLoyaltySignUps.value}</KpiVariation>
              )}
            </KpiDetail>
          </Kpi>
        </Content>
      </StyledCardContent>
    </StyledCard>
  );
};
