import {
  CurrencyCode,
  differenceMoney, money, sumMoneys,
} from '@sundayapp/web-money';
import { PaymentOverSunday } from 'src/bills/domain/orders/types';

export class PaymentOverSundayForAccounting {
  constructor(
    public paymentOverSunday: PaymentOverSunday,
  ) {
  }

  get currency() {
    return this.paymentOverSunday.orderAmount.currency;
  }

  private refundAmount() {
    return !this.paymentOverSunday.refunds
      ? money(0, this.paymentOverSunday.orderAmount.currency as CurrencyCode)
      : Object.entries(this.paymentOverSunday.refunds).reduce((total, [, refund]) => sumMoneys(total, refund.amount), money(0, this.paymentOverSunday.orderAmount.currency));
  }

  get paidAmountWithDeducedRefunds() {
    const paidAmountHavingDeducedRefunds = differenceMoney(this.paymentOverSunday.orderAmount, this.refundAmount());
    return money(Math.max(0, paidAmountHavingDeducedRefunds.amount), this.paymentOverSunday.orderAmount.currency);
  }

  get tipsAmountWithDeduceRefunds() {
    const excessOfRefundOnPaidAmount = differenceMoney(this.paymentOverSunday.orderAmount, this.refundAmount());
    if (excessOfRefundOnPaidAmount.amount < 0) {
      return sumMoneys(excessOfRefundOnPaidAmount, this.paymentOverSunday.tipsAmount);
    }
    return this.paymentOverSunday.tipsAmount;
  }
}
