import { ValuesOf } from '../utils/typescript/valuesOf';

const SETTINGS_BASE_URL = '/settings';
const ACCOUNTING_BASE_URL = '/accounting';
const TIPS_URL = '/tips';
export const RECONCILIATION_BASE_URL = '/reconciliation';

export type RouteDefinition = ValuesOf<typeof RouteDefinitions>;

export const RouteDefinitions = {
  Home: {
    path: '/home',
    name: 'home',
  },
  Home2: {
    path: '/home2',
    name: 'home2',
  },
  Auth0Impersonation: {
    path: '/auth0-impersonation',
    name: 'auth0-impersonation',
  },
  FirebaseAuthenticationRedirect: {
    path: '/auth-redirect',
    name: 'auth-redirect',
  },
  CompleteSignUp: {
    path: '/complete-sign-up',
    name: 'complete-sign-up',
  },
  SignIn: {
    path: '/sign-in',
    name: 'sign-in',
  },
  SignInConfirmation: {
    path: '/sign-in-confirmation',
    name: 'sign-in-confirmation',
  },
  SignOut: {
    path: '/sign-out',
    name: 'sign-out',
  },
  AuthenticationError: {
    path: '/authentication-error',
    name: 'authentication-error',
  },
  NoVenue: {
    path: '/no-venue',
    name: 'no-venue',
  },

  Settings: {
    path: `${SETTINGS_BASE_URL}`,
    name: 'settings',
  },
  VenueInformation: {
    path: `${SETTINGS_BASE_URL}/information`,
    name: 'settings-information',
  },
  ShiftSettings: {
    path: `${SETTINGS_BASE_URL}/shifts`,
    name: 'settings-shift',
  },
  OrderingSettings: {
    path: `${SETTINGS_BASE_URL}/ordering`,
    name: 'settings-ordering',
  },
  PaymentsSettings: {
    path: `${SETTINGS_BASE_URL}/payments`,
    name: 'settings-payments',
  },
  PaymentsCheckoutOnboarding: {
    path: `${SETTINGS_BASE_URL}/checkout-onboarding`,
    name: 'onboarding-checkout-payments',
  },
  PaymentsCheckoutOnboardingFormSent: {
    path: `${SETTINGS_BASE_URL}/checkout-onboarding-form-sent`,
    name: 'onboarding-checkout-payments-form-sent',
  },
  PaymentsFinalizeCheckoutOnboarding: {
    path: `${SETTINGS_BASE_URL}/finalize-checkout-onboarding`,
    name: 'finalize-onboarding-checkout-payments',
  },
  RevenueCentersSettings: {
    path: `${SETTINGS_BASE_URL}/revenue-centers`,
    name: 'settings-revenue-centers',
  },
  ReconciliationSettings: {
    path: `${SETTINGS_BASE_URL}/reconciliation`,
    name: 'settings-reconciliation',
  },
  AppSettings: {
    path: `${SETTINGS_BASE_URL}/settings`,
    name: 'settings-settings',
  },
  Customization: {
    path: `${SETTINGS_BASE_URL}/customization`,
    name: 'settings-customization',
  },
  ReviewsSettings: {
    path: `${SETTINGS_BASE_URL}/reviews`,
    name: 'settings-reviews',
  },
  OrderingAppSettings: {
    path: `${SETTINGS_BASE_URL}/ordering-app-settings`,
    name: 'ordering-app-settings',
  },
  MenuSettings: {
    path: `${SETTINGS_BASE_URL}/menu`,
    name: 'menu-settings',
  },
  areasSettings: {
    path: `${SETTINGS_BASE_URL}/areas`,
    name: 'settings-areas',
  },
  boxAreaSettings: {
    path: `${SETTINGS_BASE_URL}/areas/:boxId`,
    name: 'settings-box-areas',
  },
  areaCreateSettings: {
    path: `${SETTINGS_BASE_URL}/areas/:boxId/create`,
    name: 'settings-area-creation',
  },
  areaDetailsSettings: {
    path: `${SETTINGS_BASE_URL}/areas/:boxId/:areaId`,
    name: 'settings-area',
  },
  UserPermissions: {
    path: `${SETTINGS_BASE_URL}/users`,
    name: 'settings-users',
  },
  UsersFaq: {
    path: `${SETTINGS_BASE_URL}/users/faq`,
    name: 'faq',
  },
  ConfigurationReplication: {
    path: `${SETTINGS_BASE_URL}/configuration-replication`,
    name: 'configuration-replication',
  },
  DataAnalytics: {
    path: '/data-analytics',
    name: 'data-analytics',
  },
  DataAnalyticsOverview: {
    path: '/data-analytics/overview',
    name: 'data-analytics-overview',
  },
  DataAnalyticsBusinessPerformance: {
    path: '/data-analytics/business-performance',
    name: 'data-analytics-business-performance',
  },
  DataAnalyticsCustomerInsights: {
    path: '/data-analytics/customer-insights',
    name: 'data-analytics-customer-insights',
  },
  DataAnalyticsEnterpriseDashboard: {
    path: '/data-analytics/enterprise-dashboard',
    name: 'data-analytics-enterprise-dashboard',
  },
  DataAnalyticsTips: {
    path: '/data-analytics/tips',
    name: 'data-analytics-tips',
  },
  DataAnalyticsRepeatKpis: {
    path: '/data-analytics/repeat-kpis',
    name: 'data-analytics-repeat-kpis',
  },
  StaticMenus: {
    path: '/menu-manager/static-menus',
    name: 'menu-manager-static-menus',
  },
  StaticMenuDetails: {
    path: '/menu-manager/static-menus/:menuId',
    name: 'menu-manager-menu-details',
  },
  MenuManager: {
    path: '/menu-manager',
    name: 'menu-manager',
  },
  DigitalMenus: {
    path: '/menu-manager/menus',
    name: 'menu-manager-menus',
  },
  DigitalMenuDetails: {
    path: '/menu-manager/menus/:menuId',
    name: 'menu-manager-menu-details',
  },
  Products: {
    path: '/menu-manager/products',
    name: 'menu-manager-products',
  },
  NewProduct: {
    path: '/menu-manager/products/new',
    name: 'menu-manager-create-product',
  },
  ProductDetails: {
    path: '/menu-manager/products/:productId',
    name: 'menu-manager-product-details',
  },
  Blocks: {
    path: '/menu-manager/blocks',
    name: 'menu-manager-blocks',
  },
  BlocksDetails: {
    path: '/menu-manager/blocks/:blockId',
    name: 'menu-manager-block-details',
  },
  Allergens: {
    path: '/menu-manager/allergens',
    name: 'menu-manager-allergens',
  },

  QRCodes: {
    path: '/qr-codes',
    name: 'qr-codes',
  },

  SundayPlus: {
    path: '/sunday-plus',
    name: 'sunday-plus',
  },
  Reviews: {
    path: '/sunday-plus/reviews',
    name: 'reviews',
  },
  ReplyToReviews: {
    path: '/sunday-plus/reviews-reply',
    name: 'reviews-reply',
  },
  ReviewsInsights: {
    path: '/sunday-plus/insights',
    name: 'reviews-insights',
  },
  ReviewsEReputation: {
    path: '/sunday-plus/e-reputation',
    name: 'e-reputation',
  },
  ReviewsGuestExperience: {
    path: '/sunday-plus/guest-experience',
    name: 'guest-experience',
  },
  CustomerData: {
    path: '/sunday-plus/customerUsersFaq-data',
    name: 'customer-data',
  },
  RewardProgram: {
    path: '/sunday-plus/reward-program',
    name: 'reward-program',
  },
  LoyaltyOnboarding: {
    path: '/loyalty-onboarding',
    name: 'loyalty onboarding',
  },
  PongoLoyalty: {
    path: '/loyalty',
    name: 'loyalty',
  },
  PongoLoyaltyOauthRedirect: {
    path: '/loyalty-oauth-redirect',
    name: 'loyalty-oauth-redirect',
  },
  FinalizeCheckoutOnboardingRedirect: {
    path: '/finalize-checkout-onboarding',
    name: 'finalize-checkout-onboarding-redirect',
  },

  StripeSetupDone: {
    path: '/stripe-setup-done',
    name: 'stripe-setup-done',
  },
  PaymentLinks: {
    path: '/payment-links',
    name: 'payment-links',
  },
  PaymentLinksNew: {
    path: '/payment-links/new',
    name: 'payment-links-new',
  },
  PaymentLinkPanel: {
    path: '/payment-links/:sessionId',
    name: 'payment-link-panel',
  },
  PaymentLinksReadyToShare: {
    path: '/payment-links/ready-to-share',
    name: 'payment-links-ready-to-share',
  },
  Accounting: {
    path: `${ACCOUNTING_BASE_URL}`,
    name: 'accounting',
  },
  Reporting: {
    path: `${ACCOUNTING_BASE_URL}/reporting`,
    name: 'reporting',
  },
  Payouts: {
    path: `${ACCOUNTING_BASE_URL}/payouts`,
    name: 'payouts',
  },
  Invoices: {
    path: `${ACCOUNTING_BASE_URL}/invoices`,
    name: 'invoices',
  },
  HiddenInvoices: {
    path: `${ACCOUNTING_BASE_URL}/hidden-invoices`,
    name: 'hidden-invoices',
  },
  Clawbacks: {
    path: `${ACCOUNTING_BASE_URL}/clawbacks`,
    name: 'clawbacks',
  },
  Revenues: {
    path: `${ACCOUNTING_BASE_URL}/revenues`,
    name: 'revenues',
  },
  RevenuesDetails: {
    path: `${ACCOUNTING_BASE_URL}/revenues/details`,
    name: 'revenues-details',
  },

  Payments: {
    path: '/payments',
    name: 'payments',
  },
  TipsPromotion: {
    path: '/tips/tips-promotion',
    name: 'tips-promotion',
  },
  TipsDistribution: {
    path: '/tips/tips-distribution',
    name: 'tips-promotion',
  },
  TipsCollection: {
    path: '/tips/tips-collection',
    name: 'tips-collection',
  },
  TipsStaffRules: {
    path: '/tips/staff-rules',
    name: 'tips-staff-rules',
  },
  Tips: {
    path: `${TIPS_URL}`,
    name: 'tips',
  },
  SundayPooling: {
    path: '/sunday-pooling',
    name: 'sunday-pooling',
  },
  WaiterTipping: {
    path: '/tips/staff-rules/tipping',
    name: 'waiter-tipping',
  },
  WaiterTippingById: {
    path: '/tips/staff-rules/tipping/:waiterId',
    name: 'waiter-tipping',
  },
  WaiterHistory: {
    path: '/tips/staff-rules/history',
    name: 'waiter-history',
  },
  WaiterHistoryById: {
    path: '/tips/staff-rules/history/:waiterId',
    name: 'waiter-history',
  },
  PaymentTerminal: {
    path: '/payment-terminal',
    name: 'payment terminal',
  },
  SundayForRestaurants: {
    path: '/sundayForRestaurants',
    name: 'Sunday for restaurants',
  },
  PaymentsAsStartingPage: {
    path: '/',
    name: 'payments',
  },
  Bills: {
    path: '/bills',
    name: 'orders',
  },
  OPOrders: {
    path: '/op-orders',
    name: 'orders',
  },
  BoxStats: {
    path: '/boxes-stats',
    name: 'box stats',
  },
  FoodCourt: {
    path: '/food-court',
    name: 'food-court',
  },
  StaffPerformance: {
    path: '/staff-performance',
    name: 'staff-performance',
  },
  StartingPage: {
    path: '/',
    name: 'starting-page',
  },
  Venue: {
    path: 'venues/:venueId',
    name: 'venue',
  },
  RootPath: {
    path: '/',
    name: 'root',
  },
  CommunicationPreferences: {
    path: '/communication-preferences',
    name: 'communication-preferences',
  },
  EndOfService: {
    path: '/end-of-service',
    name: 'end of service',
  },
  OrganizationReport: {
    path: '/organization-report',
    name: 'organization report',
  },
  Bookings: {
    path: '/bookings',
    name: 'bookings',
  },
  Kds: {
    path: '/kds',
    name: 'kds',
  },

  OPVoucherCodes: {
    path: '/op-voucher-codes',
    name: 'op-voucher-codes',
  },
  VouchersClubHouse24: {
    path: '/op-voucher-codes-clubhouse24',
    name: 'op-voucher-codes-clubhouse24',
  },

  OPProductAvailability: {
    path: '/op-product-availability',
    name: 'op-product-availability',
  },
  Reconciliation: {
    path: RECONCILIATION_BASE_URL,
    name: 'reconciliation',
  },
  ReconciliationTabs: {
    path: `${RECONCILIATION_BASE_URL}/:reconciliationTab`,
    name: 'reconciliation tabs',
  },
  ReconciliationReports: {
    path: `${RECONCILIATION_BASE_URL}/reports`,
    name: 'reconciliation reports',
  },
  ReconciliationReportById: {
    path: `${RECONCILIATION_BASE_URL}/reports/:reportId`,
    name: 'reconciliation report',
  },
  WeeklyEmail: {
    path: '/weekly-email',
    name: 'weekly-email',
  },
  OpsTooling: {
    path: '/ops-tooling',
    name: 'ops-tooling',
  },
  TipsSetting: {
    path: '/ops-tooling/tips-settings',
    name: 'tips-settings',
  },
  PatSettings: {
    path: '/ops-tooling/pat-settings',
    name: 'pat-settings',
  },
  EnterpriseSettings: {
    path: '/ops-tooling/enterprise-settings',
    name: 'enterprise-settings',
  },
  PosPaymentSettings: {
    path: '/ops-tooling/pos-payments-settings',
    name: 'pos-payments-settings',
  },
  CheckoutLanding: {
    path: '/checkout-landing',
    name: 'checkout-landing',
  },
  Onboarding: {
    path: '/onboarding',
    name: 'onboarding',
  },
} as const;

export const isRouteInSettings = (route?: string) => route && route.includes(SETTINGS_BASE_URL);

export const subPath = (routeDefinition: RouteDefinition): string => routeDefinition.path.match(/[^/]*$/)![0];
