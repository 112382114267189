import { useNavigate } from 'react-router-dom';
import { ReactElement } from 'react';
import {
  venueDynamicMenuPath,
  venueDynamicMenusPath,
  venuePaymentsPath,
  venueSettingsAreaPath,
  venueSettingsAreasNewPath,
  venueSettingsAreasPath,
} from 'src/app/navigation/pathHelpers';
import { OrderingRoutingContextProvider } from 'src/ordering/OrderingRoutingContextProvider';
import { BusinessId } from 'src/business/domain/Business';

type MenuContextProviderProps = {
  children: ReactElement;
};

export const OrderAndPayRoutingContextProvider = ({ children }: MenuContextProviderProps) => {
  const navigateTo = useNavigate();
  const navigation = {
    goToMenuDetails: (businessId: BusinessId, menuId: string) => {
      navigateTo(venueDynamicMenuPath(businessId, menuId));
    },
    goToMenus: (businessId: BusinessId) => navigateTo(venueDynamicMenusPath(businessId)),
    goToPayments: (
      businessId: BusinessId,
      orderId: string,
      orderDisplayId: string,
      startDate: number,
      endDate: number,
    ): void => {
      navigateTo(
        `${venuePaymentsPath(businessId)}?orderDisplayId=${orderDisplayId}&startDate=${startDate}&endDate=${endDate}`,
      );
    },
    goToAreas: (businessId: BusinessId) => navigateTo(venueSettingsAreasPath(businessId)),
    goToArea: (businessId: BusinessId, boxId: string, areaId: string) => navigateTo(venueSettingsAreaPath(businessId, boxId, areaId)),
    goToAreaCreation: (businessId: BusinessId, boxId: string) => navigateTo(venueSettingsAreasNewPath(businessId, boxId)),
  };

  return (
    <OrderingRoutingContextProvider routing={navigation}>
      {children}
    </OrderingRoutingContextProvider>
  );
};
