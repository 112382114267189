// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Button, Modal, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router-dom';
import { businessStaticMenusPath } from 'src/app/navigation/pathHelpers';
import { useDeleteStaticMenu } from '../../infrastructure/mutations/useDeleteStaticMenu';
import { StaticMenuIdSchema } from '../../domain/StaticMenus';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

type MenuDeletionConfirmationModalProps = {
  menuId: string;
  menuName: string;
  isOpened: boolean;
  onClose: () => void;
};

const MenuDeletionConfirmationModal = ({ menuId, menuName, isOpened, onClose }: MenuDeletionConfirmationModalProps) => {
  const businessId = useBusinessIdOrThrow();
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const { deleteStaticMenu } = useDeleteStaticMenu();

  const onMenuDelete = async () => {
    await deleteStaticMenu(businessId, StaticMenuIdSchema.parse(menuId));
    navigate(businessStaticMenusPath(businessId));
  };

  return (
    <Modal
      title={formatMessage({ id: 'menus.delete_menu.modal.name' })}
      cancelButtonMessage={formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={onClose}
    >
      <Text size="small">{menuName}</Text>

      <Button
        type="submit"
        variant="primary"
        size="large"
        fullWidth
        marginTop={spaceUsages.largeMedium}
        onClick={onMenuDelete}
      >
        <FormattedMessage id="menus.delete_menu.modal.confirm" />
      </Button>
    </Modal>
  );
};

export default MenuDeletionConfirmationModal;
