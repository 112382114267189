import React, { useCallback, useContext } from 'react';
import { Level, LogPlugin, noLogPlugin } from './plugins';

export interface LogProviderProps {
  plugin: LogPlugin;
  children?: React.ReactNode;
}

export const LogContext = React.createContext<LogPlugin>(noLogPlugin);

export const useLog = () => {
  const log = useContext(LogContext);
  if (!log) {
    throw Error('Log must be used within a LogProvider');
  }
  return useCallback(
    (level: Level, message: string, error?: Error) => log.log(level, message, error),
    [log],
  );
};

export const LogProvider: React.FC<LogProviderProps> = ({ children, plugin }: LogProviderProps) =>
  <LogContext.Provider value={plugin}>{children}</LogContext.Provider>;
