import { TagDto } from './TagDto';
import { ProductSource, ProductType, productTypes, SnoozePeriod } from '../domain/Product';
import { AllergenDto } from './allergen/AllergenDto';

export interface MoneyDto {
  amount: number;
  currency: string;
}

export interface OverridableValueDto<T> {
  value?: T | null;
  originalValue?: T | null;
}

export interface ProductDto {
  id: string;
  name: string;
  partnerName: string;
  description: string;
  partnerDescription: string;
  shortDescription: string;
  type: (typeof productTypes)[keyof typeof productTypes];
  source: (typeof ProductSource)[keyof typeof ProductSource];
  sku: string;
  price: MoneyDto;
  pictureUrl: string;
  partnerPictureUrl: string;
  tags: TagDto[];
  allergens: AllergenDto[];
  relatedProductIds: string[];
  subProductIds: string[];
  subProductIdsInfos?: OverridableValueDto<string[]>;
  partnerSubProductIds: string[];
  calories: number;
  eatInTax?: number; // TODO make taxes not optional when menu backend has been released
  takeAwayTax?: number;
  deliveryTax?: number;
  isAvailableForOrder: boolean;
  snoozePeriod?: SnoozePeriod;
  isEligibleForOrder?: boolean;
  posConnection?: {
    posConnectionId: string;
    externalRevenueCenterId?: string;
  };
}

export interface UpdateProductDto {
  name?: string;
  description?: string;
  shortDescription?: string;
  price?: MoneyDto;
  tags?: TagDto[];
  allergens?: AllergenDto[];
  relatedProductIds?: string[];
  subProductIds?: string[];
  calories?: number;
  eatInTax?: number;
  takeAwayTax?: number;
  deliveryTax?: number;
  isAvailableForOrder?: boolean;
}

export interface CreateProductDto {
  name: string;
  description: string;
  price: MoneyDto;
  type: ProductType;
}

export interface ProductSummaryDto {
  id: string;
  name: string;
  description: string;
  shortDescription: string;
  sku?: string;
  price: MoneyDto;
  basePrice: MoneyDto;
  pictureUrl: string;
  type: (typeof productTypes)[keyof typeof productTypes];
  hasDiscrepancies: boolean;
  snoozePeriod?: SnoozePeriod;
  posConnectionId?: string;
  externalRevenueCenterId?: string;
  source: (typeof ProductSource)[keyof typeof ProductSource];
}

export function isProduct(type: string): type is (typeof productTypes)[keyof typeof productTypes] {
  return type in productTypes;
}

export interface ProductsSummaryDto {
  products: ProductSummaryDto[];
}

export type ElementDto = {
  id: string;
  type: string;
};

export type PaginatedProductsDto = {
  products: ProductDto[];
  totalCount: number;
};
