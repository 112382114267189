import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useBusinessIdOrThrow } from '../business/hooks/useBusinessId';
import { useGetBusinessLoyaltyReference } from './queries/useGetBusinessLoyaltyReference';
import MenuItem from '@mui/material/MenuItem';
import { useSavePaytronixConfiguration } from 'src/loyalty/mutations/useSavePaytronixBusinessConfiguration';
import { SubmitHandler, useForm } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

type OnboardingInputs = {
  storeCode: string;
  merchantId: string;
  cardTemplateCode: string;
  theme: string;
};
export const LoyaltyOnboarding = () => {
  const businessId = useBusinessIdOrThrow();
  const { formatMessage } = useIntl();
  const {
    data: loyaltyBusiness,
    isLoading: loyaltyReferenceIsLoading,
  } = useGetBusinessLoyaltyReference(businessId);
  const saveConfiguration = useSavePaytronixConfiguration(businessId);
  const [onboardingError, setOnboardingError] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
    },
  } = useForm<OnboardingInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const snackBar = useSnackbar();
  const onSubmit: SubmitHandler<OnboardingInputs> = async ({ storeCode, merchantId, cardTemplateCode, theme }) => {
    setOnboardingError('');
    try {
      saveConfiguration.mutate({
        loyaltyReference: 'PAYTRONIX',
        loyaltyConfiguration: {
          storeCode,
          merchantId,
          cardTemplateCode,
          theme,
        },
      });
      snackBar.addNotification({
        variant: 'success',
        text: formatMessage({ id: 'loyalty.onboarding.success', defaultMessage: 'Configuration saved' }),
      });
    } catch (error: any) {
      snackBar.addNotification({ variant: 'error', text: formatMessage({ id: 'loyalty.onboarding.errors.other' }) });
      datadogLogs.logger.error('An error occurred while trying to configure a Paytronix business', { error });
      setOnboardingError(formatMessage({ id: 'loyalty.onboarding.errors.other' }));
    }
  };

  if (loyaltyReferenceIsLoading) {
    return <CircularProgress />;
  }

  return (
    <Paper style={{ borderRadius: '1.5rem', maxWidth: '1000px' }} elevation={0}>
      <Stack margin="24px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap="16px" maxWidth="576px">
            <Typography variant="h5">
              {formatMessage({ id: 'loyalty.onboarding.title' }, { loyaltyReference: 'Paytronix' })}
            </Typography>
            {loyaltyBusiness && (
              <Box padding={'8px'}>
                <Typography>{loyaltyBusiness.loyaltyReference} connected
                  with {loyaltyBusiness.theme} theme
                  🎊</Typography>
              </Box>
            )}
            <TextField
              label="Store Code"
              required
              error={!!errors.storeCode}
              id="storeCode"
              defaultValue={loyaltyBusiness?.loyaltyConfiguration?.storeCode}
              helperText={errors.storeCode?.message}
              {...register('storeCode', {
                required: formatMessage({ id: 'loyalty.onboarding.errors.field' }, { field: 'Store code' }),
              })}
            />
            <TextField
              label="Merchant id"
              required
              error={!!errors.merchantId}
              id="merchantId"
              defaultValue={loyaltyBusiness?.loyaltyConfiguration?.merchantId}
              helperText={errors.merchantId?.message}
              {...register('merchantId', {
                required: formatMessage({
                  id: 'loyalty.onboarding.errors.field',
                }, { field: 'Merchant id' }),
              })}
            />
            <TextField
              label="Card Template Code"
              required
              error={!!errors.cardTemplateCode}
              id="cardTemplateCode"
              defaultValue={loyaltyBusiness?.loyaltyConfiguration?.cardTemplateCode}
              helperText={errors.cardTemplateCode?.message}
              {...register('cardTemplateCode', {
                required: formatMessage({ id: 'loyalty.onboarding.errors.field' }, { field: 'Card Template Code' }),
              })}
            />
            <FormControl>
              <InputLabel id="theme">Theme</InputLabel>
              <Select
                label="Program"
                defaultValue={loyaltyBusiness?.loyaltyConfiguration?.theme || 'default'}
                id="theme"
                {...register('theme')}
              >
                <MenuItem value="default">Default Theme</MenuItem>
                <MenuItem value="BHG">BHG</MenuItem>
                <MenuItem value="CENTURION">Centurion</MenuItem>
                <MenuItem value="DRAKES">Drakes</MenuItem>
                <MenuItem value="LETTUCE">Lettuce</MenuItem>
              </Select>
            </FormControl>
            {onboardingError && (
              <Alert severity="error">{onboardingError}</Alert>
            )}
            <Button type="submit" variant="contained" disabled={!isValid || isSubmitting}>
              Save
            </Button>
          </Stack>
        </form>
      </Stack>
    </Paper>
  );
};
