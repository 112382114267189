import React, { FC } from 'react';
import { Box, Modal, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import { CopyPaste } from 'src/utils/CopyPaste';
import { CheckCircleSvg } from 'src/components/icons/CheckCircleSvg';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { useLocation } from 'react-router-dom';
import { CopyLink } from './components/CopyLink';

export const PaymentLinkReadyToShareModal: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const shortLink = location.state?.shortLink;
  const close = () => navigate(-1);

  return <Modal
    open={true}
    aria-labelledby="create-success-payment-link-modal"
    disableAutoFocus
  >
    <Box sx={{
      background: 'white', maxWidth: '500px', borderRadius: '18px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }} display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center" padding="20px 24px"
           sx={{ cursor: 'pointer' }}
           onClick={close}>
        <CrossIcon />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="24px"
           padding="0 24px 40px">

        <CheckCircleSvg size={64} color={theme.palette.success.main} />

        <Box gap="16px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h4">
            <FormattedMessage id="payment_link.create.success.modal.title" />
          </Typography>

          <Typography variant="subtitle1" color={theme.palette.text.secondary} align="center">
            <FormattedMessage id="payment_link.create.success.modal.subtitle" />
          </Typography>
        </Box>

        <Box display="flex"
             border={`1px solid ${theme.palette.secondary.light}`}
             padding="16px"
             width="100%"
             borderRadius="16px"
             alignItems="center"
             justifyContent="space-between">
          <a href={shortLink} target="_blank" rel="noreferrer">
            <CopyPaste value={shortLink} content={<Typography variant="body2">{shortLink}</Typography>} />
          </a>

          <CopyLink shortLink={shortLink} />
        </Box>
      </Box>
    </Box>
  </Modal>;
};
