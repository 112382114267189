import { ReviewAndApplyChanges, selectableConfigurationsLabels } from '../domain/ConfigurationReplicationState';
import { Button, Stack, Typography } from '@mui/material';
import { ApplyConfigurationChanges } from '../domain/ApplyConfigurationChanges';
import {
  useDocumentRepositoryFirebaseStorage,
} from 'src/infrastructure/document/DocumentRepositoryFirebaseStorage';
import {
  WhiteLabelCustomizationRepositoryHttp,
} from 'src/infrastructure/venue/WhiteLabelCustomizationRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ampli } from 'src/ampli';

type ReviewState = 'reviewing' | 'applying' | 'done';

export const ReviewAndApplyChangesStep = ({ currentState }: { currentState: ReviewAndApplyChanges }) => {
  const [state, setState] = useState<ReviewState>('reviewing');
  const documentRepositoryStorage = useDocumentRepositoryFirebaseStorage();
  const whiteLabelCustomizationRepository = new WhiteLabelCustomizationRepositoryHttp(
    ConfigurationLoader.load().patOrchestratorUrl,
  );
  const changes = new ApplyConfigurationChanges(documentRepositoryStorage, whiteLabelCustomizationRepository);
  const apply = async () => {
    ampli.configurationReplicationApplied();
    setState('applying');
    await changes.applyConfigurationChanges(currentState);
    setState('done');
  };

  return (
    <Stack padding="1.5rem" gap="1rem">
      <Typography variant="h5">
        <FormattedMessage id="configuration_replication.review_and_apply.title" />
      </Typography>
      {state === 'reviewing' && (
        <>
          <Typography>
            <FormattedMessage id="configuration_replication.review_and_apply.details" />
          </Typography>
          <Stack direction="row">
            <Stack gap="1rem">
              <Typography variant="h6">
                <FormattedMessage id="configuration_replication.review_and_apply.changes_details" />
              </Typography>
              <div>
                {currentState.configurations.map((c) => (
                  <Typography key={c}>
                    <FormattedMessage id={selectableConfigurationsLabels[c]} />
                  </Typography>
                ))}
              </div>
            </Stack>
            <Stack gap="1rem">
              <Typography variant="h6">
                <FormattedMessage id="configuration_replication.review_and_apply.changes_from" />
              </Typography>
              <Typography>{currentState.sourceBusiness.name}</Typography>
            </Stack>
            <Stack gap="1rem">
              <Typography variant="h6">
                <FormattedMessage id="configuration_replication.review_and_apply.changes_to" />
              </Typography>
              {currentState.targetBusinesses.map((b) => (
                <Typography key={b.id}>{b.name}</Typography>
              ))}
            </Stack>
          </Stack>
          <Stack direction="row-reverse">
            <Button variant="contained" onClick={apply}>
              <FormattedMessage id="configuration_replication.review_and_apply.apply" />
            </Button>
          </Stack>
        </>
      )}
      {state === 'applying' && (
        <Typography>
          <FormattedMessage id="configuration_replication.review_and_apply.applying" />
        </Typography>
      )}
      {state === 'done' && (
        <Typography>
          <FormattedMessage id="configuration_replication.review_and_apply.applied" />
        </Typography>
      )}
    </Stack>
  );
};
