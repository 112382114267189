import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { MetabaseDashboard } from './domain/MetabaseDashboard';
import { useEffect } from 'react';
import { ampli } from '../ampli';
import { useCurrentBusinessOrThrow } from '../business/hooks/useCurrentBusinessOrThrow';
import { language } from '../app/i18n/I18nProvider';
import { shouldMockDataForBusiness } from '../mocks/shouldMockDataForBusiness';

export const DataAnalyticsEnterpriseDashboard = () => {
  const business = useCurrentBusinessOrThrow();

  useEffect(() => {
    ampli.dataAnalyticsCustomerInsightsClicked({
      businessId: business.id,
      businessName: business.name,
      clickDate: new Date().toISOString(),
    });
  }, []);

  if (shouldMockDataForBusiness(business.id)) {
    return <img width="100%" height="auto" src="/img/mocks/analytics/KYC-Compare-Your-Business-Mocked.png"
                alt="compare your business mocked" />;
  }

  return (
    <DataAnalyticsIframeComponent
      dashboard={
        language() === 'fr'
          ? MetabaseDashboard.ENTERPRISE_DASHBOARD_FRENCH
          : MetabaseDashboard.ENTERPRISE_DASHBOARD_ENGLISH
      }
    />
  );
};
