import { IntlShape } from 'react-intl';
import { GridCellParams, GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { renderMoney } from '@sundayapp/web-money';
import { gridMoneyComparator } from 'src/app/theme/DatagridTheme';

export const buildColumns = (intl: IntlShape, isUSVenue: boolean): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'accounting.reporting.by_waiters.name' }),
      sortComparator: gridStringOrNumberComparator,
      valueGetter: (_value, row) => row.waiter,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'amount',
      headerName: intl.formatMessage({
        id: isUSVenue ? 'accounting.reporting.us.by_waiters.amount' : 'accounting.reporting.by_waiters.amount',
      }),
      sortComparator: gridMoneyComparator,
      renderCell: ({ row }: GridCellParams) => renderMoney(row.grossRevenue, intl.locale),
      valueGetter: (_value, row) => row.grossRevenue,
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: 'service_charge',
      headerName: intl.formatMessage({ id: 'accounting.reporting.us.by_waiters.service_charge' }),
      sortComparator: gridMoneyComparator,
      renderCell: ({ row }: GridCellParams) => renderMoney(row.serviceChargeRevenue, intl.locale),
      valueGetter: (_value, row) => row.serviceChargeRevenue,
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: 'tips',
      headerName: intl.formatMessage({
        id: isUSVenue ? 'accounting.reporting.us.by_waiters.tips' : 'accounting.reporting.by_waiters.tips',
      }),
      sortComparator: gridMoneyComparator,
      renderCell: ({ row }: GridCellParams) => renderMoney(row.tipsRevenue, intl.locale),
      valueGetter: (_value, row) => row.tipsRevenue,
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
    },
  ];
  return columns.filter((column) => isUSVenue || (!isUSVenue && ['name', 'amount', 'tips'].includes(column.field)));
};
