import { DependenciesContext } from 'src/tips/dispatch/Dependencies';
import React from 'react';
import { SundayPoolingPage } from 'src/tips/dispatch/SundayPoolingPage';
import { SundayPoolingRepositoryHttp } from 'src/tips/dispatch/infrastructure/SundayPoolingRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useGetBusinessTippingConfiguration } from 'src/tips/queries/useGetBusinessTippingConfiguration';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { TipsPromotionPage } from 'src/tips/page/TipsPromotionPage';

export const SundayPooling = () => {
  const configuration = ConfigurationLoader.load();
  const business = useCurrentBusinessOrThrow();
  const { data: tipsConfiguration } = useGetBusinessTippingConfiguration(business.id);
  if (!tipsConfiguration) {
    return null;
  }

  const displayPooling = () =>
    tipsConfiguration.type === 'SundayTipping' ||
    (tipsConfiguration.type === 'POC' &&
      (tipsConfiguration.pat?.type === 'SundayTipping' || tipsConfiguration.pdq?.type === 'SundayTipping'));

  return (
    <DependenciesContext.Provider
      value={{
        sundayPoolingRepository: new SundayPoolingRepositoryHttp(configuration.bookkeepingApiBaseUrl),
      }}
    >
      {displayPooling() ? <SundayPoolingPage /> : <TipsPromotionPage />}
    </DependenciesContext.Provider>
  );
};
