import { Staff } from 'src/tips/dispatch/model/Staff';
import { TipsAllocations } from 'src/tips/dispatch/model/TipsAllocation';
import { Money } from '@sundayapp/web-money';

export type StaffAllocation = {
  staffId: string;
  staffName: string;
  weeklyWorkedHours: number;
  allocation: Money;
};

const staffName = (staff: Staff) => {
  if (staff.firstName !== '' || staff.lastName !== '') return `${staff.firstName} ${staff.lastName}`;
  if (staff.email !== '') return staff.email;
  if (staff.phoneNumber !== '') return staff.phoneNumber;
  return JSON.stringify(staff);
};

export const toStaffAllocations = (balance: Money, staff: Staff[]): StaffAllocation[] =>
  staff
    .sort((a, b) => a.firstName.localeCompare(b.firstName))
    .map((s) => ({
      staffId: s.id,
      staffName: staffName(s),
      weeklyWorkedHours: 40,
      allocation: { amount: 0, currency: balance.currency },
    }));

const roundToCent = (amount: number): number => Math.round(amount / 1000) * 1000;

export const distributeByWorkingHours = (balance: Money, staffAllocations: StaffAllocation[]): StaffAllocation[] => {
  const totalWeeklyWorkedHours = staffAllocations.reduce((acc, a) => acc + a.weeklyWorkedHours, 0);
  const newAllocations = staffAllocations.map((a) => ({
    ...a,
    allocation: {
      amount:
        totalWeeklyWorkedHours === 0 ? 0 : roundToCent((balance.amount * a.weeklyWorkedHours) / totalWeeklyWorkedHours),
      currency: balance.currency,
    },
  }));
  if (totalWeeklyWorkedHours === 0) return newAllocations;

  // redistribute rounding remainder among staff members
  let remainder = balance.amount - newAllocations.reduce((acc, a) => acc + a.allocation.amount, 0);

  if (remainder > 0) {
    let counter = 0;
    while (remainder > 0) {
      if (newAllocations[counter].allocation.amount > 0) {
        newAllocations[counter].allocation.amount += 1000;
        remainder -= 1000;
      }
      counter++;
    }
  }

  if (remainder < 0) {
    let counter = 0;
    while (remainder < 0) {
      if (newAllocations[counter].allocation.amount > 0) {
        newAllocations[counter].allocation.amount -= 1000;
        remainder += 1000;
      }
      counter++;
    }
  }

  return newAllocations;
};

export const toTipsAllocations = (staffAllocations: StaffAllocation[]): TipsAllocations =>
  staffAllocations.map((a) => ({ staffId: a.staffId, amount: a.allocation }));

export const keepOnlyStaffWithTips = (staffAllocations: StaffAllocation[]): StaffAllocation[] =>
  staffAllocations.filter((a) => a.allocation.amount > 0);

export const hasTipsAllocated = (staffAllocations: StaffAllocation[]): boolean =>
  !!staffAllocations.find((a) => a.allocation.amount > 0);
