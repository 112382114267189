import moment, { Moment } from 'moment';

/*
 Moment's methods in a functional way
 They can be referenced to in the 'clone' method usage in Instant
 */
const local = (keepLocalTime?: boolean): (m: Moment) => Moment => (m: Moment) => m.local(keepLocalTime);
const hours = (h: number): (m: Moment) => Moment => (m: Moment) => m.hours(h);
const minutes = (m: number): (m: Moment) => Moment => (mm: Moment) => mm.minutes(m);
const seconds = (s: number): (m: Moment) => Moment => (m: Moment) => m.seconds(s);
const milliseconds = (ms: number): (m: Moment) => Moment => (m: Moment) => m.milliseconds(ms);
const add = (n: number, unitOfTime: UnitOfTime): (m: Moment) => Moment => (m: Moment) => m.add(n, unitOfTime);
const subtract = (n: number, unitOfTime: UnitOfTime): (m: Moment) => Moment => (m: Moment) => m.subtract(n, unitOfTime);
const utcOffset = (b: number, keepLocalTime?: boolean): (m: Moment) => Moment => (m: Moment) => m.utcOffset(b, keepLocalTime);
const startOf = (unitOfTime: 'd' | 'day' | 'M'): (m: Moment) => Moment => (m: Moment) => m.startOf(unitOfTime);
const endOfDay = (m: Moment) => m.endOf('day');
const locale = (loc: string): (m: Moment) => Moment => (m: Moment) => m.locale(loc);

export type UnitOfTime = 'm' | 'minutes' | 'd' | 'day' | 'y' | 'M' | 'h' | 'hours';

export class Instant {
  private constructor(private time: Moment) {
    this.time = time.clone();
  }

  public static from(time: Moment) {
    return new Instant(time);
  }

  static fromEpoch(date: number) {
    return new Instant(moment(date));
  }

  private clone(mapper: (m: Moment) => Moment) {
    return new Instant(mapper(this.time.clone()));
  }

  local() {
    return this.clone(local());
  }

  format(format?: string) {
    return this.time.format(format);
  }

  toISOString() {
    return this.time.toISOString();
  }

  unix() {
    return this.time.unix();
  }

  hours(h: number): Instant {
    return this.clone(hours(h));
  }

  minutes(m: number): Instant {
    return this.clone(minutes(m));
  }

  seconds(s: number): Instant {
    return this.clone(seconds(s));
  }

  millisecond(ms: number): Instant {
    return this.clone(milliseconds(ms));
  }

  valueOf() {
    return this.time.valueOf();
  }

  add(n: number, unitOfTime: UnitOfTime): Instant {
    return this.clone(add(n, unitOfTime));
  }

  subtract(n: number, unitOfTime: UnitOfTime): Instant {
    return this.clone(subtract(n, unitOfTime));
  }

  utcOffset(b: number, keepLocalTime?: boolean): Instant {
    return this.clone(utcOffset(b, keepLocalTime));
  }

  startOf(unitOfTime: 'd' | 'day' | 'M') {
    return this.clone(startOf(unitOfTime));
  }

  endOfDay() {
    return this.clone(endOfDay);
  }

  toDate() {
    return this.time.toDate();
  }

  locale(loc: string): Instant {
    return this.clone(locale(loc));
  }

  isStrictlyBefore(other: Instant) {
    return this.time.isBefore(other.time);
  }

  isBefore(other: Instant) {
    return this.time.isSameOrBefore(other.time);
  }

  month() {
    return this.time.month();
  }

  deltaInDays(to: Instant): number {
    return Math.abs(Math.round(this.time.diff(to.time, 'days', true)));
  }

  public equals(other: Instant): boolean {
    return this.unix() === other.unix();
  }
}

export function instant(date?: number | string | Date | Moment, format?: string) {
  return Instant.from(moment(date, format));
}
