import { Stack, Typography } from '@mui/material';
import { FirstName } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/FirstName';
import { LastName } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/LastName';
import {
  DateOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/DateOfBirth';
import {
  CountryOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/CountryOfBirth';
import React from 'react';
import {
  RepresentativeAddress,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/RepresentativeAddress';
import Divider from '@mui/material/Divider';
import {
  CompanyPosition,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/CompanyPosition';


export const LegalRepresentativePersonalInfo = ({ representativeIndex }: {
  representativeIndex: number
}) => {

  return <Stack spacing={3}>
    <Typography variant={'h6'}>Informations personnelles</Typography>
    <Stack spacing={3} direction={'row'}>
      <FirstName legalRepresentativeIndex={representativeIndex} />
      <LastName legalRepresentativeIndex={representativeIndex} />
    </Stack>
    <DateOfBirth legalRepresentativeIndex={representativeIndex} />
    <CountryOfBirth legalRepresentativeIndex={representativeIndex} />
    <RepresentativeAddress representativeIndex={representativeIndex} /><
    Stack spacing={3}>
    <Divider />
    <CompanyPosition representativeIndex={representativeIndex} />
  </Stack>

  </Stack>;
};
