import React from 'react';
import { AverageRatingsChart } from '../AverageRatingsChart';
import { Box, Stack, styled } from '@mui/material';
import { DimensionAnalysis } from '../domain/DimensionsAnalysis';
import { DimensionTrend } from './DimensionTrend';
import { AverageRatingData, AverageRatingsViewModel } from '../AverageRatingsViewModel';
import { WithChartIntegrityCheck } from './WithChartIntegrityCheck';
import { DateRange } from '../../domain/DateRange';
import { useNavigate } from 'react-router-dom';
import { venueSundayPlusReviewsPath } from 'src/app/navigation/pathHelpers';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ReviewCard } from '../../browse/allReviews/components/reviewCard/ReviewCard';
import { ampli } from 'src/ampli';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { DimensionCtaButton } from './DimensionCtaButton';

interface Props {
  dimension: DimensionAnalysis;
  viewModel: AverageRatingsViewModel;
  period: DateRange;
}

const Container = styled(Box)({
  padding: '1.5rem',
  borderRadius: '1rem',
  height: '100%',
  backgroundColor: 'white',
});

const ReviewContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 16px;
  justify-content: space-between;
  background-color: #F6F6F9;
  border-radius: 8px;
`;

export const Dimension = ({
  dimension,
  viewModel,
  period,
}: Props) => {
  const intl = useIntl();

  const businessId = useBusinessIdOrThrow();
  const navigate = useNavigate();

  const timeRange = (): string => {
    if (period.isYesterday()) return 'YESTERDAY';
    if (period.isLastSevenDays()) return 'LAST_7_DAYS';
    if (period.isLastThirtyDays()) return 'LAST_30_DAYS';
    return 'LAST_7_DAYS';
  };

  const onClick = () => {
    ampli.fromGuestExperienceToFeedback({
      timerange: timeRange(),
      dimension: dimension.name,
    });
    navigate(
      `${venueSundayPlusReviewsPath(businessId)}?timerange=${timeRange()}&dimension=${dimension.name}&rating=ALL`,
    );
  };
  const firstBadReview = dimension.badReviews.find((review) => (review.feedback != undefined && review.feedback != ''));
  return (
    <Container>
      <Stack direction="column" justifyContent="space-between" gap="0.5rem">
        <DimensionTrend dimension={dimension} />
        <WithChartIntegrityCheck viewModel={viewModel}>
          <AverageRatingsChart viewModel={viewModel as AverageRatingData} />
        </WithChartIntegrityCheck>
        {firstBadReview != undefined && (
          <ReviewContainer>
            <ReviewCard key={firstBadReview.id} review={firstBadReview} highlightedDimension={dimension.name} />
          </ReviewContainer>
        )}

        <DimensionCtaButton
          label={intl.formatMessage({ id: 'review.guest_experience.dimension.cta' })}
          value={dimension.badReviews.length}
          onClick={onClick}
          disabled={dimension.badReviews.length == 0} />
      </Stack>
    </Container>
  );
};
