import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { venueSundayPlusReviewsPath } from 'src/app/navigation/pathHelpers';
import { Business } from 'src/business/domain/Business';

type ReadReviewsProps = {
  business: Business;
  serverName: string;
};

const ReadReviewsLink = styled.div`
  text-align: start;
  align-items: center;
  cursor: pointer;
  margin-left: 9px;
  text-decoration: underline #70707b;
`;

export const ReadReviews = ({ business, serverName }: ReadReviewsProps) => {
  const navigate = useNavigate();
  const onClick = () => {
    const encodedServerName = encodeURIComponent(serverName);
    navigate(
      `${venueSundayPlusReviewsPath(business.id)}?timerange=PREVIOUS_7_DAYS&platform=ALL&server=${encodedServerName}`,
    );
  };
  return (
    <ReadReviewsLink onClick={onClick}>
      <FormattedMessage id="staff_performance.read_reviews" />
    </ReadReviewsLink>
  );
};
