import {
  Button,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  tableHeadClasses,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { CreateDispatchModal } from 'src/tips/dispatch/components/CreateDispatchModal';
import { Staff } from 'src/tips/dispatch/model/Staff';
import { DispatchSuccessDialog } from 'src/tips/dispatch/components/DispatchSuccessDialog';
import { PendingTipsDispatch } from 'src/tips/dispatch/model/PendingTipsDispatch';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { PeriodDisplay } from 'src/tips/dispatch/components/PeriodDisplay';
import { useRenderMoney } from '@sundayapp/web-money';
import { tableCellClasses } from '@mui/material/TableCell';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

export const DispatchTipsOnPeriod = ({
  tipsToDispatch,
  staff,
}: {
  tipsToDispatch: PendingTipsDispatch;
  staff: Staff[];
}) => {
  const intl = useIntl();
  const renderMoney = useRenderMoney(true, false);

  const [isCreateDispatchModalOpen, setIsCreateDispatchModalOpen] = React.useState(false);
  const [isDispatchSuccessDialogOpened, setIsDispatchSuccessDialogOpened] = useState<boolean>(false);

  const handleDispatchCreation = (dispatched: boolean) => {
    setIsCreateDispatchModalOpen(false);
    setIsDispatchSuccessDialogOpened(dispatched);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: '1.5rem' }}>
        <Table
          sx={{
            [`& .${tableHeadClasses.root}`]: {
              borderBottom: 'none',
            },
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 400 }}>
                <Typography variant={'body1'} color={themeV5.palette.text.secondary}>
                  <FormattedMessage id={'tips.sunday_pooling.dispatch.collection_date'} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'body1'} color={themeV5.palette.text.secondary}>
                  <FormattedMessage id={'tips.sunday_pooling.dispatch.available'} />
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <PeriodDisplay start={tipsToDispatch.startInclusive} end={tipsToDispatch.endExclusive} variant={'h6'} />
              </TableCell>
              <TableCell>
                <Typography variant={'h6'}>{renderMoney(tipsToDispatch.amount)}</Typography>
              </TableCell>
              <TableCell>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'right'} spacing={3}>
                  <Chip
                    color="info"
                    size={'small'}
                    label={intl.formatMessage({ id: 'tips.sunday_pooling.dispatch.ready' })}
                  />
                  <Button size="small" variant="contained" onClick={() => setIsCreateDispatchModalOpen(true)}>
                    <FormattedMessage id={'tips.sunday_pooling.dispatch'} />
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <CreateDispatchModal
        opened={isCreateDispatchModalOpen}
        onClose={(dispatched) => handleDispatchCreation(dispatched)}
        tipsToDispatch={tipsToDispatch}
        staff={staff}
      />
      <DispatchSuccessDialog
        opened={isDispatchSuccessDialogOpened}
        onClose={() => setIsDispatchSuccessDialogOpened(false)}
      />
    </>
  );
};
