import { Money } from '@sundayapp/web-money';

// FIXME this has been moved from store/orders which doesn't exist anymore. Those types should be merged into orders.ts file.
export enum PaymentMean {
  SUNDAY = 'SUNDAY',
  DEBIT_CARD = 'DEBIT_CARD',
  OTHER_THAN_SUNDAY = 'OTHER_THAN_SUNDAY',
}

export interface Timestamp {
  epochSecond?: number;
  seconds: number;
  nanoseconds: number;
}

export enum RefundStatus {
  SUCCEEDED = 'SUCCEEDED',
  UNSPECIFIED = 'UNSPECIFIED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
}

export interface Refund {
  amount: Money;
  status: RefundStatus;
}

export interface PaymentOnPOS {
  amount: Money;
  paymentMean: PaymentMean;
  splitId?: string;
}

export enum PaymentOverSundayStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CREATED = 'CREATED',
  CANCELED = 'CANCELED',
}

export interface PaymentOverSunday {
  id: string;
  paidAmount: Money;
  orderAmount: Money;
  tipsAmount: Money;
  serviceChargeAmount: Money;
  status: PaymentOverSundayStatus;
  createdAt: Timestamp;
  refunds?: Record<string, Refund>;
}

export interface PaymentSunday {
  message?: string;
  reason?: string;
  status: string;
}
