import React, { FC } from 'react';
import { CircularProgress, Paper, TableContainer } from '@mui/material';
import styled from 'styled-components';
import { WaitersTable } from './WaitersTable';
import { useGetWaiterReferential } from '../queries/getWaiterReferentialQuery';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGetDirectTippingBusinessConfiguration } from 'src/tips/queries/getDirectTippingBusinessConfigurationQuery';

const Center = styled.div`
  display: flex;
  margin: auto;
  width: 100px;
  height: 70px;
`;

export const WaiterReferentialManagement: FC = () => {
  const business = useCurrentBusinessOrThrow();
  const { data: directTippingConfiguration } = useGetDirectTippingBusinessConfiguration(business.id);
  const { data: waiterReferential } = useGetWaiterReferential(business.id);

  const isEmptyWaiterReferential = () =>
    waiterReferential &&
    waiterReferential.waitersNotEligible.length === 0 &&
    waiterReferential.waiters.length === 0 &&
    waiterReferential.waitersWithServerAccountsRequired.length === 0;

  if (!waiterReferential || !directTippingConfiguration) {
    return (
      <Center>
        <CircularProgress color="inherit" />
      </Center>
    );
  }

  if (isEmptyWaiterReferential()) {
    return (
      <TableContainer sx={{ borderRadius: '10px', padding: '20px' }} component={Paper}>
        <FormattedMessage id="direct_tipping.no_waiter" defaultMessage="No waiter available yet" />
      </TableContainer>
    );
  }

  return (
    <WaitersTable
      allWaiters={waiterReferential}
      business={business}
      directTippingConfiguration={directTippingConfiguration}
    />
  );
};
