import { ChartData, ChartOptions } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { tooltipLabel } from 'src/sundayplus/reviews/insights/graphs/tooltipLabel';
import { StarsDistributionChartData } from 'src/sundayplus/reviews/insights/EReputationViewModel';
import { ChartValue } from 'src/sundayplus/reviews/insights/EReputationViewModel';
import { IntlShape } from 'react-intl';
import { getChartXAxisLabels } from 'src/sundayplus/reviews/insights/graphs/XAxisLabels';
import { EReputationPageChoicesState } from 'src/store/e-reputation';

export type KeyOption = {
  label: string
  color: string,
  borderColor?: string,
  order?: number
};

export type LineGraphDefinition<T extends string> = {
  keys: readonly T[];
  dataTypes: Record<T, KeyOption>;
  data: Record<T, ChartValue[]>;
};

export type DataTypes = LineGraphDefinition<string>['dataTypes'];

const datasetBase = {
  datalabels: {
    display: false,
  },
  borderWidth: 2,
  borderColor: 'transparent',
  borderSkipped: false,
};

export const lineChartData = <T extends string>(
  pageStatus: EReputationPageChoicesState,
  data: LineGraphDefinition<T> | undefined,
  intl: IntlShape): ChartData<'line'> => ({
    labels: getChartXAxisLabels(pageStatus.timeFrame, Date.now(), intl),
    datasets: data ? [...data.keys.map((key) => ({
      ...datasetBase,
      label: data.dataTypes[key].label,
      data: data.data[key],
      backgroundColor: data.dataTypes[key].color,
      borderColor: data.dataTypes[key].borderColor,
    }))] : [],
  });

export const lineChartOptions = (
  fromSundayKpis: StarsDistributionChartData[],
  withSundayCaption?: string,
): ChartOptions<'line'> => ({
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 30,
      right: 30,
      top: 30,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      // @ts-ignore
      backgroundColor: (context: Context) => context.dataset.backgroundColor,
      color: 'black',
      borderRadius: 24,
      borderWidth: 15,
    },
    tooltip: {
      mode: 'nearest',
      padding: 12,
      bodySpacing: 4,
      position: 'average',
      intersect: false,
      displayColors: false,
      filter: ({ dataset }) => dataset.label === withSundayCaption,
      callbacks: {
        label: (ctx) => tooltipLabel(fromSundayKpis, ctx.dataIndex),
      },
    },
  },
  scales: {
    yAxes: {
      position: 'right',
      display: false,
      offset: true,
      max: 5,
      border: {
        color: 'white',
      },
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    xAxes: {
      display: true,
      border: {
        color: 'white',
      },
      grid: {
        display: false,
      },
    },
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
});
