import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  CheckoutOnboardingInformationDraft,
  RepresentativeDraft,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Representative } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/Representative';
import {
  RepresentativeDetails,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/RepresentativeDetails';
import { DirectorPersonalInfo } from './DirectorPersonalInfo';
import { isCompleteDirector } from './isCompleteDirector';

export const EditDirector = ({
  representative,
  index,
  onDelete,
}: {
  representative: RepresentativeDraft,
  onDelete: () => void,
  index: number
}) => {
  const { formState: { errors } } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[index];
  const isReadOnly = !!representative?.roles?.authorisedSignatory || !!representative?.roles?.ubo || !!representative?.roles?.control;
  const intl = useIntl();
  const isComplete = isCompleteDirector({ representative, containsErrors: !!error });
  const title = representative.lastName && representative.firstName ? `${representative.lastName} ${representative.firstName}` :
    intl.formatMessage({ id: 'settings.payments.checkout.onboarding.individualRepresentatives.director.details' });

  return <Representative isComplete={isComplete} title={title} onDelete={onDelete} isReadOnly={isReadOnly}>
      <RepresentativeDetails>
        <DirectorPersonalInfo representativeIndex={index} />
      </RepresentativeDetails>
  </Representative>;
};
