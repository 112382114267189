import { useFormContext } from 'react-hook-form';
import React from 'react';
import { UploadDocument } from 'src/payments/components/Checkout/UploadDocuments/UploadDocument';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const ArticlesOfAssociationDocument = () => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const business = useCurrentBusinessOrThrow();
  const error = errors.articlesOfAssociationDocument;
  const uploadedDocument = watch('articlesOfAssociationDocument');
  const intl = useIntl();

  const onFileUploaded = (documentId: string) => {
    setValue('articlesOfAssociationDocument', documentId);
  };
  const onDelete = () => {
    setValue('articlesOfAssociationDocument', '');
  };

  return (
    <UploadDocument
      onRegistered={register('articlesOfAssociationDocument', {
        required: {
          value: business.address.countryCode === 'GB',
          message: intl.formatMessage({
            id: 'settings.payments.checkout.onboarding.articlesOfAssociationDocument.error',
          }),
        },
      })}
      onFileUploaded={onFileUploaded}
      onDelete={onDelete}
      documentId={uploadedDocument}
      error={error}
      documentTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.articlesOfAssociationDocument' })}
      buttonTitle={intl.formatMessage({
        id: 'settings.payments.checkout.onboarding.articlesOfAssociationDocument.button',
      })}
      purpose={'articles_of_association'}
      helperText={''}
    />
  );
};
