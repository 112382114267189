import { EnrollmentId } from 'src/business/domain/Enrollment';
import axios from 'axios';
import WaiterName from 'src/pages/Settings/AppSettings/tips/domain/WaiterName';

export class WaiterAppRepository {
  constructor(private baseUrl: string) {}

  async getNotLinkedWaiters(enrollmentId: EnrollmentId): Promise<WaiterName[]> {
    const response = await axios.get(`${this.baseUrl}/businesses/${enrollmentId}/unlinked-waiters`);
    return response.data;
  }
}
