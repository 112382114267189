import { IntlShape } from 'react-intl/src/types';
import { GridCellParams, GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { Box, Button, Typography } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { BusinessId } from 'src/business/domain/Business';

const MenuImg = styled('img')`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
const ImgContainer = styled('div')`
  border-radius: 8px;
  background-color: #d9d9d9;
  width: 34px;
  height: 34px;
`;

const BlockTitle = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: auto;
  cursor: pointer;
`;

export const buildBlockSelectionColumns = (
  intl: IntlShape,
  businessId: BusinessId,
  isSelected: (productId: string) => boolean,
): GridColDef[] => {
  return [
    {
      field: 'block',
      headerName: '',
      disableColumnMenu: true,
      display: 'flex',
      flex: 3,
      sortComparator: gridStringOrNumberComparator,
      valueGetter: (_value, row) => row.name,
      renderCell: ({ row }: GridCellParams) => (
        <BlockTitle>
          <ImgContainer>{row.pictureUrl && <MenuImg src={row.pictureUrl} />}</ImgContainer>
          <Typography>{row.title}</Typography>
        </BlockTitle>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      display: 'flex',
      width: 150,
      renderCell: ({ row }: GridCellParams) => (
        <Box display="flex" flexDirection="row" gap={2} alignItems="center" width="auto">
          {isSelected(row.id) ? (
            <Button size="small" variant="contained" endIcon={<CheckOutlinedIcon />}>
              <FormattedMessage id="menus.block.selection.button_added" />
            </Button>
          ) : (
            <Button size="small" variant="outlined">
              <FormattedMessage id="menus.block.selection.button_add" />
            </Button>
          )}
        </Box>
      ),
    },
  ];
};
