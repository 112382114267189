import { CustomerEngagements, Filter, NumericValue } from '../../domain/RewardProgramKpis';

export type Status = 'less' | 'equal' | 'greater';

export type Variation = {
  value: string;
  status: Status;
};

export const NA = '-';
export const SAME = '=';

export const NoVariation: Variation = {
  value: NA,
  status: 'equal',
};

export type CustomerEngagementVariationViewModel = {
  loyaltyParticipationRate: Variation;
  nbActiveLoyaltyCustomers: Variation;
  nbNewLoyaltySignUps: Variation;
  nbRewardsEarned: Variation;
  nbRewardsRedeemed: Variation;
};

const variationStatus = (variation: number): Status => {
  if (variation === 0) return 'equal';
  if (variation > 0) return 'greater';
  return 'less';
};

const variationSign = (variation: number): string => {
  if (variation > 0) return '+';
  return '';
};

export const toVariationItemModel = (last: NumericValue, prev: NumericValue, locale: string): Variation => {
  if (last === undefined) return NoVariation;
  if (prev === undefined) return NoVariation;

  const variation = last - prev;

  const value = variation === 0 ? SAME : `${variationSign(variation)}${Intl.NumberFormat(locale).format(variation)}`;

  return { value, status: `${variationStatus(variation)}` as Status };
};

export const loyaltyParticipationRate = (last: NumericValue, prev: NumericValue, locale: string): Variation => {
  if (last === undefined) return NoVariation;
  if (prev === undefined) return NoVariation;

  const variation = Math.round((last - prev) * 10) / 10;
  const value = variation === 0 ? SAME : `${variationSign(variation)}${Intl.NumberFormat(locale).format(variation)}pts`;

  return { value, status: `${variationStatus(variation)}` as Status };
};

export const customerEngagementVariationViewModel = (customerEngagements: CustomerEngagements, locale: string): CustomerEngagementVariationViewModel => ({
  loyaltyParticipationRate: loyaltyParticipationRate(customerEngagements.last7Days.loyaltyParticipationRate, customerEngagements.prev7Days.loyaltyParticipationRate, locale),
  nbActiveLoyaltyCustomers: toVariationItemModel(customerEngagements.last7Days.nbActiveLoyaltyCustomers, customerEngagements.prev7Days.nbActiveLoyaltyCustomers, locale),
  nbNewLoyaltySignUps: toVariationItemModel(customerEngagements.last7Days.nbNewLoyaltySignUps, customerEngagements.prev7Days.nbNewLoyaltySignUps, locale),
  nbRewardsEarned: toVariationItemModel(customerEngagements.last7Days.nbRewardsEarned, customerEngagements.prev7Days.nbRewardsEarned, locale),
  nbRewardsRedeemed: toVariationItemModel(customerEngagements.last7Days.nbRewardsRedeemed, customerEngagements.prev7Days.nbRewardsRedeemed, locale),
});

export const variationColor = (status: Status) => {
  if (status === 'greater' || status === 'equal') return '#336B42';
  return 'grey';
};

export const displayVariation = (filter: Filter): boolean => filter !== 'allTime';
