import { range } from 'lodash';
import { IntlShape } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

import { FOUR_WEEKS, KpiTimeframeName, SEVEN_DAYS, TWELVE_MONTHS } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';

dayjs.extend(weekday);
dayjs.extend(localeData);

const toHumanReadable = (intl: IntlShape) => (date: Dayjs, withWeekday: boolean) => intl.formatDate(
  date.toDate(),
  {
    day: 'numeric',
    month: 'long',
    weekday: withWeekday ? 'short' : undefined,
  },
);

export const getChartXAxisLabels = (timeframe: KpiTimeframeName, now: number, intl: IntlShape) => {
  const humanReadable = toHumanReadable(intl);

  const today = new Date(now);
  if (timeframe === SEVEN_DAYS.name()) {
    return range(7, 0, -1)
      .map((day) => dayjs(today)
        .subtract(day, 'day'))
      .map((day) => humanReadable(day, true));
  }

  if (timeframe === FOUR_WEEKS.name()) {
    return range(4, 0, -1)
      .map((day) =>
        dayjs(today)
          // this allows us to have monday as first day of the week
          .locale('fr', { weekStart: 1 })
          .add(1, 'week')
          .subtract(day, 'week')
          .weekday(0))
      .map((day) => `${humanReadable(day, false)} - ${humanReadable(day.add(6, 'day'), false)}`);
  }
  if (timeframe === TWELVE_MONTHS.name()) {
    return range(12, 0, -1)
      .map((month) =>
        dayjs(now)
          .add(1, 'month')
          .subtract(month, 'month')
          .toDate())
      .map((day) => intl.formatDate(day, { month: 'short' })
        .toUpperCase());
  }
  return [];
};
