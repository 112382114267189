import { useFormContext } from 'react-hook-form';
import { Box, FormControl, FormGroup, FormHelperText, Stack } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { Role } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/Role';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';


export const UboExtraRoles = ({ representativeIndex }: { representativeIndex: number }) => {
  const business = useCurrentBusinessOrThrow();
  const { formState: { errors } } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[representativeIndex]?.roles;
  const intl = useIntl();
  return <FormControl component="fieldset">
    <Stack spacing={2}>
      <FormGroup>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {business.address.countryCode == 'FR' && <Role role={'legalRepresentative'}
            representativeIndex={representativeIndex} 
            label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ubo.extraRole.legalRepresentative' })} />}
          {business.address.countryCode == 'GB' && <>
            <Role role={'control'}
              representativeIndex={representativeIndex} 
              label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ubo.extraRole.control' })} />
            <Role role={'director'}
              representativeIndex={representativeIndex} 
              label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ubo.extraRole.director' })} />
          </>}
        </Box>
      </FormGroup>
    </Stack>
    <FormHelperText error={!!error}>{!!error ? error?.message : ''}</FormHelperText>
  </FormControl>;
};
