import { CheckoutFormatAddress } from 'src/payments/domain/CheckoutFormatAddress';

import { Dayjs } from 'dayjs';

import { PersonalIdType } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalIdType';


export interface RequirementDue {
  field: string;
  fieldLabel: string;
  reason: string;
  message?: string;
}

export const Status = {
  CREATED: 'CREATED',
  FILLING: 'FILLING',
  PENDING_REVIEW: 'PENDING_REVIEW',
  REQUIREMENT_DUE: 'REQUIREMENT_DUE',
  ACTIVE: 'ACTIVE',
  RESTRICTED: 'RESTRICTED',
};

export type StatusType = keyof typeof Status;


export interface CheckoutOnboardingInformationDraft {
  businessRegistrationNumber?: string;
  businessType?: string;
  legalName?: string
  isTradingNameTheSameAsLegalName?: boolean;
  tradingName?: string;
  registeredAddress?: Partial<CheckoutFormatAddress>;
  isPrimaryAddressTheSameAsRegistered?: boolean;
  primaryAddress?: Partial<CheckoutFormatAddress>;
  contact?: {
    phone?: string;
    email?: string;
  };
  representatives?: RepresentativeDraft[],
  controllingCompanies?: ControllingCompanyDraft[],
  hasNoControllingCompanies?: boolean;
  hasNoUbos?: boolean;
  bankAccount?: {
    bic?: string;
    iban?: string;
    accountNumber?: string;
    bankCode?: string;
    bankStatement?: string;
  }
  incorporationDocument?: string;
  dateOfIncorporation?: Dayjs;
  articlesOfAssociationDocument?: string,
  shareholderStructureDocument?: string,
  proofOfPrincipalAddressDocument?: string,
  annualProcessingVolume?: string;
  averageTransactionValue?: string;
  highestTransactionValue?: string;
  currency?: string;
  requirementsDue?: RequirementDue[]
  consent?: boolean;
  status: StatusType
}


export type Roles = {
  ubo?: boolean;
  legalRepresentative?: boolean;
  authorisedSignatory?: boolean;
  control?: boolean;
  director?: boolean;
};

export function isOnlyControl(representative: RepresentativeDraft) {
  return representative.roles.control 
  && !representative.roles.ubo  
  && !representative.roles.director 
  && !representative.roles.authorisedSignatory;
}

export interface RepresentativeDraft {
  firstName?: string;
  lastName?: string;
  address?: Partial<CheckoutFormatAddress>
  identificationType?: PersonalIdType;
  identificationFrontDocument?: string;
  identificationBackDocument?: string;
  dateOfBirth?: Dayjs;
  countryOfBirth?: string;
  ownershipPercentage?: number;
  email?: string;
  type?: 'individual' | 'company';
  certifiedAuthorisedSignatoryDocument?: string;
  companyPosition?: string;
  roles: Roles;
}

export function powerOfAttorneyNeeded(representative: RepresentativeDraft | undefined): boolean {
  return !!representative && !representative.roles.legalRepresentative && !representative.roles.director;
}

export function companyPositionNeeded(representative: RepresentativeDraft | undefined): boolean {
  return !!representative && (!!representative.roles.legalRepresentative || !!representative.roles.control);
}

export interface ControllingCompanyDraft {
  ownershipPercentage?: number;
  legalName?: string;
  tradingName?: string;
  isTradingNameTheSameAsLegalName?: boolean;
  address?: Partial<CheckoutFormatAddress>
}
