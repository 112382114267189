import moment from 'moment';
import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { RevenuePeriod } from '../../../domain/RevenuesDatePresets';

export const AccountingDates = ({
  period,
  timezone,
}: { period: RevenuePeriod, timezone: string }) => {
  const theme = useTheme();

  const formatDate = (date: Date) => date.toLocaleDateString(navigator.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: timezone,
  });

  const formattedTimezone = moment()
    .tz(timezone)
    .zoneAbbr();
  const formatTime = (date: Date) => date.toLocaleTimeString(navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timezone,
  });

  return (
    <>
      <Grid
        justifyContent="center"
        p={2}
        mt={2}
        mb={1}
        sx={{
          backgroundColor: '#FAFAFA',
          borderRadius: '16px 16px 16px 16px',
        }}
        container
      >
        <Grid item xs={3}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
            <Typography variant="body1" color={theme.palette.text.secondary}>
              {formatDate(period.dateRange.startDate.toDate())}
            </Typography>
            <Typography variant="body2" color="#9E9E9E">
              {formatTime(period.dateRange.startDate.toDate())}
              {' '}
              (
              {formattedTimezone}
              )
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
            <Typography variant="body1" color={theme.palette.text.secondary}>
              -
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
            <Typography variant="body1" color={theme.palette.text.secondary}>
              {formatDate(period.dateRange.endDate.toDate())}
            </Typography>
            <Typography variant="body2" color="#9E9E9E">
              {formatTime(period.dateRange.endDate.toDate())}
              {' '}
              (
              {formattedTimezone}
              )
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
