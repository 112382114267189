import React, { useEffect } from 'react';
import { Button, Card, CardContent, CardHeader, CircularProgress, FormControl, Grid, TextField, Typography } from '@mui/material';
import { Form } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useForm } from 'react-hook-form';
import { ShiftsInputs } from './constants';
import { useShiftSettings } from './useShiftSettings';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { SettingsPageContainer } from '../components/SettingsPageContainer';

export const ShiftSettings = () => {
  const { formatMessage } = useIntl();
  const useSnackBar = useSnackbar();
  const { isLoading, venueShifts, mutation } = useShiftSettings();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<ShiftsInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  useEffect(() => {
    if (venueShifts) {
      reset({
        lunchFrom: venueShifts.lunch.from,
        lunchTo: venueShifts.lunch.to,
        dinnerFrom: venueShifts.dinner.from,
        dinnerTo: venueShifts.dinner.to,
      });
    }
  }, [venueShifts]);

  const onSubmit = async (inputs: ShiftsInputs) => {
    mutation
      .mutateAsync(inputs)
      .then(() =>
        useSnackBar.addNotification({
          variant: 'success',
          text: formatMessage({ id: 'settings.shift.configuration.updated' }),
        }),
      )
      .catch(() =>
        useSnackBar.addNotification({
          variant: 'error',
          text: formatMessage({ id: 'settings.shift.configuration.not_updated' }),
        }),
      );
    reset(inputs, { keepDirty: false });
  };

  if (isLoading) return <CircularProgress />;

  return (
    <SettingsPageContainer>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
          <Card>
            <CardHeader
              subheader={<FormattedMessage id="settings.shift.description" />}
              action={<Button
                type="submit"
                variant="contained"
                disabled={!isDirty || !isValid || isSubmitting}
              >
                  <FormattedMessage id="settings.save_changes" />
              </Button>}
            />
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid container item alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <Typography>
                      <FormattedMessage id="settings.shift.lunch.label" />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="lunchFrom"
                      label={formatMessage({ id: 'settings.shift.from.label' })}
                      error={!!errors.lunchFrom}
                      type="time"
                      defaultValue={venueShifts?.lunch.from}
                      InputLabelProps={{ shrink: true }}
                      {...register('lunchFrom', {
                        required: true,
                      })}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="lunchTo"
                      label={formatMessage({ id: 'settings.shift.to.label' })}
                      error={!!errors.lunchTo}
                      type="time"
                      defaultValue={venueShifts?.lunch.to}
                      InputLabelProps={{ shrink: true }}
                      {...register('lunchTo', {
                        required: true,
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid container item alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <Typography>
                      <FormattedMessage id="settings.shift.dinner.label" />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="dinnerFrom"
                      label={formatMessage({ id: 'settings.shift.from.label' })}
                      error={!!errors.dinnerFrom}
                      type="time"
                      defaultValue={venueShifts?.dinner.from}
                      InputLabelProps={{ shrink: true }}
                      {...register('dinnerFrom', {
                        required: true,
                      })}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="dinnerTo"
                      label={formatMessage({ id: 'settings.shift.to.label' })}
                      error={!!errors.dinnerTo}
                      type="time"
                      defaultValue={venueShifts?.dinner.to}
                      InputLabelProps={{ shrink: true }}
                      {...register('dinnerTo', {
                        required: true,
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
      </FormControl>
    </Form>
        </SettingsPageContainer>
  );
};
