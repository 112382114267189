import moment from 'moment';
import { AccountingRepository } from '../infrastructure/AccountingRepository';
import { DateRange } from '../domain/DateRange';
import { BusinessId } from 'src/business/domain/Business';

export type AccountingDateRange = {
  today: DateRange,
  lunch: DateRange,
  dinner: DateRange,
};
const defaultLunchStart = moment().set({
  hour: 6, minute: 0, second: 0, millisecond: 0,
});

const defaultLunchEnd = moment().set({
  hour: 18, minute: 0, second: 0, millisecond: 0,
});

const defaultDinnerStart = moment().set({
  hour: 18, minute: 0, second: 0, millisecond: 0,
});

const defaultDinnerEnd = moment().set({
  hour: 6, minute: 0, second: 0, millisecond: 0,
}).add(1, 'days');

export interface AccountingRange {
  today: DateRange;
  lunch: DateRange;
  dinner: DateRange;
}

export const defaultRange: AccountingRange = {
  today: new DateRange(
    defaultLunchStart.toDate(),
    defaultDinnerEnd.toDate(),
  ),
  lunch: new DateRange(
    defaultLunchStart.toDate(),
    defaultLunchEnd.toDate(),
  ),
  dinner: new DateRange(
    defaultDinnerStart.toDate(),
    defaultDinnerEnd.toDate(),
  ),
};

export class GetAccountingShiftUseCase {
  constructor(private accountingRepository: AccountingRepository) {
  }

  async getAccountingDateRange(businessId: BusinessId): Promise<AccountingDateRange> {
    const shifts = await this.accountingRepository.getShiftsByVenue(businessId);
    if (shifts && shifts.length > 0) {
      const shiftLunch = shifts.find((shift) => shift.shiftType === 'LUNCH');
      const lunchStart = moment().set({
        hour: shiftLunch!.startShiftHour.hour, minute: shiftLunch!.startShiftHour.minute, second: 0, millisecond: 0,
      });

      const lunchEnd = moment().set({
        hour: shiftLunch!.endShiftHour.hour, minute: shiftLunch!.endShiftHour.minute, second: 0, millisecond: 0,
      });

      const shiftDinner = shifts.find((shift) => shift.shiftType === 'DINNER');
      const dinnerStart = moment().set({
        hour: shiftDinner!.startShiftHour.hour, minute: shiftDinner!.startShiftHour.minute, second: 0, millisecond: 0,
      });

      let dinnerEnd = moment().set({
        hour: shiftDinner!.endShiftHour.hour, minute: shiftDinner!.endShiftHour.minute, second: 0, millisecond: 0,
      });

      if (shiftDinner!.endShiftHour.hour < shiftDinner!.startShiftHour.hour) {
        dinnerEnd = dinnerEnd.add(1, 'days');
      }

      return {
        today: new DateRange(
          lunchStart.toDate(),
          dinnerEnd.toDate(),
        ),
        lunch: new DateRange(
          lunchStart.toDate(),
          lunchEnd.toDate(),
        ),
        dinner: new DateRange(
          dinnerStart.toDate(),
          dinnerEnd.toDate(),
        ),

      };
    }

    return defaultRange;
  }
}
