import { Button, InputText, Modal, spaceUsages } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { businessStaticMenuPath } from 'src/app/navigation/pathHelpers';
import { useCreateStaticMenu } from '../../infrastructure/mutations/useCreateStaticMenu';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

type MenuCreationModalProps = {
  isOpened: boolean;
  onClose: () => void;
};

type MenuCreationModalInputs = {
  name: string;
};

export const MenuCreationModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MenuCreationModal = ({ isOpened, onClose }: MenuCreationModalProps) => {
  const businessId = useBusinessIdOrThrow();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { createStaticMenu } = useCreateStaticMenu();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<MenuCreationModalInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<MenuCreationModalInputs> = async ({ name }) => {
    const menuId = await createStaticMenu(businessId, name);
    navigate(businessStaticMenuPath(businessId, menuId));
  };

  return (
    <Modal
      title={formatMessage({ id: 'menus.creation.modal.name' })}
      cancelButtonMessage={formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={onClose}
    >
      <MenuCreationModalForm onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={formatMessage({ id: 'menus.creation.form.name' })}
          placeholder={formatMessage({ id: 'menus.creation.form.placeholder' })}
          id="menuName"
          {...register('name', {
            required: formatMessage({ id: 'menus.creation.form.required' }),
            pattern: { value: /.*\S.*/, message: formatMessage({ id: 'menus.creation.form.required' }) },
          })}
        />
        <Button
          type="submit"
          variant="primary"
          size="large"
          fullWidth
          disabled={!isValid || isSubmitting}
          marginTop={spaceUsages.large}
        >
          <FormattedMessage id="menus.creation.form.submit" />
        </Button>
      </MenuCreationModalForm>
    </Modal>
  );
};

export default MenuCreationModal;
