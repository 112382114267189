// eslint-disable-next-line @typescript-eslint/no-redeclare
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { ComponentType, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import { ButtonContainer, CustomizationForm, NotificationContainer } from '../Customization.styles';
import { CustomizationBanner, CustomizationColor } from 'src/domain/customization/WhiteLabelCustomization';
import CustomizationPicture from './Pictures/CustomizationPicture';
import { Alert, Box, Button, Stack, TextField, Typography, useTheme } from '@mui/material';

type BannerSettingsInputs = {
  title: string;
  description: string;
  linkLabel: string;
  linkUrl: string;
};

export type PreviewComponentProps = {
  customizationBanner?: CustomizationBanner;
  customizationColors?: CustomizationColor;
  loadingImgUrl?: string;
  logoUrl?: string;
  width?: number;
  height?: number;
};

interface BannerSettingsProps {
  venueId: string;
  banner: CustomizationBanner;
  onSaveBanner: (banner: CustomizationBanner) => void;
  imageRatio: number;
  title: string;
  defaultImageFilename: string;
  titleMaxLength: number;
  descriptionMaxLength: number;
  previewBackgroundColor: string | undefined;
  previewComponentProps: PreviewComponentProps;
  PreviewComponent: ComponentType<PreviewComponentProps>;
}

const PreviewContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} bgcolor={theme.palette.background.default}
         paddingTop={5} paddingBottom={5} borderRadius={4} gap={2}>
      {children}
    </Box>
  );
};

const BannerSettings = ({
  venueId,
  banner,
  onSaveBanner,
  imageRatio,
  title,
  defaultImageFilename,
  titleMaxLength,
  descriptionMaxLength,
  previewBackgroundColor,
  PreviewComponent,
  previewComponentProps,
}: BannerSettingsProps) => {
  const linkLabelMaxLength = 20;
  const useSnackBar = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();
  const [bannerSettingsUpdateError, setBannerSettingsUpdateError] = useState('');

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<BannerSettingsInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const watchTitle = watch('title');
  const watchDescription = watch('description');
  const watchLinkLabel = watch('linkLabel');
  const watchLinkUrl = watch('linkUrl');

  useEffect(() => {
    reset({
      title: banner?.title,
      description: banner?.description,
      linkLabel: banner?.linkLabel,
      linkUrl: banner?.linkUrl,
    });
  }, [banner]);

  usePrompt(isDirty, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));

  const onSubmit = async (inputs: BannerSettingsInputs) => {
    setBannerSettingsUpdateError('');
    try {
      onSaveBanner({ ...inputs, imageUrl: banner.imageUrl });
      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
      reset(inputs, { keepDirty: false });
    } catch (e) {
      setBannerSettingsUpdateError(intl.formatMessage({ id: 'settings.app_settings.error_saving' }));
    }
  };

  return (
    <Stack gap={3}>
      <PreviewContainer>
        <PreviewComponent {...previewComponentProps}
                          customizationBanner={{
                            title: watchTitle,
                            description: watchDescription,
                            linkLabel: watchLinkLabel,
                            linkUrl: watchLinkUrl,
                            imageUrl: banner.imageUrl,
                          }} />
        <Typography variant={'caption'} color={theme.palette.text.secondary}>
          <FormattedMessage id={'settings.customization.preview.title'} />
        </Typography>
      </PreviewContainer>
      <CustomizationForm onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <TextField
            label={intl.formatMessage({ id: 'settings.customization.banner.titleLabel' }, { maxLength: titleMaxLength })}
            id="title"
            maxLength={titleMaxLength}
            placeholder={intl.formatMessage({ id: 'settings.customization.banner.titleLabel.placeholder' })}
            helperText={!!errors.title ? errors.title?.message : ''}
            InputLabelProps={{ shrink: true }}
            error={!!errors.title}
            {...register('title', { deps: [], required: false })}
          />
          <TextField
            label={intl.formatMessage(
              { id: 'settings.customization.banner.descriptionLabel' },
              { maxLength: descriptionMaxLength },
            )}
            id="description"
            maxLength={descriptionMaxLength}
            placeholder={intl.formatMessage({ id: 'settings.customization.banner.descriptionLabel.placeholder' })}
            error={!!errors.description}
            InputLabelProps={{ shrink: true }}
            helperText={!!errors.description ? errors.description?.message : ''}
            {...register('description', { deps: [], required: false })}
          />
          <TextField
            label={intl.formatMessage(
              { id: 'settings.customization.banner.linkLabelLabel' },
              { maxLength: linkLabelMaxLength },
            )}
            id="linkLabel"
            maxLength={linkLabelMaxLength}
            placeholder={intl.formatMessage({ id: 'settings.customization.banner.linkLabelLabel.placeholder' })}
            error={!!errors.linkLabel}
            InputLabelProps={{ shrink: true }}
            helperText={!!errors.linkLabel ? errors.linkLabel?.message : ''}
            {...register('linkLabel', { deps: [], required: false })}
          />
          <TextField
            label={intl.formatMessage({ id: 'settings.customization.banner.linkUrlLabel' })}
            id="linkUrl"
            placeholder={intl.formatMessage({ id: 'settings.customization.banner.linkUrlLabel.placeholder' })}
            error={!!errors.linkUrl}
            InputLabelProps={{ shrink: true }}
            helperText={!!errors.linkUrl ? errors.linkUrl?.message : ''}
            {...register('linkUrl', { deps: [], required: false })}
          />
          <CustomizationPicture
            venueId={venueId}
            imgUrl={banner.imageUrl || ''}
            imageRatio={imageRatio}
            title={title}
            onSaveCustomizationImageUrl={(imageUrl) => {
              banner.imageUrl = imageUrl;
              onSaveBanner(banner);
            }}
            filenameForSaving={defaultImageFilename}
            allowTransparency
            previewBackgroundColor={previewBackgroundColor}
          />

          {bannerSettingsUpdateError && (
            <NotificationContainer>
              <Alert severity="error">{bannerSettingsUpdateError}</Alert>
            </NotificationContainer>
          )}
          {isValid && isDirty && (
            <ButtonContainer>
              <Button type="submit" variant="contained" size={'small'} disabled={!isValid || isSubmitting || !isDirty}>
                <FormattedMessage id="settings.save_changes" />
              </Button>
            </ButtonContainer>
          )}
        </Stack>
      </CustomizationForm>
    </Stack>
  );
};
export default BannerSettings;
