import { datadogLogs } from '@datadog/browser-logs';
import { useCurrentBusinessOrThrow } from './useCurrentBusinessOrThrow';
import { Enrollment } from '../domain/Enrollment';
import { isMainProduct, mainProducts } from 'src/domain/venue/ServiceType';
import _ from 'lodash';

// TODO: temporary hook during migration, users MUST move away from this and handle business/enrollments correctly!
export const useFirstEnrollmentOfCurrentBusinessOrThrow = (): Enrollment => {
  const business = useCurrentBusinessOrThrow();
  const firstMainEnrollment: Enrollment | undefined =
    _.chain(business.enrollments)
      .filter(e => isMainProduct(e.product))
      .orderBy(e => mainProducts.indexOf(e.product))
      .head()
      .value();

  if (!firstMainEnrollment) {
    const message = `Business '${business.id}' has no main enrollment!`;
    datadogLogs.logger.error(message);
    throw new Error(message);
  }

  return firstMainEnrollment;
};
