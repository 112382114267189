import { CustomerEngagement, NumericValue } from '../../domain/RewardProgramKpis';

export type CustomerEngagementViewModel = {
  loyaltyParticipationRate: string;
  nbActiveLoyaltyCustomers: string;
  nbLoyaltyPayments: string;
  nbNewLoyaltySignUps: string;
  nbRewardsEarned: string;
  nbRewardsRedeemed: string;
};

const DEFAULT_VALUE = '-';

const asPercentage = (locale: string) => (v: number) => `${Intl.NumberFormat(locale).format(Math.round(v * 1000) / 10)}%`;

const applyOrDefault = (v: NumericValue, f: (fv: number) => string): string => {
  if (v === undefined) {
    return DEFAULT_VALUE;
  }
  return f(v);
};

const getOrDefault = (v: NumericValue, locale: string): string => {
  const identity: (vf: number) => string = (vf: number) => Intl.NumberFormat(locale).format(vf);
  return applyOrDefault(v, identity);
};

export const fromDomain = (customerEngagement: CustomerEngagement, locale: string): CustomerEngagementViewModel => ({
  loyaltyParticipationRate: applyOrDefault(customerEngagement.loyaltyParticipationRate, asPercentage(locale)),
  nbActiveLoyaltyCustomers: getOrDefault(customerEngagement.nbActiveLoyaltyCustomers, locale),
  nbLoyaltyPayments: getOrDefault(customerEngagement.nbLoyaltyPayments, locale),
  nbNewLoyaltySignUps: getOrDefault(customerEngagement.nbNewLoyaltySignUps, locale),
  nbRewardsEarned: getOrDefault(customerEngagement.nbRewardsEarned, locale),
  nbRewardsRedeemed: getOrDefault(customerEngagement.nbRewardsRedeemed, locale),
});
