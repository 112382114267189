import { RepresentativeDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';

export const isUboComplete = ({ representative, containsErrors }: {
  representative: RepresentativeDraft | undefined,
  containsErrors: boolean
}): boolean => {
  const isLegalRepresentative = !!representative?.roles?.legalRepresentative;
  const isAddressComplete = !!representative?.address?.city && !!representative.address?.country && !!representative.address?.addressLine1 && !!representative.address?.zip;
  const isLegalRepresentativeExtraFieldsComplete = !isLegalRepresentative || (!!representative?.companyPosition);

  const isDocumentIdComplete = !!representative?.identificationFrontDocument &&
    (!!representative?.identificationFrontDocument || representative?.identificationType === 'passport');
  if (containsErrors || !representative) return false;
  return !!representative!.firstName &&
    !!representative.lastName &&
    !!representative.dateOfBirth &&
    !!representative.countryOfBirth &&
    !!representative.identificationType &&
    !!representative?.ownershipPercentage &&
    isDocumentIdComplete &&
    isAddressComplete &&
    isLegalRepresentativeExtraFieldsComplete;
};
