import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, ListItem, ListItemButton, styled } from '@mui/material';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { BusinessSelectionItem } from './BusinessSelectionItem';
import DoubleChevronSvg from '../../app/component/icons/DoubleChevronSvg';
import { switchVenue } from 'src/app/navigation/pathHelpers';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import { BusinessSelectorContainer } from './BusinessSelectorContainer';
import { useCurrentBusinessOrThrow } from '../hooks/useCurrentBusinessOrThrow';
import { BusinessSearchBar } from 'src/app/navigation/navigationMenu/component/BusinessSearchBar';

type CurrentBusinessProps = {
  openable: boolean;
};

const CurrentBusiness = styled('div')<CurrentBusinessProps>(({ openable }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '8px',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '8px',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: openable ? PaletteTheme.menu.hover : undefined,
  },
}));

const Businesses = styled(FixedSizeList)({
  paddingTop: '16px',
  '& .MuiListItemButton-root:hover': {
    backgroundColor: PaletteTheme.menu.hover,
  },
});

const BusinessItem = styled(ListItemButton)`
  cursor: pointer;
  padding: 8px 8px;
  margin: 0 8px 4px 8px;
  border-radius: 8px;
`;

export type BusinessSelectionProps = {
  compact: boolean;
};

export const BusinessSelection = ({ compact }: BusinessSelectionProps) => {
  const user = useAuthenticatedUserOrThrow();
  const currentBusiness = useCurrentBusinessOrThrow();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const isOpenable = user.claims.length > 1 && !compact;
  const [nameFilter, setNameFilter] = useState<string>('');

  const openBusinessList = () => {
    if (isOpenable) {
      setOpen(true);
    }
  };

  const closeBusinessList = () => {
    setOpen(false);
  };

  const changeFilter = (name: string) => {
    setNameFilter(name);
  };

  const filteredBusinesses = user.claims
    .filter((c) => c.businessId !== currentBusiness.id)
    .filter((c) => nameFilter === '' || c.businessName.toLowerCase().includes(nameFilter.toLowerCase()))
    .sort((a, b) => a.businessName.localeCompare(b.businessName));

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const business = filteredBusinesses[index];
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <BusinessItem
          key={business.businessId}
          onClick={() => {
            closeBusinessList();
            return navigate(switchVenue(business.businessId, currentBusiness.id, location.pathname));
          }}
        >
          <BusinessSelectionItem name={business.businessName} id={business.businessId} />
        </BusinessItem>
      </ListItem>
    );
  }

  function getHeight() {
    return Math.min(400, filteredBusinesses.length * 40 + 16);
  }

  return (
    <div>
      <CurrentBusiness openable={isOpenable} onClick={openBusinessList}>
        <BusinessSelectionItem
          isCurrent
          name={currentBusiness.name}
          id={currentBusiness.id}
          isCompact={compact}
          iconSize={32}
        />
        {isOpenable && <DoubleChevronSvg color="#B4B4BB" />}
      </CurrentBusiness>
      <BusinessSelectorContainer open={open} onCloseVenue={closeBusinessList}>
        <ListItem sx={{ cursor: 'pointer' }} onClick={closeBusinessList}>
          <BusinessSelectionItem name={currentBusiness.name} id={currentBusiness.id} iconSize={32} />
        </ListItem>
        <BusinessItem>
          <BusinessSearchBar
            onChange={(e: any) => {
              changeFilter(e.target.value);
            }}
            value={nameFilter}
          />
        </BusinessItem>
        <Divider sx={{ marginTop: '8px' }} />
        <Businesses height={getHeight()} width={360} itemSize={40} itemCount={filteredBusinesses.length}>
          {renderRow}
        </Businesses>
      </BusinessSelectorContainer>
    </div>
  );
};
