import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ConfigurationContext } from 'src/app/contexts/configuration/ConfigurationProvider';
import { initEffects } from '../effects';
import { StaffPerformance, StaffPerformanceRangeType } from '../domain/StaffPerformance';
import { queryKeys } from 'src/app/queries/utils';
import moment from 'moment';
import { DateRange } from 'src/sundayplus/reviews/domain/DateRange';
import { instant } from 'src/sundayplus/Instant';

type StaffPerformanceProps = {
  range: StaffPerformanceRangeType;
  businessId: string;
  timezone: string;
  startDate?: Date;
  endDate?: Date;
};

type FetchBuilderProps = StaffPerformanceProps & {
  staffPerformance: StaffPerformance;
};

function customDateRange(startDate: Date | undefined, timezone: string, endDate: Date | undefined) {
  const zonedStartDate = moment(startDate!)
    .tz(timezone, true)
    .toDate();
  const zonedEndDate = moment(endDate!)
    .tz(timezone, true)
    .toDate();
  return DateRange.fromDates(zonedStartDate!, zonedEndDate!);
}

function preSetDateRange(timezone: string, range: string) {
  const zonedDatetime = moment.tz(timezone);
  const endDateMoment = zonedDatetime.startOf('day')
    .utc();
  const endDate = endDateMoment.toDate();

  const daysMapping: Record<string, number> = {
    'YESTERDAY': 1,
    '30_DAYS': 30,
    '7_DAYS': 7,
  };
  const daysToSubtract = daysMapping[range] || daysMapping['7_DAYS'];
  const startDate = endDateMoment.subtract(daysToSubtract, 'days')
    .toDate();

  return DateRange.fromDates(startDate, endDate);
}

function dateRange(range: string, startDate: Date | undefined, timezone: string, endDate: Date | undefined) {
  if (range === 'CUSTOM') {
    return customDateRange(startDate, timezone, endDate);
  } else if (range === 'ALL_TIME') {
    return DateRange.fromInstants(instant(0), instant());
  } else {
    const cutoff: number = 4; // 4am aligned with accounting tab
    return preSetDateRange(timezone, range)
      .add(cutoff, 'hours');
  }
}

const fetchBuilder = ({
  staffPerformance,
  range,
  businessId,
  timezone,
  startDate,
  endDate,
}: FetchBuilderProps) => {
  return () => staffPerformance.handleStaffPerformanceForBusinessId(businessId, dateRange(range, startDate, timezone, endDate));
};

export const useStaffPerformance = ({
  range,
  businessId,
  timezone,
  startDate,
  endDate,
}: StaffPerformanceProps) => {
  const configuration = useContext(ConfigurationContext);

  const { staffPerformance } = initEffects(configuration);

  const fetchStaffPerformance = fetchBuilder({
    staffPerformance,
    range,
    businessId,
    timezone,
    startDate,
    endDate,
  });

  const staffPerformanceResult = useQuery({
    queryKey: [queryKeys.STAFF_PERFORMANCE, businessId, range, startDate, endDate],
    queryFn: fetchStaffPerformance,
  });

  return {
    isLoading: staffPerformanceResult.isLoading,
    isError: staffPerformanceResult.isError,
    error: staffPerformanceResult.error,
    data: staffPerformanceResult.data,
  };
};
