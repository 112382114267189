import * as React from 'react';
import { FormattedMessage, unknownKey, useIntl } from 'src/app/i18n/TypedIntl';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { MoneyView } from '@sundayapp/web-money';
import { RowProps, salesSummaryRevenueNames } from './RowProps';
import { RestaurantShiftTimesOption } from '../../restaurant-shifts/models/RestaurantShiftTimesOption';
import { RowWithDetails } from './RowWithDetails';
import { RowWithoutDetails } from './RowWithoutDetails';
import { InfoOutlined } from '@mui/icons-material';
import { EndOfServiceConfiguration } from '../model/EndOfServiceConfiguration';
import { ServiceCharge } from './ServiceCharge';

export const ExpandableRows = ({
  date,
  restaurantShiftTimesOption,
  revenueAndTips,
  hideTips,
  eosConfiguration,
}: {
  date: Date,
  restaurantShiftTimesOption: RestaurantShiftTimesOption,
  revenueAndTips: RowProps;
  hideTips: boolean;
  eosConfiguration: EndOfServiceConfiguration;
}) => {
  const intl = useIntl();
  const theme = useTheme();

  const typographyStyle = {
    paddingBottom: '0.5em',
    paddingTop: '0.3em',
  };

  const localisationKey = salesSummaryRevenueNames.find(v => v === revenueAndTips.name) ?? unknownKey;
  const label = intl.formatMessage({ id: localisationKey, defaultMessage: revenueAndTips.name });

  return (
    <Box gap="16px">
        <Table width="100%">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '25rem' }}>
                {date.toLocaleDateString()}
                {' '}
                &bull;
                {' '}
                {restaurantShiftTimesOption.label}
                <Typography variant="h6" sx={typographyStyle} color="#000000">
                  {label}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ width: '15rem' }}>
                <FormattedMessage id="sales_summary.header.number_of_operations" />
                <Typography variant="h6" sx={typographyStyle} color="#000000">
                  {revenueAndTips.numberOfPayment}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ width: '15rem' }}>
                <FormattedMessage id="sales_summary.header.total_with_tips" />
                <Typography variant="h6" sx={typographyStyle} color="#000000">
                  <MoneyView value={revenueAndTips.total} />
                </Typography>
              </TableCell>
              {!hideTips &&
                <TableCell align="right" sx={{ width: '15rem' }}>
                  <FormattedMessage id="sales_summary.header.total_without_tips" />
                  <Typography variant="h6" sx={typographyStyle}>
                    <MoneyView value={revenueAndTips.revenue} />
                  </Typography>
                </TableCell>
              }
              {!hideTips &&
                <TableCell align="right" sx={{ width: '15rem' }}>
                  <FormattedMessage id="sales_summary.header.tips" />
                  <Typography variant="h6" sx={typographyStyle}>
                    <MoneyView value={revenueAndTips.tips} />
                  </Typography>
                </TableCell>
              }
              {eosConfiguration.displayServiceCharge &&
                <TableCell align="right" sx={{ width: '15rem' }}>
                  <Stack direction={'row'} gap={0.5} alignItems={'center'} justifyContent={'right'}>
                    <FormattedMessage id="sales_summary.header.service_charge" defaultMessage="Service charge" />
                    { eosConfiguration.displayServiceChargeTooltip && (
                    <Tooltip
                      title={intl.formatMessage({ id: 'sales_summary.tooltip.service_charge' })}
                      placement="right"
                    >
                      <InfoOutlined sx={{ color: theme.palette.text.secondary }} />
                    </Tooltip>
                    )}
                  </Stack>
                  <Typography variant="h6" sx={typographyStyle}>
                    <ServiceCharge revenue={revenueAndTips} />
                  </Typography>
                </TableCell>
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {revenueAndTips.details.map((summary) => (
              summary.details.length > 0
                ? <RowWithDetails revenueAndTips={summary} key={summary.name} hideTips={hideTips}
                                  eosConfiguration={eosConfiguration} />
                : <RowWithoutDetails revenue={summary} isRoot key={summary.name} hideTips={hideTips}
                                     eosConfiguration={eosConfiguration} />
            ))}

          </TableBody>
        </Table>
    </Box>

  );
};
