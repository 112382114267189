import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { MetabaseDashboard } from './domain/MetabaseDashboard';
import { useCurrentBusinessOrThrow } from '../business/hooks/useCurrentBusinessOrThrow';
import { useEffect } from 'react';
import { ampli } from '../ampli';
import { language } from '../app/i18n/I18nProvider';
import { shouldMockDataForBusiness } from '../mocks/shouldMockDataForBusiness';

export const DataAnalyticsBusinessPerformance = () => {

  const business = useCurrentBusinessOrThrow();


  useEffect(() => {
    ampli.dataAnalyticsCustomerInsightsClicked({
      businessId: business.id,
      businessName: business.name,
      clickDate: new Date().toISOString(),
    });
  }, []);

  if (shouldMockDataForBusiness(business.id)) {
    return <img width="100%" height="auto" src="/img/mocks/analytics/KYC-Tips-Mocked.png" alt="tips mocked" />;
  }

  return (
    <DataAnalyticsIframeComponent
      dashboard={language() === 'fr' ? MetabaseDashboard.BUSINESS_PERFORMANCE_FRENCH : MetabaseDashboard.BUSINESS_PERFORMANCE_ENGLISH} />
  );
};
