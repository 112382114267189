import { Button, Card, styled, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router-dom';
import { breakpoints, isMobileScreen, themeV5 } from 'src/app/theme/ThemeV5';
import { Business } from 'src/business/domain/Business';
import { sundayForRestaurantsPath } from 'src/app/navigation/pathHelpers';

type TableStatusProps = {
  business: Business;
};

const StatusCardWrapper = styled('div')`
  display: flex;
  width: 100%;
  padding-top: 24px;
  position: relative;
`;

const StatusCard = styled(Card)`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 386px;
  padding: 16px;

  background-color: ${themeV5.palette.primary.main};

  @media (max-width: ${breakpoints.mobile}) {
    min-width: 0;
    flex-direction: column;
  }
`;

const MessagePart = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  height: 104px;
  cursor: pointer;

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const PhoneImg = styled('img')`
  position: absolute;
  top: 0;
  left: calc(50% - 40px);
  max-height: 137px;

  mask-image: linear-gradient(180deg, #ededf2 50%, rgba(237, 237, 242, 0) 70%);

  @media (max-width: ${breakpoints.mobile}) {
    left: calc(50% - 115px);
  }
`;

const StatusButtonWrapper = styled('div')`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
`;

const StatusButton = styled(Button)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
`;

export const TableStatus = ({ business }: TableStatusProps) => {
  const isMobile = isMobileScreen();
  const navigate = useNavigate();
  const redirectToStatus = () => {
    navigate(sundayForRestaurantsPath(business.id));
  };

  return (
    <StatusCardWrapper>
      <StatusCard>
        <MessagePart onClick={redirectToStatus}>
          <PhoneImg alt="Table status" src="/img/home/MobilePhone2.png" />
          <Typography variant="body2" color={themeV5.palette.text.primary} textAlign="center">
            <FormattedMessage id="venue.home.table.title" defaultMessage="Check each table’s status" />
          </Typography>
          <Typography variant="caption" color={themeV5.palette.grey['800']} textAlign="center">
            <FormattedMessage id="venue.home.table.subtitle" defaultMessage="during the service with your phone" />
          </Typography>
        </MessagePart>
        {isMobile ? (
          <StatusButtonWrapper>
            <StatusButton variant="contained" size="small" disableElevation onClick={redirectToStatus}>
              <Typography variant="body1" color="white" textAlign="center">
                <FormattedMessage id="venue.home.table.button" defaultMessage="open sunday for restaurants" />
              </Typography>
            </StatusButton>
          </StatusButtonWrapper>
        ) : (
          <></>
        )}
      </StatusCard>
    </StatusCardWrapper>
  );
};
