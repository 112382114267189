import { differenceMoney, Money, money, sumMoneys } from '@sundayapp/web-money';

export type PaymentMethodRef = string;

const paymentStatus = [ 'unspecified', 'pending', 'succeeded', 'failed', 'canceled', 'action_required', 'authorized' ] as const;

export type PaymentStatuses = (typeof paymentStatus)[number];

export type RefundStatuses = 'unspecified' | 'pending' | 'succeeded' | 'failed';

const refundPaymentStatus = [ 'partially_refunded', 'refunded', 'refund_pending' ] as const;

export type RefundedPaymentStatuses = (typeof refundPaymentStatus)[number];

const tabPaymentStatus = [ 'released' ] as const;

export type TabPaymentStatuses = (typeof tabPaymentStatus)[number];

export const paymentDisplayStatus = [ ...paymentStatus, ...refundPaymentStatus, ...tabPaymentStatus ];

export type Refund = {
  id: string;
  amount: Money;
  status: RefundStatuses;
  timestamp: number;
  comment: string;
};

export interface Voucher {
  code: string;
  amount: Money;
}

export type PaymentMethodType =
  | 'card'
  | 'bancontact'
  | 'edenred'
  | 'sodexo'
  | 'ticket serviços'
  | 'paypal'
  | 'conecs'
  | 'ideal'
  | 'up'
  | 'bimpli'
  | 'swile';

export type PaymentProvider =
  | 'STRIPE'
  | 'EDENRED'
  | 'TOAST'
  | 'THUNES'
  | 'PAY_CRITICAL'
  | 'PAY_GREEN'
  | 'CHECKOUT'
  | 'NEPTING'
  | 'DUMMY'
  | 'DNA'
  | 'PAYROC';

export type CardBrand =
  'visa'
  | 'mastercard'
  | 'amex'
  | 'discover'
  | 'swile'
  | 'bimpli'
  | 'up'
  | 'pass_restaurant'
  | 'jcb'
  | 'unionpay'
  | 'unknown';

export type PaymentFailedDetails = {
  failCode: string;
  declineCode: string;
  nextStep: string;
  rawFailMessage: string;
};

export type UnknownOriginPayment = {
  type: 'unknown';
  payment: Payment;
};

export type PaymentForPDQ = {
  type: 'pdq';
  payment: Payment;
  tableNumber?: string;
};


export type PaymentForOrdering = {
  type: 'ordering';
  payment: Payment;
  orderDisplayId?: string;
};

export type PosStatus = {
  status: 'ENGAGED' | 'NOTIFIED' | 'REFUSED' | 'REGISTERED' | 'SUCCEEDED' | 'UNFULFILLED';
  cause?: string;
};

export type PaymentForPat = {
  type: 'pay-at-table';
  payment: Payment;
  tableNumber?: string;
  tableId?: string;
  posStatus: PosStatus | undefined;
};

export type CheckInTabPayment = PaymentForPat & {
  subType: 'tab';
  tabDisplayId: string;
  userName: string | undefined;
};

export type TabRelatedPayment = PaymentForPat & {
  tabId: string;
  tabDisplayId: string;
  userName: string | undefined;
};

export const isTabRelated = (paymentForPat: PaymentForPat): paymentForPat is TabRelatedPayment =>
  'tabDisplayId' in paymentForPat && !!paymentForPat.tabDisplayId;

export const isPayAtTable = (specificPayment: SpecificPayment): specificPayment is PaymentForPat =>
  specificPayment.type === 'pay-at-table';

export type SpecificPayment =
  PaymentForOrdering
  | CheckInTabPayment
  | PaymentForPat
  | PaymentForPDQ
  | UnknownOriginPayment;

export const computeRefundedPaymentStatus = (payment: Payment) => {
  if (payment.hasPendingRefund()) return 'refund_pending';
  if (payment.hasBeenFullyRefunded()) return 'refunded';
  return 'partially_refunded';
};

export const computeTabPaymentStatus = (payment: Payment, selectedStatus?: string) => {
  // When an authorization on a tab is released, the payment status is set to 'canceled' but we want to display 'released' instead
  if (payment.status === 'canceled' && selectedStatus === 'released') return 'released';
  // when the filter is set to Failed 3DS, we will show the canceled payments, but we don't want to display the 'released' payment
  if (payment.status === 'canceled' && selectedStatus === 'canceled') return '';
  return payment.status;
};

export const computePaymentStatus = (payment: Payment, selectedStatus?: string): PaymentStatuses | RefundedPaymentStatuses | TabPaymentStatuses => {
  if (payment.hasRefund()) return computeRefundedPaymentStatus(payment) as RefundedPaymentStatuses;
  if (payment.tabId !== undefined) return computeTabPaymentStatus(payment, selectedStatus) as TabPaymentStatuses;
  return payment.status;
};

export class Payment {
  constructor(
    public id: string,
    public orderId: string | undefined,
    public tabId: string | undefined,
    public totalPaidByCustomer: Money,
    public tips: Money,
    public digitalFeeAmount: Money,
    public status: PaymentStatuses,
    public timestamp: number,
    public refunds: Refund[],
    public paymentProvider: PaymentProvider,
    public paymentMethodType: PaymentMethodType,
    public metadata: Record<string, string>,
    public failedDetails?: PaymentFailedDetails,
    public paymentMethodRef?: PaymentMethodRef,
    public cardBrand?: CardBrand,
    public voucher?: Voucher,
    public serialNumber?: string,
    public waiterName?: string,
  ) {
  }

  private partiallyRefundablePaymentProvider = new Set<PaymentProvider>([ 'STRIPE', 'PAY_CRITICAL', 'CHECKOUT', 'DNA' ]);

  get refundableAmount(): Money {
    return money(
      Math.max(0, this.totalPaidByCustomer.amount - this.sumRefundsAmount()),
      this.totalPaidByCustomer.currency,
    );
  }

  public computeTips(): [ initialAmount: Money, remainingAmount: Money ] {
    return [ this.tips, this.remainingTipsAmount() ];
  }

  public hasBeenFullyRefunded(): boolean {
    return this.refundableAmount.amount === 0;
  }

  private remainingTipsAmount(): Money {
    /*
      if totalRefund < subTotal                  => order amount partially refunded
      if totalRefund < subTotal + tips           => order amount refunded + tips partially refunded
      if totalRefund = subTotal + tips + voucher => full refund
     */
    const refundAmountOnTipVoucherAndDigitalFee = differenceMoney(this.sumRefundsMoneyAmount(), this.subTotal());
    if (refundAmountOnTipVoucherAndDigitalFee.amount > 0) {
      return money(
        Math.max(0, differenceMoney(this.tips, refundAmountOnTipVoucherAndDigitalFee).amount),
        this.totalPaidByCustomer.currency,
      );
    }
    return this.tips;
  }

  public computeSubtotal(): [ initialAmount: Money, remainingAmount: Money ] {
    return [ this.subTotal(), this.subTotalRemaining() ];
  }

  private subTotal(): Money {
    return differenceMoney(differenceMoney(this.totalPaidByCustomer, this.tips), this.digitalFeeAmount);
  }

  private subTotalRemaining(): Money {
    const subTotalMinusRefunds = differenceMoney(this.subTotal(), this.sumRefundsMoneyAmount());
    return {
      amount: Math.max(0, subTotalMinusRefunds.amount),
      currency: subTotalMinusRefunds.currency,
    };
  }

  public computeUserSundayPaymentFee(): [ initialAmount: Money, remainingAmount: Money ] {
    return [ this.digitalFeeAmount, this.userSundayPaymentFeeRemaining() ];
  }

  private userSundayPaymentFeeRemaining(): Money {
    const refundAmountOnDigitalFeeAndVoucher = differenceMoney(
      differenceMoney(this.sumRefundsMoneyAmount(), this.subTotal()),
      this.tips,
    );
    if (refundAmountOnDigitalFeeAndVoucher.amount > 0) {
      return money(
        Math.max(0, differenceMoney(this.digitalFeeAmount, refundAmountOnDigitalFeeAndVoucher).amount),
        this.totalPaidByCustomer.currency,
      );
    }
    return this.digitalFeeAmount;
  }

  public computeTotal(): [ initialAmount: Money, remainingAmount: Money ] {
    if (!this.voucher) {
      return [ this.totalPaidByCustomer, this.refundableAmount ];
    }
    return [ sumMoneys(this.totalPaidByCustomer, this.voucher.amount), this.remainingTotalAmount() ];
  }

  private remainingTotalAmount(): Money {
    return money(
      this.totalPaidByCustomer.amount + (this.voucher?.amount.amount ?? 0) - this.sumRefundsAmount(),
      this.totalPaidByCustomer.currency,
    );
  }

  private sumRefundsMoneyAmount(): Money {
    return this.filterSucceededAndPendingRefunds().reduce((acc, curr) => sumMoneys(acc, curr.amount), {
      amount: 0,
      currency: this.totalPaidByCustomer.currency,
    });
  }

  private sumRefundsAmount(): number {
    return this.filterSucceededAndPendingRefunds().reduce((acc, curr) => acc + curr.amount.amount, 0);
  }

  private filterSucceededAndPendingRefunds(): Refund[] {
    return this.refunds.filter(({ status }) => status === 'succeeded' || status === 'pending');
  }

  canBeRefunded(): boolean {
    return this.status === 'succeeded' && (!this.refunds.length || !this.hasBeenFullyRefunded());
  }

  isPartiallyRefundable(posReference?: string): boolean {
    return (
      this.partiallyRefundablePaymentProvider.has(this.paymentProvider) &&
      ![ 'ZONESOFT' ].includes(posReference ?? '') &&
      this.refundableAmount.amount > 0
    );
  }

  hasPendingRefund() {
    return this.refunds.find((refund) => refund.status === 'pending');
  }

  hasRefund() {
    return this.filterSucceededAndPendingRefunds().length > 0;
  }

  revenue() {
    const remaining = differenceMoney(this.subTotal(), this.sumRefundsMoneyAmount());
    return this.voucher ? sumMoneys(remaining, this.voucher.amount) : remaining;
  }
}

export const isCheckInTabPayment = (paymentForPat: PaymentForPat): paymentForPat is CheckInTabPayment =>
  paymentForPat.payment.metadata.tab_id !== undefined;

export const isCancelable = (paymentForPat: CheckInTabPayment): boolean =>
  computePaymentStatus(paymentForPat.payment) === 'authorized';

export const isCancelableTab = (specificPayment: SpecificPayment) =>
  isPayAtTable(specificPayment) && isCheckInTabPayment(specificPayment) && isCancelable(specificPayment);
