import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Button, FormControl, FormHelperText, Modal, Stack, TextField, Typography } from '@mui/material';
import { ApplicableRatingsSelector } from './ApplicableRatingsSelector';
import { ReplyTemplateDraft } from '../domain/ReplyTemplate';
import {
  ALLOWED_VARIABLE_CUSTOMER_NAME,
  ALLOWED_VARIABLE_VENUE_NAME,
  checkVariables,
} from '../domain/ReplyTemplateUtils';
import { styleButton, styleModal } from '../../../modal.style';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

interface Props {
  title: string;
  submitLabel: string;
  onReplyTemplateAction: (replyTemplateDraft: ReplyTemplateDraft) => Promise<void>;
  template?: ReplyTemplateDraft;
  useCase: 'add' | 'update' | 'duplicate';
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ReplyTemplateModal: React.FC<Props> = ({
  title,
  submitLabel,
  onReplyTemplateAction,
  template,
  handleClose,
  handleConfirm,
  isOpen,
}: Props) => {
  const intl = useIntl();
  const snackbar = useSnackbar();

  const {
    register,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<ReplyTemplateDraft>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      prefilled: template?.prefilled ?? false,
      applicableRatings: template?.applicableRatings ?? [],
      name: template?.name ?? '',
      content: template?.content ?? '',
    },
  });

  const onSubmit = async (replyTemplateDraft: ReplyTemplateDraft) => {
    try {
      await onReplyTemplateAction(replyTemplateDraft);
      handleConfirm();
    } catch (e) {
      snackbar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'error.generic_error' }),
      });
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-title">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} marginTop={2} sx={{ ...styleModal, width: 500 }}>
        <Stack direction="column" justifyContent="start" gap={3.5}>
          <Typography variant="h5" id="modal-title">
            {title}
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <TextField
              id="name"
              placeholder={intl.formatMessage({
                id: 'settings.reviews_and_loyalty.reply_template.modal.field.title.placeholder',
              })}
              label={intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.modal.field.title.label' })}
              InputLabelProps={{ shrink: true }}
              error={!!errors.name}
              sx={{ width: '70%' }}
              {...register('name', { required: true })}
            />
            <Controller
              control={control}
              rules={{ required: true }}
              name="applicableRatings"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ApplicableRatingsSelector value={value} onChange={onChange} error={!!error} />
              )}
            />
          </Stack>
          <Stack>
            <FormControl>
              <TextField
                id="templateContent"
                multiline
                rows={5}
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={!!errors.content}
                label={intl.formatMessage({
                  id: 'settings.reviews_and_loyalty.reply_template.modal.field.content.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'settings.reviews_and_loyalty.reply_template.modal.field.content.placeholder',
                })}
                {...register('content', {
                  required: true,
                  validate: {
                    checkVariables: (value) =>
                      checkVariables(value)
                        ? true
                        : `allowed variables are ${ALLOWED_VARIABLE_CUSTOMER_NAME} and ${ALLOWED_VARIABLE_VENUE_NAME}`,
                  },
                })}
                aria-describedby="component-error-text"
              />
              <FormHelperText id="component-error-text" error>
                {errors.content?.message}
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack direction="column" gap={1.5}>
            <Button
              size="large"
              variant="contained"
              style={{ ...styleButton }}
              disabled={!isValid || isSubmitting}
              type="submit"
            >
              {submitLabel}
            </Button>
            <Button size="large" variant="outlined" style={{ ...styleButton }} onClick={handleClose}>
              {intl.formatMessage({ id: 'modal.cancel' })}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

ReplyTemplateModal.defaultProps = {
  template: undefined,
};
