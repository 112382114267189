import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Visibility } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { useIntl } from 'src/app/i18n/TypedIntl';

function QRCodesDataGridColumnsDefinition(handleOpenQRCodePreviewerDialog: () => void): GridColDef[] {
  const intl = useIntl();

  return [
    {
      align: 'center',
      field: 'name',
      flex: 0.5,
      headerAlign: 'center',
      headerName: intl.formatMessage({ id: 'qr_codes.label' }),
    },
    {
      align: 'center',
      field: 'number',
      flex: 0.5,
      headerAlign: 'center',
      headerName: intl.formatMessage({ id: 'qr_codes.table_number' }),
    },
    {
      align: 'center',
      field: 'shortLink',
      flex: 0.5,
      headerAlign: 'center',
      headerName: intl.formatMessage({ id: 'qr_codes.ticket' }),
      renderCell: ({ row: { shortLink } }: GridCellParams) => (
        <Link href={shortLink} target="_blank" rel="noreferrer">
          <IconButton>
            <ListAltIcon />
          </IconButton>
        </Link>
      ),
      sortable: false,
      width: 120,
    },
    {
      align: 'center',
      field: 'preview',
      flex: 0.5,
      headerAlign: 'center',
      headerName: intl.formatMessage({ id: 'qr_codes.preview' }),
      renderCell: () => (
        <IconButton onClick={handleOpenQRCodePreviewerDialog}>
          <Visibility />
        </IconButton>
      ),
      sortable: false,
      width: 120,
    },
  ];
}

export default QRCodesDataGridColumnsDefinition;
