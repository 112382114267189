import { Box, Button, Drawer, Link, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { canArchive, canDownloadReceipt, canSendEmail, PaymentLink } from '../domain/PaymentLink';
import { useNavigate, useOutletContext } from 'react-router';
import { PaymentLinkPageOutletContext } from './PaymentLinkPage';
import React, { CSSProperties } from 'react';
import { ChipPaymentLinkStatus } from './components/ChipPaymentLinkStatus';
import { DatePanelField } from './components/DateDisplays';
import CloseIcon from '@mui/icons-material/Close';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { ArchiveOutlined, DownloadOutlined, SendOutlined } from '@mui/icons-material';
import { money, MoneyView } from '@sundayapp/web-money';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyPaste } from 'src/utils/CopyPaste';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PaymentLinkRepository } from '../infrastructure/PaymentLinkRepository';
import axios from 'axios';
import { buildQueryKey } from '../queries/paymentLinks';
import { useQueryClient } from '@tanstack/react-query';
import { isAuthorized } from 'src/domain/user/Role';
import { userRoles } from 'src/domain/user/UserRole';
import { useCurrentUser } from 'src/auth/component/UserProvider';
import { AuthenticatedUser } from 'src/auth/domain/user';
import { useSendLinkToPay } from './components/SendLinkToPay';
import { EmptyTableCellValue } from 'src/payments/components/EmptyTableCellValue';
import { PaymentMethodBrand, PaymentMethodIcon } from 'src/accounting/common/Icons/PaymentMethodIcon';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

const buttonStyle: CSSProperties = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const PaymentLinkPanel = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { paymentLinks, businessId } = useOutletContext<PaymentLinkPageOutletContext>();
  const paymentLink: PaymentLink | undefined = paymentLinks.find((link) => link.sessionId === sessionId);

  const intl = useIntl();
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const user = useCurrentUser() as AuthenticatedUser;
  const isCurrentUserAdmin = isAuthorized(
    user.claims,
    [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
    businessId,
  );

  const paymentLinkRepository = new PaymentLinkRepository(axios, configuration);

  const { SendLinkToPay, open: openSendLinkToPay } = useSendLinkToPay(paymentLink, () => {
    queryClient.invalidateQueries({ queryKey: buildQueryKey(businessId) });
    snackbar.addNotification({
      text: intl.formatMessage({ id: 'payment_link.notification.mail_sent' }),
      variant: 'success',
    });
  });

  const close = () => navigate(-1);

  if (!paymentLink) {
    close();
    return;
  }

  const dropPaymentLink = async () => {
    paymentLinkRepository.drop(paymentLink).then(() => {
      queryClient.invalidateQueries({ queryKey: buildQueryKey(businessId) });
      snackbar.addNotification({
        text: intl.formatMessage({ id: 'payment_link.notification.link_archived' }),
        variant: 'success',
      });
    });
  };

  const copy = (some: string) => {
    copyPaste(some);
    snackbar.addNotification({ text: intl.formatMessage({ id: 'common.copied' }), variant: 'success' });
  };

  const payment = paymentLink.payment;
  const receiptDownloadUrl = `${configuration.receiptApiBaseUrl}/receipts/v2/payments/${paymentLink.payment?.paymentId}`;
  return (
    <Drawer open anchor="right" sx={{ position: 'relative' }} ModalProps={{ onBackdropClick: close }}>
      <SendLinkToPay />

      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        padding={'16px 24px'}
        width="100%"
        position="absolute"
        bottom={0}
        borderTop={`1px solid ${themeV5.palette.divider}`}
      >
        <Button
          variant="contained"
          color="primary"
          sx={buttonStyle}
          startIcon={<ContentCopyIcon />}
          onClick={() => copy(paymentLink.qrCode.qrCodeURL)}
        >
          <FormattedMessage id={'payment_link.table.copy_link'} />
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" padding={3} gap={3}>
        <Box sx={{ cursor: 'pointer' }} onClick={close}>
          <CloseIcon />
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" flexDirection="row" alignItems="end" gap={1}>
            <DatePanelField date={paymentLink.qrCode.eventDate} />
            <ChipPaymentLinkStatus paymentLink={paymentLink} />
          </Box>
          <Typography variant="h4" sx={{ wordWrap: 'break-word', width: '300px' }}>
            {paymentLink.qrCode.title}
          </Typography>
          <Typography
            variant="body2"
            color={themeV5.palette.text.secondary}
            sx={{ wordWrap: 'break-word', width: '300px' }}
          >
            {paymentLink.qrCode.description}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          {canSendEmail(paymentLink) && (
            <Button
              variant="contained"
              color="secondary"
              sx={buttonStyle}
              startIcon={<SendOutlined />}
              onClick={openSendLinkToPay}
            >
              <FormattedMessage id={'payment_link.panel.cta.send_by_email'} />
            </Button>
          )}
          {canDownloadReceipt(paymentLink) && (
            <Button
              variant="contained"
              color="secondary"
              sx={buttonStyle}
              startIcon={<DownloadOutlined />}
              href={receiptDownloadUrl}
            >
              <FormattedMessage id={'payments.table.tooltip.export'} />
            </Button>
          )}
          {isCurrentUserAdmin && canArchive(paymentLink) && (
            <Button
              variant="contained"
              color="secondary"
              sx={buttonStyle}
              startIcon={<ArchiveOutlined />}
              onClick={dropPaymentLink}
            >
              <FormattedMessage id={'payment_link.table.drop_link'} />
            </Button>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1" color={themeV5.palette.text.primary}>
            <FormattedMessage id={'payment_link.panel.payment_info.title'} />
          </Typography>
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Typography variant="body1" color={themeV5.palette.text.secondary}>
              <FormattedMessage id={'payment_link.table.headers.amount'} />
            </Typography>
            <Typography variant="body1" color={themeV5.palette.text.primary}>
              <MoneyView value={money(paymentLink.qrCode.amountCents * 1000, paymentLink.qrCode.currency)} />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Typography variant="body1" color={themeV5.palette.text.secondary}>
              <FormattedMessage id={'payment_link.table.headers.created_at'} />
            </Typography>
            <Typography variant="body1" color={themeV5.palette.text.primary}>
              <DatePanelField date={paymentLink.createdAt} />
            </Typography>
          </Box>
          {user.impersonated && (
            <>
              <Box display="flex" justifyContent="space-between" gap={1}>
                <Typography variant="body1" color={themeV5.palette.text.secondary}>
                  Session id
                </Typography>
                <Link onClick={() => copy(paymentLink.sessionId)} sx={{ cursor: 'pointer' }}>
                  copy
                </Link>
              </Box>
              {!!payment && (
                <Box display="flex" justifyContent="space-between" gap={1}>
                  <Typography variant="body1" color={themeV5.palette.text.secondary}>
                    Payment id
                  </Typography>
                  <Link onClick={() => copy(payment.paymentId)} sx={{ cursor: 'pointer' }}>
                    copy
                  </Link>
                </Box>
              )}
            </>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1" color={themeV5.palette.text.primary}>
            <FormattedMessage id={'payment_link.panel.user_info.title'} />
          </Typography>
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Typography variant="body1" color={themeV5.palette.text.secondary}>
              <FormattedMessage id={'payment_link.panel.payment_info.customer_email'} />
            </Typography>
            <Typography variant="body1" color={themeV5.palette.text.primary}>
              {paymentLink.customerEmail || <EmptyTableCellValue />}
            </Typography>
          </Box>
          {payment && (
            <Box display="flex" justifyContent="space-between" gap={1}>
              <Typography variant="body1" color={themeV5.palette.text.secondary}>
                <FormattedMessage id={'sales_summary.header.payment_method'} />
              </Typography>
              <Box display="flex" gap={1}>
                <PaymentMethodIcon brand={payment.brand as PaymentMethodBrand} />
                <Typography variant="body1" color={themeV5.palette.text.primary}>
                  **** {payment.last4}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};
