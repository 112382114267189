import { AxiosStatic } from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Payments } from '../model/Payments';
import { BusinessId } from 'src/business/domain/Business';
import { EndOfServiceConfiguration } from '../model/EndOfServiceConfiguration';

export class EndOfServiceClient {

  private readonly accountingApiBaseUrl: string;

  private readonly bookkeepingApiBaseUrl: string;

  constructor(private httpClient: AxiosStatic) {
    const configuration = ConfigurationLoader.load();
    this.accountingApiBaseUrl = configuration.accountingApiBaseUrl;
    this.bookkeepingApiBaseUrl = configuration.bookkeepingApiBaseUrl;
  }

  async summaryOnAPeriod(businessId: BusinessId, startDate: Date, endDate: Date): Promise<Payments> {
    return this.getEOSDataFromProductPayment(businessId, startDate, endDate)
      .then(it => it.data);
  }

  async getEndOfServiceConfiguration(businessId: BusinessId): Promise<EndOfServiceConfiguration> {
    return this.httpClient.get<EndOfServiceConfiguration>(`${this.bookkeepingApiBaseUrl}/eos/businesses/${businessId}/configuration`, {
      headers: {
        contentType: 'application/json',
      },
    })
      .then(it => it.data)
      .catch(() => {
        return ({
          bourdoncleModeEnabled: false,
          displayPrintMode: false,
          displayRevenueCenters: false,
        } as EndOfServiceConfiguration);
      },
      );
  }

  private getEOSDataFromProductPayment(businessId: BusinessId, startDate: Date, endDate: Date) {
    return this.httpClient.get<Payments>(`${this.accountingApiBaseUrl}/eos/businesses/${businessId}/payments`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        from: startDate,
        to: endDate,
      },
    });
  }
}
