import CsvDownloader from 'react-csv-downloader';
import { useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { isGoogle, Review } from '../../domain/Review';
import { ampli } from 'src/ampli';
import { IntlShape } from 'react-intl/src/types';

interface ReviewCSVExporterButtonProps {
  venueName: string;
  venueId: string;
  reviews: Review[];
}

function ReviewCSVExporterButton({
  venueName,
  venueId,
  reviews,
}: ReviewCSVExporterButtonProps) {
  function buildFileName() {
    const isoFormattedDate = new Date(Date.now()).toISOString();
    const fileNameFormattedVenueName = venueName.toLowerCase()
      .replace(' ', '-');

    return `${isoFormattedDate}-${fileNameFormattedVenueName}`;
  }

  const formatReviews = (intl: IntlShape) =>
    reviews.map((review) => {
      const creationDateLocalizedTheSameAsPaymentExport = `${(intl.formatDate(new Date(review.creationDate).toString()))} ${(intl.formatTime(new Date(review.creationDate).toString()))}`;

      return ({
        platform: review.platform,
        origin: review.comingFromSunday ? 'sunday' : 'google',
        creationDate: creationDateLocalizedTheSameAsPaymentExport,
        rating: review.rating != null ? String(review.rating) : '',
        feedback: review.feedback != null ? review.feedback : '',
        author: isGoogle(review) ? review.author.name : '',
        tableName: review.tableName != null ? review.tableName : '',
        waiterName: review.waiterName != null ? review.waiterName : '',
        totalAmount: (review.totalAmount != null && review.totalAmount.amount != null) ? String(review.totalAmount.amount / 100000) : '',
      });
    });

  const intl = useIntl();

  const columnsDefinition = [
    {
      id: 'platform',
      displayName: intl.formatMessage({ id: 'review.csv.platform' }),
    },
    {
      id: 'origin',
      displayName: intl.formatMessage({ id: 'review.csv.origin' }),
    },
    {
      id: 'creationDate',
      displayName: intl.formatMessage({ id: 'review.creation_date' }),
    },
    {
      id: 'rating',
      displayName: intl.formatMessage({ id: 'review.rating' }),
    },
    {
      id: 'feedback',
      displayName: intl.formatMessage({ id: 'review.comment' }),
    },
    {
      id: 'author',
      displayName: intl.formatMessage({ id: 'review.csv.author' }),
    },
    {
      id: 'tableName',
      displayName: intl.formatMessage({ id: 'orders.table.number' }),
    },
    {
      id: 'waiterName',
      displayName: intl.formatMessage({ id: 'orders.table.waiter_name' }),
    },
    {
      id: 'totalAmount',
      displayName: intl.formatMessage({ id: 'review.total_spent' }),
    },
  ];

  return (
    <CsvDownloader filename={buildFileName()} wrapColumnChar='"' columns={columnsDefinition} datas={formatReviews(intl)}>
      <IconButton
        size="large"
        onClick={() => {
          ampli.reviewsCsvExportButtonClicked({ venueId });
        }}
      >
        <DownloadIcon fontSize="inherit" />
      </IconButton>
    </CsvDownloader>
  );
}

export default ReviewCSVExporterButton;
