import { Dropdown } from '@sundayapp/b2b-react-component-library';
import React, { useMemo } from 'react';
import { IntlShape } from 'react-intl/src/types';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { StatusToSendNotificationDataType } from '../../../../domain/BoxDetails';

type OrderSentStatusDropdownProps = {
  statusToSend: StatusToSendNotificationDataType;
  onStatusToSendChange: (updatedStatus: StatusToSendNotificationDataType) => void;
  disabled: boolean;
};

type StatusToSendDataOption = {
  id: StatusToSendNotificationDataType;
  label: string;
};

const statusToSendDataOptions = (intl: IntlShape): StatusToSendDataOption[] => [
  {
    id: StatusToSendNotificationDataType.SENT_TO_POS,
    label: intl.formatMessage({ id: 'box.sendSmsWhenOrderSentToPos', defaultMessage: 'order received by pos' }),
  },
  {
    id: StatusToSendNotificationDataType.READY_FOR_PICKUP,
    label: intl.formatMessage({ id: 'box.sendSmsWhenOrderReady', defaultMessage: 'order ready or finalized' }),
  },
  {
    id: StatusToSendNotificationDataType.PREPARED,
    label: intl.formatMessage({ id: 'box.sendSmsWhenOrderPrepared', defaultMessage: 'order prepared' }),
  },
  {
    id: StatusToSendNotificationDataType.FINALIZED,
    label: intl.formatMessage({ id: 'box.sendSmsWhenOrderFinalized', defaultMessage: 'order finalized' }),
  },
];

export const OrderSentStatusDropdown = ({
  statusToSend,
  onStatusToSendChange,
  disabled,
}: OrderSentStatusDropdownProps) => {
  const intl = useIntl();

  const options = useMemo(() => statusToSendDataOptions(intl), [intl]);

  const computeDropDownLabel = (value: StatusToSendNotificationDataType | undefined): string => {
    if (!value) {
      return computeDropDownLabel(StatusToSendNotificationDataType.SENT_TO_POS);
    }

    const idx = options.findIndex((opt) => opt.id === value);
    if (idx >= 0) {
      return options[idx].label;
    }
    return computeDropDownLabel(StatusToSendNotificationDataType.SENT_TO_POS);
  };

  const dropDownValue = computeDropDownLabel(statusToSend);
  const dropDownOptions: string[] = options.map((opt) => opt.label);

  const updateStatusToSend = async (statusToUpdate: string) => {
    const selectedIdx = options.findIndex((opt) => opt.label === statusToUpdate);
    const statusToSendNotification = options[selectedIdx].id;

    onStatusToSendChange(statusToSendNotification);
  };

  return (
    <Dropdown
      size="small"
      options={dropDownOptions}
      onChange={updateStatusToSend}
      value={dropDownValue}
      disabled={disabled}
    />
  );
};
