import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { RouteDefinitions } from '../RouteDefinitions';
import SignIn from '../../auth/pages/SignIn/SignIn';

export const marketingTargets = {
  PAYMENTS: 'payments',
  STAFF_PERFORMANCE: 'staff-performance',
  REVIEWS: 'sunday-plus/reviews',
  INSIGHTS: 'sunday-plus/insights',
  SETTINGS_REVIEWS: 'settings/reviews',
} as const;

type MarketingTargets = ValuesOf<typeof marketingTargets>;

export const pathFromTarget = (target: MarketingTargets) => `/${target}`;
export const queryStringFromTarget = (target: MarketingTargets) => `/?target=${target}`;

export const TrackAndRedirect = () => {
  const location = useLocation();
  const target = new URLSearchParams(location.search).get('target');

  switch (target) {
    case RouteDefinitions.StaffPerformance.name:
      return <Navigate to={queryStringFromTarget(marketingTargets.STAFF_PERFORMANCE)} />;
    case RouteDefinitions.ReviewsSettings.name:
      return <Navigate to={queryStringFromTarget(marketingTargets.SETTINGS_REVIEWS)} />;
    case RouteDefinitions.Reviews.name:
      return <Navigate to={queryStringFromTarget(marketingTargets.REVIEWS)} />;
    default:
      return <SignIn />;
  }
};

export const redirectionSearchParamName = 'target' as const;
