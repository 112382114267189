import { EnrollmentId } from 'src/business/domain/Enrollment';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { queryKeys } from 'src/app/queries/utils';
import { useQuery } from '@tanstack/react-query';
import { WaiterAppRepository } from 'src/pages/Settings/AppSettings/tips/infrastructure/WaiterAppRepository';
import waiterName from 'src/pages/Settings/AppSettings/tips/domain/WaiterName';

const query = (enrollmentId: EnrollmentId) => {
  const configuration = ConfigurationLoader.load();
  const repository = new WaiterAppRepository(configuration.waiterAppBackendUrl);
  return {
    queryKey: [queryKeys.tips.GET_WAITERS_NOT_LINKED, { enrollmentId }],
    queryFn: async (): Promise<waiterName[]> => {
      return repository.getNotLinkedWaiters(enrollmentId);
    },
  };
};

export const useWaitersNotLinked = (enrollmentId: EnrollmentId) => useQuery(query(enrollmentId));
