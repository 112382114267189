import { Money } from '@sundayapp/web-money';
import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { PaymentRepository, Range } from './PaymentRepository';
import { RepositoryHttp } from '../../common/repository/RepositoryHttp';
import {
  Payment,
  PaymentProvider,
  PaymentRefund,
  PaymentStatus,
  PaymentStatusRecord,
  RefundStatus,
  RefundStatusRecord,
} from '../types';
import { BusinessId } from 'src/business/domain/Business';

type PaymentResult = {
  payments?: Payment[];
};

type RefundResult = {
  refundId: string;
};

const ALL_STATUSES = new Set(Object.keys(PaymentStatusRecord));
const ALL_REFUND_STATUSES = new Set(Object.keys(RefundStatusRecord));

const validateRefundData = (refundData: PaymentRefund): PaymentRefund => ({
  ...refundData,
  status: ALL_REFUND_STATUSES.has(refundData.status) ? (refundData.status as RefundStatus) : RefundStatus.UNKNOWN,
});

const validatePaymentData = (paymentData: Payment): Payment => ({
  ...paymentData,
  status: ALL_STATUSES.has(paymentData.status) ? (paymentData.status as PaymentStatus) : PaymentStatus.UNKNOWN,
  refunds: paymentData.refunds.map(validateRefundData),
  paymentProvider: paymentData.paymentProvider as PaymentProvider,
  failDetails: paymentData.failDetails,
});

export class PaymentRepositoryHttp extends RepositoryHttp implements PaymentRepository {
  constructor(private paymentBackendUrl: string) {
    super();
  }

  async getPayments(businessId: BusinessId, range: Range): Promise<Payment[]> {
    const res = await axios.get<PaymentResult>(
      `${this.paymentBackendUrl}/payments?venueId=${businessId}&startDate=${range.startDate}&endDate=${range.endDate}`,
    );
    if (!res.data.payments) {
      throw new Error('Error parsing payments 1');
    }

    return (res.data.payments as Payment[]).map(validatePaymentData);
  }

  async stripeRefund(paymentId: string, refundedAmount: Money, comment: string, idempotencyKey: string): Promise<void> {
    await axios.put<RefundResult>(
      `${this.paymentBackendUrl}/admin/payments/${paymentId}/refunds`,
      { description: comment, idempotencyKey, refundAmount: refundedAmount },
      this.withDefaultHeader(),
    );
  }
}
