import React from 'react';
import { CircularProgress } from '@mui/material';
import { SundayPoolingError } from 'src/tips/dispatch/components/SundayPoolingError';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Dispatch } from 'src/tips/dispatch/Dispatch';
import { useGetAllStaff } from 'src/tips/dispatch/infrastructure/useGetAllStaff';
import { useGetAllPendingTipsDispatch } from 'src/tips/dispatch/infrastructure/useGetAllPendingTipsDispatch';

export const DispatchLoader = () => {
  const business = useCurrentBusinessOrThrow();

  const { data: tipsToDispatchArray, error: errorWhileFetchingPendingTipsDispatch } = useGetAllPendingTipsDispatch(
    business.id,
  );
  const { data: staff, error: errorWhileFetchingStaff } = useGetAllStaff(business.id);

  if (!tipsToDispatchArray || !staff) return <CircularProgress color="inherit" />;

  if (errorWhileFetchingPendingTipsDispatch || errorWhileFetchingStaff) return <SundayPoolingError />;

  return <Dispatch tipsToDispatchArray={tipsToDispatchArray} staff={staff} />;
};
