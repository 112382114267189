import moment from 'moment';

export class ShiftHour {
  constructor(public hour: number, public minute: number) {
  }

  static fromHourAndMinute(hourAndMinute: string): ShiftHour {
    const split = hourAndMinute.split(':');
    return new ShiftHour(
      parseInt(split[0], 10), parseInt(split[1], 10),
    );
  }

  toMoment() {
    return moment().set({
      hour: this.hour, minute: this.minute, second: 0, millisecond: 0,
    });
  }
}
