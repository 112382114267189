import { z } from 'zod';

export const StaffZ = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
});

export const StaffListZ = z.object({
  staff: z.array(StaffZ),
});

export type Staff = z.infer<typeof StaffZ>;
