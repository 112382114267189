import { useFirestore } from 'reactfire';
import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { ReportingDigest } from '../domain/ReportingDigest';
import { ReportingDigestUseCaseForUsa } from '../usecase/ReportingDigestUseCaseForUsa';
import { ReportingDigestUseCase } from '../usecase/ReportingDigestUseCase';
import { OrderForAccountingRepositoryFirestore } from '../infrastructure/OrderForAccountingRepositoryFirestore';
import { AccountingRepository } from '../infrastructure/AccountingRepository';
import { defaultRange, GetAccountingShiftUseCase } from '../usecase/GetAccountingShiftUseCase';
import { ReportingDigestByWaiter } from '../domain/ReportingDigestByWaiter';
import { ReportingDigestByWaiterUseCase } from '../usecase/RepotingDigestByWaiterUseCase';
import { SaveAccountingShiftUseCase } from '../usecase/SaveAccountingShiftUseCase';
import { computeDigitalFeeAmount, DEFAULT_ACCOUNT_DIGITAL_FEE_RATE } from './WaiterReporting/digital-fee.hook';
import { usePaymentAccount } from 'src/payments/queries/paymentAccount';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Business, BusinessId } from 'src/business/domain/Business';

const isUSVenue = (business: Business): boolean => business.address.countryCode === 'US';

const useDigitalFeeCompute = (business: Business) => {
  const paymentAccount = usePaymentAccount(business.patEnrollment?.id);
  const digitalFeeCapping = paymentAccount.data?.digitalFeeCapping;
  const digitalFeeRate = paymentAccount.data?.digitalFeeRate || DEFAULT_ACCOUNT_DIGITAL_FEE_RATE;
  return computeDigitalFeeAmount(business.patEnrollment?.id, { digitalFeeRate, digitalFeeCapping });
};

export const useReporting = () => {
  const business = useCurrentBusinessOrThrow();
  const firestore = useFirestore();
  const digitalFeeCompute = useDigitalFeeCompute(business);
  const [reportingDateRange, setReportingDateRange] = useState(defaultRange.today);

  const accountingRepository = new AccountingRepository(axios);
  const orderForAccountingRepository = new OrderForAccountingRepositoryFirestore(firestore);

  const getAccountingShiftUseCase = new GetAccountingShiftUseCase(accountingRepository);
  const saveAccountingShiftUseCase = new SaveAccountingShiftUseCase(accountingRepository);
  const reportingDigestByWaiterUseCase = new ReportingDigestByWaiterUseCase(
    business!,
    orderForAccountingRepository,
    accountingRepository,
    digitalFeeCompute,
  );

  const [reportingDigest, setReportingDigest] = useState<ReportingDigest | undefined>(undefined);
  const [selectedRange, setSelectedRange] = useState<'today' | 'lunch' | 'dinner' | 'yesterday' | 'custom_range'>(
    'today',
  );
  const [accountingRanges, setAccountingRanges] = useState(defaultRange);
  const [hasLoadedAccountingShift, setHasLoadingAccountingShifts] = useState(false);
  const [hasLoadedReportingDigest, setHasLoadedReportingDigest] = useState(false);
  const [hasLoadedWaiterDigest, setHasLoadedWaiterDigest] = useState(false);
  const [waitersDigest, setWaitersDigest] = useState<ReportingDigestByWaiter[]>([]);

  let reportingUseCase: ReportingDigestUseCaseForUsa | ReportingDigestUseCase;
  if (isUSVenue(business)) {
    reportingUseCase = new ReportingDigestUseCaseForUsa(business.currency, orderForAccountingRepository, accountingRepository);
  } else {
    reportingUseCase = new ReportingDigestUseCase(business.currency, orderForAccountingRepository, accountingRepository);
  }

  const fetchReportingDigest = async () => {
    setHasLoadedReportingDigest(false);
    const fetchedReportingDigest: ReportingDigest = await reportingUseCase.handle(
      business.id,
      reportingDateRange.startDate,
      reportingDateRange.endDate,
    );
    setReportingDigest(fetchedReportingDigest);
    setHasLoadedReportingDigest(true);
  };

  const fetchAccountingShift = async (businessId: BusinessId | undefined) => {
    if (businessId) {
      setHasLoadingAccountingShifts(false);
      const rangesFromShift = await getAccountingShiftUseCase.getAccountingDateRange(businessId);
      setAccountingRanges(rangesFromShift);
      setHasLoadingAccountingShifts(true);
    }
  };

  const saveAccountingShifts = async (lunchStart: string, lunchEnd: string, dinnerStart: string, dinnerEnd: string) => {
    await saveAccountingShiftUseCase.saveAccountingDateRange(business.id, lunchStart, lunchEnd, dinnerStart, dinnerEnd);
    await fetchAccountingShift(business.id);
  };

  const loadDigestsForWaiters = async () => {
    setHasLoadedWaiterDigest(false);

    if (business.patEnrollment === undefined) {
      return;
    }

    const accountingDigestByWaiters = await reportingDigestByWaiterUseCase.handle(
      business.id,
      reportingDateRange.startDate,
      reportingDateRange.endDate,
    );
    setWaitersDigest(accountingDigestByWaiters);
    setHasLoadedWaiterDigest(true);
  };

  useEffect(() => {
    fetchReportingDigest();
    loadDigestsForWaiters();
  }, [reportingDateRange]);

  useEffect(() => {
    const dateNow = new Date(Date.now());

    if (selectedRange === 'today') {
      if (moment(dateNow).isAfter(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.today.tomorrow());
      }
      if (moment(dateNow).isBefore(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.today);
      }
    }

    if (selectedRange === 'lunch') {
      if (moment(dateNow).isAfter(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.lunch.tomorrow());
      }
      if (moment(dateNow).isBefore(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.lunch);
      }
    }

    if (selectedRange === 'dinner') {
      if (moment(dateNow).isAfter(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.dinner.tomorrow());
      }
      if (moment(dateNow).isBefore(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.dinner);
      }
    }

    if (selectedRange === 'yesterday') {
      setReportingDateRange(accountingRanges.today.yesterday());
    }
  }, [selectedRange, accountingRanges]);

  useEffect(() => {
    fetchAccountingShift(business.id);
  }, [business.id]);

  return {
    reportingDigest,
    reportingDateRange,
    setSelectedRange,
    accountingRanges,
    setReportingDateRange,
    saveAccountingShifts,
    isUSVenue: isUSVenue(business),
    waitersDigest,
    hasLoadedAccountingShift,
    hasLoadedReportingDigest,
    hasLoadedWaiterDigest,
  };
};
