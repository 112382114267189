import { Checkbox, FormControl, FormControlLabel, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import {
  UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/UBO/UBOs';

export const HasNoUbosOptIn = ({ hasAlreadyEnteredUbos }: { hasAlreadyEnteredUbos: boolean }) => {
  const {
    register,
    clearErrors,
  } = useFormContext<CheckoutOnboardingInformationDraft>();

  const onRegistered = register('hasNoUbos');
  return <FormControl component="fieldset">
    <Stack spacing={2}>
      <FormControlLabel
        control={
          <Checkbox disabled={hasAlreadyEnteredUbos}  {...onRegistered} onChange={(e) => {
            clearErrors(`representatives.${UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`);
            onRegistered.onChange(e);
          }} size={'large'} />}
        label={<Stack>
          <Typography><FormattedMessage
            id={'settings.payments.checkout.onboarding.hasNoUbos'} /></Typography>
        </Stack>}
        labelPlacement="end"
      />
    </Stack>
  </FormControl>;
};
