import React, { FC, useState } from 'react';
import { Business } from 'src/business/domain/Business';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useGetAllBlocksByBusinessId } from 'src/menu/dynamic-menu/queries/block/getAllBlocksByBusinessIdQuery';
import { venueMenuBlockPath } from 'src/app/navigation/pathHelpers';
import { useNavigate } from 'react-router';
import { useCopyBlockMutation, useDeleteBlockMutation } from 'src/menu/dynamic-menu/mutations/block/useBlockMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { Block } from 'src/menu/dynamic-menu/domain/Block';
import { ConfirmActionModal } from 'src/menu/dynamic-menu/pages/ProductsPage/components/ConfirmActionModal';
import AddIcon from '@mui/icons-material/Add';
import { buildBlockColumns } from 'src/menu/dynamic-menu/pages/ProductsPage/components/blocks/buildBlockColums';
import { NoBlockOverlay } from 'src/menu/dynamic-menu/pages/ProductsPage/components/blocks/NoBlockOverlay';
import BlockCreationModal from 'src/menu/dynamic-menu/pages/ProductsPage/components/blocks/BlockCreationModal';

type Props = {
  business: Business;
};

export type BlockAction = { block: Block; actionName: 'copy' | 'delete'; targetName: Block['title'] };

export const BlocksList: FC<Props> = ({ business }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { data: blocks, isLoading } = useGetAllBlocksByBusinessId(business.id);
  const snackBar = useSnackbar();

  const [selectedAction, setSelectedAction] = useState<BlockAction | undefined>(undefined);
  const [showCreateBlockModal, setShowCreateBlockModal] = useState(false);

  const showFeedbackError = (actionName: 'copy' | 'delete') => {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({
        id: `menus.block.${actionName}.notification.error`,
      }),
    });
  };
  const showFeedbackSuccess = (actionName: 'copy' | 'delete') => {
    snackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({
        id: `menus.block.${actionName}.notification.success`,
      }),
    });
  };

  const deleteBlock = useDeleteBlockMutation(business.id);
  const copyBlock = useCopyBlockMutation(business.id);

  const onActionConfirmed = (selectedBlockAction: BlockAction) => {
    const { block, actionName } = selectedBlockAction;
    const action = actionName === 'copy' ? copyBlock : deleteBlock;
    action
      .mutateAsync(block.id)
      .then(() => {
        showFeedbackSuccess(actionName);
      })
      .catch(() => {
        showFeedbackError(actionName);
      })
      .finally(() => setSelectedAction(undefined));
  };

  const handleClose = () => setSelectedAction(undefined);

  if (isLoading || !blocks) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box display="flex" gap={2} alignItems="center" justifyContent={'end'}>
        <Button
          startIcon={<AddIcon />}
          variant={'contained'}
          size="small"
          onClick={() => setShowCreateBlockModal(true)}
        >
          <Typography variant={'body1'}>
            <FormattedMessage id="menus.blocks.new_block" defaultMessage="Add new product" />
          </Typography>
        </Button>
      </Box>

      <BlockCreationModal
        businessId={business.id}
        isOpened={showCreateBlockModal}
        onClose={() => setShowCreateBlockModal(false)}
      />
      <DataGrid
        rows={blocks}
        columns={buildBlockColumns(intl, (block, actionName) =>
          setSelectedAction({
            block,
            actionName,
            targetName: block.title,
          }),
        )}
        pageSizeOptions={[]}
        rowHeight={70}
        pagination
        disableRowSelectionOnClick
        autoHeight
        getRowId={(row) => row.id}
        onCellClick={(params) => {
          if (params.field === 'id') {
            navigate(venueMenuBlockPath(business.id, params.id.toString()));
          }
        }}
        slots={{
          noRowsOverlay: NoBlockOverlay,
        }}
      />

      {selectedAction && (
        <ConfirmActionModal
          action={selectedAction}
          elementType="block"
          onActionConfirmed={(action) => onActionConfirmed(action)}
          onClose={handleClose}
        />
      )}
    </>
  );
};
