import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { ContactUsButton } from '../../subscription/status/component/ContactUsButton';
import noRewardProgramImg from './no_reward_program.png';

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  width: 100%;
  padding: 16px;
`;

export const NoRewardProgram = () => {
  const intl = useIntl();
  return (
    <Container>
      <Grid container sm={12} md={9} justifyContent="center" alignItems="center" spacing={3} direction="column">
        <Grid item md={8} xs={12}>
          <img
            src={noRewardProgramImg}
            alt="boost customer loyalty with a reward program"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography variant="h4" textAlign="center">
            <FormattedMessage id="loyalty.program.overview.no_program.title" />
          </Typography>
        </Grid>
        <Grid item>
          <ContactUsButton
            title={intl.formatMessage({ id: 'sunday_plus.banner.after_trial.contact_us' })}
            source="no_reward_program"
            reason="loyalty"
          />
        </Grid>
      </Grid>
    </Container>
  );
};
