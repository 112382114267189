/**
 * Prompts a user when they exit the page
 */

import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

function useConfirmExit(confirmExit: () => boolean, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const { push } = navigator;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

export function usePrompt(when: boolean, message: string) {
  when = when ?? true;
  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => message;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() =>
    // eslint-disable-next-line no-alert
    window.confirm(message),
  [message]);
  useConfirmExit(confirmExit, when);
}
