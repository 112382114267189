import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { usePaymentAdapter } from '../page/usePaymentAdapter';
import { PaymentAdapter } from './PaymentAdapter';
import { EnrollmentForPayment } from '../domain/EnrollmentForPayment';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useFirstEnrollmentOfCurrentBusinessOrThrow } from 'src/business/hooks/useFirstEnrollmentOfCurrentBusinessOrThrow';

const getEnrollmentForPaymentQuery = (paymentAdapter: PaymentAdapter, enrollmentId: EnrollmentId) => ({
  queryKey: [queryKeys.GET_ENROLLMENT_FOR_PAYMENT, { enrollmentId }],
  queryFn: (): Promise<EnrollmentForPayment> => {
    return paymentAdapter.getEnrollment(enrollmentId);
  },
});

export const useEnrollmentForPayment = () => {
  const enrollment = useFirstEnrollmentOfCurrentBusinessOrThrow();
  const paymentAdapter = usePaymentAdapter();
  return useQuery(getEnrollmentForPaymentQuery(paymentAdapter, enrollment.id));
};
