import { useContext } from 'react';
import { useAsyncFn } from '../common/utils/useAsyncFn';
import {
  DetailedProductAvailability,
  ProductAvailability,
  UpdateProductAvailability,
} from '../productAvailability/types';
import { OrderingMenuConfigContext } from './configuration/OrderingMenuProvider';
import { OrderingMenu } from './domain/OrderingMenu';
import { OrderingMenuWithProducts } from './domain/OrderingMenuWithProducts';

export const useOrderingMenuRepository = () => {
  const { orderingMenuRepository } = useContext(OrderingMenuConfigContext);
  return orderingMenuRepository!;
};

export const useGetMenus = () => {
  const orderingMenuRepository = useOrderingMenuRepository();

  return useAsyncFn<OrderingMenu[], [boxId: string]>(
    async (boxId) => orderingMenuRepository.getMenus(boxId),
    [orderingMenuRepository],
  );
};

export const useGetMenuWithProducts = () => {
  const orderingMenuRepository = useOrderingMenuRepository();

  return useAsyncFn<OrderingMenuWithProducts, [menuId: string]>(
    async (menuId) => orderingMenuRepository.getMenuWithProducts(menuId),
    [orderingMenuRepository],
  );
};

export const useUpdateMenuUpsells = (menuId: string) => {
  const orderingMenuRepository = useOrderingMenuRepository();

  return useAsyncFn<void, [recommendedUpsellsProductIds: string[]]>(
    async (recommendedUpsellsProductIds) => orderingMenuRepository.updateMenuUpsells(menuId, recommendedUpsellsProductIds),
    [menuId, orderingMenuRepository],
  );
};

export const useGetProductAvailabilities = () => {
  const orderingMenuRepository = useOrderingMenuRepository();

  return useAsyncFn<ProductAvailability[], [boxId: string]>(
    async (boxId) => orderingMenuRepository.getProductAvailabilities(boxId),
    [orderingMenuRepository],
  );
};

export const useGetProductDetailsWithAvailabilities = () => {
  const orderingMenuRepository = useOrderingMenuRepository();

  return useAsyncFn<DetailedProductAvailability[], [boxId: string]>(
    async (boxId) => orderingMenuRepository.getProductDetailsWithAvailabilities(boxId),
    [orderingMenuRepository],
  );
};

export const useUpdateProductAvailabilities = (boxId: string) => {
  const orderingMenuRepository = useOrderingMenuRepository();

  return useAsyncFn<ProductAvailability[], [productAvailabilities: UpdateProductAvailability[]]>(
    async (productAvailabilities) => orderingMenuRepository.updateProductAvailabilities(boxId, productAvailabilities),
    [boxId, orderingMenuRepository],
  );
};
