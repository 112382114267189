// eslint-disable-next-line @typescript-eslint/no-redeclare
import React, { MouseEvent, ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { getMenuItemIcon, NavigationItem } from '../NavigationItems';
import { useNavigationMenuItemState } from '../useNavigationMenuItemState';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import { ThemeTooltip } from 'src/app/component/ThemeTooltip';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { Badge } from 'src/app/component/Badge/Badge';
import { LocalisationKey } from 'src/lang/en';

type SubLevelMenuItemProps = {
  menuItem: NavigationItem;
  onItemSelected: (item: NavigationItem) => void;
};

type ItemProps = {
  selected: boolean;
};


const Item = styled('div')<ItemProps>(({ selected }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: '20px',
  padding: '0px 0px 0px 60px',
  height: '36px',
  borderRadius: '8px',
  marginBottom: '4px',
  justifyContent: 'space-between',
  backgroundColor: selected ? PaletteTheme.menu.subitems.selected : '#fff',

  paddingLeft: '12px',
  '&:hover': {
    backgroundColor: selected ? PaletteTheme.menu.subitems.selectedHover : PaletteTheme.menu.subitems.hover,
  },
}));

const Link = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  border-radius: 8px;
`;

type ItemInfoProps = {
  disabled: boolean;
  selected: boolean;
};
const ItemInfo = styled('div')<ItemInfoProps>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: ${PaletteTheme.menu.color};
`;

const MenuIcon = styled('div')`
  padding: 0 8px 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type MenuItemContentProps = {
  selected: boolean;
  disabled: boolean;
  currentIcon: ReactNode;
  translatedMessageKey: LocalisationKey;
  badge?: LocalisationKey;
};

const badgeColor = (badge?: string) => (badge === 'common.download' ? 'success' : 'info');

export const MenuItemContent = ({
  selected,
  disabled,
  currentIcon,
  translatedMessageKey,
  badge,
}: MenuItemContentProps) => {
  const intl = useIntl();

  return (
    <Item selected={selected} data-intercom-target={translatedMessageKey}>
      <ItemInfo disabled={disabled} selected={selected}>
        {currentIcon && <MenuIcon>{currentIcon}</MenuIcon>}
        <FormattedMessage id={translatedMessageKey} />
      </ItemInfo>
      {badge && <Badge label={intl.formatMessage({ id: badge })} color={badgeColor(badge)} size="small" />}
    </Item>
  );
};

export function SubLevelMenuItem({
  menuItem, onItemSelected,
}: SubLevelMenuItemProps) {
  const { disabled, selected } = useNavigationMenuItemState(menuItem);

  const currentIcon = useMemo(() => getMenuItemIcon(menuItem, selected), [selected, menuItem]);
  const onItemClick = (e: MouseEvent<HTMLElement>) => {
    onItemSelected(menuItem);
    e.stopPropagation();
  };

  const item = (
    <Link to={disabled ? '' : menuItem.route ?? '#'} onClick={onItemClick}>
      <MenuItemContent
        selected={selected}
        disabled={disabled}
        currentIcon={currentIcon}
        translatedMessageKey={menuItem.text}
        badge={menuItem.badge}
      />
    </Link>
  );
  if ((!isMobileScreen() && menuItem.tooltip !== undefined)) {
    return (
      <ThemeTooltip title={<FormattedMessage id={menuItem.tooltip} defaultMessage={menuItem.tooltip} />}>
        <div>{item}</div>
      </ThemeTooltip>
    );
  }
  return item;
}

SubLevelMenuItem.defaultProps = {
  rootItem: false,
  isGeneralItem: false,
  isMobile: false,
};
