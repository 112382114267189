import { createContext, FC, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Business } from 'src/business/domain/Business';
import { useGetPosConnectionsWithRevenueCenters } from 'src/pos/queries/getPosConnectionsWithRevenueCenters';
import { useGetVenueBoxes } from 'src/ordering/box';
import { CircularProgress } from '@mui/material';
import {
  determineMenuAssetsSources,
  MenuAssetsSource,
  MenuAssetsSources,
} from 'src/menu/dynamic-menu/domain/MenuAssetsSource';

type BusinessPosConnections = {
  menuAssetsSources: MenuAssetsSources;
};

type Context = BusinessPosConnections & MenuAssetsSourceSelectionState;

const DigitalMenuReactContext = createContext<Context>({
  menuAssetsSources: { sources: [], forEnrollment: 'PAT' },
  currentMenuAssetsSource: undefined,
  resourcesFilter: undefined,
  syncSource: undefined,
  updateCurrentMenuAssetsSource: () => undefined,
});

function useGetMenuAssetsSources(business: Business): {
  isLoading: boolean;
  isError: boolean;
  data: MenuAssetsSources
} {
  const {
    data: posConnections,
    isLoading: isPosConnectionsLoading,
    isError: isPosConnectionsError,
  } = useGetPosConnectionsWithRevenueCenters(business.id);
  const {
    data: boxes,
    isLoading: isBoxesLoading,
    isError: isBoxesError,
  } = useGetVenueBoxes(business.oapEnrollment?.id);

  return {
    isLoading: isPosConnectionsLoading || isBoxesLoading,
    isError: isPosConnectionsError || isBoxesError,
    data: determineMenuAssetsSources(posConnections || [], boxes || []),
  };
}


const determineDefaultMenuAssetsSource = (menuAssetsSources: MenuAssetsSources): MenuAssetsSource | undefined => {
  if (menuAssetsSources.sources.length === 1) {
    return menuAssetsSources.sources[0];
  }
  return undefined;
};

export const DigitalMenuAssetsSourceProvider: FC<{
  menuAssetsSources: MenuAssetsSources
}> = ({ menuAssetsSources }) => {

  const defaultMenuAssetsSource = determineDefaultMenuAssetsSource(menuAssetsSources);
  const [menuAssetsSource, setMenuAssetsSource] =
    useState<MenuAssetsSource | undefined>(defaultMenuAssetsSource);
  const [resourcesSourceFilter, setResourcesSourceFilter] =
    useState<MenuAssetsSource | undefined>(undefined);
  const [syncResourcesSource, setSyncResourcesSource] =
    useState<MenuAssetsSource | undefined>(defaultMenuAssetsSource);

  const updateCurrentMenuAssetsSource = (value: MenuAssetsSource | undefined) => {
    setMenuAssetsSource(value);
    setSyncResourcesSource(value);
    setResourcesSourceFilter(value);
  };

  return <DigitalMenuReactContext.Provider
    value={{
      menuAssetsSources,
      currentMenuAssetsSource: menuAssetsSource,
      resourcesFilter: resourcesSourceFilter,
      syncSource: syncResourcesSource,
      updateCurrentMenuAssetsSource,
    }}>
    <Outlet />
  </DigitalMenuReactContext.Provider>;
};


export const DigitalMenusContext: FC = () => {
  const business = useCurrentBusinessOrThrow();

  const { data: menuAssetsSources, isLoading, isError } = useGetMenuAssetsSources(business);

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return isLoading
    ? <CircularProgress />
    : <DigitalMenuAssetsSourceProvider menuAssetsSources={menuAssetsSources} />;
};

type MenuAssetsSourceSelectionState = {
  currentMenuAssetsSource: MenuAssetsSource | undefined;
  resourcesFilter: MenuAssetsSource | undefined;
  syncSource: MenuAssetsSource | undefined;
  updateCurrentMenuAssetsSource: (value: MenuAssetsSource | undefined) => void;
};

export const useMenuAssetsSourcesState = (): MenuAssetsSourceSelectionState => {
  const context = useContext(DigitalMenuReactContext);
  return context;
};

export const useMenuAssetsSources = (): MenuAssetsSources => {
  return useContext(DigitalMenuReactContext).menuAssetsSources;
};

