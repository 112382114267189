import { useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { UpsellMenu } from './UpsellMenu';

type UpsellBoxSettingProps = {
  box: BoxDetails;
};

export const UpsellBoxSetting = ({ box }: UpsellBoxSettingProps) => {
  const intl = useIntl();

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.enableUpselling',
        defaultMessage: 'upselling',
        description: 'set upselling products at the menu level',
      })}
      description={intl.formatMessage({
        id: 'venue.upsellingOnMenusMessage',
        defaultMessage: 'if this is active, Sunday will propose additional product on the order confirmation page',
      })}
      expandable
      disabled={false}
    >
      <UpsellMenu box={box} />
    </SettingPanel>
  );
};
