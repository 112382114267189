import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CircularProgress, List, ListItemButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Divider from '@mui/material/Divider';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { venueDynamicMenuPath } from 'src/app/navigation/pathHelpers';
import { useGetRelatedMenusForProduct } from '../../../queries/product/getRelatedMenusForProductQuery';
import { BusinessId } from 'src/business/domain/Business';

const MenuListContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
`;

const MenuItem = styled(ListItemButton)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
`;
type Props = {
  businessId: BusinessId
  productId: string
};
export const RelatedMenuPanel = ({ businessId, productId }: Props) => {
  const navigateTo = useNavigate();

  const {
    data: relatedMenus,
    isLoading,
  } = useGetRelatedMenusForProduct(businessId, productId);

  if (isLoading) return <CircularProgress />;
  if (!relatedMenus || relatedMenus.length === 0) return <Box />;
  return (
    <MenuListContainer>
      <Typography fontSize="large"><FormattedMessage id="menus.product.related_menus.header"
                                                     defaultMessage="menus where’s included" /></Typography>
      <List>
        {
          relatedMenus.map((menu) => (
            <Box key={menu.id}>
              <MenuItem id={menu.id} onClick={() => navigateTo(venueDynamicMenuPath(businessId, menu.id))}>
                <Typography>{menu.name}</Typography>
                <OpenInNewIcon />
              </MenuItem>
              <Divider variant="fullWidth" />
            </Box>
          ))
        }
      </List>
    </MenuListContainer>
  );
};
