import { MerchantClawbacks, MerchantClawbacksSchema } from 'src/accounting/clawbacks/domain/MerchantClawbacks';
import { BusinessId } from 'src/business/domain/Business';
import { AxiosStatic } from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { datadogLogs } from '@datadog/browser-logs';

export type ListMerchantClawbacksQueryParams = {
  from: string,
  to: string,
  page: number,
  size: number
};


export class ClawbackRepository {
  private bookkeepingApiBaseUrl: string;

  constructor(private httpClient: AxiosStatic) {
    const configuration = ConfigurationLoader.load();
    this.bookkeepingApiBaseUrl = configuration.bookkeepingApiBaseUrl;
  }


  async list(businessId: BusinessId, params: ListMerchantClawbacksQueryParams): Promise<MerchantClawbacks> {
    try {

      const result = await this.httpClient.get<MerchantClawbacks>(
        `${this.bookkeepingApiBaseUrl}/businesses/${businessId}/clawbacks`, {
          headers: {
            contentType: 'application/json',
          },
          params,
        },
      );

      return MerchantClawbacksSchema.parse(result.data);
    } catch (e) {
      datadogLogs.logger.error('Error fetching clawbacks', { error: e });
      return { totalCharged: null, clawbacks: { items: [], totalCount: 0 } };
    }
  }
}
