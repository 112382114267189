import React from 'react';
import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';
import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { CSS } from '@dnd-kit/utilities';
import {
  ChevronRight, DragIndicator, VisibilityOffOutlined, VisibilityOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuItemName from './MenuItemName';
import MenuItemLastUpdated from './MenuItemLastUpdated';
import MenuItemSchedule from './MenuItemSchedule';
import { MenuSummary } from '../../../domain/MenuSummary';
import { venueDynamicMenuPath } from 'src/app/navigation/pathHelpers';
import { CopyMenu } from './CopyMenu';
import { BusinessId } from 'src/business/domain/Business';

const MenuItemLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-radius: ${spaceUsages.largeSmall};
  padding: 8px;
  height: 125px;
  background-color: white;
`;

const MenuItemToolColumn = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  padding: 16px;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
`;

type MenuItemProps = {
  businessId: BusinessId;
  menu: MenuSummary;
};

const MenuItem = ({
  businessId, menu,
}: MenuItemProps) => {
  const navigateTo = useNavigate();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: menu.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onMenuRoute = () => {
    navigateTo(venueDynamicMenuPath(businessId, menu.id));
  };

  return (
    <MenuItemLineContainer ref={setNodeRef} {...attributes} style={style}>
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        width={350}
        onClick={onMenuRoute}
        style={{ cursor: 'pointer' }}
      >

        <DragIndicator fontSize="large" {...listeners} style={{ cursor: 'grab', opacity: '0.5', marginRight: 16 }} />
        <MenuItemName name={menu.name} active={menu.enabled} />
        <ChevronRight fontSize="large" style={{ marginLeft: 1, opacity: menu.enabled ? 1 : 0.3 }} />
      </Box>
      <MenuItemSchedule schedules={menu.schedules} active={menu.enabled} />

      <MenuItemLastUpdated lastUpdated={menu.lastModifiedAt} active={menu.enabled} />
      <MenuItemToolColumn>
        {menu.enabled!!
          ? <VisibilityOutlined fontSize="large" />
          : <VisibilityOffOutlined fontSize="large" style={{ opacity: 0.3 }} />}
        <CopyMenu businessId={businessId} menu={menu} />
      </MenuItemToolColumn>
    </MenuItemLineContainer>
  );
};

export default MenuItem;
