import { Money } from '@sundayapp/web-money';
import { PaymentOnPOS, PaymentOverSunday, PaymentSunday } from 'src/bills/domain/orders/types';
import { BusinessId } from 'src/business/domain/Business';

export class OrderForAccounting {
  constructor(
    public id: string,
    public businessId: BusinessId,
    public openedAt: Date,
    public waiter: string,
    public salesTaxRate: number,
    public paymentsOverSunday: PaymentOverSunday[],
    public serviceCharge: Money,
    public serviceChargeRate: number,
    public orderAmount: Money,
    public salesTaxAmount: Money,
    public sundayPayments: Record<string, PaymentSunday>,
    public posPayments: PaymentOnPOS[],
  ) {}
}
