// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Panel, spaceUsages, Stats, StatsGroup, Text } from '@sundayapp/b2b-react-component-library';
import { CurrencyCode, MoneyView } from '@sundayapp/web-money';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { ReportingDigest } from '../../domain/ReportingDigest';
import { CustomMoneyView } from '../CustomMoneyView';
import { InconsistencyWarning } from '../InconsistencyWarning/InconsistencyWarning';
import { Card, CardContent } from '@mui/material';

const DataWrapper = styled.div<{ flexDirectionColumn?: boolean }>`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-direction: ${(props) => (props.flexDirectionColumn ? 'column' : 'row')};
`;

const AdditionalRevenueWrapper = styled(Panel)`
  box-shadow: none;
  border: 1px solid ${colorUsage.quaternaryBorder};
  margin-top: ${spaceUsages.mediumLarge};
  background-color: ${colorUsage.fogBackground};
`;

export const USReportingView = ({ reportingDigest }: { reportingDigest: ReportingDigest }) => {
  const { formatMessage } = useIntl();

  const { additionalRevenue } = reportingDigest;

  if (reportingDigest.detailsBySource.size === 0) {
    return <></>;
  }

  const [provider, {
    grossRevenue, netRevenue, serviceCharge, tipRevenue, inconsistency,
  }] = reportingDigest.detailsBySource.entries().next().value;

  return (
    <>
      <StatsGroup
        showDetailsLabel={(
          <span role="link" tabIndex={0}>
            {formatMessage({ id: 'accounting.show_details' })}
          </span>
        )}
        hideDetailsLabel={formatMessage({ id: 'accounting.hide_details' })}
      >
        <DataWrapper>
          <Stats
            dataLabel={formatMessage({ id: 'accounting.total' })}
            dataProvider={formatMessage({ id: 'accounting.total_description_us' })}
            amount={<CustomMoneyView value={grossRevenue} />}
            padding={spaceUsages.mediumLarge}
            data-testid="total-revenue"
          />
          <Stats
            dataLabel={formatMessage({ id: 'accounting.tips_and_service_charge' })}
            dataProvider={formatMessage({ id: 'accounting.total_tips_serviceCharge_description_us' })}
            amount={<CustomMoneyView value={safeSumMoney([tipRevenue, serviceCharge], CurrencyCode.USD)} />}
            padding={spaceUsages.mediumLarge}
            data-testid="total-tips-service-charge"
          />
        </DataWrapper>

        <Card>
          <CardContent>
            <Text
              size="medium"
              color={colorUsage.secondaryText}
              mr={spaceUsages.small}
              marginBottom={spaceUsages.mediumLarge}
            >
              Sunday revenue
            </Text>
            <Text size="xxlarge" marginBottom={spaceUsages.mediumLarge}>
              <MoneyView
                value={safeSumMoney([grossRevenue, tipRevenue, serviceCharge], CurrencyCode.USD)}
                data-testid="sunday-revenue"
              />
            </Text>

            <DataWrapper flexDirectionColumn>
              <DataWrapper>
                <Stats
                  dataLabel={`${provider} ${formatMessage({ id: 'accounting.gross_revenue' })}`}
                  dataProvider={formatMessage({ id: 'accounting.grossRevenue_description_us' })}
                  amount={<CustomMoneyView value={grossRevenue} />}
                  padding={spaceUsages.mediumLarge}
                  data-testid="gross-revenue"
                >
                  {provider !== 'toast' && <InconsistencyWarning inconsistency={inconsistency} />}
                </Stats>
                <Stats
                  dataLabel={`${provider} ${formatMessage({ id: 'accounting.tips' })}`}
                  dataProvider={formatMessage({ id: 'accounting.tips_description_us' })}
                  amount={<CustomMoneyView value={tipRevenue} />}
                  padding={spaceUsages.mediumLarge}
                  data-testid="total-tips"
                />
              </DataWrapper>
              <DataWrapper>
                <Stats
                  dataLabel={`${provider} ${formatMessage({ id: 'accounting.net_revenue' })}`}
                  dataProvider={formatMessage({ id: 'accounting.netRevenue_description_us' })}
                  amount={<CustomMoneyView value={netRevenue} />}
                  padding={spaceUsages.mediumLarge}
                  data-testid="net-revenue"
                />
                <Stats
                  dataLabel={`${provider} ${formatMessage({ id: 'accounting.service_charge' })}`}
                  dataProvider={formatMessage({ id: 'accounting.service_charge' })}
                  amount={<CustomMoneyView value={serviceCharge} />}
                  padding={spaceUsages.mediumLarge}
                  data-testid="service-charge"
                />
              </DataWrapper>
            </DataWrapper>
          </CardContent>
        </Card>
      </StatsGroup>
      <AdditionalRevenueWrapper>
        <Stats
          dataLabel={`${provider} ${formatMessage({ id: 'accounting.additional_revenue' })}`}
          dataProvider={formatMessage({ id: 'accounting.additional_revenue_description' })}
          amount={<CustomMoneyView value={additionalRevenue} />}
          data-testid="additional-revenue"
        />
      </AdditionalRevenueWrapper>
    </>
  );
};
