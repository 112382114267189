import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NoPos } from 'src/pages/NoPos/NoPos';
import { useGetWaiterHistory } from '../queries/getWaiterHistoryQuery';
import { Alert, Box, Drawer, Typography, useTheme } from '@mui/material';
import { tipsStaffRulesPath } from 'src/app/navigation/pathHelpers';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { FormattedMessage, FormattedTime, useIntl } from 'src/app/i18n/TypedIntl';
import { useGetWaiter } from '../queries/getWaiterQuery';
import { formatPhoneNumber } from './formatPhoneNumber';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';
import { WaiterHistory } from '../domain/AllWaiters';
import { useGetDirectTippingBusinessConfiguration } from 'src/tips/queries/getDirectTippingBusinessConfigurationQuery';

const DRAWER_WIDTH = 592;

const CloseButton = styled.div`
  cursor: pointer;
  margin-top: 32px;
  margin-left: 32px;
`;

export const WaiterHistoricalChanges: React.FC = () => {
  const business = useCurrentBusinessOrThrow();
  const businessIsNoPos = useBusinessIsNoPos();
  const navigate = useNavigate();
  const theme = useTheme();
  const intl = useIntl();
  const { waiterId } = useParams();
  const { data: dataWaiter } = useGetWaiter(business.id, waiterId);
  const { data: dataWaiterHistory } = useGetWaiterHistory(business.id, waiterId);
  const { data: dataTippingConfiguration } = useGetDirectTippingBusinessConfiguration(business.id);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

  if (businessIsNoPos) return <NoPos />;
  if (!waiterId || !dataWaiter || !dataWaiterHistory || !dataTippingConfiguration) return <></>;

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    navigate(tipsStaffRulesPath(business.id));
  };

  function format(history: WaiterHistory) {
    if (history.phoneNumber !== undefined) {
      return (
        <>
          <FormattedMessage id="direct_tipping.history_phone" defaultMessage="Phone number changed:" />{' '}
          {formatPhoneNumber(history.phoneNumber)}
        </>
      );
    }
    if (history.rate !== undefined) {
      return (
        <>
          {business.patEnrollment?.id === history.enrollmentId && (
            <FormattedMessage id="direct_tipping.history_rate_pat" defaultMessage="Tips percentage changed on PAT:" />
          )}
          {business.pdqEnrollment?.id === history.enrollmentId && (
            <FormattedMessage id="direct_tipping.history_rate_pdq" defaultMessage="Tips percentage changed on PDQ:" />
          )}{' '}
          {history.rate / 100}%
        </>
      );
    }
    return null;
  }

  const displayRate = (currentRate: number) => {
    if (currentRate !== 0) {
      return `${currentRate / 100}%`;
    } else {
      return intl.formatMessage({ id: 'direct_tipping.zero_rate' });
    }
  };

  const drawerContent = () => (
    <Box>
      <Box mb={'30px'}>
        <CloseButton onClick={() => closeDrawer()}>
          <CloseIcon />
        </CloseButton>
      </Box>
      <Typography variant="h3" sx={{ letterSpacing: '-3px' }} ml={4} mb={1} color={theme.palette.text.primary}>
        <FormattedMessage id="direct_tipping.history" defaultMessage="Version history" />
      </Typography>
      <Alert
        severity="info"
        sx={{
          marginTop: 4,
          paddingLeft: 4,
          paddingRight: 4,
          borderRadius: 0,
          '& .MuiAlert-message': {
            justifyContent: 'space-between',
          },
        }}
      >
        <Box>
          <FormattedMessage
            id="direct_tipping.current_phone_number"
            defaultMessage="Current phone number is:"
            values={{ waiter: dataWaiter.waiter.name }}
          />{' '}
          {formatPhoneNumber(dataWaiter.waiter.phoneNumber)}
        </Box>
        {business.patEnrollment && dataTippingConfiguration[business.patEnrollment.id] && (
          <Box>
            <FormattedMessage
              id="direct_tipping.current_rate_pat"
              defaultMessage={`Current ${dataWaiter.waiter.name}'s instant tipping configuration on PAT:`}
              values={{ waiter: dataWaiter.waiter.name }}
            />{' '}
            {displayRate(dataWaiter.waiter.rates[business.patEnrollment.id])}
          </Box>
        )}
        {business.pdqEnrollment && dataTippingConfiguration[business.pdqEnrollment.id] && (
          <Box>
            <FormattedMessage
              id="direct_tipping.current_rate_pdq"
              defaultMessage={`Current ${dataWaiter.waiter.name}'s instant tipping configuration on PDQ:`}
              values={{ waiter: dataWaiter.waiter.name }}
            />{' '}
            {displayRate(dataWaiter.waiter.rates[business.pdqEnrollment.id])}
          </Box>
        )}
        {dataWaiter.lastUpdate && (
          <Box sx={{ color: 'grey' }}>
            <FormattedMessage id="direct_tipping.updated_by" defaultMessage="by" /> {dataWaiter.lastUpdate.triggeredBy}
            {' - '}
            <FormattedTime
              value={dataWaiter.lastUpdate.updatedAt}
              month="long"
              day="numeric"
              weekday="long"
              year="numeric"
              hour="2-digit"
              minute="2-digit"
            />
          </Box>
        )}
      </Alert>
      <Box m={'30px'}>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.text.secondary} sx={{ textTransform: 'uppercase' }}>
            <FormattedMessage id="direct_tipping.historical_change" defaultMessage="historical changes" />
          </Typography>
        </Box>
        <Box mt={'20px'}>
          {dataWaiterHistory.map((history) => (
            <Box key={history.id} mb={'10px'}>
              <Box>
                <Typography variant="body1" color={theme.palette.text.primary}>
                  {format(history)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  <FormattedMessage id="direct_tipping.updated_by" defaultMessage="by" /> {history.triggeredBy}
                  {' - '}
                  <FormattedTime
                    value={history.updatedAt}
                    month="long"
                    day="numeric"
                    weekday="long"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                  />
                </Typography>
              </Box>
            </Box>
          ))}
          {dataWaiterHistory.length == 0 && (
            <FormattedMessage id="direct_tipping.no_history" defaultMessage="no history yet" />
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {isDrawerOpen && (
        <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer} PaperProps={{ sx: { width: DRAWER_WIDTH } }}>
          {drawerContent()}
        </Drawer>
      )}
    </>
  );
};
