import { CurrencyCode, Money } from '@sundayapp/web-money';
import { MoneyDto, ProductDto, ProductSummaryDto } from './ProductDto';
import { ProductSummary } from '../domain/ProductSummary';
import { Product } from '../domain/Product';

export class ProductDtoConverter {
  static toProductSummary(dto: ProductSummaryDto): ProductSummary {
    return {
      id: dto.id,
      name: dto.name,
      description: dto.description,
      shortDescription: dto.shortDescription,
      sku: dto.sku,
      price: this.fromMoney(dto.price),
      basePrice: this.fromMoney(dto.basePrice),
      pictureUrl: dto.pictureUrl,
      type: dto.type,
      hasDiscrepancies: dto.hasDiscrepancies,
      isSnoozed: !!dto?.snoozePeriod,
      posConnection: dto.posConnectionId
        ? {
          posConnectionId: dto.posConnectionId,
          externalRevenueCenterId: dto.externalRevenueCenterId,
        }
        : undefined,
      source: dto.source,
    };
  }

  static toProduct(dto: ProductDto): Product {
    return {
      id: dto.id,
      name: dto.name,
      partnerName: dto.partnerName,
      description: dto.description,
      partnerDescription: dto.partnerDescription,
      shortDescription: dto.shortDescription,
      type: dto.type,
      source: dto.source,
      sku: dto.sku,
      price: this.fromMoney(dto.price),
      pictureUrl: dto.pictureUrl,
      partnerPictureUrl: dto.partnerPictureUrl,
      tags: dto.tags ?? [],
      allergens: dto.allergens ?? [],
      relatedProductIds: dto.relatedProductIds ?? [],
      subProductIds: dto.subProductIdsInfos?.value ?? dto.subProductIds ?? [],
      isSubProductsOverridden: dto.source === 'PARTNER' && !!dto.partnerSubProductIds && !!dto.subProductIds,
      isSubProductsEditable:
        dto.source === 'SUNDAY' || !dto.partnerSubProductIds || dto.partnerSubProductIds.length === 0,
      calories: dto.calories,
      eatInTax: dto.eatInTax ?? 0, // TODO taxes won't be optional when menu backend has been released
      takeAwayTax: dto.takeAwayTax ?? 0,
      deliveryTax: dto.deliveryTax ?? 0,
      isAvailableForOrder: dto.isAvailableForOrder,
      isSnoozed: !!dto?.snoozePeriod,
      isEligibleForOrder: dto.isEligibleForOrder,
      posConnection: dto.posConnection,
    };
  }

  static fromMoney(price: MoneyDto): Money {
    return {
      amount: price.amount,
      currency: (<any>CurrencyCode)[price.currency],
    };
  }
}
