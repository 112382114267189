import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { FrBankInfo } from 'src/payments/components/Checkout/CheckoutOnboardingFR/BankAccount/FrBankInfo';
import React from 'react';
import {
  UploadBankStatementDocument,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/BankAccount/UploadBankStatementDocument';
import { UkBankInfo } from 'src/payments/components/Checkout/CheckoutOnboardingFR/BankAccount/UkBankInfo';

export const BankAccount = ({ countryCode }: { countryCode: string }) => {
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant={'h5'}>4 · <FormattedMessage
        id="settings.payments.checkout.onboarding.bankAccount"
        defaultMessage={'Bank account'} /></Typography>
      {countryCode === 'FR' && <FrBankInfo />}
      {countryCode === 'GB' && <UkBankInfo />}
      <UploadBankStatementDocument />
    </Stack>
  );
};
