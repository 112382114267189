import React from 'react';
import { Button, Divider, Modal, Stack, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { palette } from 'src/organization-report/design/palette';
import { WhiteButton } from 'src/tips/dispatch/components/WhiteButton';
import { SignDispatchModal } from 'src/tips/dispatch/components/SignDispatchModal';
import { AmountToDispatch } from 'src/tips/dispatch/components/AmountToDispatch';
import { useRenderMoney } from '@sundayapp/web-money';
import { StaffDispatch } from 'src/tips/dispatch/components/StaffDispatch';
import { Staff } from 'src/tips/dispatch/model/Staff';
import {
  distributeByWorkingHours, hasTipsAllocated,
  keepOnlyStaffWithTips,
  toStaffAllocations,
} from 'src/tips/dispatch/model/StaffAllocation';
import { DispatchPrerequisites } from 'src/tips/dispatch/components/DispatchPrerequisites';
import { PendingTipsDispatch } from 'src/tips/dispatch/model/PendingTipsDispatch';

export const CreateDispatchModal = ({
  opened,
  onClose,
  tipsToDispatch,
  staff,
}: {
  opened: boolean;
  onClose: (dispatched: boolean) => void;
  tipsToDispatch: PendingTipsDispatch;
  staff: Staff[];
}) => {
  const intl = useIntl();
  const renderMoney = useRenderMoney(true, false);

  const [staffAllocations, setStaffAllocations] = React.useState(
    distributeByWorkingHours(tipsToDispatch.amount, toStaffAllocations(tipsToDispatch.amount, staff)),
  );

  const [isSignDispatchModalOpen, setIsSignDispatchModalOpen] = React.useState(false);

  const onCloseSignDispatchModal = (dispatched: boolean) => {
    setIsSignDispatchModalOpen(false);
    if (dispatched) {
      setStaffAllocations([]);
      onClose(dispatched);
    }
  };

  const onUpdateWeeklyWorkingHours = (staffId: string, weeklyWorkedHours: number) => {
    const updated = staffAllocations.map((a) => (a.staffId === staffId ? { ...a, weeklyWorkedHours } : a));
    const redistributed = distributeByWorkingHours(tipsToDispatch.amount, updated);
    setStaffAllocations(redistributed);
  };

  const onUpdateAllStaffWeeklyWorkingHours = (weeklyWorkedHours: number) => {
    const updated = staffAllocations.map((staffAllocation) => ({
      ...staffAllocation,
      weeklyWorkedHours:
        staffAllocation.weeklyWorkedHours > 0 && weeklyWorkedHours > 0
          ? staffAllocation.weeklyWorkedHours
          : weeklyWorkedHours,
    }));
    const redistributed = distributeByWorkingHours(tipsToDispatch.amount, updated);
    setStaffAllocations(redistributed);
  };

  return (
    <Modal open={opened} aria-labelledby="tips-dispatch-modal">
      <Stack
        sx={{ background: 'white', height: '100%', overflow: 'auto', paddingBottom: '1rem' }}
        flexDirection="column"
      >
        <Stack direction="row" justifyContent="space-between" alignItems={'center'} padding="1rem 1.5rem 1rem 1.5rem">
          <Stack sx={{ cursor: 'pointer' }} onClick={() => onClose(false)}>
            <CrossIcon />
          </Stack>
          <Stack alignItems={'center'}>
            <Typography>
              <FormattedMessage id={'tips.sunday_pooling.dispatch.title'} />
            </Typography>
          </Stack>
          <Stack alignItems={'flex-end'}>
            <Button size="small" disabled={!hasTipsAllocated(staffAllocations)} variant="contained" onClick={() => setIsSignDispatchModalOpen(true)}>
              <FormattedMessage id={'tips.sunday_pooling.dispatch.continue'} />
            </Button>
          </Stack>
        </Stack>

        <DispatchPrerequisites />

        <Stack
          sx={{ width: '100%', height: '100%', overflow: 'auto' }}
          padding="3rem 0 3rem 0"
          direction="column"
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Stack maxWidth={'50rem'} height="100%" justifyContent={'space-between'} spacing={3}>
            <Stack direction={'column'} spacing={4} paddingBottom={10}>
              <AmountToDispatch amount={renderMoney(tipsToDispatch.amount)} />
              <Divider />
              <StaffDispatch
                staffAllocations={staffAllocations}
                onUpdateStaffWeeklyWorkingHours={onUpdateWeeklyWorkingHours}
                onUpdateAllStaffWeeklyWorkingHours={onUpdateAllStaffWeeklyWorkingHours}
              />
            </Stack>
            <Stack direction={'column'} sx={{ position: 'absolute', bottom: 0, maxWidth: '50rem' }} paddingBottom={2}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ background: palette.primary.black, borderRadius: '1rem' }}
                padding="1rem 1.5rem 1rem 1.5rem"
              >
                <Typography variant={'body1'} color={palette.primary.white}>
                  {`${renderMoney(tipsToDispatch.amount)} ${intl.formatMessage({ id: 'tips.sunday_pooling.dispatch.ready' })}`}
                </Typography>
                <WhiteButton disabled={!hasTipsAllocated(staffAllocations)} size="small" variant="contained" onClick={() => setIsSignDispatchModalOpen(true)}>
                  <FormattedMessage id={'tips.sunday_pooling.dispatch.continue'} />
                </WhiteButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <SignDispatchModal
          opened={isSignDispatchModalOpen}
          onClose={onCloseSignDispatchModal}
          pendingTipsDispatch={tipsToDispatch}
          staffAllocations={keepOnlyStaffWithTips(staffAllocations)}
        />
      </Stack>
    </Modal>
  );
};
