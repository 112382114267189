import { Box, Stack, Typography, useTheme } from '@mui/material';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import React from 'react';
import { styled } from '@mui/material/styles';
import { FormActions } from 'src/payments/components/Checkout/CheckoutOnboardingFR/FormActions';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { InformationBanner } from 'src/payments/components/Checkout/InformationBanner';
import { BusinessSelectionIcon } from 'src/business/components/BusinessSelectionIcon';
import { usePatCustomization } from 'src/pages/Settings/Customization/infrastructure/useCustomization';
import { BusinessId } from 'src/business/domain/Business';

const Sticky = styled('div')({
  position: 'sticky',
  top: '0',
  width: '100%',
  zIndex: 2,
  backgroundColor: 'white',
});

export const Header = ({ onClose, title, onSave, onSubmit, businessName, businessId }: {
  businessName: string,
  businessId: BusinessId,
  onClose?: () => void,
  title: string,
  onSave?: () => void,
  onSubmit?: () => void
}) => {
  const theme = useTheme();
  const { formState, getValues } = useFormContext<CheckoutOnboardingInformationDraft>();
  const requirementsDue = getValues('requirementsDue') ?? [];
  const status = getValues('status');
  const { data: customization } = usePatCustomization(businessId);

  return <Sticky>
    <Stack spacing={2} padding="20px 24px" borderBottom={`1px solid ${theme.palette.secondary.light}`}>
      <Stack direction={'row'}
             alignItems={'flex-start'}
             justifyContent="space-between" height="100%">
        <Box height={'100%'} display={'flex'} alignItems={'center'} flexDirection={'row'} gap={2}
             minWidth="30%" onClick={onClose}>
          {!!onClose && <CrossIcon />}
            <BusinessSelectionIcon name={businessName} imageUrl={customization?.logo?.logoUrls?.noResize} size={32} />
          <Typography variant={'body2'}>{businessName}</Typography>
        </Box>
        <Box flexGrow={1} display="flex" justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
          <Typography>
            {title}
          </Typography>
          <Typography variant={'subtitle2'} color={theme.palette.text.secondary}><FormattedMessage
            id={'settings.payments.checkout.onboarding.mandatoryFieldCaption'} /></Typography>
        </Box>

        <Box minWidth="30%" display="flex" alignItems={'flex-end'} gap={1} flexDirection={'column'}
             sx={{ cursor: 'pointer' }}>
          <FormActions status={status} isSubmitting={formState.isSubmitting}
                       formIsSubmitted={formState.isSubmitSuccessful}
                       onSubmit={onSubmit} onSave={onSave}
                       style={{ justifyContent: 'flex-end' }} />
        </Box>
      </Stack>
    </Stack>
    <InformationBanner isSubmitSuccessful={formState.isSubmitSuccessful} requirementsDue={requirementsDue}
                       status={status} />
  </Sticky>;
};
