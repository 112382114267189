import { z } from 'zod';
import { CurrencyCode } from '@sundayapp/web-money';
import { EnrollmentSchema } from '../domain/Enrollment';
import { Address, BusinessIdSchema, TimezoneSchema } from '../domain/Business';

export const BusinessDtoSchema = z.object({
  id: BusinessIdSchema,
  name: z.string(),
  legalName: z.string(),
  address: Address,
  timezone: TimezoneSchema,
  currency: z.nativeEnum(CurrencyCode),
  posReference: z.string().toUpperCase().optional(),
  tags: z.string().array().default([]),
  status: z.string(),
  isInitialSetupCompleted: z.boolean(),
  metadata: z.record(z.string()).default({}),
  //geolocation: ?
  enrollments: z
    .array(EnrollmentSchema)
    .default([]),
  createdAt: z.string(),
});
