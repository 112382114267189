import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Loader } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import { useBoxRepository } from '../../../box/hooks';
import { Box } from '../../../box/domain/Box';
import { AreaBoxSettings } from './components/AreaBoxSettings';
import { BoxSelector } from '../../../box/components';
import { getSpacing } from '../../../stylesheet';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { BusinessId } from 'src/business/domain/Business';

type AreaSettingsProps = {
  businessId: BusinessId;
  enrollmentId: EnrollmentId;
};

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSpacing(3)};
  justify-content: end;
  width: 100%;
`;

const SelectorTitle = styled.div`
  margin-left: ${getSpacing(2)};
  margin-bottom: ${getSpacing(1)};
`;

export const AreaListSettings = ({ businessId, enrollmentId }: AreaSettingsProps) => {
  const boxRepository = useBoxRepository();
  const [loading, makeLoading] = useState<boolean>(false);
  const [boxes, setBoxes] = useState<Box[]>([]);
  const [box, setBox] = useState<Box | undefined>();

  const onBoxChanged = useCallback(
    (boxChanged: Box) => {
      boxRepository.getBoxDetails(boxChanged.id).then(setBox);
    },
    [boxRepository],
  );

  useEffect(() => {
    makeLoading(true);
    boxRepository.getVenueBoxes(enrollmentId).then((fetchedBoxes) => {
      setBoxes(fetchedBoxes);
      onBoxChanged(fetchedBoxes[0]);
      makeLoading(false);
    });
  }, [boxRepository, onBoxChanged, enrollmentId]);

  if (loading) {
    return <Loader size="large" />;
  }

  if (boxes.length === 0) {
    return <FormattedMessage id="settings.area.no-box" defaultMessage="The venue must have one box" />;
  }

  return (
    <>
      {boxes.length > 1 && (
        <SelectorContainer>
          <SelectorTitle>
            <FormattedMessage id="settings.area.box-selector.title" defaultMessage="Configure the areas of the box" />
          </SelectorTitle>
          <BoxSelector
            enrollmentId={enrollmentId}
            onBoxUnselected={() => setBox(undefined)}
            onBoxSelected={onBoxChanged}
            initialBoxes={boxes}
            initialSelection={boxes[0].displayName || boxes[0].name}
            withEmptyBox={false}
          />
        </SelectorContainer>
      )}

      {box && <AreaBoxSettings businessId={businessId} boxId={box.id} />}
    </>
  );
};
