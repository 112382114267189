import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEndOfServiceSummary } from '../hooks/useEndOfServiceSummary';
import { ExpandableRows } from '../components/ExpandableRows';
import { DatePicker } from 'src/components/date-picker/DatePicker';
import { RestaurantShiftPicker } from '../../restaurant-shifts/RestaurantShiftPicker';
import { useRestaurantShiftTimesOptions } from '../../restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { RevenueCentersMultipleSelect } from '../components/RevenueCentersMultipleSelect';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { EndOfServiceConfiguration } from '../model/EndOfServiceConfiguration';
import { Business } from 'src/business/domain/Business';
import { isCnp, products } from 'src/operations/end-of-service/model/products';

export const EndOfService = ({ eosConfiguration, business }: { eosConfiguration: EndOfServiceConfiguration, business: Business; }) => {
  const { options } = useRestaurantShiftTimesOptions();
  const [selected, setSelected] = useState<number>(0);
  const [hideTips, setHideTips] = useState(false);

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 30);

  const {
    reportingDigest,
    reportingDate,
    setReportingDate,
    restaurantShiftTimesOption,
    setRestaurantShiftTimesOption,
    revenueCentersSelected,
    setRevenueCentersSelected,
    revenueCentersOptions,
  } = useEndOfServiceSummary(eosConfiguration);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === null) {
      return;
    }
    setSelected(newValue);
  };

  if (!reportingDigest || !restaurantShiftTimesOption) {
    return <div/>;
  }

  return (
        <Box
            sx={{
              paddingTop: 3,
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
        >
            <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction="row" spacing={2}>
                    <DatePicker
                        date={reportingDate}
                        minDate={minDate}
                        maxDate={new Date()}
                        onClickDay={setReportingDate}
                    />
                    <RestaurantShiftPicker value={restaurantShiftTimesOption} onChange={setRestaurantShiftTimesOption}
                                           options={options}/>
                    {eosConfiguration.bourdoncleModeEnabled && eosConfiguration.displayRevenueCenters && <RevenueCentersMultipleSelect onChange={setRevenueCentersSelected}
                                                                                                                                       revenueCentersOptions={revenueCentersOptions}
                                                                                                                                       values={revenueCentersSelected}/>}
                </Stack>

                <Stack direction="row" spacing={2} justifyContent={'right'}>
                    {eosConfiguration.bourdoncleModeEnabled && eosConfiguration.displayPrintMode && <Button variant="text" onClick={() => setHideTips(!hideTips)}
                                                                                                            startIcon={hideTips ? <VisibilityOutlined/> : <VisibilityOffOutlined/>}>
                        {hideTips && <FormattedMessage id="sales_summary.title.see_tips" defaultMessage="Details mode"/>}
                        {!hideTips && <FormattedMessage id="sales_summary.title.hide_tips" defaultMessage="Printing mode"/>}
                    </Button>}
                    <ToggleButtonGroup
                        value={selected}
                        exclusive
                        onChange={handleChange}
                    >
                        <ToggleButton value={0}><FormattedMessage id="sales_summary.title.payment_method"/></ToggleButton>
                        <ToggleButton value={1}><FormattedMessage id="sales_summary.title.waiter"/></ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            </Stack>

            {reportingDigest && (
                <>
                    {selected === 0 && (
                        <>
                          {  }
                          {business.patEnrollment && <ExpandableRows
                                date={reportingDate}
                                restaurantShiftTimesOption={restaurantShiftTimesOption}
                                revenueAndTips={reportingDigest.patPaymentMethod}
                                hideTips={hideTips}
                                eosConfiguration={eosConfiguration}
                            />
                          }
                          {business.oapEnrollment && <ExpandableRows
                            date={reportingDate}
                            restaurantShiftTimesOption={restaurantShiftTimesOption}
                            revenueAndTips={reportingDigest.oapPaymentMethod}
                            hideTips={hideTips}
                            eosConfiguration={eosConfiguration}
                          />
                          }
                            {eosConfiguration.displayPaymentTerminal &&
                                <ExpandableRows
                                    date={reportingDate}
                                    restaurantShiftTimesOption={restaurantShiftTimesOption}
                                    revenueAndTips={reportingDigest.tpePaymentMethod}
                                    hideTips={hideTips}
                                    eosConfiguration={eosConfiguration}
                                />}
                        </>

                    )}
                    {selected === 1 && (
                        <>
                            {reportingDigest.waitersProducts.map((waiterProducts) => {
                              const canDisplay = (business.oapEnrollment && waiterProducts.product === products.OAP)
                                || (business.patEnrollment && waiterProducts.product === products.PAT)
                                || waiterProducts.product === undefined
                                || !isCnp(waiterProducts.product);
                              console.log(waiterProducts);
                              return (
                                canDisplay && <ExpandableRows key={waiterProducts.name}
                                                        date={reportingDate}
                                                        restaurantShiftTimesOption={restaurantShiftTimesOption}
                                                        revenueAndTips={waiterProducts}
                                                        hideTips={hideTips}
                                                        eosConfiguration={eosConfiguration}/>
                              );
                            },
                            )}
                        </>
                    )}
                </>
            )}
        </Box>
  );
};
