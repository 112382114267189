import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const businessRegistrationNumberPattern: { [key: string]: RegExp } = {
  'FR': /^.{9}(.{5})?$/,
  'GB': /^(((AC|CE|CS|FC|FE|GE|GS|IC|LP|NC|NF|NI|NL|NO|NP|OC|OE|PC|R0|RC|SA|SC|SE|SF|SG|SI|SL|SO|SR|SZ|ZC|\d{2})\d{6})|((IP|SP|RS)[A-Z\d]{6})|(SL\d{5}[\dA]))$/,
};

export const BusinessRegistrationNumber = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const businessRegistrationNumberError = errors.businessRegistrationNumber;
  const business = useCurrentBusinessOrThrow();
  const country = business.address.countryCode;
  const pattern = businessRegistrationNumberPattern[business.address.countryCode] || /.*/;
  const intl = useIntl();

  return <TextField
    required
    type={country == 'FR' ? 'number' : 'text'}
    InputLabelProps={{ shrink: true }}
    error={!!businessRegistrationNumberError}
    {...register('businessRegistrationNumber', {
      required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.businessRegistrationNumber.error.required' }),
      pattern: { value: pattern, message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.businessRegistrationNumber.error.pattern' }) },
    })}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.businessRegistrationNumber' })}
    helperText={businessRegistrationNumberError?.message || intl.formatMessage({ id: 'settings.payments.checkout.onboarding.businessRegistrationNumber.error' })}
  />;
};
