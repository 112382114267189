import { useEffect } from 'react';
import { useAsync } from 'react-use';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { NoRewardProgram } from './components/NoRewardProgram';
import { RewardProgramRepository } from './infrastructure/RewardProgramRepository';
import { RewardProgramDetails } from './components/RewardProgramDetails';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const configuration = ConfigurationLoader.load();

/**
 * An empty component to display nothing while data is loading
 * An alternative is to display a loader IF the loading time is too long (or else it will flicker)
 */
const LoadingData = () => null;

export const RewardProgram = () => {
  const business = useCurrentBusinessOrThrow();
  const repository = new RewardProgramRepository(configuration.loyaltyApiBaseUrl);

  const { value: rewardProgram, loading } = useAsync(
    async () => repository.getRewardProgram(business.id),
    [business.id],
  );

  useEffect(() => {
    if (!loading) {
      const hasActiveRewardProgram = !!rewardProgram;
      ampli.loyaltyTabViewed({ activeRewardProgram: hasActiveRewardProgram });
    }
  }, [loading]);

  if (loading) {
    return <LoadingData />;
  }

  return <>{rewardProgram ? <RewardProgramDetails rewardProgram={rewardProgram} /> : <NoRewardProgram />}</>;
};
