import { z } from 'zod';
import { ServiceType } from 'src/domain/venue/ServiceType';

/*eslint @typescript-eslint/no-redeclare: 'off' */

export const EnrollmentId = z.string().brand<'EnrollmentId'>();
export type EnrollmentId = z.infer<typeof EnrollmentId>;

export enum EnrollmentStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export const ProductSchema = z.string()
  // TODO: see what we want to do about this hack (ie. remove the 'DELIVERECT' suffix, and use a 'middleware' metadata value)
  .transform(value => value.replace('ORDER_AND_PAY_DELIVERECT', 'ORDER_AND_PAY'))
  .refine(val => val in ServiceType)
  .transform(val => ServiceType[val as ServiceType]);

export const EnrollmentSchema = z.object({
  id: EnrollmentId,
  status: z.nativeEnum(EnrollmentStatus),
  product: ProductSchema,
  metadata: z.record(z.string()).default({}),
});
export type Enrollment = z.infer<typeof EnrollmentSchema>;
