// eslint-disable-next-line @typescript-eslint/no-redeclare
import React, { MouseEvent, ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { styled, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { getMenuItemIcon, NavigationItem } from '../NavigationItems';
import { useNavigationMenuItemState } from '../useNavigationMenuItemState';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import { NavigationMenuItemIcon } from './NavigationMenuItemIcon';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { Badge } from 'src/app/component/Badge/Badge';
import { LocalisationKey } from 'src/lang/en';
import { getPageName } from 'src/app/tracking/tracking';
import { ThemeTooltip } from 'src/app/component/ThemeTooltip';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';

type NavigationMenuItemProps = {
  menuItem: NavigationItem;
  compacted: boolean;
  rootItem?: boolean;
  onItemSelected: (item: NavigationItem) => void;
  isGeneralItem?: boolean;
  isMobile?: boolean;
};

type ItemProps = {
  selected: boolean;
  rootItem?: boolean;
  isGeneralItem?: boolean;
  isMobile: boolean;
};
const computeItemPadding = (rootItem: boolean | undefined, isGeneralItem: boolean | undefined) => {
  if (isGeneralItem) {
    return '0px';
  }
  if (rootItem) {
    return '20px';
  }
  return '56px';
};

const getItemHeight = (rootItem: boolean, isMobile: boolean) => {
  const height = rootItem ? 40 : 36;

  if (isMobile) {
    return `${height + 8}px`;
  }

  return `${height}px`;
};

const Item = styled('div')<ItemProps>(({ rootItem, isMobile, selected, isGeneralItem }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: '24px',
  padding: '0px 0px 0px 60px',
  height: getItemHeight(rootItem === true, isMobile),
  borderRadius: '8px',
  marginBottom: '4px',
  justifyContent: 'space-between',
  backgroundColor: selected ? PaletteTheme.menu.subitems.selected : '#fff',

  paddingLeft: computeItemPadding(rootItem, isGeneralItem),
  '&:hover': {
    backgroundColor: selected ? PaletteTheme.menu.subitems.selectedHover : PaletteTheme.menu.subitems.hover,
  },
}));

const Link = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  border-radius: 8px;
`;

type ItemInfoProps = {
  disabled: boolean;
  isGeneralItem?: boolean;
  rootItem?: boolean;
  selected: boolean;
};

const computeItemColor = (
  disabled: boolean,
  isGeneralItem: boolean | undefined,
  rootItem: boolean | undefined,
  selected: boolean,
) => {
  if (disabled) {
    return PaletteTheme.menu.subitems.disabled;
  }
  if (isGeneralItem) {
    return PaletteTheme.text?.secondary;
  }
  if (selected) {
    return PaletteTheme.menu.color;
  }
  if (!rootItem) {
    return PaletteTheme.menu.subitems.color;
  }
  return PaletteTheme.menu.color;
};

const ItemInfo = styled('div')<ItemInfoProps>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: ${(props) => computeItemColor(props.disabled, props.isGeneralItem, props.rootItem, props.selected)};
`;

const MenuIcon = styled('div')`
  padding: 0 8px 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type MenuItemContentProps = {
  selected: boolean;
  rootItem: boolean | undefined;
  isGeneralItem: boolean | undefined;
  isMobile: boolean;
  disabled: boolean;
  currentIcon: ReactNode;
  translatedMessageKey: LocalisationKey;
  badge?: LocalisationKey;
};

const badgeColor = (badge?: string) => (badge === 'common.download' ? 'success' : 'info');

export const MenuItemContent = ({
  selected,
  rootItem,
  isGeneralItem,
  isMobile,
  disabled,
  currentIcon,
  translatedMessageKey,
  badge,
}: MenuItemContentProps) => {
  const intl = useIntl();

  return (
    <Item selected={selected} rootItem={rootItem} isGeneralItem={isGeneralItem} isMobile={isMobile}
          data-intercom-target={translatedMessageKey}>
      <ItemInfo disabled={disabled} isGeneralItem={isGeneralItem} rootItem={rootItem} selected={selected}>
        {currentIcon && <MenuIcon>{currentIcon}</MenuIcon>}
        <FormattedMessage id={translatedMessageKey} />
      </ItemInfo>
      {badge && <Badge label={intl.formatMessage({ id: badge })} color={badgeColor(badge)} size="small" />}
    </Item>
  );
};

export function NavigationMenuItem({
  menuItem,
  compacted,
  rootItem,
  onItemSelected,
  isMobile = false,
  isGeneralItem = false,
}: NavigationMenuItemProps) {
  const { disabled, selected } = useNavigationMenuItemState(menuItem);
  const currentIcon = useMemo(() => getMenuItemIcon(menuItem, selected), [selected, menuItem]);
  const user = useAuthenticatedUserOrThrow();
  if (compacted) {
    return <NavigationMenuItemIcon icon={currentIcon} />;
  }

  const onItemClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onItemSelected(menuItem);
  };

  const item = (
    <Tooltip title={user.impersonated ? getPageName(menuItem.route ?? '') : ''} followCursor>
      <Link to={disabled ? '' : (menuItem.route ?? '#')} onClick={onItemClick}>
        <MenuItemContent
          selected={selected}
          rootItem={rootItem}
          isGeneralItem={isGeneralItem}
          isMobile={isMobile}
          disabled={disabled}
          currentIcon={currentIcon}
          translatedMessageKey={menuItem.text}
          badge={menuItem.badge}
        />
      </Link>
    </Tooltip>
  );
  if ((!isMobileScreen() && menuItem.tooltip !== undefined)) {
    return (
      <ThemeTooltip title={<FormattedMessage id={menuItem.tooltip} defaultMessage={menuItem.tooltip} />}>
        <div>{item}</div>
      </ThemeTooltip>
    );
  }
  return item;
}

NavigationMenuItem.defaultProps = {
  rootItem: false,
  isGeneralItem: false,
  isMobile: false,
};
