import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ProofOfIdentity,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/ProofOfIdentity/ProofOfIdentity';
import {
  CheckoutOnboardingInformationDraft,
  RepresentativeDraft,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import {
  LegalRepresentativePersonalInfo,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/LegalRepresentatives/LegalRepresentativePersonalInfo';
import {
  isLegalRepresentativeComplete,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/LegalRepresentatives/isLegalRepresentativeComplete';
import {
  RepresentativeDetails,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/RepresentativeDetails';
import { Representative } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/Representative';


export const EditLegalRepresentatives = ({
  index,
  onDelete,
  representative,
}: {
  representative: RepresentativeDraft,
  onDelete: () => void,
  index: number
}) => {
  const { formState: { errors } } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[index];
  const isUBO = !!representative?.roles?.ubo;
  const isAuthorisedSignatory = !!representative?.roles?.authorisedSignatory;
  const isReadOnly = isAuthorisedSignatory || isUBO;
  const intl = useIntl();
  const isComplete = isLegalRepresentativeComplete({ representative, containsErrors: !!error });
  const title = representative.lastName && representative.firstName ? `${representative.lastName} ${representative.firstName}` :
    intl.formatMessage({ id: 'settings.payments.checkout.onboarding.individualRepresentatives.legalRepresentative.details' });
  return <Representative isComplete={isComplete} title={title} onDelete={onDelete} isReadOnly={isReadOnly}>
    <RepresentativeDetails>
      <LegalRepresentativePersonalInfo representativeIndex={index} />
      <ProofOfIdentity representativeIndex={index} />
    </RepresentativeDetails>
  </Representative>;
};
