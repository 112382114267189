import { Box, Typography } from '@mui/material';
import { LogoWrapper, TitleEmphasisBox } from '@styles/Page/PageHeaderStyle';
import { Badge } from 'src/app/component/Badge/Badge';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { HeadTitleEmphasis, HeadTitleFormat, HeadTitleLogo, HeadTitleProps, TitleFormat as TitleFormatType } from 'src/home2/types/layout_types';

const TitleEmphasis = (titleEmphasisProps: HeadTitleEmphasis) => (
  titleEmphasisProps.hasEmphasis ?
    <TitleEmphasisBox component="span">{titleEmphasisProps.children}</TitleEmphasisBox>
    : titleEmphasisProps.children
);

const TitleFormat = (titleFormatProps: HeadTitleFormat) => {
  if (!titleFormatProps.title) {
    return null;
  }

  return (
    <Typography variant={titleFormatProps?.variant} component="span">
      {
        titleFormatProps.title.map((text: TitleFormatType, index) => (

          <TitleEmphasis key={index} hasEmphasis={text?.hasEmphasis}>
            {text && text.id && (
              <FormattedMessage
                id={text.id}
                values={text?.values}
              />
            )}
          </TitleEmphasis>
        ))
      }
    </Typography>
  );
};

const TitleLogo = (titleLogoProps: HeadTitleLogo) => (
  titleLogoProps.hasLogo ?
    (
      <Typography variant={titleLogoProps.variant}>
        {titleLogoProps.children}
        <LogoWrapper component="span" />
        {titleLogoProps.postLogoText && (
          <Box component="span">
            <TitleFormat title={titleLogoProps.postLogoText} variant={titleLogoProps.variant} />
          </Box>
        )}
      </Typography>
    )
    : titleLogoProps.children
);

export const HeadingTitleSection = (headTitleProps: HeadTitleProps) => {
  if (!headTitleProps.showTitle) {
    return null;
  }

  return (
    <Box sx={{
      display: 'flex',
      paddingBottom: '16px',
      gap: '8px',
    }}>
      <TitleLogo hasLogo={headTitleProps.hasLogo ?? false} variant={headTitleProps.variant} postLogoText={headTitleProps.postLogoText}>
        <TitleFormat title={headTitleProps?.preLogoText} variant={headTitleProps?.variant} />
      </TitleLogo>
      {headTitleProps.hasBadge && (
        <Badge label={headTitleProps.badgeLabel} color="info" size="small" />
      )}
    </Box>
  );
};
