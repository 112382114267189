import { useState } from 'react';
import { ConfigurationReplicationState } from './domain/ConfigurationReplicationState';
import { SelectConfigurationsStep } from './components/SelectConfigurationsStep';
import { SelectSourceBusinessStep } from './components/SelectSourceBusinessStep';
import { SelectTargetBusinessesStep } from './components/SelectTargetBusinessesStep';
import { ReviewAndApplyChangesStep } from './components/ReviewAndApplyChangesStep';
import { IntroduceFlowStep } from './components/IntroduceFlowStep';
import { Card, CardHeader } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const ConfigurationReplication = () => {
  const [state, setState] = useState<ConfigurationReplicationState>({
    step: 'introduce flow',
  });
  const onNext = (nextState: ConfigurationReplicationState) => setState(nextState);
  const intl = useIntl();
  return (
      <Card>
        <CardHeader title={intl.formatMessage({ id: 'configuration_replication.title' })} />
        {state.step === 'introduce flow' && <IntroduceFlowStep onNext={onNext} />}
        {state.step === 'select configurations' && <SelectConfigurationsStep onNext={onNext} />}
        {state.step === 'select source business' && <SelectSourceBusinessStep currentState={state} onNext={onNext} />}
        {state.step === 'select target businesses' && (
          <SelectTargetBusinessesStep currentState={state} onNext={onNext} />
        )}
        {state.step === 'review and apply changes' && <ReviewAndApplyChangesStep currentState={state} />}
      </Card>
  );
};
