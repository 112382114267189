import { useParams } from 'react-router-dom';
import { BusinessId } from '../domain/Business';

export const useBusinessId = () => {
  const { venueId, businessId } = useParams();
  return (businessId ?? venueId) as BusinessId | undefined;
};

export const useBusinessIdOrThrow = () => {
  const businessId = useBusinessId();
  if (!businessId) throw new Error('Venue id or business id param is expected at this point');
  return businessId;
};
