import React from 'react';
import { useFormContext } from 'react-hook-form';
import Divider from '@mui/material/Divider';
import { ProofOfIdentity } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/ProofOfIdentity/ProofOfIdentity';
import {
  CheckoutOnboardingInformationDraft,
  companyPositionNeeded,
  RepresentativeDraft,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { UboPersonalInfo } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/UBO/UboPersonalInfo';
import { isUboComplete } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IndividualRepresentatives/UBO/isUboComplete';
import { Representative } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/Representative';
import { RepresentativeDetails } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/RepresentativeDetails';

export const EditUBO = ({
  representative,
  index,
  onDelete,
}: {
  representative: RepresentativeDraft;
  onDelete: () => void;
  index: number;
}) => {
  const {
    formState: { errors },
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[index];
  const isAuthorisedSignatory = !!representative?.roles?.authorisedSignatory;
  const needsCompanyPosition = companyPositionNeeded(representative);
  const isReadOnly = isAuthorisedSignatory;
  const intl = useIntl();
  const isComplete = isUboComplete({ representative, containsErrors: !!error });
  const title =
    representative.lastName && representative.firstName
      ? `${representative.lastName} ${representative.firstName}`
      : intl.formatMessage({ id: 'settings.payments.checkout.onboarding.individualRepresentatives.ubo.details' });

  return (
    <Representative isComplete={isComplete} title={title} onDelete={onDelete} isReadOnly={isReadOnly}>
      <RepresentativeDetails>
        <UboPersonalInfo needsCompanyPosition={needsCompanyPosition} representativeIndex={index} />
        <Divider style={{ margin: '32px 0' }} />
        <ProofOfIdentity representativeIndex={index} />
      </RepresentativeDetails>
    </Representative>
  );
};
