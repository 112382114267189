import { Stack, Typography } from '@mui/material';
import { FirstName } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/FirstName';
import { LastName } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/LastName';
import {
  DateOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/DateOfBirth';
import {
  CountryOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/CountryOfBirth';
import React from 'react';
import {
  RepresentativeAddress,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/RepresentativeAddress';
import Divider from '@mui/material/Divider';
import {
  CompanyPosition,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/CompanyPosition';
import { ControlExtraRoles } from './ControlExtraRoles';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';


export const ControlPersonalInfo = ({ representativeIndex }: {
  representativeIndex: number
}) => {
  return <Stack spacing={3}>
    <Typography variant={'h6'}><FormattedMessage id={'settings.payments.checkout.onboarding.representatives.personalInformation'} /></Typography>
    <Stack spacing={3} direction={'row'}>
      <FirstName legalRepresentativeIndex={representativeIndex} />
      <LastName legalRepresentativeIndex={representativeIndex} />
    </Stack>
    <ControlExtraRoles representativeIndex={representativeIndex} />
    <DateOfBirth legalRepresentativeIndex={representativeIndex} />
    <CountryOfBirth legalRepresentativeIndex={representativeIndex} />
    <RepresentativeAddress representativeIndex={representativeIndex} />
    <Stack spacing={3}>
      <Divider />
      <CompanyPosition representativeIndex={representativeIndex} />
    </Stack>
  </Stack>;
};
