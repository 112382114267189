import React, { ReactNode } from 'react';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { UserRole } from 'src/domain/user/UserRole';
import { isAuthorized } from 'src/domain/user/Role';
import { useBusinessId } from 'src/business/hooks/useBusinessId';

interface Props {
  authorizedRoles: Array<UserRole>;
  children: ReactNode;
}

export const WithRestrictedAccess: React.FC<Props> = ({ authorizedRoles, children }: Props) => {
  const user = useAuthenticatedUserOrThrow();
  const businessId = useBusinessId();
  if (!!businessId && isAuthorized(user.claims, authorizedRoles, businessId)) {
    return <>{children}</>;
  }
  return <div>Unauthorized access</div>;
};

export function withRestrictedAccess(
  authorizedRoles: Array<UserRole>,
  WrappedComponent: React.ComponentType,
): React.ComponentType {
  const wrapped = (props: object) => (
    <WithRestrictedAccess authorizedRoles={authorizedRoles}>
      <WrappedComponent {...props} />
    </WithRestrictedAccess>
  );
  wrapped.displayName = 'WithRestrictedAccessHOC';
  return wrapped;
}
