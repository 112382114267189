import { Stack, Typography } from '@mui/material';
import { FirstName } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/FirstName';
import { LastName } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/LastName';
import { DateOfBirth } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/DateOfBirth';
import { CountryOfBirth } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/CountryOfBirth';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { RepresentativeAddress } from '../../PersonalInfo/RepresentativeAddress';

export const DirectorPersonalInfo = ({ representativeIndex }: { representativeIndex: number }) => {
  return (
    <Stack spacing={3}>
      <Typography variant={'h6'}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.representatives.personalInformation'} />
      </Typography>
      <Stack spacing={3} direction={'row'}>
        <FirstName legalRepresentativeIndex={representativeIndex} />
        <LastName legalRepresentativeIndex={representativeIndex} />
      </Stack>
      <DateOfBirth legalRepresentativeIndex={representativeIndex} />
      <CountryOfBirth legalRepresentativeIndex={representativeIndex} />
      {/* TODO Sylvain 02/08/2024: change this check when Checkout will fix the bug (only country is required) */}
      <RepresentativeAddress representativeIndex={representativeIndex} />
    </Stack>
  );
};
