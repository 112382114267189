import styled from 'styled-components';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateRanges, Filter } from '../../domain/RewardProgramKpis';
import { formatDateRange } from './RewardProgramKpisDatesFormat';

const Filters = styled('div')`
  padding: 1rem;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  font-size: 1rem;
`;

const StyledDateRanges = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  align-items: flex-start;

  margin: 1rem 0;
  > *+* {
    margin-left: 0.3rem;
  }
`;

const StyledCalendar = styled('div')`
  color: #70707B;
  width: 0.9rem;
  height: 0.9rem;
`;

const StyledDateRange = styled('div')`
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: -0.3px;
  color: #70707B;
`;

export type RewardProgramKpisFilterProps = {
  initialFilter: Filter;
  onFilterChanged: (filter: Filter) => void
  dateRanges: DateRanges;
};

export const RewardProgramKpisFilterSelector = ({ initialFilter, onFilterChanged, dateRanges }: RewardProgramKpisFilterProps) => {
  const intl = useIntl();
  const [filter, setFilter] = useState<Filter>('last7Days');

  const handleFilterChanged = (newFilter: Filter) => {
    setFilter(newFilter);
    onFilterChanged(newFilter);
  };

  const formattedDateRange = () => (filter === 'last7Days'
    ? `${formatDateRange(dateRanges.last7Days, intl.locale)} vs ${formatDateRange(dateRanges.prev7Days, intl.locale)}`
    : formatDateRange(dateRanges.allTime, intl.locale));

  return (
    <Filters>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="filter-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={initialFilter}
          onChange={(event, value) => {
            handleFilterChanged(value as Filter);
          }}
        >
          <FormControlLabel value="last7Days" control={<Radio />} label="Last 7 days" />
          <FormControlLabel value="allTime" control={<Radio />} label="All time" />
        </RadioGroup>
      </FormControl>
      <StyledDateRanges>
        <StyledCalendar><DateRangeIcon /></StyledCalendar>
        <StyledDateRange>{formattedDateRange()}</StyledDateRange>
      </StyledDateRanges>
    </Filters>
  );
};
