import { WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';
import { Business } from 'src/business/domain/Business';
import { IntlShape } from 'react-intl/src/types';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import CustomizationPicture from './components/Pictures/CustomizationPicture';
import React from 'react';
import { Column } from 'src/sundayplus/Column';
import { pixels } from 'src/sundayplus/Size';
import { Card, Typography, useTheme } from '@mui/material';
import { LandingPageColorCustomizer } from 'src/pages/Settings/Customization/components/ColorCustomizers/LoadingImageColorCustomizer';
import { CardContent } from '@styles/Card/StyledCard';

interface Props {
  whiteLabelCustomization: WhiteLabelCustomization;
  secondaryColor: string | undefined;
  business: Business;
  intl: IntlShape;
  onSaveCustomizationImageUrl: (imageUrl: string) => void;
}

export function LoadingImage({
  business,
  intl,
  onSaveCustomizationImageUrl,
  secondaryColor,
  whiteLabelCustomization,
}: Props) {
  const theme = useTheme();
  return (
    <Card>
      <CardContent>
        <Column gap={pixels(16)}>
          <Typography variant="h6">
            <FormattedMessage id="settings.customization.loadingPage.title" defaultMessage="Loading page" />
          </Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            <FormattedMessage id="settings.customization.loading_image.description" />
          </Typography>
          <LandingPageColorCustomizer business={business} whiteLabelCustomization={whiteLabelCustomization} />

          <CustomizationPicture
            previewBackgroundColor={secondaryColor}
            venueId={business.patEnrollment?.id!}
            imgUrl={whiteLabelCustomization.loadingImgUrl || ''}
            imageRatio={1}
            title={intl.formatMessage({ id: 'settings.customization.loadingPage.pictureTitle' })}
            onSaveCustomizationImageUrl={onSaveCustomizationImageUrl}
            allowTransparency
            filenameForSaving={'loading'}
          />
        </Column>
      </CardContent>
    </Card>
  );
}
