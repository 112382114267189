import React from 'react';
import { AccordionSummary, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IsCompleteBadge } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/IsCompleteBadge';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  DeleteRepresentativeDialog,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/DeleteRepresentativeDialog';

export const RepresentativeSummary = ({ complete, onDelete, title }: {
  title: string,
  complete: boolean,
  onDelete: () => void,
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  return <AccordionSummary
    expandIcon={<ExpandMoreIcon fontSize={'large'} />}
    aria-controls="panel1-content"
    id="panel1-header"
  >
    <Stack marginRight={'8px'} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Typography
          variant={'body1'}>{title}</Typography>
        <IsCompleteBadge isComplete={complete} />
      </Stack>
      <DeleteOutlineIcon
        onClick={() => {
          setConfirmDeleteOpen(true);
        }}
        color={'error'}
        fontSize={'large'}
      />
    </Stack>
    {confirmDeleteOpen &&
      <DeleteRepresentativeDialog onConfirm={() => {
        onDelete();
        setConfirmDeleteOpen(false);
      }} onClose={() => setConfirmDeleteOpen(false)} />}
  </AccordionSummary>;
};
