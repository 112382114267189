import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { CircularProgress, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { toViewModel } from './StaffPerformanceViewModel';
import { useStaffPerformance } from './useStaffPerformance';
import { Header } from '../component/DataGridHeader';
import { buildColumns } from './buildColumns';
import { NoDataOverlay } from '../component/NoDataOverlay';
import { buildGroupColumns } from './buildGroupColumns';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import moment from 'moment';
import { StaffPerformanceRangeType } from 'src/staff-performance/domain/StaffPerformance';
import { useGetSinglePosConnectionOptions } from 'src/pos/queries/getPosConnectionOptions';

const StaffDataGrid = styled(DataGrid)`
  .MuiDataGrid-columnHeader--filledGroup {
    .MuiDataGrid-columnHeaderTitleContainer {
      border-bottom: none !important;
    }
  }
  .MuiDataGrid-overlayWrapper {
    z-index: 1;
  }
`;
const defaultRange: StaffPerformanceRangeType = '7_DAYS';
export const StaffPerformance = () => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const isUSBusiness = business.address.countryCode === 'US';
  const [startDate, updateStartDate] = useState<Date>(moment().startOf('day').toDate());
  const [endDate, updateEndDate] = useState<Date>(moment().endOf('day').toDate());
  const [range, setRange] = useState<StaffPerformanceRangeType>(defaultRange);
  const { isLoading: isStaffPerformanceLoading, data } = useStaffPerformance({
    range,
    businessId: business.id,
    timezone: business.timezone,
    startDate,
    endDate,
  });
  const { isLoading: isPosConnectionLoading, data: posConnectionOptions } = useGetSinglePosConnectionOptions(business.id);
  const shouldCollectAdoptionRate = posConnectionOptions?.collectAdoptionRate ?? false;

  const isLoading = isStaffPerformanceLoading || isPosConnectionLoading;


  const onCustomRangeChanged = (currentStartDate: Date, currentEndDate: Date) => {
    updateStartDate(currentStartDate);
    updateEndDate(currentEndDate);
  };

  return (
    <>
        <Header
          range={range}
          setRange={setRange}
          startDate={startDate}
          endDate={endDate}
          setCustomRangeDate={onCustomRangeChanged}
        />
        {!isLoading && (
          <StaffDataGrid
            rows={toViewModel(data ?? [], intl.locale, business.currency, shouldCollectAdoptionRate)}
            columns={buildColumns(isUSBusiness, business)}
            disableRowSelectionOnClick
            autoHeight
            hideFooter
            columnHeaderHeight={28}
            slots={{
              noRowsOverlay: NoDataOverlay,
            }}
            columnGroupingModel={buildGroupColumns()}
          />
        )}
        {isLoading && <CircularProgress style={{ alignSelf: 'center' }} />}
    </>
  );
};
