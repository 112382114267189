import React from 'react';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router-dom';
import { OnboardingStep } from 'src/onboarding/domain/OnboardingStep';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ampli } from 'src/ampli';

interface OnboardingStepProps {
  step: OnboardingStep;
}

const OnboardingStepComponent = ({ step }: OnboardingStepProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();


  const share = () => async () => {
    if (step.isShareable) {
      await navigator.clipboard.writeText(step.buttonUri);

      pushNotification(
        intl.formatMessage({
          id: 'settings.qr-code.details.url-copied',
          defaultMessage: 'url copied in the clipboard',
          description: 'link is copied to the clipboard',
        }),
      );
      return;
    }
  };

  const onClick = () => async () => {
    ampli.onboardingActionClicked({ onboardingAction: intl.formatMessage({ id: step.buttonLabelKey }) });
    return step.isExternalUri ? window.open(step.buttonUri, '_blank') : navigate(step.buttonUri);
  };

  return (
    <Box>
      <Typography variant="body1" color={theme.palette.text.disabled} sx={{ textAlign: 'justify' }}>
        <FormattedMessage id={step.descriptionKey} />
      </Typography>
      <Box sx={{ display: 'flex', gap: theme.spacing(1), marginTop: theme.spacing(2) }}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ marginTop: theme.spacing(2) }}
          onClick={onClick()}
        >
          <FormattedMessage id={step.buttonLabelKey} />
        </Button>
        {step.isShareable && (
          <IconButton
            style={{ marginTop: theme.spacing(2) }}
            onClick={share()}
          >
            <ContentCopyIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default OnboardingStepComponent;
