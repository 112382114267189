import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Task } from 'src/menu/common/domain/Task';
import { MenuBusinessRepositoryHttp } from '../repositories/MenuBusinessRepositoryHttp';

export const QUERYKEY_MENU_GET_TASK = 'menu_get_task';
const buildQueryKey = (taskId: string) => [QUERYKEY_MENU_GET_TASK, taskId] as const;
const getTask = async ({
  queryKey: [, taskId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Task> => {
  const configuration = ConfigurationLoader.load();
  const menuRepository = new MenuBusinessRepositoryHttp(configuration.menuApiBaseUrl);
  return menuRepository.getTask(taskId);
};

export const usePollTask = (taskId: string, enabled: boolean) =>
  useQuery({
    queryKey: buildQueryKey(taskId),
    queryFn: getTask,
    refetchInterval: 5000,
    enabled,
  });
