import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { queryKeys } from 'src/app/queries/utils';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PongoStore } from '../domain/PongoStore';
import { LoyaltyRepository } from '../infrastructure/LoyaltyRepository';

const configuration = ConfigurationLoader.load();
const loyaltyRepository = new LoyaltyRepository(configuration.loyaltyApiBaseUrl);
export const useListPongoStores = (isAuthenticated: boolean | undefined) => {
  const businessId = useBusinessIdOrThrow();
  const code = useSearchParams()[0].get('code');

  return useQuery({
    queryKey: [queryKeys.loyalty.LIST_PONGO_STORES, { businessId, code }, isAuthenticated],
    queryFn: async (): Promise<PongoStore[]> => {
      if (code && isAuthenticated) {
        return loyaltyRepository.listStores(businessId);
      }
      return [];
    },
  });
};
