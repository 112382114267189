import React from 'react';
import { useParams } from 'react-router-dom';

const SetupOnce = () => {
  const params = useParams();
  return (
    <div>
      <h1>Invalid or expired token</h1>
      <p>{params.token}</p>
    </div>
  );
};

export default SetupOnce;

