import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { MoneyView } from '@sundayapp/web-money';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Order, OrderItem as OrderItemType, OrderPaymentStatus } from '../../types';
import IconButton, { IconButtonVariants } from '../../../common/components/IconButton';
import { InfoIcon } from '../../../common/components/Icons';
import OrderCutlery from '../OrderCutlery';
import OrderNotes from '../OrderNotes';
import TotalWithTaxSummary from '../TotalWithTaxSummary/TotalWithTaxSummary';
import PaymentSummary from '../PaymentSummary';
import Button, { ButtonVariant } from '../../../common/components/Button';
import RefundDrawer from '../RefundDrawer/RefundDrawer';
import OrderHistoryModal from '../OrderHistoryModal';
import { colorPalette, getSpacing, textStyles } from '../../../stylesheet';
import { useSelectOrderForRefund, useUnselectOrderForDetails, useUpdatePaymentStatus } from '../../hooks';
import OrderItem from '../OrderItem/OrderItem';
import { PaymentSummary as PaymentSummaryType, PaymentSummaryStatus } from '../../../payment/types';
import { getPathToOrderPayments } from '../../services';
import { getOrderForRefund } from '../../redux/selectors';
import { useRouting } from '../../../common/hooks';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { isAuthorized } from 'src/domain/user/Role';
import { userRoles } from 'src/domain/user/UserRole';
import CancelOrderButton from '../CancelOrderButton';
import { SendBoxOrdersToPos } from './SendBoxOrdersToPos';
import { AcceptOrderButton } from '../AcceptOrderButton/AcceptOrderButton';
import { BusinessId, invalidBusinessIdToRefactor } from 'src/business/domain/Business';

type OrderDetailsPanelProps = {
  businessId: BusinessId,
  order: Order,
};

const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(6)} ${getSpacing(3)} ${getSpacing(3)};
`;

const OrderTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.03em;
  margin: 0 0 ${getSpacing(7)};
`;

const OrderDetailsTitle = styled.h3`
  ${textStyles.small}
  font-weight: 400;
  padding-bottom: ${getSpacing(1)};
  border-bottom: 1px solid ${colorPalette.placeholderColor};
  margin: 0;
`;

const OrderItemsContainer = styled.div`
  border-bottom: 1px solid ${colorPalette.placeholderColor};
`;

const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${getSpacing(3)} 0 ${getSpacing(4)};
  ${textStyles.default}
`;

const CloseButton = styled.div`
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 16px;
  margin-left: 0;
`;

const CloseIconButton = styled(CloseIcon)``;

const RefundContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${getSpacing(1)} 0;
`;

const getDateAndTimeStrings = (timestamp: number, locale: string) => {
  const d = new Date(timestamp);
  return {
    time: d.toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
    }),
    date: d.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }),
  };
};

const isPaymentSuccessful = (payment: PaymentSummaryType) => payment.status === PaymentSummaryStatus.SUCCESS;

export const OrderDetailsPanel = ({ businessId, order }: OrderDetailsPanelProps) => {
  const { locale } = useIntl();
  const orderingRouting = useRouting();
  const [, updatePaymentStatus] = useUpdatePaymentStatus();
  const [displayOrderHistoryModal, setDisplayOrderHistoryModal] = useState<boolean>(false);
  const orderForRefund = useSelector(getOrderForRefund);
  const [, selectOrderForRefund] = useSelectOrderForRefund(order.id);

  const unselectOrder = useUnselectOrderForDetails();

  const renderOrderItems = useCallback(
    (orderItem: OrderItemType, index: number) => <OrderItem key={index} item={orderItem} />,
    [],
  );

  const orderRefunded = async (refundedOrder: Order, status: OrderPaymentStatus) => {
    await updatePaymentStatus(refundedOrder.id, status);
  };

  const showOrderHistory = useCallback(() => {
    setDisplayOrderHistoryModal(true);
  }, [setDisplayOrderHistoryModal]);

  const payment = useMemo(() => order && order.payments.find(isPaymentSuccessful), [order]);

  const user = useAuthenticatedUserOrThrow();

  const canRefund = (): boolean => !!payment && isAuthorized(user.claims, [userRoles.ADMIN], invalidBusinessIdToRefactor(order.enrollmentId));

  const onIssueRefund = useCallback(() => {
    if (order!.payments.length > 1) {
      getPathToOrderPayments(businessId, order!, orderingRouting);
    } else {
      selectOrderForRefund();
    }
  }, [order, orderingRouting, selectOrderForRefund]);

  const hideOrderHistory = useCallback(() => {
    setDisplayOrderHistoryModal(false);
  }, [setDisplayOrderHistoryModal]);

  return (
    <div>
      <OrderDetailsContainer>
        <CloseButton onClick={() => unselectOrder()}>
          <CloseIconButton />
        </CloseButton>
        <OrderTitle>
          <FormattedMessage
            id="orders.detailsTitle"
            defaultMessage="order {displayId} for {customerName}"
            values={{
              displayId: order.displayId,
              customerName: order.customerName,
            }}
          />
          <IconButton Icon={InfoIcon} variant={IconButtonVariants.BORDERLESS} onClick={showOrderHistory} />
        </OrderTitle>
        {order.placedAtEpochMilli && (
          <OrderDetailsTitle>
            <FormattedMessage
              id="orders.receivedAt"
              defaultMessage="received at {time} on {date}"
              values={getDateAndTimeStrings(order.placedAtEpochMilli, locale)}
            />
          </OrderDetailsTitle>
        )}
        <OrderItemsContainer>{order.items.map(renderOrderItems)}</OrderItemsContainer>
        <OrderCutlery cutleryNumber={order.cutleryNumber} />
        <OrderNotes notes={order.notes} />
        <TotalWithTaxSummary total={order.totalPrice} taxSummary={order.taxSummary} />
        <TotalPrice>
          <FormattedMessage id="orders.total" defaultMessage="total" />
          <MoneyView value={order.totalPrice} />
        </TotalPrice>
        <PaymentSummary
          payments={order.payments}
          currency={order.totalPrice.currency}
          serviceChargeAmount={order.serviceChargeAmount}
          discount={order.discount}
          orderTotal={order.totalPrice}
        />

        {canRefund() && (
          <RefundContainer>
            <Button variant={ButtonVariant.PRIMARY} onClick={onIssueRefund} data-testid="order-refund-button">
              <FormattedMessage id="orders.issueARefund" defaultMessage="issue a refund" />
            </Button>
          </RefundContainer>
        )}

        <RefundDrawer order={orderForRefund} payment={payment} orderRefunded={orderRefunded} />

        <SendBoxOrdersToPos order={order} />

        <AcceptOrderButton order={order} />

        <CancelOrderButton order={order} />
      </OrderDetailsContainer>
      {order && <OrderHistoryModal orderId={order.id} visible={displayOrderHistoryModal} hide={hideOrderHistory} />}
    </div>
  );
};
