import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { VenueDetails } from '../../../types';
import { BoxDetails } from '../../../../box/domain/BoxDetails';
import { SettingsCategory } from './SettingsCategory';
import {
  BusySetting,
  ClickAndCollectBoxSetting,
  ConfirmationMessageBoxSetting,
  OrderNotesBoxSetting,
  OrderNotificationBoxSettings,
  PacingBoxSetting,
  QueueingSetting,
  UpsellBoxSetting,
} from '../../../../box';
import { FoldableCategoriesVenueSetting } from 'src/ordering/venues/pages';
import { CutleryVenueSetting } from 'src/ordering/venues/pages';
import { GroupingVenueSetting } from 'src/ordering/venues/pages';
import { PayWithCashVenueSetting } from 'src/ordering/venues/pages';
import { CustomerRequiredDataVenueSetting } from 'src/ordering/venues/pages';
import { TimezoneVenueSetting } from 'src/ordering/venues/pages';
import { ServiceFeeRateVenueSetting } from 'src/ordering/venues/pages';
import { TipsRoundingVenueSetting } from '../../VenueSettings/component/TipsRoundingVenueSetting/TipsRoundingVenueSetting';
import { AlwaysSendTableNameToPosSetting } from '../../../../box/pages/BoxSettings/components/AlwaysSendTableNameToPosSetting/AlwaysSendTableNameToPosSetting';
import { ForbidsMultiboxOrdersVenueSetting } from '../../VenueSettings/component/ForbidsMultiboxOrdersVenueSetting/ForbidsMultiboxOrdersVenueSetting';
import { SortBoxesByWaitingTimeSetting } from '../../VenueSettings/component/SortBoxesByWaitingTimeSetting/SortBoxesByWaitingTimeSetting';
import { EnableBoxWaitingTimeSetting } from '../../VenueSettings/component/EnableBoxWaitingTimeSetting/EnableBoxWaitingTimeSetting';
import { ProductsAvailabilitiesSetting } from '../../../../box/pages/BoxSettings/components/ProductsAvailabilities/ProductsAvailabilities';

type VenueAndBoxComponentsProps = {
  venue: VenueDetails;
  useVenue: boolean;
  setVenue: (venue: VenueDetails) => void;

  box?: BoxDetails;
  setBox: (box: BoxDetails) => void;

  displayAdvancedOptions: boolean;
};

// eslint-disable-next-line complexity
export const VenueAndBoxComponents = ({
  venue,
  useVenue,
  setVenue,
  box,
  setBox,
  displayAdvancedOptions,
}: VenueAndBoxComponentsProps) => {
  const intl = useIntl();

  return (
    <>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'venue.settings.category.products-menus',
          defaultMessage: 'Products and menus',
        })}
      >
        {box && <UpsellBoxSetting box={box} />}
        {useVenue && <FoldableCategoriesVenueSetting venue={venue} setVenue={setVenue} />}
        {box && <OrderNotesBoxSetting box={box} setBox={setBox} />}
      </SettingsCategory>

      <SettingsCategory
        title={intl.formatMessage({
          id: 'venue.settings.category.experience',
          defaultMessage: 'Customise the experience',
        })}
      >
        {box && <ConfirmationMessageBoxSetting box={box} setBox={setBox} />}
        {box && <OrderNotificationBoxSettings venue={venue} box={box} setBox={setBox} />}
        {useVenue && <CutleryVenueSetting venue={venue} setVenue={setVenue} />}
        {useVenue && <EnableBoxWaitingTimeSetting venue={venue} setVenue={setVenue} />}
        {useVenue && <SortBoxesByWaitingTimeSetting venue={venue} setVenue={setVenue} />}
      </SettingsCategory>

      <SettingsCategory
        title={intl.formatMessage({
          id: 'venue.settings.category.operations',
          defaultMessage: 'Operations',
        })}
      >
        {useVenue && <GroupingVenueSetting venue={venue} setVenue={setVenue} />}
        {box && <PacingBoxSetting box={box} setBox={setBox} />}
        {displayAdvancedOptions && box && <BusySetting box={box} setBox={setBox} />}
        {box && <ClickAndCollectBoxSetting venue={venue} box={box} setBox={setBox} />}
        {box && <QueueingSetting box={box} setBox={setBox} />}
        {box && <ProductsAvailabilitiesSetting box={box} setBox={setBox} />}
        {useVenue && <PayWithCashVenueSetting venue={venue} setVenue={setVenue} />}
        {useVenue && <TipsRoundingVenueSetting venue={venue} setVenue={setVenue} />}
        {displayAdvancedOptions && box && <AlwaysSendTableNameToPosSetting box={box} setBox={setBox} />}
        {useVenue && <ForbidsMultiboxOrdersVenueSetting venue={venue} setVenue={setVenue} />}
      </SettingsCategory>

      {useVenue && (
        <SettingsCategory
          title={intl.formatMessage({
            id: 'venue.settings.category.other',
            defaultMessage: 'Other',
          })}
        >
          <CustomerRequiredDataVenueSetting venue={venue} setVenue={setVenue} />
          {displayAdvancedOptions && <TimezoneVenueSetting venue={venue} setVenue={setVenue} />}
          <ServiceFeeRateVenueSetting venue={venue} setVenue={setVenue} />
        </SettingsCategory>
      )}
    </>
  );
};
