export type NumericValue = number | undefined;

export type CustomerEngagement = {
  loyaltyParticipationRate: NumericValue;
  nbActiveLoyaltyCustomers: NumericValue;
  nbLoyaltyPayments: NumericValue;
  nbNewLoyaltySignUps: NumericValue;
  nbRewardsEarned: NumericValue;
  nbRewardsRedeemed: NumericValue;
};

export type DateRange = {
  from: string;
  to: string;
};

export type DateRanges = {
  allTime: DateRange,
  last7Days: DateRange,
  prev7Days: DateRange,
};

export type CustomerEngagements = {
  dateRanges: DateRanges
  allTime: CustomerEngagement;
  last7Days: CustomerEngagement;
  prev7Days: CustomerEngagement;
};

export type CustomerEngagementByVenue = {
  venueId: string;
  venueName: string;
  customerEngagement: CustomerEngagement;
};

export type CustomerEngagementsByVenue = {
  allTime: CustomerEngagementByVenue[];
  last7Days: CustomerEngagementByVenue[];
};

export type RewardProgramKpis = {
  customerEngagements: CustomerEngagements;
  customerEngagementsByVenue: CustomerEngagementsByVenue;
};

export const programLaunchDate = (rewardProgramKpis: RewardProgramKpis): string => rewardProgramKpis.customerEngagements.dateRanges.allTime.from;

export type Filter = 'allTime' | 'last7Days';
