import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useMemo, useState } from 'react';
import { IntlShape } from 'react-intl/src/types';
import { CustomerRequiredDataType, VenueDetails } from '../../../../types';
import { useUpdateVenueDetails } from '../../../../hook';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type CustomerRequiredDataVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

type CustomerRequiredDataOption = {
  id: CustomerRequiredDataType;
  label: string;
};

const customerRequiredDataOptions = (intl: IntlShape): CustomerRequiredDataOption[] => [
  {
    id: CustomerRequiredDataType.AT_LEAST_EMAIL_OR_PHONE,
    label: intl.formatMessage({
      id: 'venue.customerDataAtLeastEmailOrPhone',
      defaultMessage: 'at least email or phone number',
    }),
  },
  {
    id: CustomerRequiredDataType.EMAIL_AND_PHONE,
    label: intl.formatMessage({ id: 'venue.customerEmailAndPhone', defaultMessage: 'email and phone number' }),
  },
  {
    id: CustomerRequiredDataType.EMAIL_ONLY,
    label: intl.formatMessage({ id: 'venue.customerDataEmailOnly', defaultMessage: 'email only' }),
  },
  {
    id: CustomerRequiredDataType.PHONE_ONLY,
    label: intl.formatMessage({ id: 'venue.customerDataPhoneOnly', defaultMessage: 'phone number only' }),
  },
  {
    id: CustomerRequiredDataType.NONE,
    label: intl.formatMessage({ id: 'venue.customerDataNone', defaultMessage: 'none' }),
  },
];

export const CustomerRequiredDataVenueSetting = ({ venue, setVenue }: CustomerRequiredDataVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const options = useMemo(() => customerRequiredDataOptions(intl), [intl]);

  const computeDropDownLabel = (value: CustomerRequiredDataType): string => {
    const idx = options.findIndex((opt) => opt.id === value);
    if (idx >= 0) {
      return options[idx].label;
    }
    return computeDropDownLabel(CustomerRequiredDataType.NONE);
  };

  const dropDownValue = computeDropDownLabel(venue.customerRequiredData);
  const dropDownOptions: string[] = options.map((opt) => opt.label);

  const updateRequiredData = async (requiredData: string) => {
    const selectedIdx = options.findIndex((opt) => opt.label === requiredData);
    const customerRequiredData = options[selectedIdx].id;

    makeUpdating(true);
    setVenue({
      ...venue,
      customerRequiredData,
    });
    await updateVenueDetails({
      customerRequiredData,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.customerRequiredData',
        defaultMessage: 'customer required data',
        description: 'select customer data required to order (email, phone, both, none)',
      })}
      description={intl.formatMessage({
        id: 'venue.settings.customerRequiredData.description',
        defaultMessage: 'select what info is mandatory to ask customers before the payment',
        description: 'description of the customer required data setting purpose',
      })}
      dropDownValue={dropDownValue}
      dropDownOptions={dropDownOptions}
      dropDownOnChange={updateRequiredData}
      disabled={isUpdating}
    />
  );
};
