/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull merchant-front-end'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 31
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/implementation/merchant-front-end)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: 'ce0b3bc034d2aee73cc2c74de6098995',
  development: 'ededd33824a681e64df88a1ae29ac4df'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '31',
    branch: 'main',
    source: 'merchant-front-end',
    versionId: '61e07212-f73a-4493-8fc4-c608172007b8'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * The country code of the business
   */
  businessCountryCode?: string;
  businessId?: string;
  cardAcquirerName?: string;
  deviceType?: string;
  /**
   * used to know if the current user has impersonated somebody else
   */
  hasImpersonated?: boolean;
  PDQAppVersion?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ADMIN, GENERAL_MANAGER, MANAGER, WAITER, STAFF |
   */
  Role?: "ADMIN" | "GENERAL_MANAGER" | "MANAGER" | "WAITER" | "STAFF";
  terminalSN?: string;
  /**
   * describe the nature of the authentication used.
   *
   * 'ops' is for sunday users impersonating a merchant.
   *
   * 'merchant' is for venue users.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ops, merchant |
   */
  type?: "ops" | "merchant";
}

export interface BrigadLinkClickedProperties {
  origin: string;
}

export interface BulkReplyClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfDays: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfSelectedTemplates: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfUnselectedTemplates: number;
}

export interface BulkReplyCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfRepliesInError: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfRepliesSent: number;
}

export interface BulkReplyOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOf5starsReviews: number;
}

export interface ChargbeeBannerClickedProperties {
  businessId?: string;
  businessName?: string;
}

export interface ChargbeeBannerViewedProperties {
  businessId?: string;
  businessName?: string;
}

export interface ChargbeeConfigureClickedProperties {
  businessId?: string;
  businessName?: string;
}

export interface ConfigurationReplicationAppliedProperties {
  venueCountryCode?: string;
  venueId?: string;
  venueName?: string;
}

export interface ConfigurationReplicationChangesSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfReplicationConfigurationChanges: number;
  venueCountryCode?: string;
  venueId?: string;
  venueName?: string;
}

export interface ConfigurationReplicationSourceSelectedProperties {
  venueCountryCode?: string;
  venueId?: string;
  venueName?: string;
}

export interface ConfigurationReplicationStartedProperties {
  venueCountryCode?: string;
  venueId?: string;
  venueName?: string;
}

export interface ConfigurationReplicationTargetSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfReplicationConfigurationTargets: number;
  venueCountryCode?: string;
  venueId?: string;
  venueName?: string;
}

export interface CustomerDataCsvExportButtonClickedProperties {
  businessCountry?: string;
  businessId?: string;
  businessName?: string;
}

export interface DataAnalyticsBusinessChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  businessesIds: string[];
  date: string;
  /**
   * name of the page that fired the event
   */
  pageName?: string;
}

export interface DataAnalyticsBusinessPerformanceClickedProperties {
  businessId: string;
  businessName?: string;
  clickDate: string;
}

export interface DataAnalyticsCustomerInsightsClickedProperties {
  businessId: string;
  businessName?: string;
  clickDate: string;
}

export interface DataAnalyticsOverviewClickedProperties {
  businessId?: string;
  businessName?: string;
  clickDate: string;
}

export interface DataAnalyticsRepeatKpisProperties {
  businessId?: string;
  businessName?: string;
}

export interface DataAnalyticsTipsClickedProperties {
  businessId?: string;
  businessName?: string;
}

export interface DeleteReplyTemplateClickedProperties {
  templateId?: string;
  templateName: string;
}

export interface DimensionSelectedProperties {
  dimensionName: string;
}

export interface DuplicateReplyTemplateClickedProperties {
  templateId: string;
  templateName: string;
}

export interface EReputationGraphSelectedProperties {
  graphSelected: string;
  kpiType: string;
  period: string;
}

export interface EditReplyTemplateClickedProperties {
  templateId?: string;
  templateName: string;
}

export interface FromGuestExperienceToFeedbackProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  dimension?: string;
  timerange: string;
}

export interface GoogleReviewRepliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  ambianceRating?: number;
  creationDate: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  foodRating?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reviewGlobalRating: number;
  reviewId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  serviceRating?: number;
  templateContentEdited?: boolean;
  templateId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  valueForMoneyRating?: number;
  venueId?: string;
}

export interface GuestExperienceDateRangeFilterChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  daysSinceToday: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfDaysInRange: number;
}

export interface GuideAndTrainingClickedProperties {
  venueId?: string;
}

export interface LoyaltyTabViewedProperties {
  activeRewardProgram: boolean;
}

export interface OnboardingActionClickedProperties {
  businessId?: string;
  businessName?: string;
  onboardingAction: string;
  venueCountryCode?: string;
}

export interface OpenAiReplySuggestedProperties {
  suggestedReply: string;
}

export interface ReferralCampaignClickedProperties {
  businessId?: string;
  businessName?: string;
}

export interface ReplyTemplateCreatedProperties {
  templateName: string;
}

export interface ReplyTemplateDuplicatedProperties {
  templateName: string;
}

export interface ReplyTemplateEditedProperties {
  templateId: string;
  templateName: string;
}

export interface ReviewReplyTemplateChosenProperties {
  templateId: string;
}

export interface ReviewsCsvExportButtonClickedProperties {
  venueCountryCode?: string;
  venueId?: string;
  venueName?: string;
}

export interface ReviewsDateShortcutFilterClickedProperties {
  endDate: string;
  shortcut: string;
  startDate: string;
}

export interface ReviewsInitializationStartedProperties {
  platform: string;
  waiterName?: string;
}

export interface ReviewsPlatformShortcutFilterClickedProperties {
  platform: string;
}

export interface ReviewsReplyTimeframeSelectedProperties {
  period: string;
}

export interface ReviewsWithRatingShortcutFilterClickedProperties {
  rating: string;
}

export interface ReviewsWithWaiterShortcutFilterClickedProperties {
  waiter: string;
}

export interface ReviewsWithWithoutFeedbackShortcutFilterClickedProperties {
  feedback: string;
}

export interface ReviewsWithWithoutReviewReplyShortcutFilterClickedProperties {
  reply: string;
}

export interface StoreClickedProperties {
  storeName: string;
  venueCountryCode?: string;
  venueId?: string;
  venueName?: string;
}

export interface SundayContactUsFormSubmittedProperties {
  source: string;
}

export interface SundayPlusContactFormDisplayedProperties {
  source: string;
}

export interface SundayPlusContactUsFormSubmittedProperties {
  source: string;
}

export interface SundayReviewRepliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  ambianceRating?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  creationDate?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  foodRating?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reviewGlobalRating: number;
  reviewId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  serviceRating?: number;
  templateContentEdited?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  templateId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  valueForMoneyRating?: number;
  venueId: string;
}

export interface UserLoggedInProperties {
  venueId?: string;
}

export interface VenueProperties {
  venueCountryCode?: string;
  venueId?: string;
  venueName?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AnalyticsHomeViewed implements BaseEvent {
  event_type = 'analytics_home_viewed';
}

export class BrigadLinkClicked implements BaseEvent {
  event_type = 'BrigadLinkClicked';

  constructor(
    public event_properties: BrigadLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BulkReplyCancelled implements BaseEvent {
  event_type = 'BulkReplyCancelled';
}

export class BulkReplyClicked implements BaseEvent {
  event_type = 'BulkReplyClicked';

  constructor(
    public event_properties: BulkReplyClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BulkReplyCompleted implements BaseEvent {
  event_type = 'BulkReplyCompleted';

  constructor(
    public event_properties: BulkReplyCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BulkReplyOpened implements BaseEvent {
  event_type = 'BulkReplyOpened';

  constructor(
    public event_properties: BulkReplyOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChargbeeBannerClicked implements BaseEvent {
  event_type = 'ChargbeeBannerClicked';

  constructor(
    public event_properties?: ChargbeeBannerClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChargbeeBannerViewed implements BaseEvent {
  event_type = 'ChargbeeBannerViewed';

  constructor(
    public event_properties?: ChargbeeBannerViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChargbeeConfigureClicked implements BaseEvent {
  event_type = 'ChargbeeConfigureClicked';

  constructor(
    public event_properties?: ChargbeeConfigureClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConfigurationReplicationApplied implements BaseEvent {
  event_type = 'ConfigurationReplicationApplied';

  constructor(
    public event_properties?: ConfigurationReplicationAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConfigurationReplicationChangesSelected implements BaseEvent {
  event_type = 'ConfigurationReplicationChangesSelected';

  constructor(
    public event_properties: ConfigurationReplicationChangesSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConfigurationReplicationSourceSelected implements BaseEvent {
  event_type = 'ConfigurationReplicationSourceSelected';

  constructor(
    public event_properties?: ConfigurationReplicationSourceSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConfigurationReplicationStarted implements BaseEvent {
  event_type = 'ConfigurationReplicationStarted';

  constructor(
    public event_properties?: ConfigurationReplicationStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConfigurationReplicationTargetSelected implements BaseEvent {
  event_type = 'ConfigurationReplicationTargetSelected';

  constructor(
    public event_properties: ConfigurationReplicationTargetSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConnectGoogleAccountClicked implements BaseEvent {
  event_type = 'ConnectGoogleAccountClicked';
}

export class CreateReplyTemplateClicked implements BaseEvent {
  event_type = 'CreateReplyTemplateClicked';
}

export class CustomerDataCsvExportButtonClicked implements BaseEvent {
  event_type = 'CustomerDataCsvExportButtonClicked';

  constructor(
    public event_properties?: CustomerDataCsvExportButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataAnalyticsBusinessChanged implements BaseEvent {
  event_type = 'DataAnalyticsBusinessChanged';

  constructor(
    public event_properties: DataAnalyticsBusinessChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataAnalyticsBusinessPerformanceClicked implements BaseEvent {
  event_type = 'DataAnalyticsBusinessPerformanceClicked';

  constructor(
    public event_properties: DataAnalyticsBusinessPerformanceClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataAnalyticsCustomerInsightsClicked implements BaseEvent {
  event_type = 'DataAnalyticsCustomerInsightsClicked';

  constructor(
    public event_properties: DataAnalyticsCustomerInsightsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataAnalyticsOverviewClicked implements BaseEvent {
  event_type = 'DataAnalyticsOverviewClicked';

  constructor(
    public event_properties: DataAnalyticsOverviewClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataAnalyticsRepeatKpis implements BaseEvent {
  event_type = 'DataAnalyticsRepeatKpis';

  constructor(
    public event_properties?: DataAnalyticsRepeatKpisProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataAnalyticsTipsClicked implements BaseEvent {
  event_type = 'DataAnalyticsTipsClicked';

  constructor(
    public event_properties?: DataAnalyticsTipsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteReplyTemplateClicked implements BaseEvent {
  event_type = 'DeleteReplyTemplateClicked';

  constructor(
    public event_properties: DeleteReplyTemplateClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DimensionSelected implements BaseEvent {
  event_type = 'DimensionSelected';

  constructor(
    public event_properties: DimensionSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DuplicateReplyTemplateClicked implements BaseEvent {
  event_type = 'DuplicateReplyTemplateClicked';

  constructor(
    public event_properties: DuplicateReplyTemplateClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EReputationGraphSelected implements BaseEvent {
  event_type = 'E-reputationGraphSelected';

  constructor(
    public event_properties: EReputationGraphSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditReplyTemplateClicked implements BaseEvent {
  event_type = 'EditReplyTemplateClicked';

  constructor(
    public event_properties: EditReplyTemplateClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportReplyTemplatesClicked implements BaseEvent {
  event_type = 'ExportReplyTemplatesClicked';
}

export class FromGuestExperienceToFeedback implements BaseEvent {
  event_type = 'FromGuestExperienceToFeedback';

  constructor(
    public event_properties: FromGuestExperienceToFeedbackProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GoogleReviewReplied implements BaseEvent {
  event_type = 'GoogleReviewReplied';

  constructor(
    public event_properties: GoogleReviewRepliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GoToNewReplyPageFromPromotionBannerClicked implements BaseEvent {
  event_type = 'GoToNewReplyPageFromPromotionBannerClicked';
}

export class GuestExperienceDateRangeFilterChanged implements BaseEvent {
  event_type = 'GuestExperienceDateRangeFilterChanged';

  constructor(
    public event_properties: GuestExperienceDateRangeFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GuideAndTrainingClicked implements BaseEvent {
  event_type = 'GuideAndTrainingClicked';

  constructor(
    public event_properties?: GuideAndTrainingClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ImportReplyTemplatesClicked implements BaseEvent {
  event_type = 'ImportReplyTemplatesClicked';
}

export class LoyaltyTabViewed implements BaseEvent {
  event_type = 'LoyaltyTabViewed';

  constructor(
    public event_properties: LoyaltyTabViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewReviewsTabViewed implements BaseEvent {
  event_type = 'NewReviewsTabViewed';
}

export class OnboardingActionClicked implements BaseEvent {
  event_type = 'OnboardingActionClicked';

  constructor(
    public event_properties: OnboardingActionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenAiReplySuggested implements BaseEvent {
  event_type = 'OpenAiReplySuggested';

  constructor(
    public event_properties: OpenAiReplySuggestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PwaAppInstalled implements BaseEvent {
  event_type = 'PWAAppInstalled';
}

export class ReferralCampaignClicked implements BaseEvent {
  event_type = 'ReferralCampaignClicked';

  constructor(
    public event_properties?: ReferralCampaignClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReplyTemplateCreated implements BaseEvent {
  event_type = 'ReplyTemplateCreated';

  constructor(
    public event_properties: ReplyTemplateCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReplyTemplateDuplicated implements BaseEvent {
  event_type = 'ReplyTemplateDuplicated';

  constructor(
    public event_properties: ReplyTemplateDuplicatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReplyTemplateEdited implements BaseEvent {
  event_type = 'ReplyTemplateEdited';

  constructor(
    public event_properties: ReplyTemplateEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RevenuesViewed implements BaseEvent {
  event_type = 'RevenuesViewed';
}

export class ReviewReplyTemplateChosen implements BaseEvent {
  event_type = 'ReviewReplyTemplateChosen';

  constructor(
    public event_properties: ReviewReplyTemplateChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReviewsCsvExportButtonClicked implements BaseEvent {
  event_type = 'ReviewsCsvExportButtonClicked';

  constructor(
    public event_properties?: ReviewsCsvExportButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReviewsDateShortcutFilterClicked implements BaseEvent {
  event_type = 'ReviewsDateShortcutFilterClicked';

  constructor(
    public event_properties: ReviewsDateShortcutFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReviewsHintClicked implements BaseEvent {
  event_type = 'ReviewsHintClicked';
}

export class ReviewsInitializationStarted implements BaseEvent {
  event_type = 'ReviewsInitializationStarted';

  constructor(
    public event_properties: ReviewsInitializationStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReviewsPlatformShortcutFilterClicked implements BaseEvent {
  event_type = 'ReviewsPlatformShortcutFilterClicked';

  constructor(
    public event_properties: ReviewsPlatformShortcutFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReviewsReplyTimeframeSelected implements BaseEvent {
  event_type = 'ReviewsReplyTimeframeSelected';

  constructor(
    public event_properties: ReviewsReplyTimeframeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReviewsWithRatingShortcutFilterClicked implements BaseEvent {
  event_type = 'ReviewsWithRatingShortcutFilterClicked';

  constructor(
    public event_properties: ReviewsWithRatingShortcutFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReviewsWithWaiterShortcutFilterClicked implements BaseEvent {
  event_type = 'ReviewsWithWaiterShortcutFilterClicked';

  constructor(
    public event_properties: ReviewsWithWaiterShortcutFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReviewsWithWithoutFeedbackShortcutFilterClicked implements BaseEvent {
  event_type = 'ReviewsWithWithoutFeedbackShortcutFilterClicked';

  constructor(
    public event_properties: ReviewsWithWithoutFeedbackShortcutFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReviewsWithWithoutReviewReplyShortcutFilterClicked implements BaseEvent {
  event_type = 'ReviewsWithWithoutReviewReplyShortcutFilterClicked';

  constructor(
    public event_properties: ReviewsWithWithoutReviewReplyShortcutFilterClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StoreClicked implements BaseEvent {
  event_type = 'StoreClicked';

  constructor(
    public event_properties: StoreClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SundayContactUsFormSubmitted implements BaseEvent {
  event_type = 'Sunday+ContactUsFormSubmitted';

  constructor(
    public event_properties: SundayContactUsFormSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SundayPlusContactFormDisplayed implements BaseEvent {
  event_type = 'SundayPlusContactFormDisplayed';

  constructor(
    public event_properties: SundayPlusContactFormDisplayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SundayPlusContactUsFormSubmitted implements BaseEvent {
  event_type = 'SundayPlusContactUsFormSubmitted';

  constructor(
    public event_properties: SundayPlusContactUsFormSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SundayReviewReplied implements BaseEvent {
  event_type = 'SundayReviewReplied';

  constructor(
    public event_properties: SundayReviewRepliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserLoggedIn implements BaseEvent {
  event_type = 'UserLoggedIn';

  constructor(
    public event_properties?: UserLoggedInProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewBillClicked implements BaseEvent {
  event_type = 'ViewBillClicked';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * analytics_home_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/analytics_home_viewed)
   *
   * This event tracks when a user views the home page of the analytics pages.
   *
   * @param options Amplitude event options.
   */
  analyticsHomeViewed(
    options?: EventOptions,
  ) {
    return this.track(new AnalyticsHomeViewed(), options);
  }

  /**
   * BrigadLinkClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/BrigadLinkClicked)
   *
   * Owner: Thibaud Sanchez
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  brigadLinkClicked(
    properties: BrigadLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BrigadLinkClicked(properties), options);
  }

  /**
   * BulkReplyCancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/BulkReplyCancelled)
   *
   * Owner: Stéphane Pailha
   *
   * @param options Amplitude event options.
   */
  bulkReplyCancelled(
    options?: EventOptions,
  ) {
    return this.track(new BulkReplyCancelled(), options);
  }

  /**
   * BulkReplyClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/BulkReplyClicked)
   *
   * Owner: dorian.bussi@sundayapp.com
   *
   * @param properties The event's properties (e.g. numberOfDays)
   * @param options Amplitude event options.
   */
  bulkReplyClicked(
    properties: BulkReplyClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BulkReplyClicked(properties), options);
  }

  /**
   * BulkReplyCompleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/BulkReplyCompleted)
   *
   * Owner: dorian.bussi@sundayapp.com
   *
   * @param properties The event's properties (e.g. numberOfRepliesInError)
   * @param options Amplitude event options.
   */
  bulkReplyCompleted(
    properties: BulkReplyCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BulkReplyCompleted(properties), options);
  }

  /**
   * BulkReplyOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/BulkReplyOpened)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. numberOf5starsReviews)
   * @param options Amplitude event options.
   */
  bulkReplyOpened(
    properties: BulkReplyOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BulkReplyOpened(properties), options);
  }

  /**
   * ChargbeeBannerClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ChargbeeBannerClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  chargbeeBannerClicked(
    properties?: ChargbeeBannerClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChargbeeBannerClicked(properties), options);
  }

  /**
   * ChargbeeBannerViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ChargbeeBannerViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  chargbeeBannerViewed(
    properties?: ChargbeeBannerViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChargbeeBannerViewed(properties), options);
  }

  /**
   * ChargbeeConfigureClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ChargbeeConfigureClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  chargbeeConfigureClicked(
    properties?: ChargbeeConfigureClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChargbeeConfigureClicked(properties), options);
  }

  /**
   * ConfigurationReplicationApplied
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ConfigurationReplicationApplied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. venueCountryCode)
   * @param options Amplitude event options.
   */
  configurationReplicationApplied(
    properties?: ConfigurationReplicationAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfigurationReplicationApplied(properties), options);
  }

  /**
   * ConfigurationReplicationChangesSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ConfigurationReplicationChangesSelected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. numberOfReplicationConfigurationChanges)
   * @param options Amplitude event options.
   */
  configurationReplicationChangesSelected(
    properties: ConfigurationReplicationChangesSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfigurationReplicationChangesSelected(properties), options);
  }

  /**
   * ConfigurationReplicationSourceSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ConfigurationReplicationSourceSelected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. venueCountryCode)
   * @param options Amplitude event options.
   */
  configurationReplicationSourceSelected(
    properties?: ConfigurationReplicationSourceSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfigurationReplicationSourceSelected(properties), options);
  }

  /**
   * ConfigurationReplicationStarted
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ConfigurationReplicationStarted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. venueCountryCode)
   * @param options Amplitude event options.
   */
  configurationReplicationStarted(
    properties?: ConfigurationReplicationStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfigurationReplicationStarted(properties), options);
  }

  /**
   * ConfigurationReplicationTargetSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ConfigurationReplicationTargetSelected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. numberOfReplicationConfigurationTargets)
   * @param options Amplitude event options.
   */
  configurationReplicationTargetSelected(
    properties: ConfigurationReplicationTargetSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfigurationReplicationTargetSelected(properties), options);
  }

  /**
   * ConnectGoogleAccountClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ConnectGoogleAccountClicked)
   *
   * Owner: dorian.bussi@sundayapp.com
   *
   * @param options Amplitude event options.
   */
  connectGoogleAccountClicked(
    options?: EventOptions,
  ) {
    return this.track(new ConnectGoogleAccountClicked(), options);
  }

  /**
   * CreateReplyTemplateClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/CreateReplyTemplateClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param options Amplitude event options.
   */
  createReplyTemplateClicked(
    options?: EventOptions,
  ) {
    return this.track(new CreateReplyTemplateClicked(), options);
  }

  /**
   * CustomerDataCsvExportButtonClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/CustomerDataCsvExportButtonClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. businessCountry)
   * @param options Amplitude event options.
   */
  customerDataCsvExportButtonClicked(
    properties?: CustomerDataCsvExportButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomerDataCsvExportButtonClicked(properties), options);
  }

  /**
   * DataAnalyticsBusinessChanged
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/DataAnalyticsBusinessChanged)
   *
   * This event is triggered when there is a change in the search bar at the top of all analytics screen
   *
   * @param properties The event's properties (e.g. businessesIds)
   * @param options Amplitude event options.
   */
  dataAnalyticsBusinessChanged(
    properties: DataAnalyticsBusinessChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataAnalyticsBusinessChanged(properties), options);
  }

  /**
   * DataAnalyticsBusinessPerformanceClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/DataAnalyticsBusinessPerformanceClicked)
   *
   * This event tracks when a user clicks on the "Business Performance" section in the data analytics dashboard
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  dataAnalyticsBusinessPerformanceClicked(
    properties: DataAnalyticsBusinessPerformanceClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataAnalyticsBusinessPerformanceClicked(properties), options);
  }

  /**
   * DataAnalyticsCustomerInsightsClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/DataAnalyticsCustomerInsightsClicked)
   *
   * This event tracks when a customer clicks on a data analytics customer insights feature
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  dataAnalyticsCustomerInsightsClicked(
    properties: DataAnalyticsCustomerInsightsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataAnalyticsCustomerInsightsClicked(properties), options);
  }

  /**
   * DataAnalyticsOverviewClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/DataAnalyticsOverviewClicked)
   *
   * **This event tracks when a user clicks on the overview section of the data analytics dashboard**
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  dataAnalyticsOverviewClicked(
    properties: DataAnalyticsOverviewClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataAnalyticsOverviewClicked(properties), options);
  }

  /**
   * DataAnalyticsRepeatKpis
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/DataAnalyticsRepeatKpis)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  dataAnalyticsRepeatKpis(
    properties?: DataAnalyticsRepeatKpisProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataAnalyticsRepeatKpis(properties), options);
  }

  /**
   * DataAnalyticsTipsClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/DataAnalyticsTipsClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  dataAnalyticsTipsClicked(
    properties?: DataAnalyticsTipsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataAnalyticsTipsClicked(properties), options);
  }

  /**
   * DeleteReplyTemplateClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/DeleteReplyTemplateClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. templateId)
   * @param options Amplitude event options.
   */
  deleteReplyTemplateClicked(
    properties: DeleteReplyTemplateClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteReplyTemplateClicked(properties), options);
  }

  /**
   * DimensionSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/DimensionSelected)
   *
   * Owner: dorian.bussi@sundayapp.com
   *
   * @param properties The event's properties (e.g. dimensionName)
   * @param options Amplitude event options.
   */
  dimensionSelected(
    properties: DimensionSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DimensionSelected(properties), options);
  }

  /**
   * DuplicateReplyTemplateClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/DuplicateReplyTemplateClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. templateId)
   * @param options Amplitude event options.
   */
  duplicateReplyTemplateClicked(
    properties: DuplicateReplyTemplateClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DuplicateReplyTemplateClicked(properties), options);
  }

  /**
   * E-reputationGraphSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/E-reputationGraphSelected)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. graphSelected)
   * @param options Amplitude event options.
   */
  eReputationGraphSelected(
    properties: EReputationGraphSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EReputationGraphSelected(properties), options);
  }

  /**
   * EditReplyTemplateClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/EditReplyTemplateClicked)
   *
   * Owner: dorian.bussi@sundayapp.com
   *
   * @param properties The event's properties (e.g. templateId)
   * @param options Amplitude event options.
   */
  editReplyTemplateClicked(
    properties: EditReplyTemplateClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditReplyTemplateClicked(properties), options);
  }

  /**
   * ExportReplyTemplatesClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ExportReplyTemplatesClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param options Amplitude event options.
   */
  exportReplyTemplatesClicked(
    options?: EventOptions,
  ) {
    return this.track(new ExportReplyTemplatesClicked(), options);
  }

  /**
   * FromGuestExperienceToFeedback
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/FromGuestExperienceToFeedback)
   *
   * On guest experience page, there is a link below the first graph. This link redirects to feedbacks page with url parameters that match the filters on guest experience page (timeframe, bad reviews...)
   *
   * @param properties The event's properties (e.g. dimension)
   * @param options Amplitude event options.
   */
  fromGuestExperienceToFeedback(
    properties: FromGuestExperienceToFeedbackProperties,
    options?: EventOptions,
  ) {
    return this.track(new FromGuestExperienceToFeedback(properties), options);
  }

  /**
   * GoogleReviewReplied
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/GoogleReviewReplied)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. ambianceRating)
   * @param options Amplitude event options.
   */
  googleReviewReplied(
    properties: GoogleReviewRepliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GoogleReviewReplied(properties), options);
  }

  /**
   * GoToNewReplyPageFromPromotionBannerClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/GoToNewReplyPageFromPromotionBannerClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param options Amplitude event options.
   */
  goToNewReplyPageFromPromotionBannerClicked(
    options?: EventOptions,
  ) {
    return this.track(new GoToNewReplyPageFromPromotionBannerClicked(), options);
  }

  /**
   * GuestExperienceDateRangeFilterChanged
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/GuestExperienceDateRangeFilterChanged)
   *
   * Owner: Bastien David
   *
   * @param properties The event's properties (e.g. daysSinceToday)
   * @param options Amplitude event options.
   */
  guestExperienceDateRangeFilterChanged(
    properties: GuestExperienceDateRangeFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GuestExperienceDateRangeFilterChanged(properties), options);
  }

  /**
   * GuideAndTrainingClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/GuideAndTrainingClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. venueId)
   * @param options Amplitude event options.
   */
  guideAndTrainingClicked(
    properties?: GuideAndTrainingClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GuideAndTrainingClicked(properties), options);
  }

  /**
   * ImportReplyTemplatesClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ImportReplyTemplatesClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param options Amplitude event options.
   */
  importReplyTemplatesClicked(
    options?: EventOptions,
  ) {
    return this.track(new ImportReplyTemplatesClicked(), options);
  }

  /**
   * LoyaltyTabViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/LoyaltyTabViewed)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. activeRewardProgram)
   * @param options Amplitude event options.
   */
  loyaltyTabViewed(
    properties: LoyaltyTabViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoyaltyTabViewed(properties), options);
  }

  /**
   * NewReviewsTabViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/NewReviewsTabViewed)
   *
   * Owner: Stéphane Pailha
   *
   * @param options Amplitude event options.
   */
  newReviewsTabViewed(
    options?: EventOptions,
  ) {
    return this.track(new NewReviewsTabViewed(), options);
  }

  /**
   * OnboardingActionClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/OnboardingActionClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  onboardingActionClicked(
    properties: OnboardingActionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingActionClicked(properties), options);
  }

  /**
   * OpenAiReplySuggested
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/OpenAiReplySuggested)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. suggestedReply)
   * @param options Amplitude event options.
   */
  openAiReplySuggested(
    properties: OpenAiReplySuggestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenAiReplySuggested(properties), options);
  }

  /**
   * PWAAppInstalled
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/PWAAppInstalled)
   *
   * Owner: Sébastien Chemin
   *
   * @param options Amplitude event options.
   */
  pwaAppInstalled(
    options?: EventOptions,
  ) {
    return this.track(new PwaAppInstalled(), options);
  }

  /**
   * ReferralCampaignClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReferralCampaignClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessId)
   * @param options Amplitude event options.
   */
  referralCampaignClicked(
    properties?: ReferralCampaignClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReferralCampaignClicked(properties), options);
  }

  /**
   * ReplyTemplateCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReplyTemplateCreated)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. templateName)
   * @param options Amplitude event options.
   */
  replyTemplateCreated(
    properties: ReplyTemplateCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReplyTemplateCreated(properties), options);
  }

  /**
   * ReplyTemplateDuplicated
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReplyTemplateDuplicated)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. templateName)
   * @param options Amplitude event options.
   */
  replyTemplateDuplicated(
    properties: ReplyTemplateDuplicatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReplyTemplateDuplicated(properties), options);
  }

  /**
   * ReplyTemplateEdited
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReplyTemplateEdited)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. templateId)
   * @param options Amplitude event options.
   */
  replyTemplateEdited(
    properties: ReplyTemplateEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReplyTemplateEdited(properties), options);
  }

  /**
   * RevenuesViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/RevenuesViewed)
   *
   * Owner: Sébastien Chemin
   *
   * @param options Amplitude event options.
   */
  revenuesViewed(
    options?: EventOptions,
  ) {
    return this.track(new RevenuesViewed(), options);
  }

  /**
   * ReviewReplyTemplateChosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewReplyTemplateChosen)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. templateId)
   * @param options Amplitude event options.
   */
  reviewReplyTemplateChosen(
    properties: ReviewReplyTemplateChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewReplyTemplateChosen(properties), options);
  }

  /**
   * ReviewsCsvExportButtonClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsCsvExportButtonClicked)
   *
   * Event fired when a user exports the reviews of a venue in CSV format
   *
   * Owner: dorian.bussi@sundayapp.com
   *
   * @param properties The event's properties (e.g. venueCountryCode)
   * @param options Amplitude event options.
   */
  reviewsCsvExportButtonClicked(
    properties?: ReviewsCsvExportButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewsCsvExportButtonClicked(properties), options);
  }

  /**
   * ReviewsDateShortcutFilterClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsDateShortcutFilterClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. endDate)
   * @param options Amplitude event options.
   */
  reviewsDateShortcutFilterClicked(
    properties: ReviewsDateShortcutFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewsDateShortcutFilterClicked(properties), options);
  }

  /**
   * ReviewsHintClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsHintClicked)
   *
   * Owner: dorian.bussi@sundayapp.com
   *
   * @param options Amplitude event options.
   */
  reviewsHintClicked(
    options?: EventOptions,
  ) {
    return this.track(new ReviewsHintClicked(), options);
  }

  /**
   * ReviewsInitializationStarted
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsInitializationStarted)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. platform)
   * @param options Amplitude event options.
   */
  reviewsInitializationStarted(
    properties: ReviewsInitializationStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewsInitializationStarted(properties), options);
  }

  /**
   * ReviewsPlatformShortcutFilterClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsPlatformShortcutFilterClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. platform)
   * @param options Amplitude event options.
   */
  reviewsPlatformShortcutFilterClicked(
    properties: ReviewsPlatformShortcutFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewsPlatformShortcutFilterClicked(properties), options);
  }

  /**
   * ReviewsReplyTimeframeSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsReplyTimeframeSelected)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. period)
   * @param options Amplitude event options.
   */
  reviewsReplyTimeframeSelected(
    properties: ReviewsReplyTimeframeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewsReplyTimeframeSelected(properties), options);
  }

  /**
   * ReviewsWithRatingShortcutFilterClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsWithRatingShortcutFilterClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. rating)
   * @param options Amplitude event options.
   */
  reviewsWithRatingShortcutFilterClicked(
    properties: ReviewsWithRatingShortcutFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewsWithRatingShortcutFilterClicked(properties), options);
  }

  /**
   * ReviewsWithWaiterShortcutFilterClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsWithWaiterShortcutFilterClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. waiter)
   * @param options Amplitude event options.
   */
  reviewsWithWaiterShortcutFilterClicked(
    properties: ReviewsWithWaiterShortcutFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewsWithWaiterShortcutFilterClicked(properties), options);
  }

  /**
   * ReviewsWithWithoutFeedbackShortcutFilterClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsWithWithoutFeedbackShortcutFilterClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. feedback)
   * @param options Amplitude event options.
   */
  reviewsWithWithoutFeedbackShortcutFilterClicked(
    properties: ReviewsWithWithoutFeedbackShortcutFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewsWithWithoutFeedbackShortcutFilterClicked(properties), options);
  }

  /**
   * ReviewsWithWithoutReviewReplyShortcutFilterClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ReviewsWithWithoutReviewReplyShortcutFilterClicked)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. reply)
   * @param options Amplitude event options.
   */
  reviewsWithWithoutReviewReplyShortcutFilterClicked(
    properties: ReviewsWithWithoutReviewReplyShortcutFilterClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewsWithWithoutReviewReplyShortcutFilterClicked(properties), options);
  }

  /**
   * StoreClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/StoreClicked)
   *
   * Is google or apple store button clicked?
   *
   * @param properties The event's properties (e.g. storeName)
   * @param options Amplitude event options.
   */
  storeClicked(
    properties: StoreClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StoreClicked(properties), options);
  }

  /**
   * Sunday+ContactUsFormSubmitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/Sunday%2BContactUsFormSubmitted)
   *
   * event fired when a user send a message from the contact form
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  sundayContactUsFormSubmitted(
    properties: SundayContactUsFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SundayContactUsFormSubmitted(properties), options);
  }

  /**
   * SundayPlusContactFormDisplayed
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/SundayPlusContactFormDisplayed)
   *
   * Owner: dorian.bussi@sundayapp.com
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  sundayPlusContactFormDisplayed(
    properties: SundayPlusContactFormDisplayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SundayPlusContactFormDisplayed(properties), options);
  }

  /**
   * SundayPlusContactUsFormSubmitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/SundayPlusContactUsFormSubmitted)
   *
   * Owner: dorian.bussi@sundayapp.com
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  sundayPlusContactUsFormSubmitted(
    properties: SundayPlusContactUsFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SundayPlusContactUsFormSubmitted(properties), options);
  }

  /**
   * SundayReviewReplied
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/SundayReviewReplied)
   *
   * Owner: Stéphane Pailha
   *
   * @param properties The event's properties (e.g. ambianceRating)
   * @param options Amplitude event options.
   */
  sundayReviewReplied(
    properties: SundayReviewRepliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SundayReviewReplied(properties), options);
  }

  /**
   * UserLoggedIn
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/UserLoggedIn)
   *
   * Triggered when a user enter in a vnue in the merchant front end
   *
   * Owner: Yoann Meste
   *
   * @param properties The event's properties (e.g. venueId)
   * @param options Amplitude event options.
   */
  userLoggedIn(
    properties?: UserLoggedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserLoggedIn(properties), options);
  }

  /**
   * ViewBillClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/sundayapp/B2B%20Dash%20and%20Payment%20Terminal/events/main/latest/ViewBillClicked)
   *
   * Owner: adriana.nitescu@sundayapp.com
   *
   * @param options Amplitude event options.
   */
  viewBillClicked(
    options?: EventOptions,
  ) {
    return this.track(new ViewBillClicked(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
