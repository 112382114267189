import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const AmountToDispatch = ({ amount }: { amount: string }) => {
  return <Stack spacing={0.75} direction={'column'}>
    <Typography variant={'h6'}>
      <FormattedMessage id={'tips.sunday_pooling.dispatch.amount'} />
    </Typography>
    <Typography variant={'h4'}>{amount}</Typography>
  </Stack>;
};
