import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PageContainer } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/PageContainer';
import MenuSchedulePanel from '../../components/SchedulePanel/MenuSchedulePanel';
import MenuHeader from './components/MenuHeader';
import { Categories } from './components/Categories';
import MenuDeletionBtn from './components/MenuDeletionBtn';
import { MenuImagePicker } from './components/MenuImagePicker';
import { MenuDeletionBtnWrapper } from './DynamicMenuDetailsPage.styles';
import MenuDetailsPanel from './components/MenuDetailsPanel';
import { useGetMenuById } from '../../queries/menu/getMenuByIdQuery';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import {
  MenuProductPriceOverrides,
} from 'src/menu/dynamic-menu/pages/MenuDetailsPage/components/MenuProductPriceOverrides';

// eslint-disable-next-line complexity
export const DynamicMenuDetailsPage = () => {
  const business = useCurrentBusinessOrThrow();
  const { menuId } = useParams();
  const { data: menu } = useGetMenuById(business.id, menuId!);

  return (
    <PageContainer>
      {menu && menuId && (
        <>
          <MenuHeader businessId={business.id} menu={menu} />
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={6}>
              <MenuDetailsPanel menu={menu} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MenuSchedulePanel menu={menu} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MenuImagePicker menu={menu} />
            </Grid>
          </Grid>

        </>
      )}
      {menu && <Categories menu={menu} businessId={business.id} />}

      {menu && menu.id && <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={6}>
          <MenuProductPriceOverrides businessId={business.id} menu={menu} />
        </Grid>
      </Grid>}

      {menuId && menu?.sundayMenu && (
        <MenuDeletionBtnWrapper>
          <MenuDeletionBtn menuId={menuId} businessId={business.id} menuName={menu.name} />
        </MenuDeletionBtnWrapper>
      )}
    </PageContainer>
  );
};
