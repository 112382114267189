import axios from 'axios';
import { BusinessId } from 'src/business/domain/Business';
import { Task } from 'src/menu/common/domain/Task';
import { BusinessDtoConverter } from 'src/menu/common/repositories/BusinessConverter';
import { TaskDto, toTask } from 'src/menu/common/repositories/TaskDto';
import { RepositoryHttp } from 'src/ordering/common/repository/RepositoryHttp';
import { v4 as uuidv4 } from 'uuid';
import { ExposedMenuType } from '../domain/ExposedMenuType';
import { MenuBusiness } from '../domain/MenuBusiness';
import { AddLanguageDto } from './AddLanguageDto';
import { CreateOrUpdateMenuBusinessDto } from './CreateOrUpdateMenuBusinessDto';
import { MenuBusinessDto } from './MenuBusinessDto';
import { MenuBusinessRepository } from './MenuBusinessRepository';
import { UpdateMenuBusinessDto } from './UpdateMenuBusinessDto';

type BusinessExposedMenuTypeDto = {
  exposedMenuType: string;
};

export class MenuBusinessRepositoryHttp extends RepositoryHttp implements MenuBusinessRepository {
  constructor(private menuBackendUrl: string) {
    super();
  }

  async getBusiness(businessId: BusinessId): Promise<MenuBusiness | null> {
    const response = await axios.get<MenuBusinessDto>(`${this.menuBackendUrl}/businesses/${businessId}`,
      { validateStatus: (status) => status === 200 || status === 404 });

    if (response.status === 404) {
      return null;
    }
    return BusinessDtoConverter.toBusiness(response.data);
  }

  async createOrUpdateBusiness(businessId: BusinessId, venue: CreateOrUpdateMenuBusinessDto): Promise<void> {
    await axios.post(`${this.menuBackendUrl}/businesses/${businessId}`, venue);
  }

  async updateBusiness(businessId: BusinessId, venue: UpdateMenuBusinessDto): Promise<void> {
    await axios.patch(`${this.menuBackendUrl}/businesses/${businessId}`, venue);
  }

  async getBusinessExposedMenuType(businessId: BusinessId): Promise<ExposedMenuType> {
    const venueResponse = await axios.get<BusinessExposedMenuTypeDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/exposed-menu-type`,
    );
    if (venueResponse.data.exposedMenuType === ExposedMenuType.DYNAMIC_MENU) {
      return ExposedMenuType.DYNAMIC_MENU;
    }
    return ExposedMenuType.STATIC_MENU;
  }

  async addLanguage(businessId: BusinessId, language: AddLanguageDto): Promise<Task> {
    const task = await axios.post<TaskDto>(`${this.menuBackendUrl}/businesses/${businessId}/translate?async=true`, language, {
      withCredentials: true,
      headers: {
        correlation_id: uuidv4(),
      },
    });

    return toTask(task.data);
  }

  async removeLanguage(businessId: BusinessId, language: string): Promise<void> {
    await axios.delete(`${this.menuBackendUrl}/businesses/${businessId}/languages/${language}`, {
      withCredentials: true,
      headers: {
        correlation_id: uuidv4(),
      },
    });
  }

  async getTask(taskId: string): Promise<Task> {
    const task = await axios.get<TaskDto>(`${this.menuBackendUrl}/tasks/${taskId}`);
    return toTask(task.data);
  }
}
