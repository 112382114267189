import { groupBy } from 'lodash';
import {
  differenceMoney, Money, money, sumMoneys,
} from '@sundayapp/web-money';
import { OrderForAccountingPort } from '../domain/OrderForAccountingPort';
import { OrderForAccounting } from '../domain/OrderForAccounting';
import { ReportingDigestByWaiter } from '../domain/ReportingDigestByWaiter';
import { AccountingRepository } from '../infrastructure/AccountingRepository';
import { PaymentOverSunday } from 'src/bills/domain/orders/types';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { Business, BusinessId } from 'src/business/domain/Business';

const refundAmount = (payment: PaymentOverSunday) =>
  Object.values(payment.refunds ?? {}).reduce(
    (total, refund) => sumMoneys(total, refund.amount),
    money(0, payment.orderAmount.currency),
  );

type MoneyFlow = {
  refunded: Money;
  paid: Money;
};

export type RefundDetail = {
  sales: MoneyFlow;
  tips: MoneyFlow;
  digitalFee: MoneyFlow;
};

export const refundDetail = (payment: PaymentOverSunday & { digitalFeeAmount: Money }): RefundDetail => {
  const refundedAmount = refundAmount(payment);
  const { currency } = payment.orderAmount;

  // order amount
  const refundedAmountMinusOrderAmount = differenceMoney(refundedAmount, payment.orderAmount);
  if (refundedAmountMinusOrderAmount.amount <= 0) {
    return {
      sales: {
        refunded: refundedAmount,
        paid: differenceMoney(payment.orderAmount, refundedAmount),
      },
      tips: {
        refunded: money(0, currency),
        paid: payment.tipsAmount,
      },
      digitalFee: {
        refunded: money(0, currency),
        paid: payment.digitalFeeAmount,
      },
    };
  }

  // tips
  const refundedAmountMinusTipsAmount = differenceMoney(refundedAmountMinusOrderAmount, payment.tipsAmount);
  if (refundedAmountMinusTipsAmount.amount <= 0) {
    return {
      sales: {
        refunded: payment.orderAmount,
        paid: money(0, currency),
      },
      tips: {
        refunded: refundedAmountMinusOrderAmount,
        paid: differenceMoney(payment.tipsAmount, refundedAmountMinusOrderAmount),
      },
      digitalFee: {
        refunded: money(0, currency),
        paid: payment.digitalFeeAmount,
      },
    };
  }

  // digital fee
  const refundedAmountMinusDigitalFeeAmount = differenceMoney(refundedAmountMinusTipsAmount, payment.tipsAmount);
  if (refundedAmountMinusDigitalFeeAmount.amount <= 0) {
    return {
      sales: {
        refunded: payment.orderAmount,
        paid: money(0, currency),
      },
      tips: {
        refunded: payment.tipsAmount,
        paid: money(0, currency),
      },
      digitalFee: {
        refunded: refundedAmountMinusTipsAmount,
        paid: differenceMoney(payment.digitalFeeAmount, refundedAmountMinusTipsAmount),
      },
    };
  }

  return {
    sales: {
      refunded: payment.orderAmount,
      paid: money(0, currency),
    },
    tips: {
      refunded: payment.tipsAmount,
      paid: money(0, currency),
    },
    digitalFee: {
      refunded: payment.digitalFeeAmount,
      paid: money(0, currency),
    },
  };
};

export class ReportingDigestByWaiterUseCase {
  constructor(
    private business: Business,
    private orderForAccountingPort: OrderForAccountingPort,
    private accountingRepository: AccountingRepository,
    private digitalFeeCompute: (orderAmount: Money, serviceCharge?: number) => Money,
  ) {
  }

  async handle(businessId: BusinessId, startDate: Date, endDate: Date): Promise<ReportingDigestByWaiter[]> {
    const startDateMinus6Hours = new Date(startDate.getTime() - 1000 * 60 * 60 * 6);

    const summary = await this.accountingRepository.summaryOnAPeriod(businessId, startDate, endDate);
    const orders = await this.orderForAccountingPort.findOrders(
      businessId,
      startDateMinus6Hours,
      endDate,
      this.business.currency,
    );
    const paymentIds = Object.entries(summary.detailsBySource).flatMap(([, details]) => details.paymentIds);
    const ordersByWaiter = groupBy(orders, (order: OrderForAccounting) => order.waiter);
    return Object.entries(ordersByWaiter).map(([waiter, orderForAccounting]) => {
      const paymentsForWaiter = orderForAccounting.flatMap((order: OrderForAccounting) =>
        order.paymentsOverSunday.filter((payment) => paymentIds.includes(payment.id)));
      return {
        waiter,
        grossRevenue: this.getGrossRevenue(paymentsForWaiter),
        tipsRevenue: this.getTips(paymentsForWaiter),
        serviceChargeRevenue: this.getServiceCharge(paymentsForWaiter),
      };
    });
  }

  private orderAmountWithDeducedRefunds = (payment: PaymentOverSunday) => {
    const paidAmountHavingDeducedRefunds = differenceMoney(payment.orderAmount, refundAmount(payment));
    return money(Math.max(0, paidAmountHavingDeducedRefunds.amount), payment.orderAmount.currency);
  };

  private tipsAmountWithDeduceRefunds = (payment: PaymentOverSunday) => {
    const serviceChargeRate = payment.serviceChargeAmount.amount / payment.orderAmount.amount;
    const digitalFeeAmount = this.digitalFeeCompute(payment.orderAmount, serviceChargeRate);

    const refund = refundDetail({ ...payment, digitalFeeAmount });

    return refund.tips.paid;
  };

  getGrossRevenue(payments: PaymentOverSunday[]): Money {
    return safeSumMoney(
      payments.map((p) => this.orderAmountWithDeducedRefunds(p)),
      this.business.currency,
    );
  }

  getTips(payments: PaymentOverSunday[]): Money {
    return safeSumMoney(
      payments.map((p) => this.tipsAmountWithDeduceRefunds(p)),
      this.business.currency,
    );
  }

  getServiceCharge(payments: PaymentOverSunday[]): Money {
    return safeSumMoney(
      payments.map((p) => p.serviceChargeAmount),
      this.business.currency,
    );
  }
}
