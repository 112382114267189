import { useFormContext } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { FirstName } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/FirstName';
import { LastName } from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/LastName';
import {
  DateOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/DateOfBirth';
import {
  CountryOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/CountryOfBirth';
import React, { useEffect } from 'react';
import {
  OwnershipPercentage,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/OwnershipPercentage';
import {
  RepresentativeAddress,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/RepresentativeAddress';
import Divider from '@mui/material/Divider';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import {
  CompanyPosition,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/KeyStakeholders/PersonalInfo/CompanyPosition';
import { UboExtraRoles } from './UboExtraRoles';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

const useRemoveFormFieldsOnChangeOfRepresentativeType = (representativeIndex: number, needsCompanyPosition: boolean) => {
  const { unregister } = useFormContext<CheckoutOnboardingInformationDraft>();

  useEffect(() => {
    if (!needsCompanyPosition) {
      unregister(`representatives.${representativeIndex}.companyPosition`);
    }
  }, [needsCompanyPosition]);
};

export const UboPersonalInfo = ({ needsCompanyPosition, representativeIndex }: {
  needsCompanyPosition: boolean,
  representativeIndex: number
}) => {
  useRemoveFormFieldsOnChangeOfRepresentativeType(representativeIndex, needsCompanyPosition);

  return <Stack spacing={3}>
    <Typography variant={'h6'}><FormattedMessage
      id={'settings.payments.checkout.onboarding.representatives.personalInformation'} /></Typography>
    <Stack spacing={3} direction={'row'}>
      <FirstName legalRepresentativeIndex={representativeIndex} />
      <LastName legalRepresentativeIndex={representativeIndex} />
    </Stack>
    <UboExtraRoles representativeIndex={representativeIndex} />
    <DateOfBirth legalRepresentativeIndex={representativeIndex} />
    <CountryOfBirth legalRepresentativeIndex={representativeIndex} />

    <RepresentativeAddress representativeIndex={representativeIndex} />
    <Stack spacing={3}>
      <Divider />
      <OwnershipPercentage representativeIndex={representativeIndex} />
    </Stack>

    {needsCompanyPosition && <Stack spacing={3}>
      <Divider />
      <CompanyPosition representativeIndex={representativeIndex} />
    </Stack>
    }
  </Stack>;
};
