import { styled } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { RewardProgram } from '../../domain/RewardProgram';
import { RewardProgramKpisHeader } from './RewardProgramKpisHeader';
import { RewardProgramKpisFilterSelector } from './RewardProgramKpisFilterSelector';
import { CustomerEngagementCard } from './CustomerEngagementCard';
import { BreakdownByVenueCard } from './BreakdownByVenueCard';
import {
  CustomerEngagement,
  CustomerEngagementByVenue,
  Filter,
  programLaunchDate,
  RewardProgramKpis,
} from '../../domain/RewardProgramKpis';
import { fromDomain } from './CustomerEngagementViewModel';
import { customerEngagementVariationViewModel, displayVariation } from './CustomerEngagementVariationViewModel';

const EngagementWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  > *+* {
    margin-top: 1.5rem;
  }
`;

export type RewardProgramKpisProps = {
  rewardProgram: RewardProgram,
  rewardProgramKpis: RewardProgramKpis,
};

export const RewardProgramKpisOverview = ({ rewardProgram, rewardProgramKpis }: RewardProgramKpisProps) => {
  const intl = useIntl();
  const [filter, setFilter] = useState<Filter>('last7Days');
  const [customerEngagement, setCustomerEngagement] = useState<CustomerEngagement>(rewardProgramKpis.customerEngagements.last7Days);
  const [customerEngagementByVenue, setCustomerEngagementByVenue] = useState<CustomerEngagementByVenue[]>(rewardProgramKpis.customerEngagementsByVenue.last7Days);

  const rewardProgramKpisByFilter = (newFilter: Filter) => {
    if (newFilter === 'allTime') {
      setCustomerEngagement(rewardProgramKpis.customerEngagements.allTime);
      setCustomerEngagementByVenue(rewardProgramKpis.customerEngagementsByVenue.allTime);
    } else if (newFilter === 'last7Days') {
      setCustomerEngagement(rewardProgramKpis.customerEngagements.last7Days);
      setCustomerEngagementByVenue(rewardProgramKpis.customerEngagementsByVenue.last7Days);
    }
  };

  const handleFilterChanged = (newFilter: Filter) => {
    setFilter(newFilter);
    rewardProgramKpisByFilter(newFilter);
  };

  return (
    <>
      <RewardProgramKpisHeader rewardProgram={rewardProgram} rewardProgramLaunchDate={programLaunchDate(rewardProgramKpis)} />
      <RewardProgramKpisFilterSelector
        initialFilter={filter}
        onFilterChanged={handleFilterChanged}
        dateRanges={rewardProgramKpis.customerEngagements.dateRanges}
      />
      <EngagementWrapper>
        <CustomerEngagementCard
          customerEngagement={fromDomain(customerEngagement, intl.locale)}
          variation={customerEngagementVariationViewModel(rewardProgramKpis.customerEngagements, intl.locale)}
          displayVariation={displayVariation(filter)}
        />
        <BreakdownByVenueCard customerEngagementByVenue={customerEngagementByVenue!} />
      </EngagementWrapper>
    </>
  );
};
