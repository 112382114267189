import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import 'moment-timezone';
import { IntlShape, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { MoneyView } from '@sundayapp/web-money';
import { LocalisationKey } from 'src/lang/en';
import { BusinessDate } from 'src/components/date/BusinessDate';
import { CustomerData } from 'src/marketing/customer-data/domain/CustomerData';
import { TableEmptyState } from 'src/components/table/TableEmptyState';
import { TableLoadingState } from 'src/components/table/TableLoadingState';
import { translation } from 'src/accounting/common/translation';

const GuestConsentDataGridColumnsDefinition = (intl: IntlShape): GridColDef<CustomerData>[] => {
  return [
    {
      align: 'left',
      field: 'optInDate',
      minWidth: 180,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.date' }),
      renderCell: ({ row }) => <BusinessDate timestampMillis={row.optInDate} />,
      sortable: false,
    },
    {
      align: 'left',
      field: 'firstName',
      minWidth: 200,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.firstName' }),
      sortable: false,
    },
    {
      align: 'left',
      field: 'lastName',
      minWidth: 200,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.lastName' }),
      sortable: false,
    },
    {
      align: 'left',
      field: 'email',
      minWidth: 200,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.email' }),
      sortable: false,
    },
    {
      align: 'left',
      field: 'phoneNumber',
      minWidth: 200,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.phoneNumber' }),
      sortable: false,
    },
    {
      align: 'left',
      field: 'birthDate',
      minWidth: 200,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.birthDate' }),
      sortable: false,
    },
    {
      align: 'left',
      field: 'waiterName',
      minWidth: 200,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.staff_name' }),
      sortable: false,
    },
    {
      align: 'left',
      field: 'tableNumber',
      minWidth: 100,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.table_id' }),
      sortable: false,
    },
    {
      align: 'left',
      field: 'totalAmount',
      minWidth: 100,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.total_amount' }),
      renderCell: ({ row: { totalAmount, currency } }: GridCellParams) =>
        totalAmount && currency && <MoneyView value={{ amount: totalAmount, currency }} />,
      sortable: false,
    },
    {
      align: 'left',
      field: 'individualSplitAmount',
      minWidth: 100,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.split_amount' }),
      renderCell: ({ row: { individualSplitAmount, currency } }: GridCellParams) =>
        individualSplitAmount && currency && <MoneyView value={{ amount: individualSplitAmount, currency }} />,
      sortable: false,
    },
    {
      align: 'left',
      field: 'optInLevel',
      minWidth: 200,
      headerAlign: 'left',
      headerName: intl.formatMessage({ id: 'customer_data.opt_in_level' }),
      sortable: false,
      renderCell: ({ row: { optInLevel } }: GridCellParams) => {
        return (
          <span>
            {intl.formatMessage({ id: `customer_data.opt_in_level.${optInLevel.toLowerCase()}` as LocalisationKey })}
          </span>
        );
      },
    },
  ];
};

interface GuestCOnsentDataGridProps {
  customerData?: CustomerData[];
  loading: boolean;
}

export const GuestConsentDataGrid = ({ customerData, loading }: GuestCOnsentDataGridProps) => {
  const intl = useIntl();

  return (
    <DataGrid
      initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
      slots={{
        loadingOverlay: () => <TableLoadingState
          title={translation('settings.consent.loading_state.title')}
        />,
        noRowsOverlay: () => <TableEmptyState
          title={translation('settings.consent.empty_state.title')}
        />,
      }}
      loading={loading}
      autoHeight
      columns={GuestConsentDataGridColumnsDefinition(intl)}
      disableColumnMenu
      sortingOrder={['asc', 'desc']}
      rows={customerData}
    />
  );
};

