import { Box, Button, CircularProgress, FormControl, InputLabel, Paper, Select, Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { RouteDefinitions } from '../app/RouteDefinitions';
import { useBusinessIdOrThrow } from '../business/hooks/useBusinessId';
import { ConfigurationLoader } from '../configuration/ConfigurationLoader';
import { usePongoSaveStore } from './mutations/usePongoSaveStore';
import { useListPongoStores } from './queries/useListPongoStores';
import { useGetBusinessLoyaltyReference } from './queries/useGetBusinessLoyaltyReference';
import { useSearchParams } from 'react-router-dom';
import { useAuthenticatePongoBusiness } from 'src/loyalty/queries/useAuthenticatePongoBusiness';
import { palette } from 'src/organization-report/design/palette';

export const LoyaltyOnboardingPongo = () => {
  const [searchParams] = useSearchParams();
  const businessId = useBusinessIdOrThrow();
  const saveStore = usePongoSaveStore(businessId);
  const { data: isAuthenticated, isLoading: authenticationIsLoading } = useAuthenticatePongoBusiness();
  const {
    data: stores,
    isLoading: storesAreLoading,
  } = useListPongoStores(isAuthenticated);
  const {
    data: loyaltyBusinessConfiguration,
    isLoading: loyaltyReferenceIsLoading,
  } = useGetBusinessLoyaltyReference(businessId);

  const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>();

  const configuration = ConfigurationLoader.load();
  const redirectUrl = `${configuration.callbackUrl}${RouteDefinitions.PongoLoyaltyOauthRedirect.path}`;

  const scopes = 'reward.read,reward.update,customer.create,order.create,customer.read,customer.update,checkin.create';
  const url = `${configuration.pongoBaseUrl}/oauth/form?client_id=${configuration.pongoClientId}&state=${businessId}&redirect_uri=${redirectUrl}&scope=${scopes}`;
  const code = searchParams.get('code');

  if (loyaltyReferenceIsLoading || storesAreLoading || authenticationIsLoading) {
    return <CircularProgress />;
  }

  return (
    <Paper style={{ borderRadius: '1.5rem', maxWidth: '1000px' }} elevation={0}>
      <Stack margin="24px">
        {loyaltyBusinessConfiguration && (
          <Box padding={'8px'}>
            <Typography>Vous êtes connecté à votre compte de
              fidélité {loyaltyBusinessConfiguration.loyaltyReference} 🎊</Typography>
          </Box>
        )}

        {!loyaltyBusinessConfiguration && !code && (
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = url;
            }}
          >
            Connectez votre compte Pongo
          </Button>
        )}

        {!loyaltyBusinessConfiguration && stores && stores.length > 0 && isAuthenticated && (
          <FormControl>
            <InputLabel id="selectStore">Sélectionnez le store</InputLabel>
            <Select
              labelId="selectStore"
              label="Select ton store"
              value={selectedStoreId}
              onChange={(e) => setSelectedStoreId(e.target.value)}
            >
              {stores.map((store) => (
                <MenuItem value={store.id} key={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>

            {selectedStoreId && (
              <Button variant="contained" onClick={() => saveStore.mutate(selectedStoreId)}>
                Choisir
              </Button>
            )}
          </FormControl>
        )}

        {code && !isAuthenticated! && (
          <Stack gap="16px">
            <Typography color={palette.status.danger}>
              Erreur lors de l'authentification à votre compte pongo
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                window.location.href = url;
              }}
            >
              Réessayer
            </Button>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};
