import { RestaurantShiftTimesOption, restaurantShiftTimesOptionTypes } from './RestaurantShiftTimesOption';

export type RestaurantShiftDateRange = {
  startAt: Date;
  endAt: Date;
};

const getEndAt = (day: Date, shiftOption: RestaurantShiftTimesOption) => {
  if (shiftOption.type === restaurantShiftTimesOptionTypes.ALL_SHIFTS) {
    return new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1, 4, 0, 0, 0);
  }
  return shiftOption.endAt.hour <= shiftOption.startAt.hour
    ? new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1, shiftOption.endAt.hour, shiftOption.endAt.minute, 0, 0)
    : new Date(day.getFullYear(), day.getMonth(), day.getDate(), shiftOption.endAt.hour, shiftOption.endAt.minute, 0, 0);
};

const getStartAt = (day: Date, shiftOption: RestaurantShiftTimesOption) => {
  if (shiftOption.type === restaurantShiftTimesOptionTypes.ALL_SHIFTS) {
    return new Date(day.getFullYear(), day.getMonth(), day.getDate(), 4, 0, 0, 0);
  }
  return new Date(day.getFullYear(), day.getMonth(), day.getDate(), shiftOption.startAt.hour, shiftOption.startAt.minute, 0, 0);

};

export const restaurantShiftDateRange = (shiftOption: RestaurantShiftTimesOption, day: Date): RestaurantShiftDateRange => ({
  startAt: getStartAt(day, shiftOption),
  endAt: getEndAt(day, shiftOption),
});
