import { CurrencyCode, Money } from '@sundayapp/web-money';
import { CardEntryMode } from './CardEntryMode';
import { ContratMonetique } from 'src/operations/end-of-service/model/ContratMonetique';
import { products, Products } from 'src/operations/end-of-service/model/products';

export interface Payments {
  payments: Payment[]
}

export interface Payment {
  id: string,
  waiterName?: string,
  provider: string,
  scheme: string,
  isTrd: boolean,
  salesAmount: Money,
  tipsAmount: Money,
  revenueCenter?: string,
  cardEntryMode?: CardEntryMode,
  serviceCharges?: Money,
  contratMonetique?: ContratMonetique,
  origin: Products,
}

export const isCB = (payment: Payment) => !payment.isTrd;
export const isTrd = (payment: Payment) => payment.isTrd;
export const isOnPdq = (payment: Payment) => payment.origin === products.PDQ;
export const zero = (currency: CurrencyCode) => {return { amount: 0, currency: currency };};
export const serviceChargesOrZero = (payment: Payment, currency: CurrencyCode) => payment.serviceCharges ?? zero(currency);
// This will go to the configuration once we have it modifiable on the UI
export const includeContratMonetique = (payments: Payment[], contrat: ContratMonetique) => payments.some(p => p.contratMonetique === contrat);
