import { TablesResponse } from './TablesResponse';
import { TableDevice } from '../domain/TableDevice';
import { Table } from '../domain/Table';

export class TablesResponseMapper {
  static toTableDevices(response: TablesResponse): TableDevice[] {
    return response.tables
      .filter((table) => table.table_device)
      .map((table) => ({
        id: table.table_device!.id,
        name: table.table_device!.name,
        shortLink: table.table_device!.short_link,
        number: table.pos_table?.number ?? '',
      }));
  }

  static toTables(response: TablesResponse): Table[] {
    return response.tables
      .filter((table) => table.pos_table)
      .map((table) => ({
        id: table.pos_table!.id,
        number: table.pos_table!.number,
        label: table.table_device?.name ?? '',
        area: table.pos_table!.area,
        shortLink: table.table_device?.short_link ?? '',
      }));
  }
}
