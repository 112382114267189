import { Button, FormControl, InputLabel, Select, Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {
  SelectSourceBusiness,
  selectSourceBusiness,
  SelectTargetBusinesses,
} from '../domain/ConfigurationReplicationState';
import { useState } from 'react';
import { useUserPayAtTableBusinesses } from 'src/data-analytics/hooks/useUserPayAtTableBusinesses';
import { Business, BusinessId, BusinessIdSchema, Timezone } from 'src/business/domain/Business';
import { CurrencyCode } from '@sundayapp/web-money';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ampli } from 'src/ampli';

const noBusiness: Business = {
  address: {
    countryCode: 'UNSPECIFIED',
  },
  currency: CurrencyCode.UNSPECIFIED,
  enrollments: [],
  id: BusinessIdSchema.parse(''),
  legalName: '',
  metadata: {},
  name: '',
  status: 'DISABLED',
  isInitialSetupCompleted: false,
  tags: [],
  timezone: '' as Timezone,
  oapEnrollment: undefined,
  patEnrollment: undefined,
  pdqEnrollment: undefined,
  posReference: undefined,
  smbEnrollment: undefined,
  createdAt: '',
};

export const SelectSourceBusinessStep = ({
  currentState,
  onNext,
}: {
  currentState: SelectSourceBusiness;
  onNext: (state: SelectTargetBusinesses) => void;
}) => {
  const payAtTableBusinesses = useUserPayAtTableBusinesses();
  const [selectedBusiness, setSelectedBusiness] = useState<Business>(noBusiness);

  const next = () => {
    ampli.configurationReplicationSourceSelected();
    onNext(selectSourceBusiness(currentState, selectedBusiness));
  };

  const selectBusinessOfId = (businessId: BusinessId) => {
    const selection = payAtTableBusinesses.find((business) => business.id === businessId);
    setSelectedBusiness(selection ?? noBusiness);
  };

  return (
    <Stack padding="1.5rem" gap="1rem">
      <Typography variant="h5">
        <FormattedMessage id="configuration_replication.select_source.title" />
      </Typography>
      <Typography>
        <FormattedMessage id="configuration_replication.select_source.details" />
      </Typography>
      <FormControl style={{ minWidth: '150px' }}>
        <InputLabel id="demo-simple-select-label">
          <FormattedMessage id="configuration_replication.select_source.source" />
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={<FormattedMessage id="configuration_replication.select_source.business" />}
          value={selectedBusiness.id}
          onChange={(event) => selectBusinessOfId(event.target.value as BusinessId)}
        >
          {payAtTableBusinesses.map((business) => (
            <MenuItem key={business.id} value={business.id}>
              {business.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack direction="row-reverse">
        <Button variant="contained" onClick={next} disabled={selectedBusiness === noBusiness}>
          <FormattedMessage id="configuration_replication.select_source.next" />
        </Button>
      </Stack>
    </Stack>
  );
};
