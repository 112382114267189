import { useIntl } from 'src/app/i18n/TypedIntl';
import { Chip, Stack } from '@mui/material';
import React from 'react';
import { isGoogle, isSunday, Review } from '../../../domain/Review';
import BlueGoogle from './BlueGoogle';
import BlueGoogleFromSunday from './BlueGoogleFromSunday';
import BlueSunday from './BlueSunday';

export function SundayOrigin() {
  const intl = useIntl();
  return <Chip color="primary" label={intl.formatMessage({ id: 'review.platform.google.origin_sunday' })} />;
}

export const PlatformLogo = ({
  review,
}: { review: Review }) => (
  <>
    {isGoogle(review) && review.comingFromSunday && (
    <Stack gap={1} direction="row" alignItems="center">
      <BlueGoogleFromSunday width={48} height={20} />
    </Stack>
    )}
    {isGoogle(review) && !review.comingFromSunday && (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        borderRadius: '40px',
        padding: '0px',
        backgroundColor: '#EBEDFB',
      }}
    >
      <BlueGoogle size={20} />
    </Stack>
    )}
    {isSunday(review) && <BlueSunday size={20} />}
  </>
);
