import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { FluctuationComponent } from './FluctuationComponent';
import { dimensionsPx } from '../design/dimensions';
import { palette } from '../design/palette';

/* eslint-disable-next-line complexity */
export const KpiString = ({
  title,
  titleImageName,
  value,
  valueType,
  valueFontSize = dimensionsPx.fonts.s,
  fluctuation,
  fluctuationType,
  additionalInformation,
  imagePath,
  titleColor,
  countryCode,
  color,
}: {
  title?: string;
  titleImageName?: string;
  value: string | null;
  valueType?: ' pts' | '%' | '';
  valueFontSize?: string;
  fluctuation: number | null;
  fluctuationType: ' pts' | '%' | '';
  additionalInformation?: string[];
  imagePath?: string;
  titleColor?: string;
  countryCode: string;
  color?: string;
}) => (
  <Stack display="flex" flexDirection="column">
    <Stack display="flex" flexDirection="row" alignItems="center">
      {titleImageName && (
        <Box
          style={{
            filter: `drop-shadow(0px 1000px 0 ${titleColor ?? color})`,
            transform: 'translateY(-1000px)',
          }}
        >
          <img
            src={`../../assets/venueKpi/organization/${titleImageName}.png`}
            height={dimensionsPx.line_height.s}
            alt=""
            color={titleColor ?? color}
          />
        </Box>
      )}
      <Typography color={titleColor ?? color} fontSize={dimensionsPx.fonts.s} ml={dimensionsPx.section_separator.xxs}>
        {title}
      </Typography>
    </Stack>
    <Stack flexDirection="row" gap={1} alignItems="center">
      {imagePath && <img height={dimensionsPx.line_height.s} src={imagePath} alt="" />}
      <Typography color={color} fontSize={valueFontSize}>
        {value === null ? 'N/A' : `${value}${valueType}`}
        {' '}
      </Typography>
      <FluctuationComponent
        value={value}
        fluctuation={fluctuation}
        fluctuationType={fluctuationType}
        countryCode={countryCode}
      />
    </Stack>
    {additionalInformation &&
      additionalInformation.map((additional) => (
        <Stack key={additional} flexDirection="row">
          <Typography color={palette.neutral.grey400}>{additional}</Typography>
        </Stack>
      ))}
  </Stack>
);

KpiString.defaultProps = {
  color: palette.primary.white, pts: true, fluctuationType: '', valueType: '',
};
