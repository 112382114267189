import { Alert, Button, Card, CardContent, CardHeader, Chip, CircularProgress, FormControl, InputLabel, Paper, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTipsSuggestion } from 'src/pages/Settings/AppSettings/tips/query/useListBusinessUsersQuery';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useSetTipsSuggestion } from 'src/pages/Settings/AppSettings/tips/mutation/useSetTipsSuggestion';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { useWaitersNotLinked } from 'src/pages/Settings/AppSettings/tips/query/useWaitersNotLinked';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Controller, useForm } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import { TipsSuggestion } from 'src/pages/Settings/AppSettings/tips/domain/TipsSuggestion';
import Divider from '@mui/material/Divider';
import { WaiterLine } from 'src/pages/Settings/AppSettings/AppSettings.styles';
import { MoneyOff } from '@mui/icons-material';
import { experiment } from 'src/feature-flipping/infrastructure/experiment';
import { TipsConfigurationLoaded2 } from 'src/pages/Settings/AppSettings/components/TipsConfigurationLoaded2';

const TipsConfigurationLoaded = ({
  enrollmentId,
  product,
  tipsConfig,
}: { enrollmentId: EnrollmentId, product: ServiceType, tipsConfig: TipsSuggestion }) => {
  const { data: notLinkedWaiters } = useWaitersNotLinked(enrollmentId);
  const waiterNames = notLinkedWaiters ?? [];
  const {
    saveTips,
    mutation: { isPending },
  } = useSetTipsSuggestion(enrollmentId);
  const {
    formState: {
      errors,
    },
    control,
    handleSubmit,
    watch,
  } = useForm<TipsSuggestion>(
    { defaultValues: tipsConfig },
  );
  const tipsDisabled = !watch('tippingEnabled');

  function coreSettings() {
    return <>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography>Show emojis</Typography>
        <Controller disabled={tipsDisabled} render={({ field }) => <Switch checked={field.value} {...field} />} name={'emojisEnabled'} control={control} />
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography>How to display tips</Typography>
        <FormControl>
          <Controller
            name="tippingDisplayStrategy"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Select
                id="tippingDisplayStrategy"
                disabled={tipsDisabled || product === ServiceType.ORDER_AND_PAY}
                {...field}
              >
                <MenuItem value={'TIP_LABEL_TIP_AMOUNT'}>Amount e.g. 3€</MenuItem>
                <MenuItem value={'TIP_PERCENTAGE_TIP_AMOUNT'}>Percentage e.g. 9%</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography>Configure the amounts</Typography>
        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
          <Controller
            name={'tippingPercentageFirst'}
            control={control}
            rules={{
              validate: value => {
                if (value == 0) return "tip can't be 0";
                return value != watch('tippingPercentageSecond') && value !== watch('tippingPercentageThird') || 'tips are equal';
              },
            }}
            render={({ field }) => <TextField
              disabled={tipsDisabled || product === ServiceType.ORDER_AND_PAY}
              type={'number'} label={'Left'}  {...field} error={errors.tippingPercentageFirst?.message === 'tip can\'t be 0'} />} />
          <Controller
            name={'tippingPercentageSecond'}
            control={control}
            rules={{
              validate: value => {
                if (value == 0) return "tip can't be 0";
                return value != watch('tippingPercentageFirst') && value !== watch('tippingPercentageThird') || 'tips are equal';
              },
            }}
            render={({ field }) => <TextField type={'number'} label={'Center'}
                                              disabled={tipsDisabled || product === ServiceType.ORDER_AND_PAY}
                                              {...field} error={errors.tippingPercentageSecond?.message === 'tip can\'t be 0'} />} />
          <Controller
            name={'tippingPercentageThird'}
            control={control}
            rules={{
              validate: value => {
                if (value == 0) return 'ZERO_TIP';
                return value != watch('tippingPercentageFirst') && value !== watch('tippingPercentageSecond') || 'tips are equal';
              },
            }}
            render={({ field }) => <TextField type={'number'} label={'Right'}
                                              disabled={tipsDisabled || product === ServiceType.ORDER_AND_PAY}
                                              {...field} error={errors.tippingPercentageThird?.message == 'ZERO_TIP'} />} />
        </Stack>
      </Stack>
      {(errors.tippingPercentageFirst?.message === 'tips are equal' ||
          errors.tippingPercentageSecond?.message === 'tips are equal' ||
          errors.tippingPercentageThird?.message === 'tips are equal') &&
        <Alert severity="warning">Tips can't be the same.</Alert>
      }
      {product === ServiceType.PAY_AT_TABLE && (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography>Preselect a tip</Typography>
          <FormControl sx={{
            m: 1,
            minWidth: 200,
          }}>
            <InputLabel id="preselect-tips-label">Tips preselection</InputLabel>
            <Controller
              name="preSelectedTips"
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <Select
                  disabled={tipsDisabled}
                  id="preselect-tips"
                  label="Preselect tips"
                  {...field}
                >
                  <MenuItem value={'NONE'}>No preselection</MenuItem>
                  <MenuItem value={'FIRST'}>Left</MenuItem>
                  <MenuItem value={'SECOND'}>Center</MenuItem>
                  <MenuItem value={'THIRD'}>Right</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Stack>
      )}
    </>;
  }

  function onOffSettings() {
    return <Stack spacing={2}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography>
          <FormattedMessage id={'settings.app_settings.tips.allow_tips'} />
        </Typography>
        <Controller render={({ field }) => <Switch checked={field.value} {...field} />} name={'tippingEnabled'} control={control} />
      </Stack>
      <Divider />
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography>
          <FormattedMessage id={'settings.app_settings.tips.enforce_account_for_tips_for_pat'} />
        </Typography>
        <Controller name={'enforceAccountForTipsForPat'} disabled={tipsDisabled} render={({ field }) => <Switch checked={field.value} {...field} />} control={control} />
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography>
          <FormattedMessage id={'settings.app_settings.tips.enforce_account_for_tips_for_pdq'} />
        </Typography>
        <Controller name={'enforceAccountForTipsForPdq'} disabled={tipsDisabled} render={({ field }) => <Switch checked={field.value} {...field} />} control={control} />
      </Stack>
      {((tipsConfig?.enforceAccountForTipsForPdq
        || watch('enforceAccountForTipsForPdq')
        || tipsConfig?.enforceAccountForTipsForPat
        || watch('enforceAccountForTipsForPat'))
        && waiterNames.length > 0) && (
        <Paper elevation={8} sx={{
          borderRadius: 4,
          padding: 2,
        }}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography fontSize={'small'}><FormattedMessage id={'settings.app_settings.tips.waiter_not_linked'} /></Typography>
            <WaiterLine>
              {waiterNames?.map((name) => (<Chip icon={<MoneyOff />} label={name.value} key={name.value} style={{ margin: '5px' }} />))}
            </WaiterLine>
          </Stack>
        </Paper>
      )}
      <Divider />

    </Stack>;
  }

  return <form onSubmit={handleSubmit((data) => saveTips(data))}>
      <Card>
        <CardHeader title="Tips configuration" action={<Button type="submit" variant="contained" disabled={isPending}>Save</Button>} />
        <CardContent>
          {onOffSettings()}
          {coreSettings()}
        </CardContent>
      </Card>
    </form>;
};

const TipsConfiguration = ({
  enrollmentId,
  product,
}: { enrollmentId: EnrollmentId, product: ServiceType }) => {
  const intl = useIntl();
  const {
    data: tipsConfig,
    isLoading,
  } = useTipsSuggestion(enrollmentId);
  const displaySmartTipSetting = experiment.variant('display-smart-tip-setting', 'disabled').value === 'enabled';

  if (isLoading) {
    return <Card>
      <CardHeader title={intl.formatMessage({ id: 'settings.app_settings.tips.configuration_title' })} />
      <CardContent><CircularProgress /></CardContent>
    </Card>;
  }

  if (displaySmartTipSetting) return <TipsConfigurationLoaded2 enrollmentId={enrollmentId} product={product} tipsConfig={tipsConfig!} />;
  return <TipsConfigurationLoaded enrollmentId={enrollmentId} product={product} tipsConfig={tipsConfig!} />;
};

export const TipsConfigurationForProduct = () => {
  const business = useCurrentBusinessOrThrow();
  if (business.patEnrollment) return <TipsConfiguration enrollmentId={business.patEnrollment.id} product={ServiceType.PAY_AT_TABLE} />;
  if (business.oapEnrollment) return <TipsConfiguration enrollmentId={business.oapEnrollment.id} product={ServiceType.ORDER_AND_PAY} />;
  return null;
};
