import { PageLayout } from '../../shared/styles/Page/PageLayoutStyle';
import { PageLayoutProps } from '../../types/layout_types';
import { ListActions } from '../organisms/Body/Section/ListActions';
import { PageSubTitle } from '../organisms/Header/PageSubTitle';
import { HeadingTitleSection } from '../organisms/Header/PageTitle';

export const Page = (pageProps: PageLayoutProps) => {
  return (
    <PageLayout noBanner={pageProps.noBanner} maxWidth={false}>
      {pageProps.hasHeadTitle && pageProps.hasHeadTitle.showTitle && <HeadingTitleSection {...pageProps.hasHeadTitle}/>}
      {pageProps.hasActions && <ListActions />}
      {pageProps.hasHeadSubTitle && <PageSubTitle />}
      {pageProps.children}
    </PageLayout>
  );
};