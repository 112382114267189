/* eslint-disable max-lines */
import { Money } from '@sundayapp/web-money';
import { FulfillmentType } from '../area/domain/FulfillmentType';
import { PaymentSummary, TaxSummary } from '../payment/types';

export enum OrderStatus {
  PARSED = 'PARSED',
  RECEIVED = 'RECEIVED',
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  DUPLICATE = 'DUPLICATE',
  PRINTED = 'PRINTED',
  PREPARING = 'PREPARING',
  PREPARED = 'PREPARED',
  PLACED = 'PLACED',
  BOX_ORDERS_PLACED = 'BOX_ORDERS_PLACED',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  IN_DELIVERY = 'IN_DELIVERY',
  FINALIZED = 'FINALIZED',
  AUTO_FINALIZED = 'AUTO_FINALIZED',
  CANCEL = 'CANCEL',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  POS_RECEIVED_FAILED = 'POS_RECEIVED_FAILED',
  PARSE_FAILED = 'PARSE_FAILED',
  RESOLVED = 'RESOLVED',
  FULLY_PAID = 'FULLY_PAID',
  DELIVERECT_NOTIFICATION_FAILED = 'DELIVERECT_NOTIFICATION_FAILED',
  NOTIFIED_TO_DELIVERECT = 'NOTIFIED_TO_DELIVERECT',
  NOTIFIED_TO_POS = 'NOTIFIED_TO_POS',
  ORDER_GROUPED = 'ORDER_GROUPED',
  WAITING_FOR_GROUPING = 'WAITING_FOR_GROUPING',
  SCHEDULED = 'SCHEDULED',
  QUEUED = 'QUEUED',
}

export enum OrderPaymentStatus {
  TO_BE_PAID = 'TO_BE_PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
  FAILED = 'FAILED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  REFUNDED = 'REFUNDED',
  AUTO_REFUNDED = 'AUTO_REFUNDED',
}

// noinspection JSUnusedGlobalSymbols
export enum ConfirmationMethod {
  NONE = 'NONE',
  MANUALLY = 'MANUALLY',
  AUTOMATICALLY = 'AUTOMATICALLY',
  FULLY_PAID = 'FULLY_PAID',
  MANUALLY_CASH = 'MANUALLY_CASH',
  MANUALLY_CARD = 'MANUALLY_CARD',
  MANUALLY_OFFERED = 'MANUALLY_OFFERED',
}

export type BoxOrderId = string;
export type OrderId = string;

type BoxOrderSummaryFields = {
  id: string;
  boxId: string;
  areaId: string | null;
  fulfillmentMethod: FulfillmentType;
  totalPrice: Money;
  totalPaid?: Money;
  tipsAmount?: Money;
  discount?: Money;
  placedAtEpochMilli?: number;
  customerName: string;
  paymentStatus: OrderPaymentStatus;
  paidBySunday: boolean;

  displayId?: string | null;
  tableName?: string | null;
  status?: OrderStatus;
  groupedOrderId?: string | null;
  smsSent?: boolean;
  smsSentAtEpochMilli?: number;
  confirmationMethod?: ConfirmationMethod;
  orderFromCentralTill?: boolean;
  reason?: string;
  refundAmount?: Money;
};

export type BoxOrderSummary = BoxOrderSummaryFields & {
  id: BoxOrderId;
  orderId: OrderId;
};

export type OrderSummary = BoxOrderSummaryFields & {
  boxOrders: BoxOrderSummary[];
  regroupedOrderSummaries: OrderSummary[];
  pickupTimestamp?: number;
  bookingEventName?: string;
  enrollmentId: string;
};

export type OrderItem = {
  menuId: string;
  productId: string;
  name: string;
  description?: string;
  quantity: number;
  price: Money;
  notes?: string | null;
  subItems: OrderItem[];
};

type OrderDetails = {
  items: OrderItem[];
  payments: PaymentSummary[];
  taxSummary: TaxSummary;
  notes: string;
  totalPaid: Money;

  cutleryNumber?: number;
  serviceChargeAmount?: Money;
  discount?: Money;
  confirmationMethod?: ConfirmationMethod;
  enrollmentId: string;
};

export type BoxOrderDetails = {
  id: BoxOrderId;
  orderId: OrderId;
  displayId: string;
  boxId: string;
  status: OrderStatus;
  placedAtEpochMilli: number;
  items: OrderItem[];
};

export type BoxOrderPacingData = {
  boxId: string;
  instantEpochMilli: number;
  nbWaitingOrders: number;
  nbInPreparationOrders: number;
};

export type Order = BoxOrderSummaryFields &
OrderDetails & {
  id: OrderId;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Event = {
  aggregateId: string;
  atEpochMilli: number;
  eventId: number;
  eventName: string;
  payload: string;
};

export type EventsList = {
  events: Event[];
};

export type BoxOrderHistory = {
  boxOrderId: BoxOrderId;
  events: EventsList;
};

export type OrderHistory = {
  orderId: OrderId;
  boxOrders: BoxOrderHistory[];
  events: EventsList;
  groupedOrder: OrderHistory | null;
};
