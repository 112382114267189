import { CurrencyCode } from '@sundayapp/web-money';
import { chain } from 'lodash';
import { oneReporting, Reporting, sumReportings } from './ReportingDigest';
import { isCB, isOnPdq, isTrd, Payment, Payments, serviceChargesOrZero } from './Payments';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { NO_REVENUE_CENTER } from './RevenueCenter';
import { toWaitersProductsReporting } from './toWaitersProductsReporting';
import { EndOfServiceConfiguration } from './EndOfServiceConfiguration';
import {
  emptyWaitersReporting,
  isCnp,
  isCp,
  KindOfPayment,
  mapProductToKindOfPayment,
  products,
  Products,
} from './products';

function getSchemePayments(product: Products, payments: Payment[], currency: CurrencyCode) {
  return chain(payments)
    .groupBy('scheme')
    .toPairs()
    .map(([key, schemePayments]) => {
      const revenue = safeSumMoney(schemePayments.map((payment) => payment.salesAmount), currency);
      const tips = safeSumMoney(schemePayments.map((payment) => payment.tipsAmount), currency);
      const serviceCharges = safeSumMoney(schemePayments.map((payment) => serviceChargesOrZero(payment, currency)), currency);
      return {
        name: key,
        numberOfPayment: schemePayments.length,
        product,
        revenue,
        tips,
        serviceCharges,
        total: safeSumMoney([revenue, serviceCharges, tips], currency),
        details: [],
      };
    });
}

export function toSchemeReporting(product: Products, name: string, payments: Payment[], currency: CurrencyCode, filter: (payment: Payment) => {}): Reporting {
  const filteredPayments = payments.filter(filter)
    .filter(payment => payment.scheme !== 'AMEX');
  const revenue = safeSumMoney(filteredPayments.map((payment) => payment.salesAmount), currency);
  const tips = safeSumMoney(filteredPayments.map((payment) => payment.tipsAmount), currency);
  const serviceCharges = safeSumMoney(filteredPayments.map((payment) => serviceChargesOrZero(payment, currency)), currency);
  return {
    name,
    numberOfPayment: filteredPayments.length,
    product,
    revenue,
    tips,
    serviceCharges,
    total: safeSumMoney([revenue, serviceCharges, tips], currency),
    details: getSchemePayments(product, filteredPayments, currency)
      .value(),
  };
}

export function toAmexReporting(product: Products, name: string, payments: Payment[], currency: CurrencyCode): Reporting {
  const filteredPayments = payments.filter(payment => payment.scheme === 'AMEX');
  const revenue = safeSumMoney(filteredPayments.map((payment) => payment.salesAmount), currency);
  const tips = safeSumMoney(filteredPayments.map((payment) => payment.tipsAmount), currency);
  const serviceCharges = safeSumMoney(filteredPayments.map((payment) => serviceChargesOrZero(payment, currency)), currency);
  return {
    name,
    numberOfPayment: filteredPayments.length,
    product,
    revenue,
    tips,
    serviceCharges,
    total: safeSumMoney([revenue, serviceCharges, tips], currency),
    details: [],
  };
}

export function paymentHasRevenueCenter(revenueCentersSelected: string[], payment: Payment) {
  const allRevenueCenter = revenueCentersSelected.length === 0;
  return allRevenueCenter || revenueCentersSelected.includes(payment.revenueCenter || NO_REVENUE_CENTER);
}

export function toPaymentMethodReporting(
  eosConfiguration: EndOfServiceConfiguration,
  name: string,
  payments: Payments,
  product: Products,
  currency: CurrencyCode,
  revenueCentersSelected: string[] = [],
): Reporting {

  const isCandidateForEOS = (payment: Payment) => {
    if (product === products.PAT) {
      return payment.origin === products.PAT && paymentHasRevenueCenter(revenueCentersSelected, payment);
    }

    if (product === products.OAP) {
      return payment.origin === products.OAP && paymentHasRevenueCenter(revenueCentersSelected, payment);
    }

    return isOnPdq(payment) && paymentHasRevenueCenter(revenueCentersSelected, payment);
  };

  const filteredPayments = payments.payments.filter(isCandidateForEOS);

  const card = toSchemeReporting(product, 'sales_summary.cb_total', filteredPayments, currency, isCB);
  const trd = toSchemeReporting(product, 'sales_summary.trd_total', filteredPayments, currency, isTrd);

  const amexPayments = filteredPayments.filter(payment => payment.scheme === 'AMEX');

  if (product === products.PDQ && amexPayments.length > 0) {
    const amex = toAmexReporting(product, 'AMEX', filteredPayments, currency);
    if (eosConfiguration.displayTrd) return sumReportings(name, currency, [card, trd, amex]);
    return sumReportings(name, currency, [card, amex]);
  }

  if (eosConfiguration.displayTrd) return sumReportings(name, currency, [card, trd]);
  return oneReporting(name, currency, card);
}

function toWaiterProductsReporting(
  eosConfiguration: EndOfServiceConfiguration,
  waiterPayments: Payment[],
  currency: CurrencyCode,
): Reporting[] {
  const result = waiterPayments.reduce((acc, payment) => {
    const kindOfPayment: KindOfPayment = mapProductToKindOfPayment(payment.origin);

    return {
      ...acc,
      [kindOfPayment]: {
        name: acc[kindOfPayment].name,
        numberOfPayment: waiterPayments.length,
        revenue: safeSumMoney([acc[kindOfPayment].revenue, payment.salesAmount], currency),
        tips: safeSumMoney([acc[kindOfPayment].tips, payment.tipsAmount], currency),
        serviceCharges: safeSumMoney([acc[kindOfPayment].serviceCharges, serviceChargesOrZero(payment, currency)], currency),
        total: safeSumMoney([acc[kindOfPayment].total, safeSumMoney([payment.salesAmount, serviceChargesOrZero(payment, currency), payment.tipsAmount], currency)], currency),
        details: [],
      },
    };
  }, emptyWaitersReporting(currency));

  const waiterProductsReporting = Object.values(result);

  const patWaiterPayments = waiterPayments.filter(p => isCnp(p.origin));
  waiterProductsReporting[0].product = products.PAT;
  waiterProductsReporting[0].details = [toSchemeReporting(products.PAT, 'sales_summary.cb_total', patWaiterPayments, currency, isCB)];
  if (eosConfiguration.displayTrd) waiterProductsReporting[0].details.push(toSchemeReporting(products.PAT, 'sales_summary.trd_total', patWaiterPayments, currency, isTrd));

  if (eosConfiguration.displayPaymentTerminal) {
    waiterProductsReporting[1].product = products.PDQ;

    const tpeWaiterPayments = waiterPayments.filter(p => isCp(p.origin));

    const card = toSchemeReporting(products.PDQ, 'sales_summary.cb_total', tpeWaiterPayments, currency, isCB);
    const trd = toSchemeReporting(products.PDQ, 'sales_summary.trd_total', tpeWaiterPayments, currency, isTrd);

    const amexPayments = tpeWaiterPayments.filter(payment => payment.scheme === 'AMEX');

    waiterProductsReporting[1].details = [card];
    if (eosConfiguration.displayTrd) waiterProductsReporting[1].details.push(trd);
    if (amexPayments.length > 0) {
      const amex = toAmexReporting(products.PDQ, 'AMEX', tpeWaiterPayments, currency);
      waiterProductsReporting[1].details.push(amex);
    }
  } else {
    waiterProductsReporting.pop();
  }

  return waiterProductsReporting;
}

export function createReportingDigest(
  eosConfiguration: EndOfServiceConfiguration,
  payments: Payments,
  currency: CurrencyCode,
) {
  return {
    patPaymentMethod: toPaymentMethodReporting(eosConfiguration, 'sales_summary.pat_total', payments, products.PAT, currency, []),
    oapPaymentMethod: toPaymentMethodReporting(eosConfiguration, 'sales_summary.oap_total', payments, products.OAP, currency, []),
    tpePaymentMethod: toPaymentMethodReporting(eosConfiguration, 'sales_summary.tpe_total', payments, products.PDQ, currency, []),
    waitersProducts: toWaitersProductsReporting(eosConfiguration, payments, currency, [], toWaiterProductsReporting),
  };
}
