import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Button, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { Outlet, useLocation } from 'react-router-dom';
import { AccountingRepository } from '../../../reporting/infrastructure/AccountingRepository';
import { revenuesDatePresets, revenueTabDatePresets } from '../../domain/RevenuesDatePresets';
import { RevenuesDates } from './filter/RevenuesDates';
import { RevenueTableDataLoading } from './table/RevenueTableDataLoading';
import { RevenuesTable } from './table/RevenuesTable';
import { Business } from 'src/business/domain/Business';
import { isRevenuesSummary, RevenuesSummary } from '../../domain/RevenueSummary';
import { useNavigate } from 'react-router';
import {
  extractTimeFrameFilterFromURLParams,
  TimeFrameFilter,
  URL_DATE_FORMAT,
} from 'src/payments/components/TimeFrameFilter';
import { convertToRevenueSummaryWithAmex } from 'src/accounting/revenues/domain/RevenueSummaryWithAmex';

const RevenueWrapper = styled.div`
  @media (min-width: 1200px) {
    max-width: 100%;
  }
`;

export const Revenues = ({ business }: { business: Business }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [revenueSummary, setRevenueSummary] = useState<RevenuesSummary | undefined>(undefined);
  const accountingRepository = useRef(new AccountingRepository(axios));
  const location = useLocation();
  const navigate = useNavigate();

  const datePresets = revenuesDatePresets(business.timezone);
  const revenuePeriod = extractTimeFrameFilterFromURLParams<typeof revenueTabDatePresets>(
    location.search,
    datePresets,
    revenueTabDatePresets.LAST_7_DAYS_FILTER,
  );
  const dateRange = {
    startDate: revenuePeriod.dateRange.startDate.clone().utc().format(),
    endDate: revenuePeriod.dateRange.endDate.clone().utc().format(),
  };
  const period = useMemo(
    () => ({
      start: dateRange.startDate,
      end: dateRange.endDate,
    }),
    [dateRange.startDate, dateRange.endDate],
  );

  const isBusinessWithServiceCharge = business.address.countryCode === 'US' || business.address.countryCode === 'GB';

  useEffect(() => {
    setLoading(true);
    accountingRepository.current
      .getRevenuesSummary(business.id, period.start, period.end)
      .then((data) => {
        if (isRevenuesSummary(data)) {
          const revenueSummaryWithAmex = convertToRevenueSummaryWithAmex(data);
          setRevenueSummary(revenueSummaryWithAmex);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [period.start, period.end, business.id]);

  const openDrawer = () => {
    const start = revenuePeriod.dateRange.startDate.format(URL_DATE_FORMAT);
    const end = revenuePeriod.dateRange.endDate.format(URL_DATE_FORMAT);
    navigate(`${location.pathname}/details?preset=${revenuePeriod.filter}&startDate=${start}&endDate=${end}`);
  };

  return (
    <RevenueWrapper>
      <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
        <TimeFrameFilter datePresets={datePresets} period={revenuePeriod} timezone={business.timezone} />

        <Button
          onClick={() => openDrawer()}
          variant="contained"
          data-intercom-target={'accounting.revenues.cta.see_details_and_download'}
        >
          <FormattedMessage id="accounting.revenues.cta.see_details_and_download" />
        </Button>
      </Box>
      <RevenuesDates period={revenuePeriod} timezone={business.timezone} theme={theme} />

      {loading || !revenueSummary ? (
        <RevenueTableDataLoading />
      ) : (
        <RevenuesTable
          revenuesSummary={revenueSummary}
          period={revenuePeriod}
          isBusinessWithServiceCharge={isBusinessWithServiceCharge}
          intl={intl}
          theme={theme}
        />
      )}
      {revenueSummary && (
        <Outlet
          context={{
            businessId: business.id,
            businessCurrency: business.currency,
            timezone: business.timezone,
            period: revenuePeriod,
            revenueSummary,
          }}
        />
      )}
    </RevenueWrapper>
  );
};
