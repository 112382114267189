import {
  CheckoutOnboardingInformationDraft,
  ControllingCompanyDraft,
  RepresentativeDraft,
  Roles,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';

const mapRepresentativeRoles = (roles?: Roles) => {
  if (!roles) {
    return undefined;
  }
  return Object.entries(roles)?.map(([ role, isSelected ]) => {
    if (isSelected) {
      switch (role) {
        case 'ubo':
          return 'ubo';
        case 'legalRepresentative':
          return 'legal_representative';
        case 'authorisedSignatory':
          return 'authorised_signatory';
        case 'control':
          return 'control_person';
        case 'director':
          return 'director';
      }
    }
    return isSelected ? role : undefined;
  }).filter(role => role !== undefined);
};
const mapRepresentatives = (representatives: RepresentativeDraft[], controllingCompanies: ControllingCompanyDraft[]) => {
  const individualRepresentativesDto = representatives?.map(representative =>
    ({
      firstName: representative.firstName,
      lastName: representative.lastName,
      address: {
        addressLine1: representative.address?.addressLine1,
        city: representative.address?.city?.trim(),
        zip: representative.address?.zip,
        country: representative.address?.country,
      },
      email: representative.email,
      identificationType: representative.identificationType,
      identificationFrontDocument: representative.identificationFrontDocument,
      identificationBackDocument: representative.identificationBackDocument,
      dateOfBirth: representative.dateOfBirth?.format('DD/MM/YYYY'),
      countryOfBirth: representative.countryOfBirth,
      ownershipPercentage: representative.ownershipPercentage,
      type: 'individual',
      companyPosition: representative.companyPosition,
      certifiedAuthorisedSignatoryDocument: representative.certifiedAuthorisedSignatoryDocument,
      roles: mapRepresentativeRoles(representative.roles),
    }));
  const controllingCompaniesDto = controllingCompanies?.map(representative => ({
    ownershipPercentage: representative.ownershipPercentage,
    address: {
      country: representative.address?.country,
    },
    legalName: representative.legalName,
    tradingName: representative.isTradingNameTheSameAsLegalName ? representative.legalName : representative.tradingName,
    type: 'company',
  }));

  return [ ...individualRepresentativesDto, ...controllingCompaniesDto ];
};
const mapPrimaryAddress = (checkoutOnboardingInfo: CheckoutOnboardingInformationDraft) => {
  if (checkoutOnboardingInfo.isPrimaryAddressTheSameAsRegistered) {
    return {
      addressLine1: checkoutOnboardingInfo?.registeredAddress?.addressLine1,
      city: checkoutOnboardingInfo?.registeredAddress?.city?.trim(),
      zip: checkoutOnboardingInfo?.registeredAddress?.zip,
      country: checkoutOnboardingInfo?.registeredAddress?.country,
    };
  }
  return {
    addressLine1: checkoutOnboardingInfo?.primaryAddress?.addressLine1,
    city: checkoutOnboardingInfo?.primaryAddress?.city?.trim(),
    zip: checkoutOnboardingInfo?.primaryAddress?.zip,
    country: checkoutOnboardingInfo?.primaryAddress?.country,
  };
};
export const mapCheckoutInformationFromDomainToDto = (isDraft: boolean, checkoutOnboardingInfo: CheckoutOnboardingInformationDraft) => ({
  draft: isDraft,
  businessRegistrationNumber: checkoutOnboardingInfo.businessRegistrationNumber,
  businessType: checkoutOnboardingInfo.businessType,
  legalName: checkoutOnboardingInfo.legalName,
  tradingName: checkoutOnboardingInfo.isTradingNameTheSameAsLegalName ? checkoutOnboardingInfo.legalName : checkoutOnboardingInfo.tradingName,
  registeredAddress: {
    addressLine1: checkoutOnboardingInfo?.registeredAddress?.addressLine1,
    city: checkoutOnboardingInfo?.registeredAddress?.city?.trim(),
    zip: checkoutOnboardingInfo?.registeredAddress?.zip,
    country: checkoutOnboardingInfo?.registeredAddress?.country,
  },
  primaryAddress: mapPrimaryAddress(checkoutOnboardingInfo),
  contact: {
    phone: checkoutOnboardingInfo?.contact?.phone?.trim().replace(/\s+/g, ''),
    email: checkoutOnboardingInfo?.contact?.email,
  },
  representatives: mapRepresentatives(checkoutOnboardingInfo?.representatives ?? [], checkoutOnboardingInfo?.controllingCompanies ?? []),
  annualProcessingVolume: checkoutOnboardingInfo.annualProcessingVolume,
  averageTransactionValue: checkoutOnboardingInfo.averageTransactionValue,
  highestTransactionValue: checkoutOnboardingInfo.highestTransactionValue,
  bankAccount: {
    bic: checkoutOnboardingInfo.bankAccount?.bic,
    iban: checkoutOnboardingInfo.bankAccount?.iban,
    accountNumber: checkoutOnboardingInfo.bankAccount?.accountNumber,
    bankCode: checkoutOnboardingInfo.bankAccount?.bankCode,
    bankStatement: checkoutOnboardingInfo.bankAccount?.bankStatement,
  },
  incorporationDocument: checkoutOnboardingInfo.incorporationDocument,
  dateOfIncorporation: checkoutOnboardingInfo.dateOfIncorporation?.format('MM/YYYY'),
  articlesOfAssociationDocument: checkoutOnboardingInfo.articlesOfAssociationDocument,
  shareholderStructureDocument: checkoutOnboardingInfo.shareholderStructureDocument,
  proofOfPrincipalAddressDocument: checkoutOnboardingInfo.proofOfPrincipalAddressDocument,
  consent: checkoutOnboardingInfo.consent,
});
