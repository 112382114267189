import { Customization } from './domain/Customization';
import { Business } from 'src/business/domain/Business';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Panel, spaceUsages, Text, Title } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { PreviewContainer } from './components/Preview.style';
import LandingPreview from './components/LandingPreview';
import { LogoHandler } from './components/Pictures/LogoHandler';
import { CoverHandler } from './components/Pictures/CoverHandler';
import React from 'react';
import { Column } from 'src/sundayplus/Column';
import { pixels } from 'src/sundayplus/Size';

export function LandingImages({
  customization,
  business,
  previewBackgroundColor,
}: {
  customization: Customization | undefined;
  business: Business;
  previewBackgroundColor: string | undefined;
}) {
  return (
    <Panel flexDirection="column">
      <Column gap={pixels(16)}>
        <Title size="title4" marginBottom={spaceUsages.mediumLarge} keepCase>
          <FormattedMessage id="settings.app_settings.app_design.title" />
        </Title>

        <Text size="small" color={colorUsage.tertiaryText} keepCase>
          <FormattedMessage id="settings.customization.landing_images.description" />
        </Text>

        <Panel flexDirection="column" marginTop={spaceUsages.small} background={colorUsage.clearBackground}>
          <PreviewContainer>
            <LandingPreview
              coverUrl={customization?.cover?.m}
              logoUrl={customization?.logo?.logoUrls?.noResize}
              previewBackgroundColor={previewBackgroundColor}
            />
          </PreviewContainer>
        </Panel>

        <div>
          <Text size="small" color={colorUsage.tertiaryText}>
            <FormattedMessage id="settings.app_settings.app_design.appearance.logo" />
          </Text>
          <LogoHandler venueId={business.patEnrollment?.id!} />
        </div>

        <div>
          <Text size="small" color={colorUsage.tertiaryText}>
            <FormattedMessage id="settings.app_settings.app_design.appearance.cover" />
          </Text>
          <CoverHandler venueId={business.patEnrollment?.id!} />
        </div>
      </Column>
    </Panel>
  );
}
