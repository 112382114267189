import { RepresentativeDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';

export const isCompleteControl = ({ representative, containsErrors }: {
  representative: RepresentativeDraft | undefined,
  containsErrors: boolean
}): boolean => {
  if (containsErrors || !representative) {
    return false;
  }
  const isAddressComplete = !!representative.address?.city && !!representative.address?.country && !!representative.address?.addressLine1 && !!representative.address?.zip;
  const isDocumentIdComplete = !!representative.identificationFrontDocument && 
    (!!representative.identificationFrontDocument || representative?.identificationType === 'passport');
  return !!representative!.firstName &&
    !!representative.lastName &&
    !!representative.dateOfBirth &&
    !!representative.countryOfBirth &&
    !!representative.identificationType &&
    !!representative.companyPosition &&
    isDocumentIdComplete &&
    isAddressComplete;
};