import React, { useCallback, useEffect, useState } from 'react';
import { Loader } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { VenueDetails } from '../../types';
import { useVenueRepository } from '../../hook';
import { useBoxRepository } from '../../../box/hooks';
import { BoxDetails } from '../../../box/domain/BoxDetails';
import { BoxSelector } from '../../../box/components';
import { Box } from '../../../box/domain/Box';
import { MonoBoxSettings } from './component/MonoBoxSettings';
import { FoodCourtSettings } from './component/FoodCourtSettings';
import { BoxSettings } from './component/BoxSettings';
import { getSpacing } from '../../../stylesheet';
import { EnrollmentId } from 'src/business/domain/Enrollment';

type AppVenueSettingsProps = {
  enrollmentId: EnrollmentId;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SettingContainer = styled.div`
  margin-top: ${getSpacing(2)};
`;

// eslint-disable-next-line complexity
export const AppVenueSettings = ({ enrollmentId }: AppVenueSettingsProps) => {
  const intl = useIntl();
  const venueRepository = useVenueRepository();
  const boxRepository = useBoxRepository();

  const [venue, setVenue] = useState<VenueDetails>();
  const [box, setBox] = useState<BoxDetails>();

  useEffect(() => {
    venueRepository.getVenue(enrollmentId).then(setVenue);
  }, [venueRepository, enrollmentId]);

  const appSettingComponent = useCallback(() => {
    if (!venue) {
      return <></>;
    }
    if (!venue.isFoodCourt && box) {
      return <MonoBoxSettings venue={venue} box={box} setVenue={setVenue} setBox={setBox} />;
    }
    if (venue.isFoodCourt && box) {
      return <BoxSettings box={box} setBox={setBox} />;
    }
    if (venue.isFoodCourt) {
      return <FoodCourtSettings venue={venue} setVenue={setVenue} />;
    }
    return <></>;
  }, [venue, box]);

  if (!venue) {
    return <Loader size="large" />;
  }

  const onBoxSelected = (selectedBox: Box) => {
    setBox(undefined);
    setTimeout(() => boxRepository.getBoxDetails(selectedBox.id).then(setBox), 0);
  };

  return (
    <Container>
      <BoxSelector
        enrollmentId={venue.id}
        onBoxSelected={onBoxSelected}
        onBoxUnselected={() => setBox(undefined)}
        emptyMessage={intl.formatMessage({ id: 'box.selector.venue', defaultMessage: 'venue' })}
      />

      <SettingContainer>{appSettingComponent()}</SettingContainer>
    </Container>
  );
};
