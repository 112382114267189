import { Money } from '@sundayapp/web-money';
import { ValuesOf } from 'src/utils/typescript/valuesOf';

export type TipsSummaryByWaiter = {
  waiterName: string | undefined,
  tipsCount: number,
  directTipsAmount: Money;
  posPooledAmount: Money;
  sundayPooledTipsAmount: Money;
  directTipsStatus?: DirectTipStatus
};

export const directTipStatuses = {
  AWAITING_WAITER_ASSOCIATION: 'AWAITING_WAITER_ASSOCIATION',
  IN_PROGRESS: 'IN_PROGRESS',
  RECEIVED: 'RECEIVED',
} as const;

export type DirectTipStatus = ValuesOf<typeof directTipStatuses>;
