export const TooltipTheme = {
  styleOverrides: {
    tooltip: {
      borderRadius: '8px',
      backgroundColor: 'black',
      margin: '0px',
      fontSize: '1em',
    },
    arrow: {
      color: 'black',
    },
  },
};
