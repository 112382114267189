import React, { useCallback } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import dayjs from 'dayjs';
import { translation, Translation } from '../../../common/translation';

export type PeriodFilterValue = { filter: string; translation: Translation };

interface Props {
  periodFilter: string
  setCurrentPeriodFilter: (periodFilter: string) => void;
  filterValues?: PeriodFilterValue[];
  sx: SxProps;
}

export const LAST_7_DAYS_FILTER = 'LAST_7_DAYS_FILTER';
export const LAST_WEEK_FILTER = 'LAST_WEEK_FILTER';
export const MONTH_TO_DATE_FILTER = 'MONTH_TO_DATE_FILTER';
export const LAST_MONTH_FILTER = 'LAST_MONTH_FILTER';
export const CURRENT_YEAR_FILTER = 'CURRENT_YEAR_FILTER';
export const PREVIOUS_YEAR_FILTER = 'PREVIOUS_YEAR_FILTER';

const DEFAULT_FILTER_VALUES: () => PeriodFilterValue[] = () => {
  const currentYear = dayjs().year();
  const previousYear = dayjs().subtract(1, 'year').year();

  return ([
    { filter: LAST_7_DAYS_FILTER, translation: translation('accounting.payout_tab.last_7_days_filter') },
    { filter: LAST_WEEK_FILTER, translation: translation('accounting.payout_tab.last_week_filter') },
    { filter: MONTH_TO_DATE_FILTER, translation: translation('accounting.payout_tab.month_to_date_filter') },
    { filter: LAST_MONTH_FILTER, translation: translation('accounting.payout_tab.last_month_filter') },
    {
      filter: CURRENT_YEAR_FILTER,
      translation: translation('accounting.payout_tab.current_year_filter', { currentYear }),
    },
    {
      filter: PREVIOUS_YEAR_FILTER,
      translation: translation('accounting.payout_tab.previous_year_filter', { previousYear }),
    },
  ]);
};

export const PeriodFilter: React.FC<Props> = ({
  periodFilter,
  setCurrentPeriodFilter,
  filterValues,
  sx,
}: Props) => {
  const intl = useIntl();

  const values = filterValues || DEFAULT_FILTER_VALUES();

  const changeFilter = useCallback((event: SelectChangeEvent) => {
    setCurrentPeriodFilter(event.target.value as string);
  },
  [setCurrentPeriodFilter]);

  return (
    <>
      <FormControl sx={sx} size="small">
        <InputLabel>
          {intl.formatMessage({
            id: 'accounting.payout_tab.select_period',
            defaultMessage: 'select period',
          })}
        </InputLabel>
        <Select
          label={intl.formatMessage({ id: 'accounting.payout_tab.select_period', defaultMessage: 'select period' })}
          onChange={changeFilter}
          value={periodFilter}
        >
          {values.map((value) => (
            <MenuItem value={value.filter} key={value.filter}>
              {intl.formatMessage({ id: value.translation.id }, value.translation.values)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
