import React from 'react';
import { Alert, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import { useRenderMoney } from '@sundayapp/web-money';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { AmountToDispatch } from 'src/tips/dispatch/components/AmountToDispatch';
import { DispatchedBy } from 'src/tips/dispatch/components/DispatchedBy';
import CheckIcon from '@mui/icons-material/Check';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useCurrentUser } from 'src/auth/component/UserProvider';
import { AuthenticatedUser } from 'src/auth/domain/user';
import { StaffAllocation, toTipsAllocations } from 'src/tips/dispatch/model/StaffAllocation';
import { StaffDispatchReport } from 'src/tips/dispatch/components/StaffDispatchReport';
import { DispatchPrerequisites } from 'src/tips/dispatch/components/DispatchPrerequisites';
import { PendingTipsDispatch } from 'src/tips/dispatch/model/PendingTipsDispatch';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useDispatchTips } from 'src/tips/dispatch/infrastructure/useDispatchTips';

const SignButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button size="small" variant="contained" onClick={onClick} startIcon={<CheckIcon />}>
      <FormattedMessage id={'tips.sunday_pooling.dispatch.sign'} />
    </Button>
  );
};

export const SignDispatchModal = ({
  opened,
  onClose,
  pendingTipsDispatch,
  staffAllocations,
}: {
  opened: boolean;
  onClose: (completed: boolean) => void;
  pendingTipsDispatch: PendingTipsDispatch;
  staffAllocations: StaffAllocation[];
}) => {
  const business = useCurrentBusinessOrThrow();
  const user = useCurrentUser() as AuthenticatedUser;
  const renderMoney = useRenderMoney(true, false);
  const { dispatchTips, mutation } = useDispatchTips();
  const dispatch = () =>
    dispatchTips(business.id, pendingTipsDispatch.id, toTipsAllocations(staffAllocations)).then(() => {
      onClose(true);
    });
  return (
    <Modal open={opened} aria-labelledby="tips-dispatch-sign-modal">
      <Stack
        sx={{ background: 'white', height: '100%', overflow: 'auto', paddingBottom: '1rem' }}
        flexDirection="column"
      >
        <Stack direction="row" justifyContent="space-between" alignItems={'center'} padding="1rem 1.5rem 1rem 1.5rem">
          <Stack sx={{ cursor: 'pointer' }} onClick={() => onClose(false)}>
            <ChevronLeftOutlined />
          </Stack>
          <Stack alignItems={'center'}>
            <Typography>
              <FormattedMessage id={'tips.sunday_pooling.dispatch.confirm'} />
            </Typography>
          </Stack>
          <Stack alignItems={'flex-end'}>
            <SignButton onClick={dispatch} />
          </Stack>
        </Stack>

        <DispatchPrerequisites />

        <Stack
          sx={{ width: '100%', height: '100%', overflow: 'auto' }}
          padding="3rem 0 3rem 0"
          flexDirection="column"
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Stack maxWidth={'50rem'} height="100%" justifyContent={'space-between'}>
            <Stack direction={'column'} spacing={4} paddingBottom={8}>
              <AmountToDispatch amount={renderMoney(pendingTipsDispatch.amount)} />
              <Divider />
              <StaffDispatchReport staffAllocations={staffAllocations} />
              <Divider />
              <DispatchedBy triggeredBy={`${user.firstName} ${user.lastName}`} dispatchedAt={new Date()} />

              {mutation.error && (
                <Alert severity="error">
                  <FormattedMessage id={'tips.sunday_pooling.dispatch.error'} />
                </Alert>
              )}

              <SignButton onClick={dispatch} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
