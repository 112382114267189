import React, { useEffect, useState } from 'react';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { Search } from '@mui/icons-material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { CircularProgress, Grid, InputAdornment, styled, TextField } from '@mui/material';
import QRCodesCSVExporterButton from './components/QRCodesCSVExporterButton';
import QRCodePreviewerDialog from './components/QRCodePreviewerDialog';
import QRCodesDataGridColumnsDefinition from './components/QRCodesDataGridColumnsDefinition';
import { useBusinessIsNoPos } from '../business/hooks/useBusinessIsNoPos';
import { CustomLoadingOverlay } from '../app/component/loading/CustomLoadingGridOverlay';
import { TableDeviceRepositoryHttp } from '../floorplan/infrastructure/TableDeviceRepositoryHttp';
import { CustomNoRowsOverlay } from '../app/component/materialui-extension/CustomNoRowsOverlay';
import { ConfigurationLoader } from '../configuration/ConfigurationLoader';
import QrCodePreview from './components/QrCodePreview';
import { useCurrentBusinessOrThrow } from '../business/hooks/useCurrentBusinessOrThrow';
import { TableDevice } from '../floorplan/domain/TableDevice';

const QrCodesTableActions = styled(Grid)({ padding: '1rem' });

function QRCodes() {
  const intl = useIntl();
  const [isTableDevicesLoading, setIsTableDevicesLoading] = useState<boolean>(true);
  const [listableTableDevices, setListableTableDevices] = useState<TableDevice[]>([]);
  const [cachedListableTableDevices, setCachedListableTableDevices] = useState<TableDevice[]>([]);
  const [openQRCodePreviewerDialog, setOpenQRCodePreviewerDialog] = useState(false);
  const [previewedTable, setPreviewedTable] = useState<TableDevice | null>(null);

  const business = useCurrentBusinessOrThrow();
  const enrollmentId = business.patEnrollment!.id;
  const businessName = business.name;
  const venueIsNoPos = useBusinessIsNoPos();
  const configuration = ConfigurationLoader.load();
  const tableDeviceRepository = new TableDeviceRepositoryHttp(configuration.sundayDeviceManagerUrl);

  function handleOpenQRCodePreviewerDialog() {
    setOpenQRCodePreviewerDialog(true);
  }

  function handleCloseQRCodePreviewerDialog() {
    setOpenQRCodePreviewerDialog(false);
  }

  useEffect(() => {
    tableDeviceRepository
      .listTableDevices(business.id)
      .then((tableDevicesResponse) => {
        const tableDevices = tableDevicesResponse.sort((first, second) => {
          if (first.number === '') return 1;
          if (second.number === '') return -1;
          return first.number.localeCompare(second.number, undefined, { numeric: true });
        });

        setCachedListableTableDevices(tableDevices);
        setListableTableDevices(tableDevices);
      })
      .catch((e) => {
        console.error('failed to load floor plan', e);
      })
      .finally(() => setIsTableDevicesLoading(false));
  }, []);

  function handleOnCellClick({ row: { id, shortLink, number, name } }: GridCellParams) {
    setPreviewedTable({
      id,
      number,
      shortLink,
      name,
    });
  }

  function searchTables(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (event.target.value.length > 0) {
      setListableTableDevices(cachedListableTableDevices.filter((table) => table.number === event.target.value));
    } else {
      setListableTableDevices([...cachedListableTableDevices]);
    }
  }

  const venueHasNoTable = !isTableDevicesLoading && listableTableDevices.length === 0;

  if (isTableDevicesLoading) return <CircularProgress />;
  if (venueIsNoPos && venueHasNoTable) return <QrCodePreview venueId={enrollmentId} />;

  return (
    <>
      <Grid container>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item style={{ padding: '1rem' }}>
            <h2 style={{ margin: 0 }}>
              {`${listableTableDevices.length} ${
                listableTableDevices.length > 0
                  ? intl.formatMessage({ id: 'qr_codes.tables' })
                  : intl.formatMessage({ id: 'qr_codes.table' })
              }`}
            </h2>
          </Grid>
          <Grid item>
            <QrCodesTableActions container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Grid item>
                <QRCodesCSVExporterButton
                  venueId={enrollmentId}
                  venueName={businessName}
                  listableTableDevices={listableTableDevices}
                />
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  variant="outlined"
                  label={intl.formatMessage({ id: 'qr_codes.table_number' })}
                  onChange={searchTables}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </QrCodesTableActions>
          </Grid>
        </Grid>
        <Grid item xs={12} lg>
          <DataGrid
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            slots={{ loadingOverlay: CustomLoadingOverlay, noRowsOverlay: CustomNoRowsOverlay }}
            loading={isTableDevicesLoading}
            autoHeight
            columns={QRCodesDataGridColumnsDefinition(handleOpenQRCodePreviewerDialog)}
            disableColumnMenu
            onCellClick={handleOnCellClick}
            rows={listableTableDevices}
            sortingOrder={['asc', 'desc']}
          />
        </Grid>
      </Grid>

      {previewedTable && (
        <QRCodePreviewerDialog
          previewedTable={previewedTable}
          isOpen={openQRCodePreviewerDialog}
          handleClose={handleCloseQRCodePreviewerDialog}
        />
      )}
    </>
  );
}

export default QRCodes;
